import React, { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router";
import { BannerBox } from "../../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../../assets/StyleComponents/FontSize/Heading.style";
import SelectDropDownComponent, {
  CustomOptionsForCurrency,
  DropdownIndicator,
  MyOptions
} from "../../../../components/SelectDropDown/selectDropDown";
import { WalletContext } from "../../../../contexts/WalletPage/BalancePage/wallet";
import { DepositContext } from "../../../../contexts/WalletPage/DepositPage/depositContext";
import { getCoinNetworks, getBeneficiary, getFqa, getImportantPoints } from "../../../../lib/api";
import { formatAPIErrors, numberWithCommas } from "../../../../utils/general";
import { formatNumbers } from "../../../../utils/helpers";
import { Link } from "react-router-dom";
import { ButtonComponent } from "../../../../assets/StyleComponents/Button.style";
import NonFiatAddressComponent from "../Web/nonFiatAddress";
import FiatAddressComponent from "../Web/faitAddress";
import { showErrorMsg } from "../../../../utils/notification";
import { Label } from "../../../../assets/StyleComponents/FontSize/para.style";
import {
  AddBeneficiary,
  Enable2FA,
  GetKycVerified,
  SuspensionAlert
} from "../../../../components/Alert";
import Select from "react-select";
import { CustomOptionsForBeneficiary } from "../../../../components/ReusedComponent/SelectReused";
import { reactDropdownStyles, selectProviderCustomOptions } from "../../../../utils";
import LcxPayComponent from "../Web/LcxPayComponent";
import BlincOptionsComponent from "../Web/BlincOptions";

const formattedArrayNetwork = (array) => {
  return array.map((item) => {
    return {
      id: item,
      label: item.label,
      value: item.value
    };
  });
};

const WithdrawalFormComponent = () => {
  const [selectedBeneficiary, setSelectedBeneficiary] = useState("");
  const userData = useSelector((state) => state.dashboard.userData);

  let kycStatus = useMemo(
    () => (userData && userData.KYC && userData.KYC.STATUS ? userData.KYC.STATUS : "LEVEL0"),
    [userData.KYC.STATUS]
  );
  let status2fa = useMemo(
    () =>
      userData && userData.twoFactorAuth && userData.twoFactorAuth.status
        ? userData.twoFactorAuth.status
        : false,
    [userData.twoFactorAuth.status]
  );

  let { exchWallet } = useContext(WalletContext);

  let {
    currencyList,
    setCurrencyList,
    setSelectedNetwork,
    setSelectedProvider,
    selectedNetwork,
    selectedCoin,
    setNetworkList,
    networkList,
    callGetWithdrawal,
    coinChanged,
    beneficaryList,
    setBeneficaryList,
    setSelectedCoin,
    setFqa,
    setIsFqaLoading,
    setImportantPoint,
    setLoadingImportantPoint,
    initialWithdrawalLoad,
    isCardSelected,
    setIsCardSelected
    // selectedProvider
  } = useContext(DepositContext);
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [show2fa, setShow2fa] = useState(false);
  // eslint-disable-next-line no-unused-vars
  let optionList = useMemo(() => setCurrencyList(exchWallet.data), [exchWallet]);
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);

  useEffect(() => {
    return () => {
      setSelectedCoin("");
      setSelectedNetwork("");
      setSelectedProvider("");
      setIsCardSelected(false);
    };
  }, []);

  // Condition to handle the seletion of CARD
  useEffect(() => {
    if (isCardSelected && !exchWallet) {
      setIsCardSelected(false);
      return;
    }
    if (isCardSelected) {
      const coinData = exchWallet.data.find((data) => data.coin === "BTC");
      onCoinChangeHandler(coinData);
    }
  }, [isCardSelected]);

  useEffect(() => {
    if (
      networkList &&
      networkList.length > 0 &&
      isCardSelected &&
      selectedCoin &&
      selectedCoin.coin === "BTC" &&
      userData.status !== "RESTRICTED" &&
      !(kycStatus === "LEVEL0" || kycStatus === "LEVEL1")
    ) {
      const networkData = networkList.find((data) => data?.label === "Lightning");
      handleNetworkCurrency(networkData);
      setIsCardSelected(false);
    }
  }, [networkList]);

  const onCoinChangeHandler = (coinData) => {
    getNetworks(coinData);
    coinChanged(coinData);
    const state = { ...history?.location?.state };
    delete state.deleted;
    history.replace({ ...history?.state, state });
  };

  const handleNetworkCurrency = (network) => {
    setSelectedBeneficiary("");
    setSelectedNetwork(network?.id);
    if (selectedCoin?.coinType === "fiat_token") {
      getUserBeneficiaries();
    }
  };

  const getUserBeneficiaries = () => {
    getBeneficiary()
      .then((res) => {
        let data = res?.data?.data || [];
        let beneficiaries = data.map((item, i) => {
          return { id: i, label: item?.bankName, value: item };
        });
        setBeneficaryList(beneficiaries);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      });
  };

  const getNetworks = (coin) => {
    getCoinNetworks({ coin: coin.coin, ttype: "WITHDRAWAL" })
      .then((res) => {
        let data = res && res.data.data;
        setNetworkList(
          data.networks.map((val, i) => {
            return {
              id: val.id,
              label: val.name,
              value: val.name,
              fee: val.fee ? val.fee : "",
              max: val.max ? val.max : "",
              min: val.min ? val.min : "",
              supports: val?.supports || ""
            };
          })
        );
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      });
  };

  useEffect(() => {
    if (selectedCoin !== "" && selectedCoin.coinType === "fiat_token") {
      if (beneficaryList && beneficaryList.length === 0) {
        setShowModal(true);
      }
    }
  }, [beneficaryList]);

  useEffect(() => {
    if (selectedCoin !== "") {
      if (kycStatus === "LEVEL0" || kycStatus === "LEVEL1") {
        setShowModal(true);
      } else if (kycStatus === "LEVEL2" && !status2fa) {
        setShow2fa(true);
      }
      if (history.location.state?.state) {
        let coinValue = history.location.state?.state?.getValue;
        getNetworks(coinValue);
      }
      let data2 = {
        type: "WITHDRAWAL",
        key: selectedCoin?.coin || "",
        network: selectedNetwork?.label ? selectedNetwork?.label : selectedCoin?.coin,
        provider: selectedNetwork?.label ? selectedNetwork?.label : selectedCoin?.coin
      };
      let data = {
        type: "WITHDRAWAL",
        key: selectedNetwork?.label === "LCX Pay" ? "LCXPAY" : selectedCoin?.coin,
        network: selectedNetwork?.label ? selectedNetwork?.label : selectedCoin?.coin,
        provider: selectedNetwork?.label ? selectedNetwork?.label : selectedCoin?.coin
      };

      setLoadingImportantPoint(true);
      setIsFqaLoading(true);
      getFqa(data)
        .then((res) => {
          if (res.status === 200) {
            setFqa(res.data.data);
            setIsFqaLoading(false);
          }
        })
        .catch((err) => {
          setIsFqaLoading(false);
          showErrorMsg(formatAPIErrors(err), { autoClose: 7000 });
        });

      getImportantPoints(data2)
        .then((res) => {
          if (res.status === 200) {
            setImportantPoint(res.data.data);
            setLoadingImportantPoint(false);
          }
        })
        .catch((err) => {
          setLoadingImportantPoint(false);
          showErrorMsg(formatAPIErrors(err), { autoClose: 7000 });
        });
      let param = {
        coin: selectedCoin?.coin || "",
        page: 0
      };
      if (!initialWithdrawalLoad) callGetWithdrawal(param);
    } else {
      if (showModal) {
        setShowModal(false);
      }
    }
  }, [selectedCoin, selectedNetwork]);

  const onCloseModal = () => {
    setShow2fa(false);
    setShowModal(false);
    setSelectedNetwork("");
  };
  const onCloseModal2 = () => {
    setShow2fa(false);
    setShowModal(false);
    setSelectedNetwork("");
    setSelectedCoin("");
  };
  const checkKycStatus = kycStatus === "LEVEL0" || kycStatus === "LEVEL1" ? true : false;

  return (
    <BannerBox mt="34px" mtMd="24px" width={"85%"} widthLg={"100%"} weight="300">
      <Label htmlFor="Network" txtGrey mb="0px" size="14px" width="100%">
        Choose Asset
      </Label>

      <SelectDropDownComponent
        dataComponent={{
          DropdownIndicator,
          Option: MyOptions
        }}
        name="selectCurrency"
        style={{ fontWeight: 300 }}
        placeholder="Select Asset"
        optionList={currencyList}
        selected_coin={selectedCoin}
        chooseLabel={CustomOptionsForCurrency}
        value={selectedCoin.coin ? { label: selectedCoin?.coin, options: selectedCoin.coin } : ""}
        defaultValue={
          selectedCoin.coin ? { label: selectedCoin?.coin, options: selectedCoin.coin } : ""
        }
        handlePairName={onCoinChangeHandler}
      />

      {selectedCoin !== "" && !selectedCoin.enableWithdrawals && (
        <SuspensionAlert type="Withdrawal" coin={selectedCoin.coin} />
      )}

      {selectedCoin !== "" && selectedCoin.enableWithdrawals && (
        <>
          {selectedCoin !== "" && selectedCoin.coin && (
            <>
              <BannerHeading txtGrey size="14px" mt={"6px"} sizeMd="12px">
                Main balance:{" "}
                <b>
                  {`${numberWithCommas(formatNumbers(selectedCoin?.balance?.freeBalance || 0))} ${
                    selectedCoin.coin
                  } `}
                </b>
                {selectedCoin?.withdrawalFeeCoin !== selectedCoin?.coin && (
                  <b>
                    {`| ${numberWithCommas(
                      formatNumbers(
                        exchWallet?.data?.find(
                          (data) => data.coin === selectedCoin?.withdrawalFeeCoin
                        )?.balance?.freeBalance || 0
                      )
                    )} ${selectedCoin.withdrawalFeeCoin}`}
                  </b>
                )}
              </BannerHeading>
            </>
          )}

          {selectedCoin && checkKycStatus ? (
            <GetKycVerified open={showModal} onClose={onCloseModal} />
          ) : show2fa ? (
            <Enable2FA onClose={onCloseModal2} />
          ) : (
            status2fa &&
            selectedCoin && (
              <BannerBox mt="15px">
                <Label htmlFor="Network" txtGrey size="14px" width="100%" mb="4px">
                  Choose {selectedCoin.coinType === "fiat_token" ? " Provider" : "Network"}
                </Label>

                <Select
                  name="selectNetwork"
                  placeholder={
                    selectedCoin.coinType === "fiat_token" ? "Select Provider" : "Select Network"
                  }
                  components={{ DropdownIndicator }}
                  isSearchable={true}
                  formatOptionLabel={(vals) =>
                    selectProviderCustomOptions({ ...vals, isDarkTheme })
                  }
                  onChange={handleNetworkCurrency}
                  styles={reactDropdownStyles({ isDarkTheme, borderBottom: true, height: "" })}
                  options={formattedArrayNetwork(networkList)}
                  value={
                    selectedNetwork && selectedNetwork !== "" && selectedNetwork?.value
                      ? { label: selectedNetwork?.value, options: selectedNetwork?.value }
                      : ""
                  }
                  minMenuHeight={200}
                  maxMenuHeight={200}
                  menuPlacement="auto"
                />
              </BannerBox>
            )
          )}

          {selectedCoin &&
            selectedCoin.coinType === "fiat_token" &&
            selectedNetwork &&
            selectedNetwork.label &&
            selectedNetwork?.label !== "LCX Pay" &&
            selectedNetwork?.label !== "BLINC" && (
              <Fragment>
                {checkKycStatus ? (
                  <GetKycVerified open={showModal} onClose={onCloseModal} />
                ) : show2fa ? (
                  <Enable2FA onClose={onCloseModal} />
                ) : beneficaryList && beneficaryList.length === 0 ? (
                  <Fragment>{showModal && <AddBeneficiary onClose={onCloseModal} />}</Fragment>
                ) : (
                  <>
                    <Label htmlFor="Network" txtGrey size="14px" width="100%" mt="16px" mb="4px">
                      Choose Bank Name
                    </Label>

                    <BannerBox
                      display="flex"
                      alignItems="center"
                      justifyContent="between"
                      className="d-flex align-items-center justify-content-between"
                    >
                      <BannerBox width="75%" height="45px" className="wth-per-75 hght-45">
                        <Select
                          name="selectBeneficiary"
                          placeholder="Select Bank"
                          components={{ DropdownIndicator }}
                          isSearchable={false}
                          formatOptionLabel={CustomOptionsForBeneficiary}
                          onChange={(val) => setSelectedBeneficiary(val)}
                          styles={reactDropdownStyles({
                            isDarkTheme,
                            borderBottom: true,
                            height: "48px"
                          })}
                          options={formattedArrayNetwork(beneficaryList)}
                          value={selectedBeneficiary}
                          minMenuHeight={200}
                          maxMenuHeight={200}
                          menuPlacement="auto"
                        />
                      </BannerBox>

                      <BannerBox
                        width="25%"
                        ml="16px"
                        className="wth-per-25 ml-3"
                        textAlignMd="right"
                      >
                        <Link to="/wallet/bank_accounts">
                          <ButtonComponent
                            OutlinePrimary
                            btnHeight={"48px"}
                            width={"96px"}
                            widthMd={"80px"}
                          >
                            Manage
                          </ButtonComponent>
                        </Link>
                      </BannerBox>
                    </BannerBox>
                  </>
                )}
              </Fragment>
            )}

          {selectedNetwork && selectedNetwork.label && selectedNetwork.label === "LCX Pay" && (
            <LcxPayComponent
              kycStatus={kycStatus}
              show2fa={show2fa}
              onCloseModal={onCloseModal}
              showModa={showModal}
            />
          )}
          {selectedNetwork && selectedNetwork.label && selectedNetwork.label === "BLINC" && (
            <BlincOptionsComponent />
          )}
          {selectedCoin?.coinType !== "fiat_token" &&
            selectedNetwork &&
            selectedNetwork.label &&
            selectedNetwork.label !== "LCX Pay" &&
            selectedNetwork.label !== "BLINC" && <NonFiatAddressComponent />}

          {selectedCoin?.coinType === "fiat_token" &&
            selectedNetwork &&
            selectedNetwork.label &&
            selectedBeneficiary &&
            selectedNetwork.label !== "LCX Pay" &&
            selectedNetwork.label !== "BLINC" && (
              <FiatAddressComponent selectedBeneficiary={selectedBeneficiary} />
            )}
        </>
      )}
    </BannerBox>
  );
};

export default withRouter(WithdrawalFormComponent);
// (WithdrawalModal
