/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import styled, { css } from "styled-components";
import { dFlexJustifyContentBetween } from "./GlobalStyle/GlobalStyles/GlobalStyle.style";
import { MarginStyle } from "./Reusable/Margin.style";
import { PaddingStyle } from "./Reusable/Padding.style";
import { DisplayStyle } from "./Reusable/Display.style";

const BannerSection = styled.section`
  margin: ${(props) => props.margin || 0};
  ${(props) => props.width && `width: ${props.width}`};
  ${(props) => props.position && `position: ${props.position}`};
  ${(props) => props.radius && `border-radius: ${props.radius || "5px"}`};
  ${(props) => props.border && `border: ${props.border}`};
  ${(props) => props.top && `top: ${props.top}`};
  ${(props) => props.left && `left: ${props.left}`};
  ${(props) => props.bottom && `bottom: ${props.bottom}`};
  ${(props) => props.right && `right: ${props.right}`};
  ${(props) => props.zIndex && `z-index: ${props.zIndex}`};
  ${(props) => props.minHeight && `min-height: ${props.minHeight}`};
  ${(props) => props.height && `height: ${props.height}`};
  ${(props) => props.background && `background: ${props.background}`};
  ${(props) => props.bgImg && `background-image: ${props.bgImg}`};
  ${(props) => props.bckOrigin && `background-origin: ${props.bckOrigin}`};
  ${(props) => props.bckClip && `background-clip: ${props.bckClip}`};
  ${(props) => props.paddTop && `padding-top: ${props.paddTop}`};
  ${(props) => props.paddBottom && `padding-bottom: ${props.paddBottom}`};
  ${(props) => props.paddLeft && `padding-left: ${props.paddLeft} !important`};
  ${(props) => props.paddRight && `padding-right: ${props.paddRight}`};
  ${(props) => props.padd && `padding: ${props.padd}`};
  ${(props) => props.display && `display: ${props.display}`};
  ${(props) => props.direction && `flex-direction: ${props.direction}`};
  ${(props) => props.justify && `justify-content: ${props.justify}`};
  ${(props) => props.alignItem && `align-items: ${props.alignItem}`};
  ${(props) => props.gap && `gap: ${props.gap}`};
  ${(props) => props.mt && `margin-top: ${props.mt}`};
  ${(props) => props.mb && `margin-bottom: ${props.mb}`};
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}`};
  ${(props) => props.maxHeight && `max-height: ${props.maxHeight}`};
  ${(props) => props.BorderTop && `border-top: ${props.BorderTop}`};
  ${(props) => props.verticalAlign && `vertical-align: ${props.verticalAlign}`};
  ${(props) => props.flex && `flex: ${props.flex}`};
  ${(props) => props.order && `order: ${props.order}`};
  ${(props) => props.GridCols && `grid-template-columns: ${props.GridCols}`};
  ${(props) => props.GridRows && `grid-template-rows: ${props.GridRows}`};
  ${(props) => props.Gridgap && `row-gap: ${props.Gridgap}`};
  ${(props) => props.padding && `padding: ${props.padding}`};
  ${(props) => props.overflowY && `overflow-y: ${props.overflowY}`};

  ${(props) =>
    props.SectionWhite &&
    css`
      background: ${({ theme }) => theme.level1Card} !important;
    `}
  ${(props) =>
    props.TutorialBg &&
    css`
      background: ${({ theme }) => theme.TutorialColor} !important;
    `}
  ${(props) =>
    props.GreyBg &&
    css`
      background: ${({ theme }) => theme.level3NotClicked} !important;
    `}
  ${(props) =>
    props.BeginerBg &&
    css`
      background: ${({ theme }) => theme.TutorialBg} !important;
    `}
    ${(props) =>
    props.GridBorder &&
    css`
      border: 1px solid ${({ theme }) => theme.DropdownBorder} !important;
    `}

  ${(props) =>
    props.SectionWhite2 &&
    css`
      background: ${({ theme }) => theme.mainBackground} !important;
    `}
  ${(props) =>
    props.MainPageSectionBg &&
    css`
      background: ${({ theme }) => theme.mainBackgroundPageBg} !important;
    `}
  
  ${(props) =>
    props.SectionSky &&
    css`
      background: ${({ theme }) => theme.level1CardSky} !important;
    `}
  
  ${(props) =>
    props.SectionGrey &&
    css`
      background: ${({ theme }) => theme.mainBackground} !important;
    `}
  ${(props) =>
    props.ExchangemarketWidth &&
    css`
      max-width: 1124px !important;
      width: 91% !important;
      height: 776px !important;
      border-radius: 30px;
      overflow: hidden !important;
      box-shadow: 0 3px 99px 0 rgba(4, 112, 225, 0.1);
      @media (max-width: 1024px) {
        border-radius: 20px;
        width: 95% !important;
        max-width: 992px !important;
      }
      @media (max-width: 767px) {
        border-radius: 10px;
      }
    `}

  ${(props) =>
    props.SectionWhite2 &&
    css`
      background: ${({ theme }) => theme.mainBackground} !important;
    `}
  ${(props) =>
    props.MainPageSectionBg &&
    css`
      background: ${({ theme }) => theme.mainBackgroundPageBg} !important;
    `}
  ${(props) =>
    props.SettingBorderBottom &&
    css`
      border-bottom: 2px solid ${({ theme }) => theme.DropdownBorder};
    `}
  ${(props) =>
    props.SectionSky &&
    css`
      background: ${({ theme }) => theme.level1CardSky} !important;
    `}
  
  ${(props) =>
    props.SectionGrey &&
    css`
      background: ${({ theme }) => theme.mainBackground} !important;
    `}
  ${(props) =>
    props.ExchangemarketWidth &&
    css`
      max-width: 1124px !important;
      width: 91% !important;
      height: 776px !important;
      border-radius: 30px;
      overflow: hidden !important;
      box-shadow: 0 3px 99px 0 rgba(4, 112, 225, 0.1);
      @media (max-width: 1024px) {
        border-radius: 20px;
        width: 95% !important;
        max-width: 992px !important;
      }
      @media (max-width: 767px) {
        border-radius: 10px;
      }
    `}
  
  ${(props) =>
    props.SectionWhite &&
    css`
      background: ${({ theme }) => theme.level1Card} !important;
    `}

  ${(props) =>
    props.TutorialBg &&
    css`
      background: ${({ theme }) => theme.TutorialColor} !important;
    `}
  ${(props) =>
    props.GreyBg &&
    css`
      background: ${({ theme }) => theme.level3NotClicked} !important;
    `}
  ${(props) =>
    props.BeginerBg &&
    css`
      background: ${({ theme }) => theme.TutorialBg} !important;
    `}

  ${(props) =>
    props.SectionWhite2 &&
    css`
      background: ${({ theme }) => theme.mainBackground} !important;
    `}
  ${(props) =>
    props.FAQBorderKYC &&
    css`
      background: ${({ theme }) => theme.level1Card} !important;
      border: 1px solid ${({ theme }) => theme.borderColor} !important;
    `}
  ${(props) =>
    props.MainPageSectionBg &&
    css`
      background: ${({ theme }) => theme.mainBackgroundPageBg} !important;
    `}
  ${(props) =>
    props.SettingBorderBottom &&
    css`
      border-bottom: 2px solid ${({ theme }) => theme.DropdownBorder};
    `}
  ${(props) =>
    props.SectionSky &&
    css`
      background: ${({ theme }) => theme.level1CardSky} !important;
    `}
  
  ${(props) =>
    props.SectionGrey &&
    css`
      background: ${({ theme }) => theme.mainBackground} !important;
    `}
  ${(props) =>
    props.ExchangemarketWidth &&
    css`
      max-width: 1124px !important;
      width: 91% !important;
      height: 776px !important;
      border-radius: 30px;
      overflow: hidden !important;
      box-shadow: 0 3px 99px 0 rgba(4, 112, 225, 0.1);
      @media (max-width: 1024px) {
        border-radius: 20px;
        width: 95% !important;
        max-width: 992px !important;
      }
      @media (max-width: 767px) {
        border-radius: 10px;
      }
    `}

@media(max-width: 1024px) {
    ${(props) => props.paddLg && `padding: ${props.paddLg}`};
    ${(props) => props.marginLg && `margin: ${props.marginLg}`};
    ${(props) => props.mbLg && `margin-bottom: ${props.mbLg}`};
    ${(props) => props.radiusLg && `border-radius: ${props.radiusLg || "5px"}`};
    ${(props) => props.paddTopLg && `padding-top: ${props.paddTopLg} !important`};
    ${(props) => props.gapLg && `gap: ${props.gapLg}`};
    ${(props) => props.paddBottomLg && `padding-bottom: ${props.paddBottomLg}`};
    ${(props) => props.backgroundLg && `background: ${props.backgroundLg}`};
    ${(props) => props.displayLg && `display: ${props.displayLg}`};
    ${(props) => props.widthLg && `width: ${props.widthLg}`};
    ${(props) => props.directionLg && `flex-direction: ${props.directionLg}`};
    ${(props) => props.GridColsLg && `grid-template-columns: ${props.GridColsLg}`};
    ${(props) => props.GridRowsLg && `grid-template-rows: ${props.GridRowsLg}`};
    ${(props) => props.GridgapLg && `row-gap: ${props.GridgapLg}`};
  }

  @media (max-width: 767px) {
    ${(props) => props.paddMd && `padding: ${props.paddMd}`};
    ${(props) => props.directionMd && `flex-direction: ${props.directionMd}`};
    ${(props) => props.marginMd && `margin: ${props.marginMd}`};
    ${(props) => props.mbMd && `margin-bottom: ${props.mbMd}`};
    ${(props) => props.radiusMd && `border-radius: ${props.radiusMd || "5px"}`};
    ${(props) => props.paddTopMd && `padding-top: ${props.paddTopMd} !important`};
    ${(props) => props.gapMd && `gap: ${props.gapMd}`};
    ${(props) => props.paddBottomMd && `padding-bottom: ${props.paddBottomMd}`};
    ${(props) => props.backgroundMd && `background: ${props.backgroundMd}`};
    ${(props) => props.displayMd && `display: ${props.displayMd}`};
    ${(props) => props.GridColsMd && `grid-template-columns: ${props.GridColsMd}`};
    ${(props) => props.GridRowsMd && `grid-template-rows: ${props.GridRowsMd}`};
    ${(props) => props.GridgapMd && `row-gap: ${props.GridgapMd}`};
    ${(props) => props.widthMd && `width: ${props.widthMd}`};
  }
  @media (max-width: 380px) {
    ${(props) => props.paddSm && `padding: ${props.paddSm} !important`};
  }
`;

const MainElement = styled.main`
  ${(props) => props.width && `width: ${props.width}`};
  ${(props) => props.position && `position: ${props.position}`};
  ${(props) => props.radius && `border-radius: ${props.radius || "5px"}`};
  ${(props) => props.border && `border: ${props.border}`};
  ${(props) => props.borderTop && `border-top: ${props.borderTop}`};
  ${(props) => props.borderBottom && `border-bottom: ${props.borderBottom}`};
  ${(props) => props.borderLeft && `border-left: ${props.borderLeft}`};
  ${(props) => props.borderRight && `border-right: ${props.borderright}`};
  ${(props) => props.top && `top: ${props.top}`};
  ${(props) => props.left && `left: ${props.left}`};
  ${(props) => props.bottom && `bottom: ${props.bottom}`};
  ${(props) => props.right && `right: ${props.right}`};
  ${(props) => props.zIndex && `z-index: ${props.zIndex}`};
  ${(props) => props.minHeight && `min-height: ${props.minHeight}`};
  ${(props) => props.height && `height: ${props.height}`};
  ${(props) => props.background && `background: ${props.background}`};
  ${(props) => props.bgImg && `background-image: ${props.bgImg}`};
  ${(props) => props.bckOrigin && `background-origin: ${props.bckOrigin}`};
  ${(props) => props.bckClip && `background-clip: ${props.bckClip}`};
  ${(props) => props.paddingTop && `padding-top: ${props.paddingTop}`};
  ${(props) => props.paddingBottom && `padding-bottom: ${props.paddingBottom}`};
  ${(props) => props.paddingLeft && `padding-left: ${props.paddingLeft} !important`};
  ${(props) => props.paddingRight && `padding-right: ${props.paddingRight}`};
  ${(props) => props.padding && `padding: ${props.padding}`};
  ${(props) => props.display && `display: ${props.display}`};
  ${(props) => props.direction && `flex-direction: ${props.direction}`};
  ${(props) => props.justify && `justify-content: ${props.justify}`};
  ${(props) => props.alignItem && `align-items: ${props.alignItem}`};
  ${(props) => props.gap && `gap: ${props.gap}`};
  ${(props) => props.mt && `margin-top: ${props.mt}`};
  ${(props) => props.mb && `margin-bottom: ${props.mb}`} !important;
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}`};
  ${(props) => props.margin && `margin: ${props.margin}`};
  ${(props) => props.overflow && `overflow: ${props.overflow}`};

  transition: all 0.5s ease-in;
  ${MarginStyle}
  ${PaddingStyle}
  
  ${(props) =>
    props.SectionWhite &&
    css`
      background: ${({ theme }) => theme.level1Card} !important;
    `}
  ${(props) =>
    props.MainBgLandingPage &&
    css`
      background: ${({ theme }) => theme.mainBackgroundPageBg} !important;
    `}
  
  ${(props) =>
    props.SectionWhite2 &&
    css`
      background: ${({ theme }) => theme.mainBackground} !important;
    `}
  ${(props) =>
    props.MainPageSectionBg &&
    css`
      background: ${({ theme }) => theme.mainBackgroundPageBg} !important;
    `}
  
  ${(props) =>
    props.SectionSky &&
    css`
      background: ${({ theme }) => theme.level1CardSky} !important;
    `}
  
  ${(props) =>
    props.SectionGrey &&
    css`
      background: ${({ theme }) => theme.mainBackground} !important;
    `}

  ${(props) =>
    props.ExchangeContainer &&
    css`
      width: 100%;
      max-width: 1124px;
      box-sizing: border-box;
    `}

    ${(props) =>
    props.ExchangeContainer &&
    css`
      width: 100%;
      max-width: 1124px;
      box-sizing: border-box;
      min-height: calc(100vh - 60px);

      @media (max-width: 1200px) {
        width: 95% !important;
      }

      @media (max-width: 1024px) {
        width: 95% !important;
        max-width: 992px !important;
      }

      @media (max-width: 767px) {
        width: 95% !important;
      }
    `}

  @media(min-width: 1024px) {
    ${(props) => props.minHeightXL && `min-height: ${props.minHeightXL}`} !important;
  }
  @media (max-width: 1024px) {
    ${(props) => props.paddingLg && `padding: ${props.paddingLg}`};
    ${(props) => props.marginLg && `margin: ${props.marginLg}`};
    ${(props) => props.radiusLg && `border-radius: ${props.radiusLg || "5px"}`};
    ${(props) => props.paddTopLg && `padding-top: ${props.paddTopLg} !important`};
    ${(props) => props.gapLg && `gap: ${props.gapLg}`};
    ${(props) => props.paddingBottomLg && `padding-bottom: ${props.paddingBottomLg}`};
    ${(props) => props.backgroundLg && `background: ${props.backgroundLg}`};
    ${(props) => props.mbLg && `margin-bottom: ${props.mb}`} !important;
    ${(props) => props.overflowLg && `overflow: ${props.overflowLg}`};
    ${(props) => props.directionLg && `flex-direction: ${props.directionLg}`};
  }

  @media (max-width: 767px) {
    ${(props) => props.paddingMd && `padding: ${props.paddingMd}`};
    ${(props) => props.marginMd && `margin: ${props.marginMd}`};
    ${(props) => props.radiusMd && `border-radius: ${props.radiusMd || "5px"}`};
    ${(props) => props.paddTopMd && `padding-top: ${props.paddingTopMd} !important`};
    ${(props) => props.gapMd && `gap: ${props.gapMd}`};
    ${(props) => props.paddingBottomMd && `padding-bottom: ${props.paddBottomMd}`};
    ${(props) => props.backgroundMd && `background: ${props.backgroundMd}`};
    ${(props) => props.mbMd && `margin-bottom: ${props.mb}`} !important;
    ${(props) => props.overflowMd && `overflow: ${props.overflowMd}`};
  }
  @media (max-width: 380px) {
    ${(props) => props.paddSm && `padding: ${props.paddSm} !important`};
  }
`;

const BannerAside = styled.aside`
  ${(props) => props.width && `width: ${props.width}`};
  ${(props) => props.position && `position: ${props.position}`};
  ${(props) => props.radius && `border-radius: ${props.radius || "5px"}`};
  ${(props) => props.border && `border: ${props.border}`};
  ${(props) => props.top && `top: ${props.top}`};
  ${(props) => props.left && `left: ${props.left}`};
  ${(props) => props.bottom && `bottom: ${props.bottom}`};
  ${(props) => props.right && `right: ${props.right}`};
  ${(props) => props.zIndex && `z-index: ${props.zIndex}`};
  ${(props) => props.minHeight && `min-height: ${props.minHeight}`};
  ${(props) => props.height && `height: ${props.height}`};
  ${(props) => props.background && `background: ${props.background}`};
  ${(props) => props.bgImg && `background-image: ${props.bgImg}`};
  ${(props) => props.bckOrigin && `background-origin: ${props.bckOrigin}`};
  ${(props) => props.bckClip && `background-clip: ${props.bckClip}`};
  ${(props) => props.paddTop && `padding-top: ${props.paddTop}`};
  ${(props) => props.paddBottom && `padding-bottom: ${props.paddBottom}`};
  ${(props) => props.paddLeft && `padding-left: ${props.paddLeft} !important`};
  ${(props) => props.paddRight && `padding-right: ${props.paddRight}`};
  ${(props) => props.padd && `padding: ${props.padd}`};
  ${(props) => props.display && `display: ${props.display}`};
  ${(props) => props.direction && `flex-direction: ${props.direction}`};
  ${(props) => props.justify && `justify-content: ${props.justify}`};
  ${(props) => props.alignItem && `align-items: ${props.alignItem}`};
  ${(props) => props.gap && `gap: ${props.gap}`};
  ${(props) => props.mt && `margin-top: ${props.mt}`};
  ${(props) => props.mb && `margin-bottom: ${props.mb}`} !important;
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}`};
  ${(props) => props.margin && `margin: ${props.margin}`};
  ${(props) => props.top && `top: ${props.top}`};

  ${(props) =>
    props.StickyLcxToken &&
    css`
      position: sticky;
      top: 0;
    `}

  @media(max-width: 1024px) {

  ${(props) =>
    props.SectionWhite &&
    css`
      background: ${({ theme }) => theme.level1Card} !important;
    `}
  ${(props) =>
    props.MainBgLandingPage &&
    css`
      background: ${({ theme }) => theme.mainBackgroundPageBg} !important;
    `}
  
  ${(props) =>
    props.SectionWhite2 &&
    css`
      background: ${({ theme }) => theme.mainBackground} !important;
    `}
  ${(props) =>
    props.MainPageSectionBg &&
    css`
      background: ${({ theme }) => theme.mainBackgroundPageBg} !important;
    `}
  
  ${(props) =>
    props.SectionSky &&
    css`
      background: ${({ theme }) => theme.level1CardSky} !important;
    `}
  
  ${(props) =>
    props.SectionGrey &&
    css`
      background: ${({ theme }) => theme.mainBackground} !important;
    `}

    ${(props) =>
      props.ExchangeContainer &&
      css`
        width: 100%;
        max-width: 1124px;
        box-sizing: border-box;

        @media (max-width: 1200px) {
          width: 95% !important;
        }

        @media (max-width: 1024px) {
          width: 95% !important;
          max-width: 992px !important;
        }

        @media (max-width: 767px) {
          width: 95% !important;
        }
      `}

  @media (max-width: 1024px) {
    ${(props) => props.paddLg && `padding: ${props.paddLg}`};
    ${(props) => props.marginLg && `margin: ${props.marginLg}`};
    ${(props) => props.radiusLg && `border-radius: ${props.radiusLg || "5px"}`};
    ${(props) => props.paddTopLg && `padding-top: ${props.paddTopLg} !important`};
    ${(props) => props.gapLg && `gap: ${props.gapLg}`};
    ${(props) => props.paddBottomLg && `padding-bottom: ${props.paddBottomLg}`};
    ${(props) => props.backgroundLg && `background: ${props.backgroundLg}`};
    ${(props) => props.mbLg && `margin-bottom: ${props.mb}`} !important;
  }

  @media (max-width: 767px) {
    ${(props) => props.paddMd && `padding: ${props.paddMd}`};
    ${(props) => props.marginMd && `margin: ${props.marginMd}`};
    ${(props) => props.radiusMd && `border-radius: ${props.radiusMd || "5px"}`};
    ${(props) => props.paddTopMd && `padding-top: ${props.paddTopMd} !important`};
    ${(props) => props.gapMd && `gap: ${props.gapMd}`};
    ${(props) => props.paddBottomMd && `padding-bottom: ${props.paddBottomMd}`};
    ${(props) => props.backgroundMd && `background: ${props.backgroundMd}`};
    ${(props) => props.mbMd && `margin-bottom: ${props.mb}`} !important;
  }
  @media (max-width: 380px) {
    ${(props) => props.paddSm && `padding: ${props.paddSm} !important`};
  }
`;

const BannerHeadingSpan = styled.span`
  ${MarginStyle}
  ${(props) => props.cursorPointer && "cursor: pointer"};
  ${(props) => props.color && `color: ${props.color}`};
  ${(props) => props.bck && `background-color: ${props.bck}`};
  ${(props) => props.height && `height: ${props.height}`};
  ${(props) => props.size && `font-size: ${props.size || "16px"}`};
  ${(props) => props.width && `width: ${props.width}`};
  ${(props) => props.minWidth && `min-width: ${props.minWidth}`};
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}`};
  ${(props) => props.weight && `font-weight: ${props.weight || 300}`};
  ${(props) => props.margin && `margin: ${props.margin || "0"}`};
  ${(props) => props.left && `left: ${props.left}`};
  ${(props) => props.top && `top: ${props.top}`};
  ${(props) => props.right && `right: ${props.right}`};
  ${(props) => props.padding && `padding: ${props.padding}`};
  ${(props) => props.paddingLeft && `padding-left: ${props.paddingLeft}`};
  ${(props) => props.lineHeight && `line-height: ${props.lineHeight}`};
  ${(props) => props.float && `float: ${props.float}`};
  ${(props) => props.shadow && `box-shadow: ${props.shadow}`};
  ${(props) => props.position && `position: ${props.position}`};
  ${(props) => props.bottom && `bottom: ${props.bottom}`};
  ${(props) => props.top && `top: ${props.top}`};
  ${(props) => props.left && `left: ${props.left}`};
  ${(props) => props.right && `right: ${props.right}`};
  ${(props) => props.textAlign && `text-align: ${props.textAlign}`};
  ${(props) => props.spacing && `letter-spacing: ${props.spacing}`};
  ${(props) => props.BorderRadius && `border-radius: ${props.BorderRadius}`};
  ${(props) => props.ml && `margin-left: ${props.ml}`};
  ${(props) => props.mr && `margin-right: ${props.mr}`};
  ${(props) => props.mb && `margin-bottom: ${props.mb}`};
  ${(props) => props.mt && `margin-top: ${props.mt}`};
  ${(props) => props.fontStyle && `font-style: ${props.fontStyle}`};
  ${(props) => props.textTransform && `text-transform: ${props.textTransform}`};
  ${(props) => props.paddingLeft && `padding-left: ${props.paddingLeft}`};
  ${(props) => props.textDecoration && `text-decoration: ${props.textDecoration}`};
  ${(props) => props.display && `display: ${props.display}`};
  ${(props) => props.alignItems && `align-items: ${props.alignItems}`};
  ${(props) => props.zIndex && `z-index: ${props.zIndex}`};
  ${(props) => props.display && `display: ${props.display}!important`};
  ${(props) => props.flex && `flex: ${props.flex}!important`};
  ${(props) => props.direction && `flex-direction: ${props.direction}!important`};
  ${(props) => props.alignItems && `align-items: ${props.alignItems}!important`};
  ${(props) => props.justifyContent && `justify-content: ${props.justifyContent}!important`};
  ${(props) => props.textTransform && `text-transform: ${props.textTransform}!important`};
  ${(props) => props.verticalAlign && `vertical-align: ${props.verticalAlign}`};
  ${(props) => props.bg && `background: ${props.bg}!important`};
  ${(props) => props.opacity && `opacity: ${props.opacity}!important`};

  & img {
    ${(props) => props.width && `width: ${props.width}`};
    ${(props) => props.imgMargin && `margin: ${props.imgMargin}`};
  }

  @media (max-width: 1024px) {
    ${(props) => props.widthLg && `width: ${props.widthLg}`};
    ${(props) => props.minWidthLg && `min-width: ${props.minWidthLg}`};
    ${(props) => props.maxWidthLg && `max-width: ${props.maxWidthLg}`};
    ${(props) => props.marginLg && `margin: ${props.marginLg || "0"}`};
    ${(props) => props.paddingLg && `padding: ${props.paddingLg}`};
    ${(props) => props.sizeLg && `font-size: ${props.sizeLg || "16px"}`};
    ${(props) => props.textAlignLg && `text-align: ${props.textAlignLg}`};
    ${(props) => props.topLg && `top: ${props.topLg}`};
    ${(props) => props.leftLg && `left: ${props.leftLg}`};
    ${(props) => props.rightLg && `right: ${props.rightLg}`};
    ${(props) => props.paddingTopLg && `padding: ${props.paddingTopLg} !important`};
    ${(props) => props.mtLg && `margin-top: ${props.mtLg}`};
    ${(props) => props.mrLg && `margin-right: ${props.mrLg}`};
  }

  @media (max-width: 767px) {
    ${(props) => props.widthMd && `width: ${props.widthMd}`};
    ${(props) => props.heightMd && `height: ${props.heightMd}`};
    ${(props) => props.minWidthMd && `min-width: ${props.minWidthMd}`};
    ${(props) => props.maxWidthMd && `max-width: ${props.maxWidthMd}`};
    ${(props) => props.marginMd && `margin: ${props.marginMd || "0"}`};
    ${(props) => props.paddingMd && `padding: ${props.paddingMd}`};
    ${(props) => props.paddingTopMd && `padding: ${props.paddingTopMd} !important`};
    ${(props) => props.sizeMd && `font-size: ${props.sizeMd || "16px"}`};
    ${(props) => props.textAlignMd && `text-align: ${props.textAlignMd}`};
    ${(props) => props.topMd && `top: ${props.topMd}`};
    ${(props) => props.leftMd && `left: ${props.leftMd}`};
    ${(props) => props.rightMd && `right: ${props.rightMd}`};
    ${(props) => props.mtMd && `margin-top: ${props.mtMd}`};
    ${(props) => props.sizeMd && `font-size: ${props.sizeMd}`};
    ${(props) => props.justifyContentMd && `justify-content: ${props.justifyContentMd}`};
  }

  @media (max-width: 568px) {
    ${(props) => props.widthSm && `width: ${props.widthSm}`};
    ${(props) => props.minWidthSm && `min-width: ${props.minWidthSm}`};
    ${(props) => props.maxWidthSm && `max-width: ${props.maxWidthSm}`};
    ${(props) => props.marginSm && `margin: ${props.marginSm || "0"}`};
    ${(props) => props.paddingSm && `padding: ${props.paddingSm} !important`};
    ${(props) => props.sizeSm && `font-size: ${props.sizeSm || "16px"}`};
    ${(props) => props.textAlignSm && `text-align: ${props.textAlignSm}`};
  }
  @media (max-width: 380px) {
    ${(props) => props.sizeSm && `padding: ${props.sizeSm} !important`};
  }

  ${(props) =>
    props.BodyText &&
    css`
      color: ${({ theme }) => theme.bodyText} !important;
    `}

  ${(props) =>
    props.WarningBodyText &&
    css`
      color: #012243 !important;
    `}
    ${(props) =>
    props.OTPSpan &&
    css`
      position: absolute;
      right: 12px;
      height: 100%;
      font-size: 14px;
      font-weight: bold;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: ${({ theme }) => theme.primary};
      // background-color: transparent;

      &.disabled {
        color: ${({ theme }) => theme.subText} !important;
        cursor: not-allowed !important;
        right: 12px;
      }
    `}
   ${(props) =>
    props.BodyColor &&
    css`
      color: ${(props) => props.color || props.theme.bodyText};
    `};
  ${(props) =>
    props.TabBoxBg &&
    css`
      background: ${(props) => props.theme.NewSpans};
      color: ${(props) => props.theme.bodyText};
    `};
  ${(props) =>
    props.BodyColorBg &&
    css`
      background: ${(props) => props.theme.bodyText};
      color: ${(props) => props.theme.bodyText3};
      display: flex;
      justify-content: center;
    `};
  ${(props) =>
    props.RadioBtnIconBg &&
    css`
      background: ${(props) => props.theme.bgActiveTable};
    `};
  ${(props) =>
    props.RadioBtnIconBgSelected &&
    css`
      background: ${(props) => props.theme.primaryBlue};
    `};
  ${(props) =>
    props.RecommendedKycSelectedBG &&
    css`
      background: transparent;
      border: 1px solid ${(props) => props.theme.primaryBlue};
      color: ${(props) => props.theme.primaryBlue};
    `};
  ${(props) =>
    props.TextTruncate &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `};

  ${(props) =>
    props.headerText &&
    css`
      color: ${({ theme }) => theme.headerText} !important;
    `}
  ${(props) =>
    props.ProgressLine &&
    css`
      height: 19.3px;
      min-width: calc(33% - 50px);
      max-width: 100%;
      border-bottom: 1px solid ${({ theme }) => theme.ProgesssBarSettings};
    `}
  ${(props) =>
    props.ProgressLineActive &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.primaryBlue};
    `}

  ${(props) =>
    props.CardLevel1 &&
    css`
      background: ${({ theme }) => theme.level1Card} !important;
    `}

  ${(props) =>
    props.CardLevel2 &&
    css`
      background: ${({ theme }) => theme.level2Card} !important;
    `}
  ${(props) =>
    props.WalletActiveTab &&
    css`
      color: ${({ theme }) => theme.primary} !important;
      font-weight: 500 !important;
    `}
  
  ${(props) =>
    props.txtGrey &&
    css`
      color: ${(props) => props.theme.subText};
    `};
  ${(props) =>
    props.StatusLineMain &&
    css`
      border-left: 2px dashed ${(props) => props.theme.DropdownBorder};
      height: 60px;
      @media (max-width: 767px) {
        height: 70px;
      }
    `};

  ${(props) =>
    props.txtDanger &&
    css`
      color: ${(props) => props.color || props.theme.danger};
    `};

  ${(props) =>
    props.txtSuccess &&
    css`
      color: ${(props) => props.color || props.theme.success};
    `};

  ${(props) =>
    props.txtPrimary &&
    css`
      color: ${(props) => props.color || props.theme.primary};
    `};
  ${(props) =>
    props.ArrowTopSettings &&
    css`
      position: absolute;
      top: -10px;
      height: 20px;
      width: 20px;
      transform: rotate(45deg);
      z-index: 10;
      border: 1px solid;
      left: 46%;
      border-width: 1px 0 0 1px;
      border-color: ${(props) => props.theme.DropdownBorder};
      background: ${({ theme }) => theme.mainBackground} !important;
    `};
  ${(props) =>
    props.BorderCardSettings &&
    css`
      border-color: ${(props) => props.theme.DropdownBorder};
    `};
  ${(props) =>
    props.BorderCardSettingsRejected &&
    css`
      border-color: ${(props) => props.theme.rejectedRed} !important;
    `};
  ${(props) =>
    props.BorderCardSettingsOrange &&
    css`
      border-color: ${(props) => props.theme.primaryYellow} !important;
    `};
  ${(props) =>
    props.BorderCardSettingsUnderApproval &&
    css`
      border-color: ${(props) => props.theme.primaryButton} !important;
    `};
  ${(props) =>
    props.Tooltip &&
    css`
      display: block;
      background: ${(props) => props.theme.level1Card};
      color: #fff;
      border-radius: 4px;

      &::after {
        content: "";
        position: absolute;
        top: 10%; /* Position below the tooltip */
        right: -6%;
        transform: translateY(-50%);
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent transparent ${(props) => props.theme.TooltipColors};
        filter: drop-shadow(rgba(0, 0, 0, 0.3) 0 2px 10px);
      }
    `};
  ${(props) =>
    props.FlagShapeTc &&
    css`
      padding-top: 2px;
      background: ${(props) => props.theme.primary};
      color: white !important;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-bottom: 8px solid ${(props) => props.theme.level1Card};
        border-left: 44px solid transparent;
        border-right: 44px solid transparent;
      `}
`;

const BannerBox = styled.div`
  .setDecimalsThreeDots .decimalListThreeDot {
    display: none !important;
    transition: all 0.5s linear;
  }

  .setDecimalsThreeDots:hover .decimalListThreeDot {
    display: flex !important;
    top: 27px;
    margin-right: -15px;
  }

  .fInput__form__textAreaInput {
    border: none !important;
    border-radius: 5px !important;
    padding: 0 8px;
    border: 1px solid ${({ theme }) => theme.borderColor} !important;
  }
  .fInput__form__input {
    border: none !important;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid ${({ theme }) => theme.borderColor} !important;
    &.showActive {
      border-bottom: 1px solid ${({ theme }) => theme.primary} !important;
    }
  }

  .fInput__form__input--Disabled {
    color: ${({ theme }) => theme.subText};
  }
  .fInput__form__input--bRed {
    border-color: ${({ theme }) => theme.danger}!important;
    input {
      color: ${({ theme }) => theme.danger}!important;
    }
  }

  ${MarginStyle}
  ${PaddingStyle}
  ${DisplayStyle}

  ${(props) => props.borderLeft && `border-left: ${props.borderLeft}`};
  ${(props) => props.borderRight && `border-right: ${props.borderRight}`};
  ${(props) => props.cursorPointer && `cursor: ${props.cursorPointer}!important`};
  ${(props) => props.background && `background: ${props.background}!important`};
  ${(props) => props.bgPosition && `background-position: ${props.bgPosition}!important`};
  ${(props) => props.bgSize && `background-size: ${props.bgSize}!important`};
  ${(props) => props.display && `display: ${props.display}!important`};
  ${(props) => props.flex && `flex: ${props.flex}!important`};
  ${(props) => props.direction && `flex-direction: ${props.direction}!important`};
  ${(props) => props.alignItems && `align-items: ${props.alignItems}!important`};
  ${(props) => props.alignContent && `align-content: ${props.alignContent}!important`};
  ${(props) => props.justifyContent && `justify-content: ${props.justifyContent}!important`};
  ${(props) => props.flexWrap && `flex-wrap: ${props.flexWrap}`};
  ${(props) => props.flexFill && `flex-fill: ${props.flexFill}!important`};
  ${(props) => props.bck && `background: ${props.bck}`};
  ${(props) =>
    props.border &&
    `border: ${props.border !== true ? props.border : ` 1px solid ${props.theme.borderColor}`}`};
  ${(props) => props.textAlign && `text-align: ${props.textAlign}`};
  ${(props) => props.color && `color: ${props.color}`};
  ${(props) => props.radius && `border-radius: ${props.radius || "10px"}`};
  ${(props) => props.bgImg && `background-image: url(${props.bgImg})`};
  ${(props) => props.bg && `background-color: ${props.bg}`};
  ${(props) => props.size && `font-size: ${props.size}`};
  ${(props) => props.borderWidth && `border-width: ${props.borderWidth} !important`};
  ${(props) => props.shadow && `box-shadow: ${props.shadow}`};
  ${(props) => props.position && `position: ${props.position}`};
  ${(props) => props.BorderRadius && `border-radius: ${props.BorderRadius}`};
  ${(props) => props.BorderRadiusLR && `border-top-left-radius: ${props.BorderRadiusLR}`};
  ${(props) => props.BorderRadiusTR && `border-top-right-radius: ${props.BorderRadiusTR}`};
  ${(props) => props.lineHeight && `line-height: ${props.lineHeight}`};
  ${(props) => props.width && `width: ${props.width}`};
  ${(props) => props.height && `height: ${props.height}`};
  ${(props) => props.maxHeight && `max-height: ${props.maxHeight}`};
  ${(props) => props.minHeight && `min-height: ${props.minHeight}`};
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth} !important`};
  ${(props) => props.minWidth && `min-width: ${props.minWidth} !important`};
  ${(props) => props.minHeight && `min-height: ${props.minHeight}`};
  ${(props) => props.top && `top: ${props.top}`};
  ${(props) => props.bottom && `bottom: ${props.bottom}`};
  ${(props) => props.left && `left: ${props.left}`};
  ${(props) => props.right && `right: ${props.right}`};
  ${(props) => props.zIndex && `z-index: ${props.zIndex}`};
  ${(props) => props.paddingLeft && `padding-left: ${props.paddingLeft}`};
  ${(props) => props.paddingTop && `padding-top: ${props.paddingTop}`};
  ${(props) => props.paddingBottom && `padding-bottom: ${props.paddingBottom}`};
  ${(props) => props.overflowX && `overflow-x: ${props.overflowX}`};
  ${(props) => props.overflow && `overflow: ${props.overflow}`};
  ${(props) => props.overflowY && `overflow-y: ${props.overflowY}`};
  ${(props) =>
    props.topBorder &&
    `border-top: ${
      props.topBorder !== true ? props.topBorder : ` 1px solid ${props.theme.borderColor}!important`
    }`};
  ${(props) =>
    props.leftBorder &&
    `border-left: ${
      props.leftBorder !== true
        ? props.leftBorder
        : ` 1px solid ${props.theme.borderColor}!important`
    }`};
  ${(props) =>
    props.rightBorder &&
    `border-right: ${
      props.rightBorder !== true
        ? props.rightBorder
        : ` 1px solid ${props.theme.borderColor}!important`
    }`};
  ${(props) =>
    props.bottomBorder &&
    `border-bottom: ${
      props.bottomBorder !== true
        ? props.bottomBorder
        : ` 1px solid ${props.theme.borderColor}!important`
    }`};
  ${(props) =>
    props.tradeBottomBorder &&
    `border-bottom: ${
      props.tradeBottomBorder !== true
        ? props.tradeBottomBorder
        : ` 1px solid ${props.theme.inputBorder}`
    }`};
  ${(props) =>
    props.borderColor &&
    `border-color: ${
      props.borderColor !== true ? props.bottomBorder : `${props.theme.borderColor}`
    }`};
  ${(props) =>
    props.borderWidth &&
    `border-width: ${props.borderWidth !== true ? props.borderWidth : "1px"} !important`};
  ${(props) =>
    props.borderStyle &&
    `border-style: ${props.borderStyle !== true ? props.borderStyle : "solid"} !important`};
  ${(props) => props.overflow && `overflow: ${props.overflow}!important`};
  ${(props) => props.weight && `font-weight: ${props.weight}`};
  ${(props) => props.opacity && `opacity: ${props.opacity}`};
  ${(props) => props.order && `order: ${props.order}`};
  ${(props) => props.overflow && `overflow: ${props.overflow}`};
  ${(props) => props.position && `position: ${props.position}`};
  ${(props) => props.gap && `gap: ${props.gap}`};
  ${(props) => props.gridCols && `grid-template-columns: ${props.gridCols}`};
  ${(props) => props.gridRows && `grid-template-rows: ${props.gridRows}`};
  ${(props) => props.boxSizing && `box-sizing: ${props.boxSizing}`};
  ${(props) => props.boxShadow && `box-shadow: ${props.boxShadow}`};
  ${(props) => props.bgRepeat && `background-repeat: ${props.bgRepeat}`};
  ${(props) => props.ColGap && `column-gap: ${props.ColGap}`};
  ${(props) => props.backdropFilter && `backdrop-filter: ${props.backdropFilter}`};
  ${(props) => props.borderRadius && `border-radius: ${props.borderRadius}`};
  ${(props) => props.mr && `margin-right: ${props.mr} !important`};

  ${(props) =>
    props.gridTemplateColums &&
    css`
      display: grid !important;
      grid-template-columns: ${(props) => props.gridColumn};
      ${(props) => props.gridGap && `gap:  ${props.gridGap}`};
      ${(props) => props.gridColumnGap && `grid-column-gap:  ${props.gridColumnGap}`};
      ${(props) => props.lastTrue && `align-self:  ${props.lastTrue}`};

      @media (max-width: 1200px) {
        grid-template-columns: ${(props) => props.gridColumnXl};
        ${(props) => props.gridGapXl && `gap:  ${props.gridGapXl}`};
        ${(props) => props.gridColumnGapXl && `grid-column-gap:  ${props.gridColumnGapXl}`};
        ${(props) => props.lastTrueXl && `align-self:  ${props.lastTrueXl}`};
        ${(props) => props.marginLeft && `margin-left:  ${props.marginLeft}`} !important;
      }

      @media (max-width: 1024px) {
        grid-template-columns: ${(props) => props.gridColumnLg};
        ${(props) => props.gridGapLg && `gap:  ${props.gridGapLg}`};
        ${(props) => props.gridColumnGapLg && `grid-column-gap:  ${props.gridColumnGapLg}`};
        ${(props) => props.lastTrueLg && `align-self:  ${props.lastTrueLg}`};
        ${(props) => props.marginLeft && `margin-left:  ${props.marginLeft}`} !important;
        ${(props) => props.widthLg && `width:  ${props.widthLg}`} !important;
        ${(props) => props.maxWidthLg && `max-width: ${props.maxWidthLg}`} !important;
      }

      @media (max-width: 767px) {
        grid-template-columns: ${(props) => props.gridColumnMd};
        ${(props) => props.gridGapMd && `gap:  ${props.gridGapMd}`};
        ${(props) => props.gridColumnGapMd && `grid-column-gap:  ${props.gridColumnGapMd}`};
        ${(props) => props.lastTrueMd && `align-self:  ${props.lastTrueMd}`};
        ${(props) => props.widthMd && `width:  ${props.widthMd}`} !important;
        ${(props) => props.maxWidthMd && `max-width: ${props.maxWidthMd}`} !important;
      }
    `}

  ${(props) =>
    props.FilterRangeSeperation &&
    css`
      &.filterRange_Seperation {
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: #fff;
        border-radius: 50%;
        border: 2px solid #a4c2f7;
        top: 2px;
        box-shadow: none;
      }

      &.left-0 {
        left: -1.9%;
      }
      &.left-24 {
        left: 23%;
      }
      &.left-49 {
        left: 48%;
      }
      &.left-74 {
        left: 73%;
      }
      &.left-100 {
        left: 98%;
      }
      &.left-100.txt-lft-100 {
        left: 92.3%;
      }
    `}

  ${(props) =>
    props.ExchangeContainer &&
    css`
      width: 100%;
      max-width: 1124px;
      margin: 0 auto;
      box-sizing: border-box;
    `}

  ${(props) =>
    props.LeftBoxBadge &&
    css`
      width: 11px;
      height: 35px;
      background: #0470e1;
      left: 0;
      top: 26px;
    `}
  ${(props) =>
    props.AnimationMain &&
    css`
      animation-name: animatetop;
      transition: all ease-in;
      animation-duration: 0.5s;
      top: -4px;

      // &.animation-back {
      //   animation-name: animatetopreverse;
      //   transition: all 0s ease-out;
      //   animation-duration: 0.5s;
      //   top: -4px;

      //   @keyframes animatetopreverse {
      //     0% {
      //       top: -4px;
      //       opacity: 1;
      //     }

      //     100% {
      //       top: 100px;
      //       opacity: 0;
      //     }
      //   }
      // }

      @keyframes animatetop {
        0% {
          top: 100px;
          opacity: 0;
        }
        100% {
          top: -4px;
          opacity: 1;
        }
      }
    `}
  ${(props) =>
    props.AnimationMainReverse &&
    css`
      animation-name: animatetopreverse;
      transition: all 0s ease-out;
      animation-duration: 0.5s;

      top: -4px;
      @keyframes animatetopreverse {
        0% {
          top: -4px;
          opacity: 1;
        }

        100% {
          top: 100px;
          opacity: 0;
        }
      }
    `}

  ${(props) =>
    props.CardLevel2 &&
    css`
      background: ${({ theme }) => theme.level2Card} !important;
    `}
  ${(props) =>
    props.DangerKyc &&
    css`
      background: ${({ theme }) => theme.dangerNotification} !important;
    `}
  ${(props) =>
    props.CardBorderPrimary &&
    css`
      border: 1px solid ${({ theme }) => theme.primaryBlue} !important;
    `}f
  ${(props) =>
    props.BgSecondaydanger &&
    css`
      background: ${({ theme }) => theme.secondaryRed} !important;
    `}
  ${(props) =>
    props.BorderFrameForPhotoKYC &&
    css`
      --b: 1px; /* thickness of the border */
      --c: ${(props) => props.theme.borderColor}; /* color of the border */
      --w: 20px; /* width of border */
      --r: 25px; /* radius */
      position: relative;
      &::before {
        content: "";
        position: absolute;
        inset: 0;
        background: var(--c, red);
        --_g: #0000 90deg, #000 0;
        --_p: var(--w) var(--w) no-repeat;
        --mask: conic-gradient(from 90deg at top var(--b) left var(--b), var(--_g)) 0 0 / var(--_p),
          conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) 100% 0 / var(--_p),
          conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0 100% / var(--_p),
          conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g)) 100% 100% /
            var(--_p);
        -webkit-mask: var(--mask);
        mask: var(--mask);
      }
    `}
  ${(props) =>
    props.BorderFrameForPhotoKYCDanger &&
    css`
      --b: 1px; /* thickness of the border */
      --c: #f2a9a9; /* color of the border */
      --w: 20px; /* width of border */
      --r: 25px; /* radius */
      position: relative;
      &::before {
        border-radius: 10px;
        content: "";
        position: absolute;
        inset: 0;
        background: var(--c, red);
        --_g: #0000 90deg, #000 0;
        --_p: var(--w) var(--w) no-repeat;
        --mask: conic-gradient(from 90deg at top var(--b) left var(--b), var(--_g)) 0 0 / var(--_p),
          conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) 100% 0 / var(--_p),
          conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0 100% / var(--_p),
          conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g)) 100% 100% /
            var(--_p);
        -webkit-mask: var(--mask);
        mask: var(--mask);
      }
    `}

  ${(props) =>
    props.PrimaryBorderCard &&
    css`
      border: 1px solid ${({ theme }) => theme.primaryBlue} !important;
      background: ${({ theme }) => theme.bgActiveTable} !important;
    `}
  ${(props) =>
    props.PrimaryCardDottedBorder &&
    css`
      border: 1px dotted ${({ theme }) => theme.borderColor} !important;
      background: ${({ theme }) => theme.bgActiveTable} !important;
      border-radius: 10px;
    `}
  ${(props) =>
    props.DangerCardDottedBorder &&
    css`
      border: 1px dotted ${({ theme }) => theme.borderColor} !important;
      background: ${({ theme }) => theme.dangerNotification} !important;
      border-radius: 10px;
    `}
  ${(props) =>
    props.PrimaryBorderCardTimeline &&
    css`
      border-top: 1px solid ${({ theme }) => theme.primaryBlue} !important;
    `}

  ${(props) =>
    props.DangerBorderCard &&
    css`
      border: 1px solid ${({ theme }) => theme.primaryRed} !important;
      background: ${({ theme }) => theme.dangerNotification} !important;
    `}

  ${(props) =>
    props.WarningBorderCard &&
    css`
      border: 1px solid ${({ theme }) => theme.warningTxt} !important;
      background: ${({ theme }) => theme.warningFill} !important;
    `}

  ${(props) =>
    props.SuccessBorderCard &&
    css`
      border: 1px solid ${({ theme }) => theme.success} !important;
      background: ${({ theme }) => theme.primarySuccess} !important;
    `}
  ${(props) =>
    props.BorderTimeLine &&
    css`
      border-left: 8px solid ${({ theme }) => theme.TimeLineBg} !important;
      background: ${({ theme }) => theme.TimeLineBg} !important;
      opacity: 0.1;
      // height: 500px;
    `}
  ${(props) =>
    props.BufferArea &&
    css`
      background: ${({ theme }) => theme.bgActiveTable} !important;
    `}

  ${(props) =>
    props.CardLevel2BorderColor &&
    css`
      border-color: ${({ theme }) => theme.level2Card} !important;
    `}
  ${(props) =>
    props.LeftSidebar &&
    css`
  cursor: pointer;
  position: absolute;
  top: 16%;
 left: 1.8%
  bottom: 0;
  width: 60px;
  height: 60px;
  background: ${({ theme }) => theme.level2Card} !important;;
  border-radius: 50%;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
  align-items: center;
  z-index: 100;
  @media (max-width: 767px) {
   width: 30px;
   height: 30px;
   top: 22%;
  }
  img {
   display: block;
   margin: 0 auto;
   @media (max-width: 767px) {
     width: 15px;
     height: 15px;
    }
  }
  `}
  ${(props) =>
    props.RightSidebar &&
    css`
      cursor: pointer;
      position: absolute;
      top: 16%;
      bottom: 0;
      width: 60px;
      height: 60px;
      background: ${({ theme }) => theme.level2Card} !important;
      border-radius: 50%;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
      align-items: center;
      z-index: 100;
      position: absolute;
      left: 94%;
      bottom: 0;
      align-items: center;
      z-index: 100;
      @media (max-width: 767px) {
        width: 30px;
        height: 30px;
        top: 22%;
      }
      img {
        display: block;
        margin: 0 auto;
        @media (max-width: 767px) {
          width: 15px;
          height: 15px;
        }
      }
    `}

  ${(props) =>
    props.ArrowDown &&
    css`
      width: 0;
      height: 0;
      border-left: 10px solid transparent !important;
      border-right: 10px solid transparent !important;
      border-top: 15px solid ${({ theme }) => theme.level2Card} !important;
      outline: ${({ theme }) => theme.level2Card};
    `}

  ${(props) =>
    props.BorderDashedSky &&
    css`
      border: 1px dashed ${props.theme.primary} !important;
    `}

  ${(props) =>
    props.CardWhiteShadow &&
    css`
      box-shadow: 0px 4px 4px 0px #0122431f !important;
    `}
  ${(props) =>
    props.CardDarkShadow &&
    css`
      box-shadow: 0px 4px 4px 0px #0122431f;
    `}

  ${(props) =>
    props.CardWhite2 &&
    css`
      background: ${({ theme }) => theme.level1Card} !important;
      border: ${({ theme }) => `1px solid ${theme.borderColor}`} !important;
    `}
  ${(props) =>
    props.CardWhite &&
    css`
      background: ${({ theme }) => theme.level1Card} !important;
    `}
  ${(props) =>
    props.DisplayFlex &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}

  ${(props) =>
    props.CardGrey2 &&
    css`
      background: ${({ theme }) => theme.level3CardGrey} !important;
      border: 1px solid ${({ theme }) => theme.DropdownBorder} !important;
      padding: 5px 8px !important;
    `}

  ${(props) =>
    props.CardSky &&
    css`
      background: ${({ theme }) => theme.level1CardSky} !important;
    `}

  ${(props) =>
    props.CardPrimary &&
    css`
      background: ${({ theme }) => theme.primary} !important;
      color: #fff !important;
    `}

  ${(props) =>
    props.CardDashboardSky &&
    css`
      background: ${({ theme }) => theme.level2CardSky} !important;
    `}

  ${(props) =>
    props.CardGrey &&
    css`
      background: ${({ theme }) => theme.mainBackground} !important;
    `}
  ${(props) =>
    props.BadgeBG &&
    css`
      background: ${({ theme }) => theme.AccorianBg} !important;
    `}

  ${(props) =>
    props.CardYellow &&
    css`
      background: ${({ theme }) => theme.warning} !important;
    `}

  ${(props) =>
    props.CardSuccessLight &&
    css`
      background: ${({ theme }) => theme.primarySuccess} !important;
    `}

  ${(props) =>
    props.TruncateThreeDot &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}

  ${(props) =>
    props.HoverScaleBox &&
    css`
      &:hover {
        transform: translateY(-5px) scale(1.02);
        transition: all 0.25s ease;
      }
    `}
  ${(props) =>
    props.CircleParentBox &&
    css`
      position: relative;
      cursor: pointer;
      width: 180px;
      height: 180px;
      box-shadow: 0 0 40px 0 rgba(4, 112, 225, 0.25);
      border-radius: 50%;
      background-color: ${({ theme }) => theme.level2Card};
      overflow: hidden;
      @media (max-width: 1024px) {
        width: 97px;
        height: 97px;
      }
      @media (max-width: 767px) {
        width: 97px;
        height: 97px;
      }
    `}
  ${(props) =>
    props.ImageParent &&
    css`
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
    `}
  ${(props) =>
    props.ExchangeFooterGrey &&
    css`
      background: #e6f1fc !important;
    `}
  ${(props) =>
    props.ExchangeContainer &&
    css`
      width: 100%;
      max-width: 1124px;
      margin: 0 auto;
      box-sizing: border-box;

      @media (max-width: 1200px) {
        width: 95% !important;
      }

      @media (max-width: 1024px) {
        width: 95% !important;
        max-width: 992px !important;
      }
    `}

    ${(props) =>
    props.InputBottomBorder &&
    css`
      width: 100%;
      max-width: 1124px;
      margin: 0 auto;
      box-sizing: border-box;

      @media (max-width: 1200px) {
        width: 95% !important;
      }

      @media (max-width: 1024px) {
        width: 95% !important;
        max-width: 992px !important;
      }

      @media (max-width: 767px) {
        width: 95% !important;
      }
    `}
      

  ${(props) =>
    props.ChartTabActive &&
    css`
      ${dFlexJustifyContentBetween}
      border: 1px solid ${(props) => props.theme.primary};
      border-radius: 2px;
      background: ${(props) => props.theme.activeTabBox};
    `}

  ${(props) =>
    props.ChartTabUnActive &&
    css`
      ${dFlexJustifyContentBetween}
      border: 1px solid ${(props) => props.theme.borderColor};
      border-radius: 2px;
      background: ${(props) => props.theme.tabBox};
    `}
    
  ${(props) =>
    props.ContentBox &&
    css`
      vertical-align: top;
      flex: 1;

      @media (max-width: 1150px) {
        &:after {
          display: block;
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          background: rgba(4, 36, 113, 0.6);
          content: "";
          z-index: 10;
          visibility: hidden;
          opacity: 0;
          transition: all 0.4s ease;
        }
      }
      @media (min-width: 767px) and (max-width: 1024px) {
        padding: 1.5rem;
      }
    `}

  ${(props) =>
    props.ArrowTopSettings &&
    css`
      position: absolute;
      top: -10px;
      height: 20px;
      width: 20px;
      transform: rotate(45deg);
      z-index: 10;
      border: 1px solid;
      left: 46%;
      border-width: 1px 0 0 1px;
      border-color: ${(props) => props.theme.DropdownBorder};
      background: ${({ theme }) => theme.mainBackground} !important;
    `};

  ${(props) =>
    props.TradeInputBackground &&
    css`
      background: ${(props) => props.theme.dashboardTableHead};
    `};

  ${(props) =>
    props.InputError &&
    css`
      border-bottom: 2px solid ${(props) => props.theme.danger};
    `};

  ${(props) =>
    props.BCKActive &&
    css`
      background: ${(props) => props.theme.level1Card};
      border-top: 2px solid ${(props) => props.theme.primary};
    `};

  ${(props) =>
    props.BorderCardSettings &&
    css`
      border: 0.5px solid ${(props) => props.theme.DropdownBorder};
    `};

  ${(props) =>
    props.BorderCardSettingsRejected &&
    css`
      border: 0.5px solid ${(props) => props.theme.rejectedRed} !important;
    `};

  ${(props) =>
    props.BorderCardSettingsOrange &&
    css`
      border: 0.5px solid ${(props) => props.theme.primaryYellow} !important;
    `};

  ${(props) =>
    props.BorderCardSettingsUnderApproval &&
    css`
      border: 0.5px solid ${(props) => props.theme.primaryButton} !important;
    `};
  ${(props) =>
    props.DropdownCardHoverEffect &&
    css`
      height: 48px;

      &:hover {
        background: ${(props) => props.theme.headerText};
        border: 1px solid ${(props) => props.theme.inputBorder};
      }
    `};

  ${(props) =>
    props.settingInputBackupKey &&
    css`
      background: ${(props) => props.theme.settingsInputbg};
      padding: 14px 21px 14px 13.5px;
      border-radius: 5px;
      box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.05);
      border: solid 1px ${(props) => props.theme.DropdownBorder};
      height: 48px;
    `};

  ${(props) =>
    props.CircleParentBox &&
    css`
      position: relative;
      cursor: pointer;
      width: 180px;
      height: 180px;
      box-shadow: 0 0 40px 0 rgba(4, 112, 225, 0.25);
      border-radius: 50%;
      background-color: ${({ theme }) => theme.level2Card};
      overflow: hidden;
      @media (max-width: 1024px) {
        width: 97px;
        height: 97px;
      }
      @media (max-width: 767px) {
        width: 97px;
        height: 97px;
      }
    `}

  ${(props) =>
    props.LeftSidebar &&
    css`
    cursor: pointer;
    position: absolute;
    top: 16%;
    left: 1.8%
    bottom: 0;
    width: 60px;
    height: 60px;
    background: ${({ theme }) => theme.level2Card} !important;;
    border-radius: 50%;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
    align-items: center;
    z-index: 100;
    @media (max-width: 767px) {
      width: 30px;
      height: 30px;
      top: 22%;
    }
    img {
      display: block;
      margin: 0 auto;
      @media (max-width: 767px) {
        width: 15px;
        height: 15px;
        }
      }
  `}
  ${(props) =>
    props.RightSidebar &&
    css`
      cursor: pointer;
      position: absolute;
      top: 16%;
      bottom: 0;
      width: 60px;
      height: 60px;
      background: ${({ theme }) => theme.level2Card} !important;
      border-radius: 50%;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
      align-items: center;
      z-index: 100;
      position: absolute;
      left: 94%;
      bottom: 0;
      align-items: center;
      z-index: 100;
      @media (max-width: 767px) {
        width: 30px;
        height: 30px;
        top: 22%;
      }
      img {
        display: block;
        margin: 0 auto;
        @media (max-width: 767px) {
          width: 15px;
          height: 15px;
        }
      }
    `}
  ${(props) =>
    props.InternalTransferBorder &&
    css`
      border: ${({ theme }) => `1px solid ${theme.inputBorder}`} !important;
    `}

  ${(props) =>
    props.WalletBottomBorder &&
    css`
      border-bottom: ${({ theme }) => `1px solid ${theme.inputBoxBorder}`} !important;
    `}

  ${(props) =>
    props.StatusLine &&
    css`
      width: calc(100% - 160px);
      border-bottom: ${({ theme }) => `2px dashed ${theme.borderColor}`} !important;
    `}

  ${(props) =>
    props.WalletCard &&
    css`
      width: 262px;
      height: 189px;
      background: ${({ theme }) => theme.level3NotClicked} !important;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      padding: 1.5rem;
    `}
  ${(props) =>
    props.Status &&
    css`
      width: 24px;
      height: 24px;
      border-radius: 50%;

      &.confirm-status {
        color: #fff;
        background-color: rgba(0, 128, 0, 0.8);
      }
      &.reject-status {
        border: 3px solid ${({ theme }) => theme.danger};
      }

      &.pending-status {
        border: 3px solid #ffb93d;
      }
    `}

  ${(props) =>
    props.WalletActiveCard &&
    css`
      background: ${({ theme }) => theme.bgActiveTable} !important;
      border-bottom: 10px solid ${({ theme }) => `${theme.primary}`};
      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 17px solid ${({ theme }) => theme.primary};
        bottom: -26px;
        left: 45%;
      }
    `}

  ${(props) =>
    props.BadgeForDropDown &&
    css`
      @media (max-width: 1024px) {
        // width: 95%;
        max-width: 992px !important;
      }

      @media (max-width: 767px) {
        // width: 95%;
        width: 30px !important;
      }
    `}

  ${(props) =>
    props.BodyColor &&
    css`
      color: ${(props) => props.color || props.theme.bodyText};
    `}

  ${(props) =>
    props.ContentBox &&
    css`
      vertical-align: top;
      flex: 1;

      @media (max-width: 1150px) {
        &:after {
          display: block;
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          background: rgba(4, 36, 113, 0.6);
          content: "";
          z-index: 10;
          visibility: hidden;
          opacity: 0;
          transition: all 0.4s ease;
        }
      }
      @media (min-width: 767px) and (max-width: 1024px) {
        padding: 1.5rem;
      }
    `}

  ${(props) =>
    props.TradingCompVideoWrapper &&
    css`
      // border: 2px solid #000;
      height: 100%;
      position: absolute;
      right: 16px;
      top: 0;
      overflow: hidden;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      video {
        height: inherit !important;
        top: 0;
        left: 0;
      }
    `}
${(props) =>
    props.ArrowTopSettings &&
    css`
      position: absolute;
      top: -10px;
      height: 20px;
      width: 20px;
      transform: rotate(45deg);
      z-index: 10;
      border: 1px solid;
      left: 46%;
      border-width: 1px 0 0 1px;
      border-color: ${(props) => props.theme.DropdownBorder};
      background: ${({ theme }) => theme.mainBackground} !important;
    `}

  ${(props) =>
    props.InputSelectKyc &&
    css`
      select {
        width: 100%;
        height: 48px !important;
        text-decoration: none;
        border: none !important;
        padding: 0 12px;
        display: flex;
        justify-content: center;
        border-radius: 5px 5px 0 0;
        font-weight: 500 !important;
        color: ${(props) => props.theme.bodyText};
        background-color: ${(props) => props.theme.inputBackground};
        border-bottom: 1px solid ${({ theme }) => theme.inputBorder} !important;

        &:focus {
          text-decoration: none;
          outline: none;
          border: none;
          background-color: ${(props) => props.theme.inputBackground};
        }

        &::placeholder {
          font-size: 14px;
          font-weight: 300;
          color: ${(props) => props.theme.subText};

          @media (max-width: 767px) {
            font-size: 12px;
          }
        }

        &.not-selected {
          color: ${(props) => props.theme.subText};
        }

        option {
          color: ${(props) => props.theme.bodyText} !important;
        }
      }
    `}
  ${(props) =>
    props.BCKActive &&
    css`
      background: ${(props) => props.theme.level1Card};
      border-top: 2px solid ${(props) => props.theme.primary};
    `}
  ${(props) =>
    props.BorderCardSettings &&
    css`
      border: 0.5px solid ${(props) => props.theme.DropdownBorder};
    `}
  ${(props) =>
    props.BorderCardSettingsRejected &&
    css`
      border: 0.5px solid ${(props) => props.theme.rejectedRed} !important;
    `}
  ${(props) =>
    props.BorderCardSettingsOrange &&
    css`
      border: 0.5px solid ${(props) => props.theme.primaryYellow} !important;
    `}
  ${(props) =>
    props.BorderCardSettingsUnderApproval &&
    css`
      border: 0.5px solid ${(props) => props.theme.primaryButton} !important;
    `}
  ${(props) =>
    props.settingInputBackupKey &&
    css`
      background: ${(props) => props.theme.settingsInputbg};
      padding: 14px 21px 14px 13.5px;
      border-radius: 5px;
      box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.05);
      border: solid 1px ${(props) => props.theme.DropdownBorder};
      height: 48px;
    `}

  ${(props) =>
    props.CircleParentBox &&
    css`
      position: relative;
      cursor: pointer;
      width: 180px;
      height: 180px;
      box-shadow: 0 0 40px 0 rgba(4, 112, 225, 0.25);
      border-radius: 50%;
      background-color: ${({ theme }) => theme.level2Card};
      overflow: hidden;
      @media (max-width: 1024px) {
        width: 97px;
        height: 97px;
      }
      @media (max-width: 767px) {
        width: 97px;
        height: 97px;
      }
    `}

  ${(props) =>
    props.LeftSidebar &&
    css`
    cursor: pointer;
    position: absolute;
    top: 16%;
    left: 1.8%
    bottom: 0;
    width: 60px;
    height: 60px;
    background: ${({ theme }) => theme.level2Card} !important;;
    border-radius: 50%;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
    align-items: center;
    z-index: 100;
    @media (max-width: 767px) {
      width: 30px;
      height: 30px;
      top: 22%;
    }
    img {
    display: block;
    margin: 0 auto;
    @media (max-width: 767px) {
      width: 15px;
      height: 15px;
      }
    }
`}
${(props) =>
    props.RightSidebar &&
    css`
      cursor: pointer;
      position: absolute;
      top: 16%;
      bottom: 0;
      width: 60px;
      height: 60px;
      background: ${({ theme }) => theme.level2Card} !important;
      border-radius: 50%;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
      align-items: center;
      z-index: 100;
      position: absolute;
      left: 94%;
      bottom: 0;
      align-items: center;
      z-index: 100;
      @media (max-width: 767px) {
        width: 30px;
        height: 30px;
        top: 22%;
      }
      img {
        display: block;
        margin: 0 auto;
        @media (max-width: 767px) {
          width: 15px;
          height: 15px;
        }
      }
    `}
  ${(props) =>
    props.FormNumberContainer &&
    css`
  display: flex;
  align-items: center;
  height: 44px;
  width: 100%;
  position: relative;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.05);
  border: none;
  border-radius: 5px 5px 0 0;
  background-color: ${({ theme }) => theme.inputBackground};
  border-bottom: 1px solid ${({ theme }) => theme.inputBorder};
  color: ${({ theme }) => theme.subText};

  input {
    width: 100%;
    text-decoration: none;
    border: none;
    height: 100%;
    padding:0px 10px;
    display: flex;
    align-items: center;
    height: 48px;
    width: 100%;
    position: relative;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.05);
    border: none;
    border-radius: 5px 5px 0 0;
    background-color: ${({ theme }) => theme.inputBackground};
    border-bottom: 1px solid ${({ theme }) => theme.inputBorder};
    color: ${({ theme }) => theme.subText};
    font-weight:500;

    &:focus {
        background-color: ${({ theme }) => theme.inputBackground};
        outline:0px;
    }
    &:placeholder {
      color: ${({ theme }) => theme.subText};
    }

    @media (max-width: 767px) {
      width: 100%;
      max-width: 100%;
    }
    input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
         -webkit-appearance: none;
      }
      input[type=number] {
        -moz-appearance: textfield;
      }

  `}
  ${(props) =>
    props.BadgeForDropDown &&
    css`
      margin-left: auto;
      background: ${({ theme }) => `${theme.primary}`} !important;
      color: ${({ theme }) => `${theme.level2CardSky}`} !important;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-wrap: nowrap;
      font-weight: 500;
      width: 28px;
      height: 28px;
      padding: 3px;
      order: 99;
      @media (max-width: 767px) {
        width: 45px;
      }
    `}

  ${(props) =>
    props.InternalTransferBorder &&
    css`
      border: ${({ theme }) => `1px solid ${theme.inputBorder}`} !important;
    `}

  ${(props) =>
    props.LeftBoxBadge &&
    css`
      width: 11px;
      height: 35px;
      background: #0470e1;
      left: 0;
      top: 26px;
    `}
  ${(props) =>
    props.LoginPageDomainDiv &&
    css`
      height: 44px;
      min-width: 297px;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      display: flex;
      align-items: center;
      background-color: ${({ theme }) => theme.inputBackground};
      color: ${({ theme }) => theme.subText} !important;
      cursor: not-allowed !important;
    `}
    ${(props) =>
    props.InternalTransferBorder &&
    css`
      border: ${({ theme }) => `1px solid ${theme.inputBorder}`} !important;
    `}
    ${(props) =>
    props.WalletBottomBorder &&
    css`
      border-bottom: ${({ theme }) => `1px solid ${theme.inputBoxBorder}`} !important;
    `}
    ${(props) =>
    props.StatusLine &&
    css`
      width: calc(100% - 160px);
      border-bottom: ${({ theme }) => `2px dashed ${theme.borderColor}`} !important;
    `}

    ${(props) =>
    props.WalletCard &&
    css`
      width: 262px;
      height: 189px;
      background: ${({ theme }) => theme.level3NotClicked} !important;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      padding: 1.5rem;
    `}

    ${(props) =>
    props.WalletActiveCard &&
    css`
      background: ${({ theme }) => theme.bgActiveTable} !important;
      border-bottom: 10px solid ${({ theme }) => `${theme.primary}`};
      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 17px solid ${({ theme }) => theme.primary};
        bottom: -26px;
        left: 45%;
      }
    `}
    ${(props) =>
    props.BadgeForDropDown &&
    css`
      margin-left: auto;
      background: ${({ theme }) => `${theme.primary}`} !important;
      color: ${({ theme }) => `${theme.level2CardSky}`} !important;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-wrap: nowrap;
      font-weight: 500;
      width: 28px;
      height: 28px;
      padding: 3px;
      order: 99;
      @media (max-width: 767px) {
        width: 45px;
      }
    `}

    ${(props) =>
    props.InternalTransferBorder &&
    css`
      border: ${({ theme }) => `1px solid ${theme.inputBorder}`} !important;
    `}

    ${(props) =>
    props.LeftBoxBadge &&
    css`
      width: 11px;
      height: 35px;
      background: #0470e1;
      left: 0;
      top: 26px;
    `}
    ${(props) =>
    props.LoginPageDomainDiv &&
    css`
      height: 48px;
      min-width: 297px;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 300;
      margin-top: 16px;
      background: ${({ theme }) => theme.level1Card} !important;
    `}
    ${(props) =>
    props.LoginFormBg &&
    css`
      min-width: 450px;
      max-width: 604px;
      margin-top: 40px;
      border-radius: 10px;
      padding: 32px;
      background-color: ${({ theme }) => theme.level1Card} !important;

      @media (max-width: 1024px) {
        min-width: 644px;
        max-width: 644px;
        padding: 32px 40px;
      }
      @media (max-width: 767px) {
        min-width: 328px;
        max-width: 328px;
        margin-top: 24px;
        padding: 24px 16px;
      }
    `}
    ${(props) =>
    props.RegisterFormBg &&
    css`
      min-width: 630px;
      max-width: 720px;
      margin-top: 40px;
      border-radius: 10px;
      padding: 32px;
      background-color: ${({ theme }) => theme.level1Card} !important;

      @media (max-width: 1024px) {
        min-width: 644px;
        max-width: 644px;
        padding: 32px 40px;
      }
      @media (max-width: 767px) {
        min-width: 328px;
        max-width: 328px;
        margin-top: 24px;
        padding: 24px 16px;
      }
    `}
  
  ${(props) =>
    props.ModalContainer &&
    css`
      border-radius: 10px;
      padding: 24px 24px 24px 24px;
      font-weight: 500;
      width: 450px;
      color: #012243;
      background-color: ${({ theme }) => theme.level1Card} !important;
      color: ${({ theme }) => theme.bodyText};
    `}

  ${(props) =>
    props.ModalContainer &&
    css`
      border-radius: 10px;
      padding: 24px 24px 24px 24px;
      font-weight: 500;
      width: 450px;
      color: #012243;
      background-color: ${({ theme }) => theme.level1Card} !important;

      @media (orientation: landscape) {
        height: fit-content;
      }

      @media (max-width: 767px) {
        padding: 19px 16px 32px 16px;
        width: 328px;
      }
    `}
  ${(props) =>
    props.WalletsBala &&
    css`
      width: 11px;
      height: 35px;
      background: #0470e1;
      left: 0;
      top: 26px;
    `}
  ${(props) =>
    props.NonAuthInput &&
    css`
      position: absolute;
      right: 12px;
      height: 100%;
      font-size: 14px;
      font-weight: bold;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: ${({ theme }) => theme.primary} !important;
    `}
  ${(props) =>
    props.NonAuthInputDisabled &&
    css`
      color: ${({ theme }) => theme.subText} !important;
      cursor: not-allowed;
    `}
  ${(props) =>
    props.FormInputContainer &&
    css`
      display: flex;
      align-items: center;
      height: 44px;
      width: 100%;
      position: relative;
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.05);
      border: none;
      border-radius: 5px 5px 0 0;
      background-color: ${({ theme }) => theme.inputBackground};
      border-bottom: 1px solid ${({ theme }) => theme.inputBorder};
    `}
  ${(props) =>
    props.FormNumberContainer &&
    css`
      display: flex;
      align-items: center;
      height: 44px;
      width: 100%;
      position: relative;
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.05);
      border: none;
      border-radius: 5px 5px 0 0;
      background-color: ${({ theme }) => theme.inputBackground};
      border-bottom: 1px solid ${({ theme }) => theme.inputBorder};

      form-control {
      }
    `}

  ${(props) =>
    props.ArrowDown &&
    css`
      width: 0;
      height: 0;
      border-left: 10px solid transparent !important;
      border-right: 10px solid transparent !important;
      border-top: 15px solid ${({ theme }) => theme.level2Card} !important;
      outline: ${({ theme }) => theme.level2Card};
    `}
  ${(props) =>
    props.ImageParent &&
    css`
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
    `}
  ${(props) =>
    props.TruncateThreeDot &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}
${(props) =>
    props.CardLevel2 &&
    css`
      background: ${({ theme }) => theme.level2Card} !important;
    `}
${(props) =>
    props.CardLevel3 &&
    css`
      background: ${({ theme }) => theme.level3CardGrey} !important;
      box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.3);
    `}
    ${(props) =>
    props.FeesBannerBg &&
    css`
      background: ${({ theme }) => theme.bgActiveTable} !important;
    `}
    
${(props) =>
    props.CardLevel2BorderColor &&
    css`
      border-color: ${({ theme }) => theme.level2Card} !important;
    `}
  ${(props) =>
    props.ExchShowLine &&
    css`
      width: 17px;
      height: 3px;
      margin: 20.5px 14px 0 0;
      background: ${({ theme }) => theme.bodyText2};
      border-radius: 0;
    `}

  ${(props) =>
    props.BorderDashedSky &&
    css`
      border: 1px dashed ${props.theme.primary} !important;
    `}

  ${(props) =>
    props.CardWhiteShadow &&
    css`
      box-shadow: 0px 4px 4px 0px #0122431f !important;
    `}
  ${(props) =>
    props.OptionHover &&
    css`
      &:hover {
        background: ${({ theme }) => theme.OptionsBg};
        border: 1px solid ${({ theme }) => theme.DropdownBorder};
      }
    `}
  ${(props) =>
    props.CardDarkShadow &&
    css`
      box-shadow: 0px 4px 4px 0px #0122431f;
    `}
  ${(props) =>
    props.FlexColumnBetweenSpace &&
    css`
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    `}
  ${(props) =>
    props.FormContainer &&
    css`
      display: flex;
      align-items: center;
      height: 44px;
      width: 100%;
      position: relative;
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.05);
      border: none;
      border-radius: 5px 5px 0 0;
      background-color: ${({ theme }) => theme.inputBackground};
      border-bottom: 1px solid ${({ theme }) => theme.inputBorder};

      .inputMain {
        width: 100% !important;
        text-decoration: none;
        border: none;
        height: 44px;
        background: transparent;
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        justify-content: center;
        padding: 0px 10px;
        color: ${(props) => props.theme.bodyText};

        &:focus {
          outline: 0px;
        }
      }

      @media (max-width: 767px) {
        width: 100%;
        max-width: 100%;
      }
    `}
  ${(props) =>
    props.DatePickerContainer &&
    css`
      display: block;
      height: 44px;
      width: 100%;
      position: relative;
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.05);
      border: none;
      border-radius: 5px 5px 0 0;
      background-color: ${({ theme }) => theme.inputBackground};
      // border-bottom: 1px solid ${({ theme }) => theme.inputBorder};
    `}
  ${(props) =>
    props.OptionsDropdown &&
    css`
      position: relative;
      cursor: pointer;
      display: inline-block;

      optionsdropdown__container {
        display: flex;
        align-items: center;
        display: inline-block !important;
        border-radius: 5px;
        width: 24px;
        height: 24px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .optionsdropdown__options {
        position: absolute;
        bottom: 32px;
        z-index: 100;
        border-radius: 5px;
        right: 0;

        // For Center
        // left: 50%;
        // transform: translate(-50%, 0);

        @include themed() {
          background-color: t($popups-bg);
          box-shadow: 0 3px 6px 0 t($sep-color);
          outline: 1px solid t($sep-color);
        }

        &.green {
          @include themed() {
            box-shadow: none;
            outline: 1px solid t($primary-green);
          }
        }

        &.red {
          @include themed() {
            outline: 1px solid t($primary-red);
          }
        }
      }

      .optionsdropdown__options ul {
        width: 100%;
        list-style: none;
        padding: 0;
        margin: 0;
        border-radius: 5px;
        z-index: 100;

        @include themed() {
          background-color: t($popups-bg);
        }
      }

      .optionsdropdown__options ul li {
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 16px;
        font-weight: 500;
        padding: 0 24px;
        white-space: nowrap;

        @include themed() {
          color: t($primary-text);
        }
      }

      .optionsdropdown__options ul li:hover {
        cursor: pointer;
        width: 100%;
        border-radius: 5px;
        color: #012243;

        @include themed() {
          background-color: t($button-active);
        }
      }

      .optionsdropdown__options ul a {
        text-decoration: none;
        outline: none;

        &:hover {
          text-decoration: none;
          outline: none;
        }
      }

      // Get Bank Details Options
      .optionsdropdown__options ul .bankDetails__options {
        height: 44px;
        width: 124px;
        padding: 10px 35px 10px 16px;

        a {
          color: inherit;
          text-decoration: none;
          outline: none;

          &:hover {
            text-decoration: none;
            outline: none;
          }
        }
      }
    `}

  ${(props) =>
    props.CardWhite2 &&
    css`
      background: ${({ theme }) => theme.level1Card} !important;
      border: ${({ theme }) => `1px solid ${theme.borderColor}`} !important;
    `}

  ${(props) =>
    props.CardWhite &&
    css`
      background: ${({ theme }) => theme.level1Card} !important;
    `}

  ${(props) =>
    props.CardLevel3OnlyBackground &&
    css`
      background: ${({ theme }) => theme.level3CardGrey} !important;
    `}

  ${(props) =>
    props.DisplayFlex &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}
  ${(props) =>
    props.CardGrey2 &&
    css`
      background: ${({ theme }) => theme.level3CardGrey} !important;
      border: 1px solid ${({ theme }) => theme.DropdownBorder} !important;
      padding: 5px 8px !important;
    `}

  ${(props) =>
    props.CardSky &&
    css`
      background: ${({ theme }) => theme.level1CardSky} !important;
    `}
  ${(props) =>
    props.BorderBottomAccordian &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.borderColor} !important;
    `}

  ${(props) =>
    props.CardDashboardSky &&
    css`
      background: ${({ theme }) => theme.level2CardSky} !important;
    `}

  ${(props) =>
    props.CardGrey &&
    css`
      background: ${({ theme }) => theme.mainBackground} !important;
    `}

  ${(props) =>
    props.CardYellow &&
    css`
      background: ${({ theme }) => theme.warning} !important;
    `}

  ${(props) =>
    props.CardSuccessLight &&
    css`
      background: ${({ theme }) => theme.primarySuccess} !important;
    `}

  ${(props) =>
    props.TruncateThreeDot &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}
  ${(props) =>
    props.ExcAssetsSearchBarParent &&
    css`
    input{
      min-width: 250px;
      border: none;
      &:focus{ border: none; outline: none; }
    }
    span{
      img{
        position: relative;
        right: 26px;
        top: 10px;
      }
  `}

  ${(props) =>
    props.HoverScaleBox &&
    css`
      &:hover {
        transform: translateY(-5px) scale(1.02);
        transition: all 0.25s ease;
      }
    `}
  ${(props) =>
    props.CircleParentBox &&
    css`
      position: relative;
      cursor: pointer;
      width: 180px;
      height: 180px;
      box-shadow: 0 0 40px 0 rgba(4, 112, 225, 0.25);
      border-radius: 50%;
      background-color: ${({ theme }) => theme.level2Card};
      overflow: hidden;
      @media (max-width: 1024px) {
        width: 97px;
        height: 97px;
      }
      @media (max-width: 767px) {
        width: 97px;
        height: 97px;
      }
    `}
  ${(props) =>
    props.ImageParent &&
    css`
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
    `}
  ${(props) =>
    props.ExchangeFooterGrey &&
    css`
      background: #e6f1fc !important;
    `}
  ${(props) =>
    props.ExchangeContainer &&
    css`
      width: 100%;
      max-width: 1124px;
      margin: 0 auto;
      box-sizing: border-box;

      @media (max-width: 1200px) {
        width: 95% !important;
      }

      @media (max-width: 1024px) {
        width: 95% !important;
        max-width: 992px !important;
      }

      @media (max-width: 767px) {
        width: 95% !important;
      }
    `}

  ${(props) =>
    props.MarketCardBox &&
    css`
      border-radius: 10px;
      overflow: hidden;
      background: ${({ theme }) => theme.level1Card};
      box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
    `}
  ${(props) =>
    props.HoverScaleBox &&
    css`
      &:hover {
        transform: translateY(-5px) scale(1.02);
        transition: all 0.25s ease;
      }
    `}
  ${(props) =>
    props.PrimaryBorderTopMarketCard &&
    css`
      border-top: 15px solid ${({ theme }) => theme.primary} !important;
      height: 100%;
    `}
  ${(props) =>
    props.ExchangeFooterGrey &&
    css`
      background: #e6f1fc !important;
    `}
  ${(props) =>
    props.DashboardBordersdark &&
    css`
      border: 1px solid ${({ theme }) => theme.DashbordCardsBorder} !important;
      border-top: none !important;
    `}
  ${(props) =>
    props.LineThroughBorder &&
    css`
      border: 1px solid ${({ theme }) => theme.borderColor} !important;
    `}
  ${(props) =>
    props.SecurityCard &&
    css`
      width: 240px;
      height: 240px;
      padding: 46px 26px 33px;
      box-shadow: 0 3px 20px 0 ${({ theme }) => theme.SecurityBoxShadow};
      background: ${({ theme }) => theme.mainCardBackground};
      border-radius: 10px;
      @media (max-width: 1024px) {
        width: 100%;
        gap: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 76px;
        padding: 17px 26px 17px;
        background: ${({ theme }) => theme.level1Card} !important;
      }
    `}
  ${(props) =>
    props.ExchangeContainer &&
    css`
      width: 100%;
      max-width: 1124px;
      margin: 0 auto;
      box-sizing: border-box;
    `}
  

      ${(props) =>
    props.PrimaryBorderTopMarketCard &&
    css`
      border-top: 15px solid ${({ theme }) => theme.primary} !important;
      height: 100%;
    `}

      ${(props) =>
    props.ExchangeFooterGrey &&
    css`
      background: #e6f1fc !important;
    `}
      
  ${(props) =>
    props.DashboardBordersdark &&
    css`
      border: 1px solid ${({ theme }) => theme.DashbordCardsBorder} !important;
      border-top: none !important;
    `}

  ${(props) =>
    props.LineThroughBorder &&
    css`
      border: 1px solid ${({ theme }) => theme.borderColor} !important;
    `}
  
  ${(props) =>
    props.SecurityCard &&
    css`
      width: 240px;
      height: 240px;
      padding: 46px 26px 33px;
      box-shadow: 0 3px 20px 0 ${({ theme }) => theme.SecurityBoxShadow};
      background: ${({ theme }) => theme.mainCardBackground};
      border-radius: 10px;
      @media (max-width: 1024px) {
        width: 100%;
        gap: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 76px;
        padding: 17px 26px 17px;
        background: ${({ theme }) => theme.level1Card} !important;
      }
    `}
  
  ${(props) =>
    props.ExchangeContainer &&
    css`
      width: 100%;
      max-width: 1124px;
      margin: 0 auto;
      box-sizing: border-box;

      @media (max-width: 1200px) {
        width: 95% !important;
      }

      @media (max-width: 1024px) {
        width: 95% !important;
        max-width: 992px !important;
      }

      @media (max-width: 767px) {
        width: 95% !important;
      }
    `}
  ${(props) =>
    props.NavbarLink &&
    css`
      overflow-x: auto;
      a {
        text-decoration: none !important;
        margin: 0 8px;
        padding: 0 16px;
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
      }

      @media (max-width: 1200px) {
        a {
          white-space: nowrap;
        }
      }

      @media (max-width: 1024px) {
        a {
          font-size: 14px;
          white-space: nowrap;
        }
      }

      @media (max-width: 767px) {
        a {
          font-size: 14px;
          white-space: nowrap;
        }
      }
    `}
  
  ${(props) =>
    props.ToastNotificationSucess &&
    css`
      background: ${({ theme }) => theme.successLight} !important;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
      @media (max-wdith: 768px) {
        min-width: 98% !important;
      }
    `}

  ${(props) =>
    props.ToastNotificationDanger &&
    css`
      background: ${({ theme }) => theme.dangerNotification} !important;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
    `}

  ${(props) =>
    props.ToastNotificationWarning &&
    css`
      background: ${({ theme }) => theme.warningNotification} !important;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
    `}

      @media (max-width: 1200px) {
    ${(props) => props.displayXl && `display: ${props.displayXl} !important;`}
    ${(props) => props.directionXl && `flex-direction: ${props.directionXl} !important;`}
        ${(props) => props.widthXl && `width: ${props.widthXl} !important;`}
        ${(props) => props.minWidthXl && `min-width: ${props.minWidthXl} !important;`}
        ${(props) => props.sizeXl && `font-size: ${props.sizeXl};`}
        ${(props) => props.textAlignXl && `text-align: ${props.textAlignXl};`}
        ${(props) => props.alignItemsXl && `align-items: ${props.alignItemsXl}!important;`}
        ${(props) =>
      props.justifyContentXl && `justify-content: ${props.justifyContentXl}!important;`}
        ${(props) => props.positionXl && `position: ${props.positionXl} !important;`}
        ${(props) => props.flexWrapXl && `flex-wrap: ${props.flexWrapXl}!important;`}
        ${(props) => props.radiusXl && `border-radius: ${props.radiusXl}!important;`}
        ${(props) => props.overflowXl && `overflow: ${props.overflowXl} !important;`}
        ${(props) => props.heightXl && `height: ${props.heightXl};`}
        ${(props) => props.orderXl && `order:  ${props.orderXl};`}
        ${(props) => props.gridColsXl && `grid-template-columns: ${props.gridColsXl} !important;`}
        ${(props) => props.gridRowsXl && `grid-template-rows: ${props.gridRowsXl} !important;`}
  }

  @media (max-width: 1024px) {
    ${(props) => props.displayLg && `display: ${props.displayLg} !important;`}
    ${(props) => props.directionLg && `flex-direction: ${props.directionLg} !important;`}
        ${(props) => props.widthLg && `width: ${props.widthLg} !important;`}
        ${(props) => props.heightLg && `height: ${props.heightLg} !important;`}
        ${(props) => props.sizeLg && `font-size: ${props.sizeLg};`}
        ${(props) => props.textAlignLg && `text-align: ${props.textAlignLg};`}
        ${(props) => props.alignItemsLg && `align-items: ${props.alignItemsLg}!important;`}
        ${(props) =>
      props.justifyContentLg && `justify-content: ${props.justifyContentLg}!important;`}
        ${(props) => props.positionLg && `position: ${props.positionLg}!important;`}
        ${(props) => props.flexWrapLg && `flex-wrap: ${props.flexWrapLg}!important;`}
        ${(props) => props.radiusLg && `border-radius: ${props.radiusLg}!important;`}
        ${(props) => props.overflowLg && `overflow: ${props.overflowLg}!important;`} 
        ${(props) => props.heightLg && `height: ${props.heightLg};`}
        ${(props) => props.orderLg && `order:  ${props.orderLg};`}
        ${(props) => props.gridColsLg && `grid-template-columns: ${props.gridColsLg} !important;`}
        ${(props) => props.gridRowsLg && `grid-template-rows: ${props.gridRowsLg} !important;`}
        ${(props) => props.gapLg && `gap: ${props.gapLg} !important;`}
        ${(props) => props.topLg && `top: ${props.topLg};`}
        ${(props) => props.bottomLg && `bottom: ${props.bottomLg};`}
        ${(props) => props.leftLg && `left: ${props.leftLg};`}
        ${(props) => props.rightLg && `right: ${props.rightLg};`}
        ${(props) => props.widthLg && `width: ${props.widthLg};`}
        ${(props) => props.maxWidthLg && `max-width: ${props.maxWidthLg};`}
        ${(props) => props.minWidthLg && `min-width: ${props.minWidthLg};`}
        ${(props) => props.flexLg && `flex: ${props.flexLg}!important;`}
        ${(props) => props.directionLg && `flex-direction: ${props.directionLg} !important;`}
        ${(props) => props.bgLg && `background: ${props.bgLg}!important;`}
        ${(props) => props.bgPositionLg && `background-position: ${props.bgPositionLg}!important;`}
        ${(props) => props.bgSizeLg && `background-size: ${props.bgSizeLg}!important;`}
        ${(props) => props.flexWrapLg && `flex-wrap: ${props.flexWrapLg} !important`};
  }

  @media (max-width: 767px) {
    ${(props) => props.displayMd && `display: ${props.displayMd} !important;`}
    ${(props) => props.directionMd && `flex-direction: ${props.directionMd} !important;`}
    ${(props) => props.heightMd && `height: ${props.heightMd} !important`};
    ${(props) => props.minHeightMd && `min-height: ${props.minHeightMd} !important`};
    ${(props) => props.widthMd && `width: ${props.widthMd} !important`};
    ${(props) => props.sizeMd && `font-size: ${props.sizeMd}`};
    ${(props) => props.textAlignMd && `text-align: ${props.textAlignMd}`} !important;
    ${(props) => props.marginMd && `margin: ${props.marginMd} !important`};
    ${(props) => props.paddingMd && `padding: ${props.paddingMd} !important`};
    ${(props) => props.paddRightMd && `padding-right: ${props.paddRightMd} !important`};
    ${(props) => props.paddLeftMd && `padding-left: ${props.paddLeftMd} !important`};
    ${(props) => props.paddingTopMd && `padding-top: ${props.paddingTopMd} !important`};
    ${(props) => props.padddingBottomMd && `padding-bottom: ${props.padddingBottomMd} !important`};
    ${(props) => props.alignItemsMd && `align-items: ${props.alignItemsMd} !important`};
    ${(props) => props.justifyContentMd && `justify-content: ${props.justifyContentMd} !important`};
    ${(props) => props.mtMd && `margin-top: ${props.mtMd} !important`};
    ${(props) => props.mbMd && `margin-bottom: ${props.mbMd} !important`};
    ${(props) => props.mrMd && `margin-right: ${props.mrMd} !important`};
    ${(props) => props.mlMd && `margin-left: ${props.mlMd} !important`};
    ${(props) => props.positionMd && `position: ${props.positionMd} !important`};
    ${(props) => props.flexWrapMd && `flex-wrap: ${props.flexWrapMd}!important`};
    ${(props) => props.radiusMd && `border-radius: ${props.radiusMd}!important`};
    ${(props) => props.overflowMd && `overflow: ${props.overflowMd}`} !important;
    ${(props) => props.heightMd && `height: ${props.heightMd}`};
    ${(props) => props.orderMd && `order:  ${props.orderMd}`};
    ${(props) => props.gridColsMd && `grid-template-columns: ${props.gridColsMd} !important`};
    ${(props) => props.gridRowsMd && `grid-template-rows: ${props.gridRowsMd} !important`};
    ${(props) => props.gapMd && `gap: ${props.gapMd} !important`};
    ${(props) => props.topMd && `top: ${props.topMd}`};
    ${(props) => props.bottomMd && `bottom: ${props.bottomMd}`};
    ${(props) => props.leftMd && `left: ${props.leftMd}`};
    ${(props) => props.rightMd && `right: ${props.rightMd}`};
    ${(props) => props.widthMd && `width: ${props.widthMd}`};
    ${(props) => props.maxWidthMd && `max-width: ${props.maxWidthMd}`};
    ${(props) => props.minWidthMd && `min-width: ${props.minWidthMd}!important`};
    ${(props) => props.flexMd && `flex: ${props.flexMd}!important`};
    ${(props) => props.bgMd && `background: ${props.bgMd}!important`};
    ${(props) => props.bgPositionMd && `background-position: ${props.bgPositionMd}!important`};
    ${(props) => props.bgSizeMd && `background-size: ${props.bgSizeMd}!important`};
    ${(props) => props.flexWrapMd && `flex-wrap: ${props.flexWrapMd} !important`};
  }
  @media (max-width: 380px) {
    ${(props) => props.paddingSm && `padding: ${props.paddingSm} !important`};
  }
`;

export { BannerSection, BannerHeadingSpan, BannerBox, MainElement, BannerAside };
