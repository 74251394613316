import { css } from "styled-components";

const PaddingStyle = css`
  ${({ pt }) => pt && `padding-top: ${pt}; `}
  ${({ pb }) => pb && `padding-bottom: ${pb}; `}
  ${({ pl }) => pl && `padding-left: ${pl}; `}
  ${({ pr }) => pr && `padding-right: ${pr}; `}
  ${({ px }) => px && `padding-right: ${px} !important; padding-left: ${px} !important; `}
  ${({ py }) => py && `padding-top: ${py} !important; padding-bottom: ${py} !important; `}
  ${({ padding }) => padding && `padding: ${padding} !important; `}

  @media (max-width: 1200px) {
    ${({ ptXl }) => ptXl && `padding-top: ${ptXl}; `}
    ${({ pbXl }) => pbXl && `padding-bottom: ${pbXl}; `}
    ${({ plXl }) => plXl && `padding-left: ${plXl}; `}
    ${({ prXl }) => prXl && `padding-right: ${prXl}; `}
    ${({ pxXl }) => pxXl && `padding-right: ${pxXl} !important; padding-left: ${pxXl} !important; `}
    ${({ pyXl }) => pyXl && `padding-top: ${pyXl} !important; padding-bottom: ${pyXl} !important; `}
    ${({ paddingXl }) => paddingXl && `padding: ${paddingXl} !important; `}
  }

  @media (max-width: 1024px) {
    ${({ ptLg }) => ptLg && `padding-top: ${ptLg}; `}
    ${({ pbLg }) => pbLg && `padding-bottom: ${pbLg}; `}
    ${({ plLg }) => plLg && `padding-left: ${plLg}; `}
    ${({ prLg }) => prLg && `padding-right: ${prLg}; `}
    ${({ pxLg }) => pxLg && `padding-right: ${pxLg} !important; padding-left: ${pxLg} !important; `}
    ${({ pyLg }) => pyLg && `padding-top: ${pyLg} !important; padding-bottom: ${pyLg} !important; `}
    ${({ paddingLg }) => paddingLg && `padding: ${paddingLg} !important; `}
  }

  @media (max-width: 767px) {
    ${({ ptMd }) => ptMd && `padding-top: ${ptMd}; `}
    ${({ pbMd }) => pbMd && `padding-bottom: ${pbMd}; `}
    ${({ plMd }) => plMd && `padding-left: ${plMd}; `}
    ${({ prMd }) => prMd && `padding-right: ${prMd}; `}
    ${({ pxMd }) => pxMd && `padding-right: ${pxMd} !important; padding-left: ${pxMd} !important; `}
    ${({ pyMd }) => pyMd && `padding-top: ${pyMd} !important; padding-bottom: ${pyMd} !important; `}
    ${({ paddingMd }) => paddingMd && `padding: ${paddingMd} !important; `}
  }

  @media (max-width: 560px) {
    ${({ ptSm }) => ptSm && `padding-top: ${ptSm}; `}
    ${({ pbSm }) => pbSm && `padding-bottom: ${pbSm}; `}
    ${({ plSm }) => plSm && `padding-left: ${plSm}; `}
    ${({ prSm }) => prSm && `padding-right: ${prSm}; `}
    ${({ pxSm }) => pxSm && `padding-right: ${pxSm} !important; padding-left: ${pxSm} !important; `}
    ${({ pySm }) => pySm && `padding-top: ${pySm} !important; padding-bottom: ${pySm} !important; `}
    ${({ paddingSm }) => paddingSm && `padding: ${paddingSm} !important; `}
  }
`;

export { PaddingStyle };
