/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-console */
import React, { useState, useRef } from "react";
import { DivElement, ModalWrapper, TextWrapper } from "../../../../components/Reusable";
import { CloseIcon, CopyRoundedIcon, LinkIcon } from "../../../../assets/svgs";
import { BannerBox } from "../../../../assets/StyleComponents/Exchange.style";
import { ButtonComponent } from "../../../../assets/StyleComponents/Button.style";
import { Label } from "../../../../assets/StyleComponents/FontSize/para.style";
import { FormInput } from "../../../../assets/StyleComponents/Input.style";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import styled from "styled-components";
import Carousel from "../../../../components/Reusable/Carousel";
import { DownBarWrapper } from "../../../../components/Reusable/ModalWrapper/BottomBar";
import { showSuccessMsg } from "../../../../utils/notification";
import CopyToClipboard from "react-copy-to-clipboard";
import ShareButton from "./ShareIcons";
import html2canvas from "html2canvas";
import { customDecode } from "../../../../utils/envScript/envCrypto";
// import { useScreenshot } from "use-react-screenshot";

const TextArea = styled.textarea`
  width: 100%; /* Full width */
  height: 150px; /* Set height */
  padding: 10px; /* Padding inside the textarea */
  border: 1px solid #ccc; /* Border styling */
  border-radius: 4px; /* Rounded corners */
  font-size: 16px; /* Font size */
  background: ${(props) => (props.bck ? props.bck : props.theme.inputBackground)};
  border: 1px solid ${(props) => (props.bck ? props.bck : props.theme.inputBackground)};
  color: ${(props) => props.theme.bodyText};

  &:focus {
    border-color: #3498db; /* Change border color on focus */
    outline: none; /* Remove default outline */
  }
`;

function InviteFriends({ onClose, referalCode, referalLink, ImageArray }) {
  const { width } = useWindowSize();
  const Content = `Sign up for a LCX account and claim exclusive rewards from the LCX referral program! bonus at .`;
  const [content, setContent] = useState(Content || "");
  const [enabled, setEnabled] = useState(0);
  const imageRef = useRef(null);
  // const [_, takeScreenShot] = useScreenshot();

  function convertImageToBase64(url, callback) {
    const img = new Image();
    img.crossOrigin = "Anonymous"; // This is important for CORS
    img.onload = function () {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL("image/png"); // You can specify the image format
      callback(dataURL);
    };
    img.src = url;
  }

  // Helper function to calculate the bounds of the non-white space in the canvas
  const getContentBounds = (imageData) => {
    const { data, width, height } = imageData;
    let minX = width,
      minY = height,
      maxX = 0,
      maxY = 0;

    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const index = (y * width + x) * 4;
        const alpha = data[index + 3]; // RGBA, alpha is the 4th value

        if (alpha > 0) {
          // Non-transparent pixel found
          if (x < minX) minX = x;
          if (x > maxX) maxX = x;
          if (y < minY) minY = y;
          if (y > maxY) maxY = y;
        }
      }
    }
    return {
      x: minX,
      y: minY,
      width: maxX - minX + 1,
      height: maxY - minY + 1
    };
  };

  const onClickDownload = () => {
    html2canvas(imageRef.current).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = "referral.png";
      link.click();
    });
  };

  return width > 767 ? (
    <ModalWrapper>
      <DivElement InviteModalContainer>
        <DivElement displayBtw margin="20px 0px">
          <TextWrapper fontSize="24px" fontSizeSm="16px" lineHeight="24px" fontWeight="500">
            Invite Friends
          </TextWrapper>
          <CloseIcon pointer onClick={onClose} />
        </DivElement>
        <BannerBox
          topBorder
          display="flex"
          alignItems="center"
          width="100%"
          pt="20px"
          gap="40px"
          pb="65px"
        >
          <BannerBox width="50%">
            <Carousel
              ImageArray={ImageArray}
              setEnabled={setEnabled}
              enabled={enabled}
              referalLink={`${customDecode(process.env.REACT_APP_EXCHANGE_URL)}/join/${referalCode}`}
              imageRef={imageRef}
            />
          </BannerBox>
          <BannerBox width="50%">
            <BannerBox>
              <Label size="16px" weight="400">
                Referral Code
              </Label>
              <BannerBox display="flex" position="relative">
                <FormInput
                  type="text"
                  sizeMd="12px"
                  required="required"
                  radius="5px 0px 0px 5px"
                  size="14px"
                  weight="500"
                  value={`${referalCode}`}
                  BodyColor
                  style={{ borderBottom: "0px" }}
                />
                <CopyToClipboard
                  text={referalCode}
                  onCopy={() => showSuccessMsg("Referral Code Copied")}
                >
                  <ButtonComponent
                    width="86px"
                    display="flex"
                    widthMd="48px"
                    justifyContent="space-evenly"
                    radius="0px 5px 5px 0px"
                    // DisabledBTNWhiteText={!KYCStatus}
                    size="14px"
                    alignItems="center"
                    style={{ borderTopRightRadius: "5px", borderBottomRightRadius: "5px" }}
                  >
                    <CopyRoundedIcon />
                    {width > 767 && `Copy`}
                  </ButtonComponent>
                </CopyToClipboard>
              </BannerBox>
            </BannerBox>
            {/* ++++++++++++++++++++Refer btn ends+++++++++++++++++++ */}
            <BannerBox mt="40px">
              <BannerBox display="flex" gap="10px">
                <Label size="16px" weight="400">
                  Customise your text{" "}
                </Label>
              </BannerBox>
              <TextArea
                title="Content"
                name="SourceOfFundsComment"
                value={content}
                onChange={(e) => setContent(e.target.value)}
              ></TextArea>
            </BannerBox>
            <BannerBox display="flex" alignItems="center" gap="20px" mt="28px">
              <ShareButton
                onClickDownload={onClickDownload}
                referalLink={referalLink}
                content={content}
                enabled={enabled}
                images={ImageArray}
              />
            </BannerBox>
          </BannerBox>
        </BannerBox>
      </DivElement>
    </ModalWrapper>
  ) : (
    <DownBarWrapper>
      <DivElement paddingSm="19px 16px 17px" DownBarContainer flexDirection="column">
        <DivElement displayBtw margin="20px 0px">
          <TextWrapper fontSize="24px" fontSizeSm="16px" lineHeight="24px" fontWeight="500">
            Invite Friends
          </TextWrapper>
          <CloseIcon pointer onClick={onClose} />
        </DivElement>
        <BannerBox
          topBorder
          display="flex"
          alignItems="center"
          width="100%"
          pt="20px"
          gap="40px"
          direction="column"
        >
          <BannerBox position="relative" width="50%" widthMd="100%" margin="0px auto">
            <Carousel
              ImageArray={ImageArray}
              setEnabled={setEnabled}
              enabled={enabled}
              referalLink={`${customDecode(process.env.REACT_APP_EXCHANGE_URL)}/join/${referalCode}`}
              imageRef={imageRef}
            />
          </BannerBox>

          <BannerBox width="50%" widthMd="100%">
            <BannerBox mb="24px">
              <Label size="14px" weight="500">
                Referral Link
              </Label>
              <BannerBox display="flex" position="relative">
                <LinkIcon absolute top="12px" left="6px" transform="rotate(135deg)" />
                <FormInput
                  type="text"
                  sizeMd="12px"
                  required="required"
                  padding="0px 0px 0px 35px"
                  radius="5px 0px 0px 5px"
                  size="14px"
                  weight="500"
                  value={`${customDecode(process.env.REACT_APP_EXCHANGE_URL)}/join/${referalCode}`}
                  BodyColor
                  style={{ borderBottom: "0px" }}
                />
                <CopyToClipboard
                  text={`${customDecode(process.env.REACT_APP_EXCHANGE_URL)}/join/${referalCode}`}
                  onCopy={() => showSuccessMsg("referral link copied")}
                >
                  <ButtonComponent
                    width="86px"
                    display="flex"
                    widthMd="48px"
                    justifyContent="space-evenly"
                    radius="0px 5px 5px 0px"
                    size="14px"
                    alignItems="center"
                    style={{ borderTopRightRadius: "5px", borderBottomRightRadius: "5px" }}
                  >
                    <CopyRoundedIcon />
                    {width > 767 && `Copy`}
                  </ButtonComponent>
                </CopyToClipboard>
              </BannerBox>
            </BannerBox>
            <BannerBox>
              <Label size="14px" weight="400">
                Referral Code
              </Label>
              <BannerBox display="flex" position="relative">
                <FormInput
                  type="text"
                  sizeMd="12px"
                  required="required"
                  radius="5px 0px 0px 5px"
                  size="14px"
                  weight="500"
                  value={`${referalCode}`}
                  BodyColor
                  style={{ borderBottom: "0px" }}
                />
                <ButtonComponent
                  width="86px"
                  display="flex"
                  widthMd="48px"
                  justifyContent="space-evenly"
                  radius="0px 5px 5px 0px"
                  // DisabledBTNWhiteText={!KYCStatus}
                  size="14px"
                  alignItems="center"
                  style={{ borderTopRightRadius: "5px", borderBottomRightRadius: "5px" }}
                >
                  <CopyRoundedIcon />
                  {width > 767 && `Copy`}
                </ButtonComponent>
              </BannerBox>
            </BannerBox>

            <BannerBox display="flex" alignItems="center" gap="20px" mt="28px">
              {/* {SocialMediaIcons.map((values, index) => {
                return <div key={index}>{values.Icon}</div>;
              })} */}

              <ShareButton
                onClickDownload={onClickDownload}
                referalLink={referalLink}
                content={content}
                enabled={enabled}
                images={ImageArray}
              />
            </BannerBox>
          </BannerBox>
        </BannerBox>
      </DivElement>
    </DownBarWrapper>
  );
}

export default InviteFriends;
