import styled, { css } from "styled-components";
import { DivElement } from "../../../../../components/Reusable";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const Box = styled.div`
  ${(props) =>
    props.type === "buy" &&
    css`
      background-color: ${(props) => props.theme.buyAnimationBg};
    `}

  ${(props) =>
    props.type === "sell" &&
    css`
      background-color: ${(props) => props.theme.sellAnimationBg};
    `}

  div {
    width: 20px;
    height: 20px;
    border: 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    border-radius: 2px;

    ${(props) =>
      props.type === "sell" &&
      css`
        color: ${(props) => props.theme.primaryRed};
        border-color: ${(props) => props.theme.primaryRed};
      `}

    ${(props) =>
      props.type === "buy" &&
      css`
        color: ${(props) => props.theme.primaryGreen};
        border-color: ${(props) => props.theme.primaryGreen};
      `}
  }
`;

const Bar = styled.div`
  height: 20px;
  width: ${(props) => props.width};
  position: relative;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 0 4px;
  transition: 300ms linear;

  &::before {
    content: "";
    position: absolute;
    display: block;
    top: 0px;
    width: 0px;
    height: 0px;
  }

  ${(props) =>
    props.type === "sell" &&
    css`
      background-color: ${(props) => props.theme.sellAnimationBg};
      color: ${(props) => props.theme.primaryRed};
      margin-left: 25px;
      justify-content: flex-end;
      min-width: 25px;

      &::before {
        left: -20px;
        border-top: solid 20px ${(props) => props.theme.sellAnimationBg};
        border-left: solid 20px transparent;
      }
    `}

  ${(props) =>
    props.type === "buy" &&
    css`
      background-color: ${(props) => props.theme.buyAnimationBg};
      color: ${(props) => props.theme.primaryGreen};
      min-width: 20px;

      &::before {
        right: -20px;
        border-bottom: solid 20px ${(props) => props.theme.buyAnimationBg};
        border-right: solid 20px transparent;
      }
    `}
`;

const BuySellRatio = () => {
  const [percent, setPercent] = useState(0);
  const obBuyGraphData = useSelector((state) => state.trading.obBuyGraphData);
  const obSellGraphData = useSelector((state) => state.trading.obSellGraphData);

  useEffect(() => {
    let total = obBuyGraphData.sumOfAmounts + obSellGraphData.sumOfAmounts;
    let percentage;
    if (total > 0) {
      percentage = (100 / total) * obBuyGraphData.sumOfAmounts;
    } else {
      percentage = 50;
    }
    setPercent(parseInt(percentage));
  }, [obBuyGraphData, obSellGraphData]);

  return (
    <DivElement height="32px" paddingTop="12px">
      <DivElement width="100%" height="20px" flex>
        <Box type="buy">
          <div>B</div>
        </Box>
        <DivElement flex width="calc(100% - 40px)">
          <Bar type="buy" width={`${percent}%`}>
            {percent}%
          </Bar>
          <Bar type="sell" width={`calc(${100 - percent}% - 20px)`}>
            {100 - percent}%
          </Bar>
        </DivElement>
        <Box type="sell">
          <div>S</div>
        </Box>
      </DivElement>
    </DivElement>
  );
};

export default BuySellRatio;
