import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import { DivElement, TextWrapper } from "../../../../../../components/Reusable";
import { memo } from "react";
import { numberFormatter } from "../../../../../../utils";

const Container = styled.span`
  direction: ltr;
  .avg_sum_tool_tip {
    background-color: ${(props) => props.theme.cardBg};
    min-width: 244px;
    height: 100px;
    border: 1px solid #ffc955;
    padding: 12px 16px;
    border-radius: 10px;
  }
`;

const AvgSumToolTip = ({ index, avgSumData, side }) => {
  const selectedPair = useSelector((state) => state.trading.selectedPair);
  const selectedPairConfig = useSelector((state) => state.trading.selectedPairConfig);

  return (
    <Container>
      <ReactTooltip
        id={`avgSum${side}-${index}`}
        className="avg_sum_tool_tip"
        place="right"
        effect="solid"
      >
        <DivElement flexJustifyBtw marginBottom="8px">
          <TextWrapper lineHeight="20px" paddingRight="16px">
            Avg. Price
          </TextWrapper>
          <TextWrapper lineHeight="20px">
            ≈ {numberFormatter(avgSumData.avgPrice, selectedPairConfig?.orderPrecision?.Price || 8)}
          </TextWrapper>
        </DivElement>
        <DivElement flexJustifyBtw marginBottom="8px">
          <TextWrapper
            lineHeight="20px"
            paddingRight="16px"
          >{`Sum ${selectedPair.base}`}</TextWrapper>
          <TextWrapper lineHeight="20px">
            ≈{" "}
            {numberFormatter(avgSumData.sumOfBase, selectedPairConfig?.orderPrecision?.Amount || 8)}
          </TextWrapper>
        </DivElement>
        <DivElement flexJustifyBtw>
          <TextWrapper
            lineHeight="20px"
            paddingRight="16px"
          >{`Sum ${selectedPair.quote}`}</TextWrapper>
          <TextWrapper lineHeight="20px">
            ≈{" "}
            {numberFormatter(avgSumData.sumOfQuote, selectedPairConfig?.orderPrecision?.Total || 8)}
          </TextWrapper>
        </DivElement>
      </ReactTooltip>
    </Container>
  );
};

export default memo(AvgSumToolTip);
