import React, { useCallback, useEffect, useState } from "react";

import config from "../../../constants/config";
import {
  BannerBox,
  BannerHeadingSpan,
  BannerSection
  // MainElement
  // MainElement
} from "../../../assets/StyleComponents/Exchange.style";
import { HeadingSix } from "../../../assets/StyleComponents/FontSize/HeadingSix.style";
import { Formik, Form } from "formik";
import { TextInput } from "../../../components/Reusable/FormInput";
import { PasswordInput } from "../../../components/Reusable/FormInput";
import { CountriesDropdown } from "../../../components/Reusable/FormInput";
import { useDispatch, useSelector } from "react-redux";
import { formatCountriesArray } from "../../../utils";
import { ButtonComponent } from "../../../assets/StyleComponents/Button.style";
import { Checkbox, DivElement, ImgElement } from "../../../components/Reusable";
import { AnchorTag, ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";
import { RouterLink } from "../../../components/Reusable";
import {
  // KYCRegister,
  MoonRegister,
  RegisterLightMarketingBanner,
  LightThemeRegister
} from "../../../assets/png";
// import LightThemeRegister from "../../../assets/png/all/registeMarketLight.png";
import { HeadingThird } from "../../../assets/StyleComponents/FontSize/headingThird.style";
import styled from "styled-components";
import RegisterStep2 from "./Step2";
import { getCountriesList } from "../../../actions/kyc";
import * as Yup from "yup";
import { showErrorMsg } from "../../../utils/notification";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { registerWithEmailOTP } from "../../../actions/auth";
// import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import NationalityModal from "../../../components/Modules/Kyc/Steps/NationalityModal";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import OnholdModal from "./OnholdModal";
import { H1Tag } from "../../../assets/StyleComponents/FontSize/fonts.style";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import ReferralBanner from "./ReferralBanner";

// import HeadingSecond from "../../au"
const registerValidation = Yup.object().shape({
  email: Yup.string()
    .email("Email Address must be a valid email")
    .required("Email Address is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .max(32, "Password must be at most 32 characters")
    .matches(
      /^(?=.*[A-Z])(?=.*\d).{6,32}$/,
      "Password must contain one uppercase letter and number."
    )
    .required("Password is required"),

  referralCode: Yup.string(),
  Country: Yup.string()
    // .notOneOf(["United Kingdom"], "This Country is not allowed")
    // .trim()
    .required("Country of residence is required")
});

const PopupReferral = styled(ParaTag)`
  border-radius: 0px 10px 10px 10px;
  padding: 15px;
  background: ${(props) => props.theme.level3CardTC};
  color: ${(props) => props.theme.bodyText};
`;

function RegisterStep1({ setStep, step, email, setEmail, refer }) {
  const reffered = false;
  const [modal, setModal] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [emailField, setEmailField] = useState("");
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { width } = useWindowSize();
  const isTheme = useSelector((state) => state.theme.isTheme);
  const fromReferral = useLocation()?.state && true;
  const prevLang = localStorage.getItem("ljs-lang");

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha("register");
    return token || undefined;
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  useEffect(() => {
    dispatch(getCountriesList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInitialValues = () => {
    return {
      email: "",
      password: "",
      referralCode: refer ? refer : "",
      Country: "",
      bncOwner: false
    };
  };

  const countriesList = useSelector((state) => state.kyc.countriesList);
  const CheckSuportForCountry = () => {
    if (selectedCountry === "United Kingdom") {
      setModal(true);
      return false;
    } else {
      const NotIncluded =
        countriesList?.supportedCountry &&
        countriesList?.supportedCountry?.some((values) => values.name === selectedCountry);
      if (selectedCountry.length > 1) {
        setModal(!NotIncluded && true);
      }
      return NotIncluded && true;
    }
  };

  const EmailCheck = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // function validatePassword(password) {
  //   const passwordPattern = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]+$/;
  //   return passwordPattern.test(password);
  // }

  const onSubmit = async (values, { setSubmitting }) => {
    const checkList = CheckSuportForCountry();
    const EmailCheckList = EmailCheck(emailField);
    const source = query.get("utm_source");

    if (checkList && EmailCheckList) {
      setSubmitting(true);
      if (!values.bncOwner) {
        showErrorMsg("Please check that you are the beneficial owner of this account.");
        return;
      }

      let data = {
        password: values.password,
        email: values.email,
        Country: selectedCountry,
        bncOwner: true,
        userType: "individual",
        source: source ? source : ""
      };
      setEmail(values.email);

      if (refer) {
        data.referralCode = refer || values.referralCode;
      }
      if (config.debug) data.debug = "true";
      if (!executeRecaptcha) {
        return;
      }

      const token = await handleReCaptchaVerify();
      if (!token) return;
      await dispatch(registerWithEmailOTP({ ...data, "g-recaptcha-response": token }));
      setSubmitting(false);
    }
  };

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return (
    <>
      {step === 2 && <RegisterStep2 onClose={() => setStep(1)} email={email} />}
      <DivElement
        pageContainerNew
        display="flex"
        alignItems="center"
        gap="32px"
        gapMd="20px"
        widthSm="100%"
        displaySm="block"
        marginSm="32px 0px"
        maxWidthSm="100%"
      >
        <BannerSection
          width="55%"
          display="flex"
          displayLg="none"
          direction="column"
          gap="32px"
          height="700px"
          widthLg="40%"
          displayMd="none"
        >
          {reffered && (
            <BannerBox
              CardWhite
              radius="10px"
              height="188px"
              display="flex"
              gap="20px"
              padding="32px"
            >
              <ImgElement src={MoonRegister} width="80px" height="80px" />

              <BannerBox>
                <HeadingThird mb="20px" size="20px" weight="500" BodyColor>
                  The Moon
                  <BannerHeadingSpan size="14px" weight="500" ml="8px" txtGrey>
                    Referral Code: 1205
                  </BannerHeadingSpan>
                </HeadingThird>
                <PopupReferral>
                  Join me on LCX now! Come unlock up to $30,020 in exclusive rewards and be invited
                  to exclusive events!
                </PopupReferral>
              </BannerBox>
            </BannerBox>
          )}
          <BannerBox
            CardWhite
            radius="10px"
            height={reffered ? "594px" : "700px"}
            // background={`url(${RegisterLightMarketingBanner})`}
          >
            <BannerBox
              display="flex"
              height="100%"
              direction="column"
              justifyContent="center"
              alignItems="center"
              width="100%"
              margin="0px auto"
              gap="20px"
              radius="10px"
              style={{
                // backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                background:
                  refer || fromReferral
                    ? null
                    : `url(${
                        isTheme ? RegisterLightMarketingBanner : LightThemeRegister
                      }) no-repeat center`
              }}
            >
              {(refer || fromReferral) && <ReferralBanner />}
              {/* <ImgElement
                src={isTheme ? RegisterLightMarketingBanner : LightThemeRegister}
                width="75%"
                height="500px"
                heightMd="209px"
              /> */}
              {/* <BannerHeading
                textAlign="center"
                size="20px"
                sizeLg="18px"
                BodyColor
                width="80%"
                margin="10px auto"
              >
                Join LCX Now! Register to enjoy the full range of benefits offered by LCX.
              </BannerHeading> */}
            </BannerBox>
          </BannerBox>
        </BannerSection>
        {/* +++++++++++++++++ */}
        <BannerSection
          SectionWhite
          width="45%"
          widthLg="100%"
          radius="10px"
          height="700px"
          widthMd="100%"
          displayMd="block"
        >
          <BannerBox bottomBorder padding="16px 40px" paddingMd="16px 20px">
            <H1Tag size="24px" weight="500" sizeMd="16px">
              Register
            </H1Tag>
          </BannerBox>
          <BannerBox>
            <Formik
              initialValues={getInitialValues()}
              validateOnMount={true}
              validationSchema={registerValidation}
              onSubmit={onSubmit}
            >
              {(data) => {
                let { values, setFieldValue, errors, isSubmitting } = data;
                setEmailField(values.email);

                return (
                  <>
                    <Form>
                      <BannerBox
                        display="flex"
                        direction="column"
                        alignItems="center"
                        padding="32px 40px"
                        paddingMd="32px 20px"
                        width="100%"
                      >
                        <TextInput
                          title="Email Address"
                          name="email"
                          value={values.email}
                          placeholder="satoshi@lcx.com"
                        />

                        <PasswordInput
                          title="Password"
                          name="password"
                          value={values.password}
                          placeholder="**********"
                          margin="0px 0px 25px 0px"
                          padding="0 40px 0 0.7rem"
                          width="100%"
                        />

                        <CountriesDropdown
                          title="Country of Residency"
                          name="Country"
                          value={values.Country}
                          setFieldValue={setFieldValue}
                          setSelectedCountry={setSelectedCountry}
                          disabled={!countriesList}
                          countryList={formatCountriesArray(
                            countriesList?.allCountry ? countriesList?.allCountry : []
                          )}
                          placeholder="Select Country of Residence"
                          error={errors.Country}
                        />

                        {refer ? (
                          <TextInput
                            title="Referral Code"
                            name="referralCode"
                            value={values?.referralCode}
                            placeholder="Referral Code"
                          />
                        ) : (
                          <TextInput
                            title="Referral Code"
                            name="referralCode"
                            value={values.referralCode}
                            placeholder="Referral Code"
                            optional={true}
                            expandable={true}
                          />
                        )}

                        <BannerBox
                          display="flex"
                          alignItems="center"
                          gap="8px"
                          mb="20px"
                          justifyContent="start"
                          mr="20px"
                        >
                          <Checkbox
                            checked={values.bncOwner}
                            setChecked={(value) => setFieldValue("bncOwner", value)}
                            // containerProps={{ marginLeft: "5px" }}
                          />{" "}
                          <HeadingSix
                            size="12px"
                            BodyColor
                            lineHeight="20px"
                            sizeMd="12px"
                            weight="400"
                          >
                            I am beneficial owner & agree{" "}
                            <AnchorTag
                              txtPrimary
                              href="https://lcx.com/terms-of-service"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Terms of Service
                            </AnchorTag>{" "}
                            and{" "}
                            <AnchorTag
                              txtPrimary
                              href="https://lcx.com/privacy-policy"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              Privacy Policy.
                            </AnchorTag>
                          </HeadingSix>
                        </BannerBox>
                        <ButtonComponent
                          PrimaryButton
                          // type="submit"
                          width="100%"
                          height="50px"
                          heightMd="48px"
                          sizeMd="14px"
                          // onClick={onSubmit}
                          disabled={isSubmitting}
                        >
                          {/* {loader ? <LoadingButton /> : "Submit"} */}
                          {"Register"}
                        </ButtonComponent>

                        <BannerBox display="flex" justifyContent="center" mt="20px">
                          <HeadingSix size="14px">Already have an LCX account?&nbsp;</HeadingSix>
                          <RouterLink
                            to={`${prevLang ? `/${prevLang}` : "/en"}/login`}
                            txtPrimary
                            fontSize="14px"
                            underline
                          >
                            Log in
                          </RouterLink>
                        </BannerBox>
                      </BannerBox>

                      {modal && width > 767 && (
                        <NationalityModal
                          givenEmail={values.email}
                          country={values.Country}
                          onClose={() => setModal(!modal)}
                        />
                      )}
                      {modal && width < 767 && (
                        <OnholdModal
                          givenEmail={values.email}
                          country={values.Country}
                          onClose={() => setModal(!modal)}
                        />
                      )}
                    </Form>
                  </>
                );
              }}
            </Formik>
          </BannerBox>
        </BannerSection>
      </DivElement>
    </>
  );
}

export default RegisterStep1;
