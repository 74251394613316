import React, { useEffect, useRef } from "react";
import { BannerBox, BannerSection } from "../../assets/StyleComponents/Exchange.style";
// import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { ParaTag } from "../../assets/StyleComponents/FontSize/para.style";
import { ButtonComponent } from "../../assets/StyleComponents/Button.style";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import ExchangeLightTheme from "../../assets/videos/ExchangeLightTheme.mp4";
import ExchangeDarkTheme from "../../assets/videos/ExchangeDarkTheme.mp4";
import { useWindowSize } from "../../Hooks/CheckWidthHeight";
import { H1Tag } from "../../assets/StyleComponents/FontSize/fonts.style";

function BannerSectionMain(props) {
  const { promotion } = props;
  const location = useHistory();
  const videoRef = useRef(null);
  const { width } = useWindowSize();

  useEffect(() => {
    // Function to preload the video
    const preloadVideo = () => {
      if (videoRef.current) {
        videoRef.current.load();
      }
    };

    // Call the preload function when the component mounts
    preloadVideo();

    // Cleanup function to remove event listeners or perform other cleanup
    return () => {
      // Add cleanup code if needed
    };
  }, []);
  const Redirect = () => {
    return location.push("/register");
  };
  const isTheme = useSelector((state) => state.theme.isTheme);
  return (
    <BannerSection
      display="flex"
      direction="column"
      alignItem="center"
      justify="space-between"
      gap="50px"
      paddTop={promotion ? "100px" : "70px"}
      // paddTopLg="100px"
      paddTopMd={promotion ? "100px" : "48px"}
      gapLg="0px"
      background={
        isTheme
          ? "linear-gradient(180deg, #0B0D12 0%, #11181F 100%);"
          : "linear-gradient(180deg, rgba(151, 201, 253, 0) 0%, rgba(151, 201, 253, 0.32) 100%);"
      }
      backgroundMd="none"
      backgroundLg="none"
    >
      <BannerBox>
        <H1Tag size="52px" weight="500" textAlign="center" sizeLg="44px" sizeMd="24px">
          Crypto Exchange {width < 767 && <br />} with Integrity
        </H1Tag>
        <ParaTag
          weight="500"
          size="20px"
          textAlign="center"
          mt="16px"
          sizeMd="12px"
          mtMd="16px"
          txtGrey
        >
          Buy, sell and store digital assets at LCX. {width < 767 && <br />} Europe’s leading
          regulated crypto exchange.
        </ParaTag>
        <BannerBox display="flex" justifyContent="center" mt="32px" mtMd="24px">
          <ButtonComponent
            SuccessButton
            minWidth="159px"
            height="44px"
            color="#012243"
            widthMd="144px"
            onClick={Redirect}
            sizeMd="14px"
          >
            Start Trading
          </ButtonComponent>
        </BannerBox>
      </BannerBox>
      <BannerBox
        width="100%"
        display="flex"
        justifyContent="center"
        height={width > 1024 && "460px"}
      >
        <BannerBox
          // height="fit-content"
          displayLg="none"
          displayMd="none"
          maxWidth="1124px"
          width="100%"
          boxSizing="border-box"
        >
          {!isTheme ? (
            <video
              ref={videoRef}
              id="background-video"
              preload="metadata"
              autoPlay
              loop
              muted
              style={{
                width: "100%",
                // height: "100%",
                borderRadius: "16px 16px 0 0",
                verticalAlign: "bottom"
              }}
              src={ExchangeLightTheme}
              type="video/mp4"
              // eslint-disable-next-line react/no-unknown-property
              playsInline
            ></video>
          ) : (
            <video
              ref={videoRef}
              id="background-video"
              preload="metadata"
              autoPlay
              loop
              muted
              style={{
                width: "100%",
                // height: "100%",
                borderRadius: "16px 16px 0 0",
                verticalAlign: "bottom"
              }}
              src={ExchangeDarkTheme}
              type="video/mp4"
              // eslint-disable-next-line react/no-unknown-property
              playsInline
            ></video>
          )}
        </BannerBox>
      </BannerBox>
    </BannerSection>
  );
}

export default BannerSectionMain;
