import styled from "styled-components";
import TimerCET from "./TimerCET";
import { DivElement, TextWrapper } from "../../../../../components/Reusable";
import { DocumentIcon, FaqIcon, SignalGreenIcon, SupportIcon } from "../../../../../assets/svgs";
import { useHubspotChat } from "../../../../../Hooks/HubSpot/hubSpot";
import { useWindowSize } from "../../../../../Hooks/CheckWidthHeight";
import { AnchorTag } from "../../../../../assets/StyleComponents/FontSize/para.style";
import { BannerBox, BannerHeadingSpan } from "../../../../../assets/StyleComponents/Exchange.style";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { HeadingSix } from "../../../../../assets/StyleComponents/FontSize/HeadingSix.style";
import { useMarquee } from "../../../../../Hooks/marquee/usemarquee";

const Container = styled.div`
  box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.04);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 37px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  background-color: ${(props) => props.theme.cardBg};
  z-index: 10000;
`;

const MarqueeContainter = styled(BannerBox)`
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  transition: all 400ms ease-out;

  .marquee-content {
    display: flex;
    animation: marquee 20s linear infinite;
  }
  @keyframes marquee {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-50%);
    }
  }
  .marquee-paragraph {
    font-size: 12px;
    margin-right: 20px;
  }
`;

const TradePageFooter = () => {
  const { toggleChatSupport } = useHubspotChat();
  const { width } = useWindowSize();
  const isMobile = width <= 767 ? true : false;
  const tickersData = useSelector((state) => state.trading.tickersData);

  const tickers = useMemo(() => {
    let keys = Object.keys(tickersData);
    if (keys.length === 0) {
      return [];
    } else {
      let data = keys.map((item) => {
        return { ...tickersData[item] };
      });
      return data;
    }
  }, [tickersData]);

  const TopGainers = useMemo(() => {
    return tickers.length > 0 && tickers?.sort((a, b) => b?.change - a?.change).slice(0, 15);
  }, [tickers]);

  const marqueeRef = useMarquee(10);

  return (
    <Container>
      <DivElement flexAlignCenter>
        <SignalGreenIcon marginRight="6px" />{" "}
        <TextWrapper
          nowrap
          display="flex"
          secondary
          fontSize="12px"
          fontWeight="400"
          className="server_time_localise_js"
        >
          Server time: <TimerCET />
        </TextWrapper>
      </DivElement>

      {width > 1024 && (
        <DivElement minWidth="35%" className="marquee_localise_js">
          <MarqueeContainter>
            <div className="marquee-content" ref={marqueeRef}>
              {TopGainers.length > 0 &&
                TopGainers.map((values) => (
                  <HeadingSix className="marquee-paragraph" BodyColor key={values.symbol}>
                    {values.symbol}
                    <BannerHeadingSpan
                      ml="10px"
                      txtSuccess={values.change >= 0}
                      txtDanger={values.change < 0}
                      size="12px"
                    >
                      {values.change}%
                    </BannerHeadingSpan>
                  </HeadingSix>
                ))}
              {TopGainers.length > 0 &&
                TopGainers.map((values) => (
                  <HeadingSix
                    className="marquee-paragraph"
                    BodyColor
                    key={`${values.symbol}-duplicate`}
                  >
                    {values.symbol}
                    <BannerHeadingSpan
                      ml="10px"
                      txtSuccess={values.change >= 0}
                      txtDanger={values.change < 0}
                      size="12px"
                    >
                      {values.change}%
                    </BannerHeadingSpan>
                  </HeadingSix>
                ))}
            </div>
          </MarqueeContainter>
        </DivElement>
      )}
      {!isMobile && (
        <DivElement flexGrow="1" nowrap display="flex" flexAlignCenter>
          <AnchorTag rel="noreferrer" href="https://docs.lcx.com/" target="_blank" margin="0 20px">
            <DivElement cursorPointer flexAlignCenter>
              <DocumentIcon marginRight="4px" height="16px" width="16px" />{" "}
              <TextWrapper nowrap secondary fontSize="12px" fontWeight="400">
                API Docs
              </TextWrapper>
            </DivElement>
          </AnchorTag>
          <AnchorTag rel="noreferrer" href="https://www.lcx.com/info/faq/" target="_blank">
            <DivElement cursorPointer flexAlignCenter>
              <FaqIcon marginRight="4px" height="16px" width="16px" />{" "}
              <TextWrapper nowrap secondary fontSize="12px" fontWeight="400">
                FAQ’s
              </TextWrapper>
            </DivElement>
          </AnchorTag>
          <DivElement cursorPointer flexAlignCenter marginLeft="32px" onClick={toggleChatSupport}>
            <SupportIcon marginRight="4px" height="16px" width="16px" />{" "}
            <TextWrapper nowrap secondary fontSize="12px" fontWeight="400">
              Chat with us
            </TextWrapper>
          </DivElement>
        </DivElement>
      )}
    </Container>
  );
};

export default TradePageFooter;
