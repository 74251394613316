import { connect } from "react-redux";
import { compose } from "redux";
import styled, { css } from "styled-components";

const Wrapper = styled.div`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "500")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "")};
  letter-spacing: ${(props) => (props.lSpacing ? props.lSpacing : "")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "")};
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "")};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "")};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "")};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "")};
  padding: ${(props) => (props.padding ? props.padding : "")};
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : "")};
  padding-right: ${(props) => (props.paddingRight ? props.paddingRight : "")};
  padding-bottom: ${(props) => (props.paddingBottom ? props.paddingBottom : "")};
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : "")};
  display: ${(props) => (props.display ? props.display : "")};
  position: ${(props) => (props.position ? props.position : "")};
  text-decoration: ${(props) => (props.textDecoration ? props.textDecoration : "")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "")};
  color: ${(props) => (props.isDarkTheme ? "#dedfe0" : "#012243")};
  border: ${(props) => (props.border ? props.border : "")};
  border-top: ${(props) => (props.borderTop ? props.borderTop : "")};
  border-right: ${(props) => (props.borderRight ? props.borderRight : "")};
  border-bottom: ${(props) => (props.borderBottom ? props.borderBottom : "")};
  border-left: ${(props) => (props.borderLeft ? props.borderLeft : "")};
  width: ${(props) => (props.width ? props.width : "")};
  min-width: ${(props) => (props.minWidth ? props.minWidth : "")};
  overflow-wrap: ${(props) => (props.overflowWrap ? props.overflowWrap : "")};
  vertical-align: ${(props) => (props.verticalAlign ? props.verticalAlign : "")};
  opacity: ${(props) => (props.opacity ? props.opacity : "")};
  text-overflow: ${(props) => (props.textOverflow ? props.textOverflow : "")};
  overflow: ${(props) => (props.overflow ? props.overflow : "")};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "")};

  @media (max-width: 1024px) {
    font-size: ${(props) => (props.fontSizeMd ? props.fontSizeMd : "")};
    font-weight: ${(props) => (props.fontWeightMd ? props.fontWeightMd : "")};
    line-height: ${(props) => (props.lineHeightMd ? props.lineHeightMd : "")};
    max-width: ${(props) => (props.maxWidthMd ? props.maxWidthMd : "")};
    max-height: ${(props) => (props.maxHeightMd ? props.maxHeightMd : "")};
    margin: ${(props) => (props.marginMd ? props.marginMd : "")};
    margin-top: ${(props) => (props.marginTopMd ? props.marginTopMd : "")};
    margin-right: ${(props) => (props.marginRightMd ? props.marginRightMd : "")};
    margin-bottom: ${(props) => (props.marginBottomMd ? props.marginBottomMd : "")};
    margin-left: ${(props) => (props.marginLeftMd ? props.marginLeftMd : "")};
    padding: ${(props) => (props.paddingMd ? props.paddingMd : "")};
    text-align: ${(props) => (props.textAlignMd ? props.textAlignMd : "")};
    width: ${(props) => (props.widthMd ? props.widthMd : "")};
    min-width: ${(props) => (props.minWidthMd ? props.minWidthMd : "")};
  }

  @media (max-width: 767px) {
    font-size: ${(props) => (props.fontSizeSm ? props.fontSizeSm : "")};
    font-weight: ${(props) => (props.fontWeightSm ? props.fontWeightSm : "")};
    line-height: ${(props) => (props.lineHeightSm ? props.lineHeightSm : "")};
    max-width: ${(props) => (props.maxWidthSm ? props.maxWidthSm : "")};
    max-height: ${(props) => (props.maxHeightSm ? props.maxHeightSm : "")};
    margin: ${(props) => (props.marginSm ? props.marginSm : "")};
    margin-top: ${(props) => (props.marginTopSm ? props.marginTopSm : "")};
    margin-right: ${(props) => (props.marginRightSm ? props.marginRightSm : "")};
    margin-bottom: ${(props) => (props.marginBottomSm ? props.marginBottomSm : "")};
    margin-left: ${(props) => (props.marginLeftSm ? props.marginLeftSm : "")};
    padding: ${(props) => (props.paddingSm ? props.paddingSm : "")};
    text-align: ${(props) => (props.textAlignSm ? props.textAlignSm : "")};
    width: ${(props) => (props.widthSm ? props.widthSm : "")};
    min-width: ${(props) => (props.minWidthSm ? props.minWidthSm : "")};
  }

  ${(props) =>
    props.primary &&
    css`
      color: ${(props) => (props.isDarkTheme ? "#dedfe0" : "#012243")};
    `}

  ${(props) =>
    props.primaryDark &&
    css`
      color: #dedfe0;
    `}

  ${(props) =>
    props.secondary &&
    css`
      color: ${(props) => (props.isDarkTheme ? "#a7a9ab" : "#606f80")};
    `}

  ${(props) =>
    props.secondaryDisabled &&
    css`
      color: ${(props) => (props.isDarkTheme ? "#5c5f66" : "#ccced0")};
    `}

  ${(props) =>
    props.secondaryDark &&
    css`
      color: #a7a9ab;
    `}

    ${(props) =>
    props.blue &&
    css`
      color: ${(props) => (props.isDarkTheme ? "#70a6e0" : "#0470e1")};
    `}

    ${(props) =>
    props.red &&
    css`
      color: ${(props) => (props.isDarkTheme ? "#e66767" : "#e65252")};
    `}

    ${(props) =>
    props.green &&
    css`
      color: ${(props) => (props.isDarkTheme ? "#68bf60" : "#008000")};
    `}

    ${(props) =>
    props.bgSuccess &&
    css`
      background-color: ${(props) => (props.isDarkTheme ? "#68bf60" : "#008000")};
      color: ${(props) => (props.isDarkTheme ? "#012243" : "#dedfe0")};
    `}

        ${(props) =>
    props.bgWarning &&
    css`
      background-color: ${(props) => (props.isDarkTheme ? "#e66767" : "#e65252")};
      color: ${(props) => (props.isDarkTheme ? "#012243" : "#dedfe0")};
    `}

    ${(props) =>
    props.inputBorder &&
    css`
      color: ${(props) => props.theme.inputBorder};
    `}

    ${(props) =>
    props.orange &&
    css`
      color: #f7931a;
    `}

    ${(props) =>
    props.bgCream &&
    css`
      background-color: ${(props) => (props.isDarkTheme ? "#162029" : "#fafafa")};
    `}
    
    ${(props) =>
    props.cursor &&
    css`
      cursor: pointer;
    `}
    ${(props) =>
    props.underline &&
    css`
      text-decoration: underline;
    `}

     ${(props) =>
    props.dashed &&
    css`
      text-decoration: dashed !important;
    `}

    ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}

    ${(props) =>
    props.bdGrey &&
    css`
      border-color: ${(props) => (props.isDarkTheme ? "#5c5f66" : "#ccced0")};
    `}

    ${(props) =>
    props.bdTransparent &&
    css`
      border-color: transparent;
    `}

    ${(props) =>
    props.bdBlue &&
    css`
      border-color: ${(props) => (props.isDarkTheme ? "#70a6e0" : "#0470e1")};
    `}

    ${(props) =>
    props.nowrap &&
    css`
      white-space: nowrap;
    `}

    ${(props) =>
    props.wordWrap &&
    css`
      word-break: break-all;
      word-wrap: break-word;
    `}

    ${(props) =>
    props.bgYellow &&
    css`
      background-color: ${(props) => (props.isDarkTheme ? "#ffc955" : "#ffc955")};
      color: #012243;
    `}

    ${(props) =>
    props.bgRed &&
    css`
      background-color: ${(props) => (props.isDarkTheme ? "#e66767" : "#e65252")};
    `}

    ${(props) =>
    props.fontWhite &&
    css`
      color: #fff !important;
    `}

     ${(props) =>
    props.highlight &&
    css`
      :hover {
        color: ${(props) => (props.isDarkTheme ? "#fff" : "#000")};
        border-bottom: ${(props) => (props.isDarkTheme ? "1px dashed #fff" : "1px dashed #000")};
      }
    `}
`;

const TextWrapper = (props) => {
  return <Wrapper {...props} />;
};

const mapStateToProps = (state, passedProps) => ({
  isDarkTheme: passedProps.isDarkTheme != null ? passedProps.isDarkTheme : state.theme.isDarkTheme
});

export default compose(connect(mapStateToProps))(TextWrapper);
