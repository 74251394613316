import { call, put, takeEvery, takeLeading, takeLatest } from "redux-saga/effects";
import {
  flushUserState,
  removeAuthorizationForPublic,
  unregisterAllOldServiceWorkers
} from "../utils/general";
import { UPDATE_CURRENT_PAIR_DETAILS } from "../Modules/Trades/const";
import apiConstants from "../constants/apiConstants";
import {
  getMarketTopData,
  getMarketLists,
  selectedPair,
  askPairList,
  logoutUser,
  tradeSetting,
  resetTradeTable,
  getTradePairConfig,
  getTradePairsConfig,
  getTradeHistory,
  selectedPrice,
  updatePairData,
  getOpenOrders,
  getOrderHistory,
  getUserTradeHistory,
  cancelTradeSetting,
  getUserDetails,
  getWalletBalance,
  getRecentActivity,
  setup2FA,
  generate2Auth,
  changePassword,
  getApiKeyManagment,
  getTradeWalletBalance,
  orderTabRecords,
  getMarketTickerData,
  updateFeeList,
  getFeesList,
  getAllWallets,
  createWallet,
  getTransactions,
  withdrawToken,
  withdrawToken2FA,
  getTradingCompetition,
  getContestList,
  getBeneficiary,
  getBeneficiarySingle,
  watchList,
  getAntiPhisingCode,
  updateAntiPhisingCode,
  getActiveSessions,
  logoutfromdevice,
  getOrderFees,
  getTimeZoneCET,
  favTradePairsList,
  updateSocketMarketData,
  updateSocketTickerData,
  getLeaderBoard,
  getPrivateLeaderBoard,
  getUserAllReports,
  downloadReports,
  getHistoryBond,
  getCountryList,
  getKycFaqContent,
  loginUser,
  twoFa,
  twoFaAndRecaptchaLoginOtp,
  recaptchaLoginOtp,
  checkIsLoggedIn,
  getUnderMaintenanceStatus,
  getTokenSales,
  getAllClientSales,
  verifyRedirectUrl,
  getAuthCode,
  resetFilterOrders,
  getAllcategoriesMarket,
  getUserOrders,
  registerWithEmailOTP,
  getTokenInfo,
  updateUserAddress
} from "../lib/api";
import {
  HANDLE_2FA,
  HANDLE_2FA_AND_OTP,
  HANDLE_2FA_EXPIRE,
  HANDLE_EMAIL_NOT_VERIFIED,
  HANDLE_EMAIL_OTP_EXPIRE,
  HANDLE_OTP
} from "../constants/response";
import { setCookie } from "../utils/cookies";
import { updateUserData } from "../lib/User/UserApi";
import { SET_AVAILABLE_LANGUAGES } from "../Modules/Generic/const";
import {
  fetchAvailableLanguagesFailure,
  fetchAvailableLanguagesSuccess
} from "../Modules/Generic/action";

function* _watchList(action) {
  const response = yield call(watchList, action.payload);
  yield put({
    type: apiConstants.GET_WATTCHLIST_DATA_SUCCESS,
    response: response
  });
}

function* _logoutUser() {
  try {
    const response = yield call(logoutUser);
    flushUserState();
    yield put({
      type: apiConstants.LOGOUT_USER_SUCCESS,
      response: response.data
    });
    yield put(removeAuthorizationForPublic());
  } catch (e) {
    flushUserState();
    yield put({ type: apiConstants.LOGOUT_USER_FAIL, response: e.response });
  }
}

function* _getMarketLists(action) {
  try {
    const response = yield call(getMarketLists, action.payload);
    yield put({
      type: apiConstants.GET_MARKET_LISTS_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({ type: apiConstants.GET_MARKET_LISTS_FAIL, response: e.response });
  }
}

function* _getMarketTopData() {
  try {
    const response = yield call(getMarketTopData);
    yield put({
      type: apiConstants.GET_MARKET_TOP_DATA_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({ type: apiConstants.GET_MARKET_TOP_DATA_FAIL, response: e.response });
  }
}

function* _selectedPair(action) {
  try {
    const response = yield call(selectedPair, action.payload);
    yield put({
      type: apiConstants.SELECTED_PAIR_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.SELECTED_PAIR_FAIL,
      response: e.response
    });
  }
}

function* _askPairList(action) {
  try {
    const response = yield call(askPairList, action.payload);
    yield put({
      type: apiConstants.ASK_PAIR_LIST_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.ASK_PAIR_LIST_FAIL,
      response: e.response
    });
  }
}

function* _getTradePairConfig(action) {
  try {
    const response = yield call(getTradePairConfig, action.payload);
    yield put({
      type: UPDATE_CURRENT_PAIR_DETAILS,
      payload: response
    });
    yield put({
      type: apiConstants.GET_TRADE_PAIR_CONFIG_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.GET_TRADE_PAIR_CONFIG_FAIL,
      response: e.response
    });
  }
}

function* _getTradePairsConfig(action) {
  try {
    const response = yield call(getTradePairsConfig, action.payload);
    yield put({
      type: apiConstants.GET_TRADE_PAIRS_CONFIG_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.GET_TRADE_PAIRS_CONFIG_FAIL,
      response: e.response
    });
  }
}
function* _getLeaderBoard(action) {
  try {
    const response = yield call(getLeaderBoard, action.payload);
    yield put({
      type: apiConstants.LEADERBOARD_PAIR_LIST_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.LEADERBOARD_PAIR_LIST_FAIL,
      response: e.response
    });
  }
}
function* _getPrivateLeaderBoard(action) {
  try {
    const response = yield call(getPrivateLeaderBoard, action.payload);
    yield put({
      type: apiConstants.LEADERBOARD_PRIVATE_PAIR_LIST_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.LEADERBOARD_PRIVATE_PAIR_LIST_FAIL,
      response: e.response
    });
  }
}

function* _getTradeHistory(action) {
  try {
    const response = yield call(getTradeHistory, action.payload);
    yield put({
      type: apiConstants.GET_RECENT_TRADE_HISTROY_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.GET_RECENT_TRADE_HISTROY_FAIL,
      response: e.response
    });
  }
}
function* _getActiveSessions(action) {
  try {
    const response = yield call(getActiveSessions, action.payload);
    yield put({
      type: apiConstants.GET_ACTIVESESSIONS_CODE_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.GET_ACTIVESESSIONS_CODE_FAIL,
      response: e.response
    });
  }
}

function* _getUserAllReports(action) {
  try {
    const response = yield call(getUserAllReports, action.payload);
    yield put({
      type: apiConstants.GET_USER_REPORTS_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.GET_USER_REPORTS_FAIL,
      response: e.response
    });
  }
}

function* _downloadReports(action) {
  try {
    const response = yield call(downloadReports, action.payload);
    yield put({
      type: apiConstants.DOWNLOAD_REPORT_REQUEST_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.DOWNLOAD_REPORT_REQUEST_FAIL,
      response: e.response
    });
  }
}
function* _getOrderFees(action) {
  try {
    const response = yield call(getOrderFees, action.payload);
    yield put({
      type: apiConstants.GET_ORDER_FEES_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.GET_ORDER_FEES_FAIL,
      response: e.response
    });
  }
}
function* _getAPIKeys(action) {
  try {
    const response = yield call(getApiKeyManagment, action.payload);
    yield put({
      type: apiConstants.GET_API_LIST_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.GET_API_LIST_FAIL,
      response: e.response
    });
  }
}

function* _selectedPrice(action) {
  try {
    const response = yield call(selectedPrice, action.payload);
    yield put({
      type: apiConstants.SET_SELECTED_PRICE_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.SET_SELECTED_PRICE_FAIL,
      response: e.response
    });
  }
}

function* _updatePairData(action) {
  try {
    const response = yield call(updatePairData, action.payload);
    yield put({
      type: apiConstants.SET_PAIR_DATA_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.SET_PAIR_DATA_FAIL,
      response: e.response
    });
  }
}

function* _getOpenOrders(action) {
  try {
    const response = yield call(getOpenOrders, action.payload);
    if (action.payload.offset) {
      yield put({
        type: apiConstants.GET_OPEN_ORDERS_SUCCESS,
        payload: action.payload,
        response: response
      });
    } else {
      yield put({
        type: apiConstants.GET_OPEN_ORDERS_SUCCESS,
        response: response
      });
    }
  } catch (e) {
    yield put({
      type: apiConstants.GET_OPEN_ORDERS_FAIL,
      response: e.response
    });
  }
}

function* _resetFilterOrders() {
  try {
    const response = yield call(resetFilterOrders);
    yield put({
      type: apiConstants.RESET_FILTER_ORDERS_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.RESET_FILTER_ORDERS_FAIL,
      response: e.response
    });
  }
}

function* _getUserTradeHistory(action) {
  try {
    const response = yield call(getUserTradeHistory, action.payload);
    if (action.payload.offset) {
      yield put({
        type: apiConstants.GET_TRADE_HISTROY_SUCCESS,
        payload: action.payload,
        response: response
      });
    } else {
      yield put({
        type: apiConstants.GET_TRADE_HISTROY_SUCCESS,
        response: response
      });
    }
  } catch (e) {
    yield put({
      type: apiConstants.GET_TRADE_HISTROY_FAIL,
      response: e.response
    });
  }
}

function* _getOrderHistory(action) {
  try {
    const response = yield call(getOrderHistory, action.payload);
    if (action.payload.offset) {
      yield put({
        type: apiConstants.GET_ORDER_HISTORY_SUCCESS,
        payload: action.payload,
        response: response
      });
    } else {
      yield put({
        type: apiConstants.GET_ORDER_HISTORY_SUCCESS,
        response: response
      });
    }
  } catch (e) {
    yield put({
      type: apiConstants.GET_ORDER_HISTORY_FAIL,
      response: e.response
    });
  }
}

function* _tradeSetting(action) {
  const response = yield call(tradeSetting, action.payload);
  yield put({
    type: apiConstants.TRADE_SETTING_SUCCESS,
    response: response
  });
  localStorage.setItem("tradeSetting", JSON.stringify(response));
}

function* _resetTradeTable(action) {
  const response = yield call(resetTradeTable, action.payload);
  yield put({
    type: apiConstants.RESET_TRADE_SETTING_SUCCESS,
    response: response
  });
  // localStorage.setItem('resetTable', JSON.stringify(response))
}

function* _cancelTradeSetting(action) {
  const response = yield call(cancelTradeSetting, action.payload);
  yield put({
    type: apiConstants.CANCEL_TRADE_SETTING_SUCCESS,
    response: response
  });
  // localStorage.setItem('resetTable', JSON.stringify(response))
}

function* _getUserDetails(action) {
  try {
    const response = yield call(getUserDetails);
    yield put({
      type: apiConstants.ALL_LOADING,
      response: true
    });
    yield put({
      type: apiConstants.GET_USER_DETAILS_DATA_SUCCESS,
      response: response.data
    });
    yield put({
      type: apiConstants.ALL_LOADING_SUCCESS,
      response: false
    });
  } catch (e) {
    yield put({
      type: apiConstants.ALL_LOADING,
      response: true
    });
    yield put({ type: apiConstants.GET_USER_DETAILS_DATA_FAIL, response: e.response });
    yield put({
      type: apiConstants.ALL_LOADING_FAIL,
      response: false
    });
  }
}

function* _getWalletBalance(action) {
  try {
    const response = yield call(getWalletBalance);
    let data = response.data;
    let res = {
      data: data?.data,
      totalBalance: data?.totalBalance
    };

    yield put({
      type: apiConstants.GET_WALLET_BALANCE_DATA_SUCCESS,
      response: res
    });
  } catch (e) {
    yield put({ type: apiConstants.GET_WALLET_BALANCE_DATA_FAIL, response: e.response });
  }
}

function* _getRecentActivity(action) {
  try {
    const response = yield call(getRecentActivity, action.payload);
    yield put({
      type: apiConstants.GET_RECENT_ACTIVITY_DATA_SUCCESS,
      response: response.data
    });
  } catch (e) {
    yield put({ type: apiConstants.GET_RECENT_ACTIVITY_DATA_FAIL, response: e.response });
  }
}

function* _setup2FA(action) {
  try {
    const response = yield call(setup2FA, action.payload);
    yield put({
      type: apiConstants.UPDATE_LOCAL_USER_STATE,
      payload: {
        twoFactorAuth: {
          status: action.payload.status
        }
      }
    });
    yield put({
      type: apiConstants.SETUP_2FA_SUCCESS,
      response: response.data
    });
  } catch (e) {
    yield put({
      type: apiConstants.SETUP_2FA_FAIL,
      response: e.response
    });
  }
}

function* _generate2AuthToken() {
  try {
    const response = yield call(generate2Auth);

    yield put({
      type: apiConstants.GENERATE_TWO_AUTH_TOKEN_SUCCESS,
      response: response.data
    });
  } catch (e) {
    yield put({
      type: apiConstants.GENERATE_TWO_AUTH_TOKEN_FAIL,
      response: e.response
    });
  }
}

function* _changePassword(action) {
  try {
    const response = yield call(changePassword, action.payload);

    yield put({
      type: apiConstants.CHANGE_PASSWORD_SUCCESS,
      response: response.data
    });
  } catch (e) {
    yield put({
      type: apiConstants.CHANGE_PASSWORD_FAIL,
      response: e.response
    });
  }
}

function* _getTradeWalletBalance(action) {
  try {
    const response = yield call(getTradeWalletBalance, action.payload);

    yield put({
      type: apiConstants.GET_WALLET_BALANCE_SUCCESS,
      response: response.data
    });
  } catch (e) {
    yield put({
      type: apiConstants.GET_WALLET_BALANCE_FAIL,
      response: e.response
    });
  }
}

function* _orderTabRecords(action) {
  try {
    const response = yield call(orderTabRecords, action.payload);
    yield put({
      type: apiConstants.SET_ORDER_HISTORY_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.SET_ORDER_HISTORY_FAIL,
      response: e.response
    });
  }
}

function* _getMarketTickerData() {
  try {
    const response = yield call(getMarketTickerData);
    yield put({
      type: apiConstants.GET_MARKET_TICKER_LISTS_SUCCESS,
      response: response.data.data
    });
  } catch (e) {
    yield put({
      type: apiConstants.GET_MARKET_TICKER_LISTS_FAIL,
      response: e.response
    });
  }
}

function* _getFeesList() {
  try {
    const response = yield call(getFeesList);
    yield put({
      type: apiConstants.GET_FEES_LIST_SUCCESS,
      response: response.data
    });
  } catch (e) {
    yield put({
      type: apiConstants.GET_FEES_LIST_FAIL,
      response: e.response
    });
  }
}

function* _updateFeeList(action) {
  try {
    const response = yield call(updateFeeList, action.payload);
    yield put({
      type: apiConstants.UPDATE_FEES_LIST_SUCCESS,
      response: response.data
    });
  } catch (e) {
    yield put({
      type: apiConstants.UPDATE_FEES_LIST_FAIL,
      response: e.response
    });
  }
}

function* _getAllWallets() {
  try {
    const response = yield call(getAllWallets);
    let data = response.data;
    let res = {
      data: data?.data,
      totalBalance: data?.totalBalance
    };
    yield put({
      type: apiConstants.GET_ALL_WALLETS_SUCCESS,
      response: res
    });
  } catch (e) {
    yield put({
      type: apiConstants.GET_ALL_WALLETS_FAIL,
      response: e.response
    });
  }
}

function* _createWallet(action) {
  try {
    const response = yield call(createWallet, action.payload);

    yield put({
      type: apiConstants.CREATE_WALLET_SUCCESS,
      response: response.data
    });
  } catch (e) {
    yield put({
      type: apiConstants.CREATE_WALLET_FAIL,
      response: e.response
    });
  }
}

function* _getTransactions(action) {
  try {
    const response = yield call(getTransactions, action.payload);
    yield put({
      type: apiConstants.GET_TRANSACTIONS_SUCCESS,
      response: response.data,
      mode: action.payload.type
    });
  } catch (e) {
    yield put({
      type: apiConstants.GET_TRANSACTIONS_FAIL,
      response: e.response
    });
  }
}

function* _withdrawToken(action) {
  try {
    const response = yield call(withdrawToken, action.payload);
    yield put({
      type: apiConstants.WITHDRAW_TOKEN_SUCCESS,
      response: response.data
    });
  } catch (e) {
    yield put({
      type: apiConstants.WITHDRAW_TOKEN_FAIL,
      response: e.response
    });
  }
}

function* _withdrawToken2FA(action) {
  try {
    const response = yield call(withdrawToken2FA, action.payload);
    yield put({
      type: apiConstants.WITHDRAW_TOKEN_2FA_SUCCESS,
      response: response.data
    });
  } catch (e) {
    yield put({
      type: apiConstants.WITHDRAW_TOKEN_2FA_FAIL,
      response: e.response
    });
  }
}

function* _updatePrivateSocketBalance(action) {
  try {
    const response = yield call(orderTabRecords, action.payload);

    yield put({
      type: apiConstants.UPDATE_SOCKET_BALANCE_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.UPDATE_SOCKET_BALANCE_FAIL,
      response: e.response
    });
  }
}

function* _updatePrivateSocketOrder(action) {
  try {
    const response = yield call(orderTabRecords, action.payload);

    yield put({
      type: apiConstants.UPDATE_SOCKET_ORDERS_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.UPDATE_SOCKET_ORDERS_FAIL,
      response: e.response
    });
  }
}
function* _updateActiveUser(action) {
  try {
    const response = yield call(logoutfromdevice, action.payload);

    yield put({
      type: apiConstants.UPDATE_ACTIVESESSIONS_CODE_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.UPDATE_ACTIVESESSIONS_CODE_FAIL,
      response: e.response
    });
  }
}

function* _getTradingCompetition(action) {
  try {
    const response = yield call(getTradingCompetition, action.payload);

    yield put({
      type: apiConstants.GET_TRADING_COMPETITION_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.GET_TRADING_COMPETITION_FAIL,
      response: e.response
    });
  }
}

function* _getBeneficiary(action) {
  try {
    const response = yield call(getBeneficiary);

    yield put({
      type: apiConstants.GET_BENEFICIARY_ALL_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.GET_BENEFICIARY_ALL_FAIL,
      response: e.response
    });
  }
}

function* _getBeneficiarySingle(action) {
  try {
    const response = yield call(getBeneficiarySingle, action.payload);

    yield put({
      type: apiConstants.GET_BENEFICIARY_SINGLE_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.GET_BENEFICIARY_SINGLE_FAIL,
      response: e.response
    });
  }
}

function* _getContestList(action) {
  try {
    const response = yield call(getContestList, action.payload);

    yield put({
      type: apiConstants.GET_CONTEST_USER_LIST_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.GET_CONTEST_USER_LIST_FAIL,
      response: e.response
    });
  }
}

// Get Anti-phising Code
function* _getAntiPhisingCode() {
  try {
    const response = yield call(getAntiPhisingCode);
    yield put({
      type: apiConstants.GET_ANTIPHISING_CODE_SUCCESS,
      response: response.data
    });
  } catch (e) {
    yield put({
      type: apiConstants.GET_ANTIPHISING_CODE_FAIL,
      response: e.response
    });
  }
}

function* _updateAntiPhisingCode(action) {
  try {
    const response = yield call(updateAntiPhisingCode, action.payload);

    yield put({
      type: apiConstants.UPDATE_ANTIPHISING_CODE_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.UPDATE_ANTIPHISING_CODE_FAIL,
      response: e.response
    });
  }
}

function* _favTradePairsList(action) {
  try {
    const response = yield call(favTradePairsList, action.payload);
    yield put({
      type: apiConstants.FAV_PAIR_LIST_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.FAV_PAIR_LIST_FAIL,
      response: e.response
    });
  }
}

// Accounts Module
function* _getBondInvestHistory(action) {
  try {
    const response = yield call(getHistoryBond, action.payload);
    yield put({
      type: apiConstants.GET_BOND_INVEST_HISTORY_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.GET_BOND_INVEST_HISTORY_FAIL,
      response: e.response
    });
  }
}

function* _getCountries(action) {
  try {
    const response = yield call(getCountryList);
    yield put({
      type: apiConstants.GET_COUNTRIES_LIST_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.GET_COUNTRIES_LIST_FAIL,
      response: e.response
    });
  }
}

// get Kyc Content
function* _getKycContent(action) {
  try {
    const response = yield call(getKycFaqContent, action.payload);

    yield put({
      type: apiConstants.GET_KYC_FAQ_CONTENT_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.GET_KYC_FAQ_CONTENT_FAIL,
      response: e.response
    });
  }
}

// Login user using email and password
function* _loginUser(action) {
  try {
    const response = yield call(loginUser, action.payload);
    if (response.data && response.data.status === "error") {
      if (response.data.message === HANDLE_2FA_AND_OTP) {
        // 2FA and OTP
        yield put({
          type: apiConstants.NEED_TWO_AUTH_FOR_LOGIN,
          response: response.data
        });
        yield put({
          type: apiConstants.NEED_OTP_FOR_LOGIN,
          response: response.data
        });
      }
      if (response.data.message === HANDLE_2FA) {
        yield put({
          type: apiConstants.NEED_TWO_AUTH_FOR_LOGIN,
          response: response.data
        });
      }
      if (response.data.message === HANDLE_OTP) {
        yield put({
          type: apiConstants.NEED_OTP_FOR_LOGIN,
          response: response.data
        });
      }
    } else {
      let { data } = response;
      let token = data.data.token;
      let { domain, redirectUrl } = data.data;
      unregisterAllOldServiceWorkers();

      localStorage.setItem("accessToken", token);
      setCookie("lcx-csrf", data.data.state, 10);

      yield put({
        type: apiConstants.LOGIN_USER_SUCCESS,
        response: response.data
      });
      if (domain !== "exchange")
        yield put({
          type: apiConstants.REDIRECT_BACK,
          response: { domain, redirectUrl }
        });
    }
  } catch (e) {
    if (e.response.data.message === HANDLE_EMAIL_NOT_VERIFIED) {
      yield put({
        type: apiConstants.REDIRECT_TO_VERIFY_EMAIL,
        response: e.response
      });
    } else {
      yield put({ type: apiConstants.LOGIN_USER_FAIL, response: e.response });
    }
  }
}

function* _recaptchaLoginOtp(action) {
  try {
    const response = yield call(recaptchaLoginOtp, action.payload);
    if (response.data && response.data.status === "error") {
      yield put({
        type: apiConstants.NEED_OTP_FOR_LOGIN,
        response: response.data
      });
    } else {
      let { data } = response;
      let token = data.data.token;
      let { domain, redirectUrl } = data.data;
      unregisterAllOldServiceWorkers();
      localStorage.setItem("accessToken", token);
      setCookie("lcx-csrf", data.data.state, 10);
      yield put({
        type: apiConstants.LOGIN_WITH_TWO_FA_SUCCESS,
        response: response.data
      });
      if (domain !== "exchange")
        yield put({
          type: apiConstants.REDIRECT_BACK,
          response: { domain, redirectUrl }
        });
    }
  } catch (e) {
    if (e.response.data.message === HANDLE_EMAIL_OTP_EXPIRE) {
      yield put({
        type: apiConstants.TWO_FA_OR_OTP_EXPIRE_REDIRECT,
        response: e.response
      });
    } else {
      yield put({ type: apiConstants.LOGIN_WITH_TWO_FA_FAIL, response: e.response });
    }
  }
}

function* _registerWithEmailOTP(action) {
  try {
    const response = yield call(registerWithEmailOTP, action.payload);
    yield put({
      type: apiConstants.REGISTER_EMAIL_SUCCESS,
      response: response.data
    });
  } catch (e) {
    // if (e.response.data.message === HANDLE_EMAIL_OTP_EXPIRE) {
    //   yield put({
    //     type: apiConstants.TWO_FA_OR_OTP_EXPIRE_REDIRECT,
    //     response: e.response
    //   });
    // } else {
    yield put({ type: apiConstants.REGISTER_EMAIL_FAIL, response: e.response });
    // }
  }
}

function* _resetRegisterAuthCode() {
  try {
    yield put({
      type: apiConstants.RESET_REGISTER_AUTH_CODE_SUCCESS,
      response: true
    });
  } catch (e) {
    throw new e();
  }
}

function* _twoFaAndRecaptchaLoginOtp(action) {
  try {
    const response = yield call(twoFaAndRecaptchaLoginOtp, action.payload);
    if (response.data && response.data.status === "error") {
      yield put({
        type: apiConstants.NEED_TWO_AUTH_FOR_LOGIN,
        response: response.data
      });
      yield put({
        type: apiConstants.NEED_OTP_FOR_LOGIN,
        response: response.data
      });
    } else {
      let { data } = response;
      let token = data.data.token;
      let { domain, redirectUrl } = data.data;
      unregisterAllOldServiceWorkers();

      localStorage.setItem("accessToken", token);
      setCookie("lcx-csrf", data.data.state, 10);
      yield put({
        type: apiConstants.LOGIN_WITH_TWO_FA_SUCCESS,
        response: response.data
      });
      if (domain !== "exchange")
        yield put({
          type: apiConstants.REDIRECT_BACK,
          response: { domain, redirectUrl }
        });
    }
  } catch (e) {
    if (
      e.response.data.message === HANDLE_EMAIL_OTP_EXPIRE ||
      e.response.data.message === HANDLE_2FA_EXPIRE
    ) {
      yield put({
        type: apiConstants.TWO_FA_OR_OTP_EXPIRE_REDIRECT,
        response: e.response
      });
    } else {
      yield put({ type: apiConstants.LOGIN_WITH_TWO_FA_FAIL, response: e.response });
    }
  }
}

function* _twoFa(action) {
  try {
    const response = yield call(twoFa, action.payload);
    if (response.data && response.data.status === "error") {
      yield put({
        type: apiConstants.NEED_TWO_AUTH_FOR_LOGIN,
        response: response.data
      });
    } else {
      let { data } = response;
      let token = data.data.token;
      let { domain, redirectUrl } = data.data;
      unregisterAllOldServiceWorkers();
      localStorage.setItem("accessToken", token);
      setCookie("lcx-csrf", data.data.state, 10);
      yield put({
        type: apiConstants.LOGIN_WITH_TWO_FA_SUCCESS,
        response: response.data
      });
      if (domain !== "exchange")
        yield put({
          type: apiConstants.REDIRECT_BACK,
          response: { domain, redirectUrl }
        });
    }
  } catch (e) {
    if (e.response.data.message === HANDLE_2FA_EXPIRE) {
      yield put({
        type: apiConstants.TWO_FA_OR_OTP_EXPIRE_REDIRECT,
        response: e.response
      });
    } else {
      yield put({ type: apiConstants.LOGIN_WITH_TWO_FA_FAIL, response: e.response });
    }
  }
}

function* _checkIsLoggedIn() {
  try {
    const response = yield call(checkIsLoggedIn);
    if (response) {
      yield put({ type: apiConstants.CHECK_IS_USER_LOGGED_IN_SUCCESS, response: response.data });
    } else {
      throw new Error("User Not Logged In");
    }
  } catch (e) {
    flushUserState();
    yield put({ type: apiConstants.CHECK_IS_USER_LOGGED_IN_FAIL, response: e.response });
  }
}

function* _handle401() {
  yield put({
    type: apiConstants.SESSION_EXPIRED
  });
  yield put({
    type: apiConstants.LOGOUT_USER_SUCCESS
  });
}

// Site Under Maintenance
function* _getSiteUnderMaintenance() {
  try {
    const response = yield call(getUnderMaintenanceStatus);

    yield put({
      type: apiConstants.SITE_MAINTENANCE_SUCCESS,
      response: response
    });
  } catch (e) {
    if (e.response && e.response.status === 503) {
      yield put({
        type: apiConstants.SITE_UNDER_MAINTENANCE,
        response: e.response
      });
    } else
      yield put({
        type: apiConstants.SITE_MAINTENANCE_FAIL,
        response: e.response
      });
  }
}

function* _getTokenSales() {
  try {
    const response = yield call(getTokenSales);
    yield put({
      type: apiConstants.GET_TOKEN_SALES_SUCCESS,
      response: response ? response.data : ""
    });
  } catch (e) {
    yield put({
      type: apiConstants.GET_TOKEN_SALES_FAIL,
      response: e.response
    });
  }
}

function* _getAllClientSale() {
  try {
    const response = yield call(getAllClientSales);

    yield put({
      type: apiConstants.GET_ALL_CLIENT_SALES_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.GET_ALL_CLIENT_SALES_FAIL,
      response: e.response
    });
  }
}

function* _verifyRedirectUrl(action) {
  try {
    const response = yield call(verifyRedirectUrl, action.payload);
    const { domain } = response.data.data;
    if (domain !== "exchange") {
      yield put({
        type: apiConstants.VERIFY_REDIRECT_URL_SUCCESS,
        response: response.data.data
      });
    }
  } catch (error) {
    yield put({ type: apiConstants.VERIFY_REDIRECT_URL_FAIL });
  }
}

function* _getAuthCode(action) {
  try {
    const response = yield call(getAuthCode, action.payload);
    let token = response?.data?.data?.authCode;
    let { domain, redirectUrl } = action.payload;
    yield put({
      type: apiConstants.GET_AUTH_CODE_SUCCESS,
      response: token
    });
    yield put({
      type: apiConstants.REDIRECT_BACK,
      response: { domain, redirectUrl }
    });
  } catch (e) {
    yield put({ type: apiConstants.GET_AUTH_CODE_FAIL, response: e.response });
  }
}

function* _getTimeZoneCET() {
  try {
    const response = yield call(getTimeZoneCET);
    yield put({
      type: apiConstants.GET_TIME_ZONE_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.GET_TIME_ZONE_FAIL,
      response: e.response
    });
  }
}

function* _updateSocketMarketData(action) {
  try {
    const response = yield call(updateSocketMarketData, action.payload);

    yield put({
      type: apiConstants.GET_MARKET_LISTS_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({ type: apiConstants.GET_MARKET_LISTS_FAIL, response: e.response });
  }
}

function* _updateSocketTickerData(action) {
  try {
    const response = yield call(updateSocketTickerData, action.payload);

    yield put({
      type: apiConstants.UPDATE_TICKER_DATA_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({ type: apiConstants.UPDATE_TICKER_DATA_FAIL, response: e.response });
  }
}

function* _getSelectedPairConfig(action) {
  try {
    const response = yield call(getTradePairConfig, action.payload);
    yield put({
      type: apiConstants.GET_SELECTED_PAIR_CONFIG_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.GET_SELECTED_PAIR_CONFIG_FAIL,
      response: e.response
    });
  }
}

function* _getAllPairsCategory() {
  try {
    const response = yield call(getAllcategoriesMarket);
    yield put({
      type: apiConstants.GET_ALL_PAIRS_CATEGORIES_SUCCESS,
      response: response.data
    });
  } catch (e) {
    yield put({
      type: apiConstants.GET_ALL_PAIRS_CATEGORIES_FAIL,
      response: e.response
    });
  }
}

function* _getUserOrders(action) {
  try {
    const response = yield call(getUserOrders, action.payload);

    let res = {
      data: response?.data?.data,
      totalCount: response?.data?.totalCount,
      type: action.payload.type
    };
    yield put({
      type: apiConstants.GET_USER_ORDERS_SUCCESS,
      response: res
    });
  } catch (e) {
    yield put({
      type: apiConstants.GET_USER_ORDERS_FAIL,
      response: e.response
    });
  }
}

function* _updateUserData(action) {
  try {
    if (action.payload !== "noData") {
      const response = yield call(updateUserData, action.payload);

      yield put({
        type: apiConstants.UPDATE_USER_DATA_SUCCESS,
        response: response
      });
    }
  } catch (e) {
    yield put({
      type: apiConstants.UPDATE_USER_DATA_FAIL,
      response: e.response
    });
  }
}

function* _fetchAvailableLanguagesSaga() {
  try {
    const data = yield new Promise((resolve, reject) => {
      window.Localize.getAvailableLanguages((err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
    yield put(fetchAvailableLanguagesSuccess(data));
  } catch (err) {
    yield put(fetchAvailableLanguagesFailure(err));
  }
}

function* _tokenInfo(action) {
  try {
    const response = yield call(getTokenInfo, action.payload);
    yield put({
      type: apiConstants.GET_TOKEN_INFO_DATA_SUCCESS,
      response: response
    });
  } catch (e) {
    yield put({
      type: apiConstants.GET_TOKEN_INFO_DATA_FAIL,
      response: e.response
    });
  }
}

function* _postAddressUpdate(action) {
  try {
    const response = yield call(updateUserAddress, action.payload);
    // update user data post submitting POA
    const userResponse = yield call(getUserDetails);

    yield put({
      type: apiConstants.POST_USER_ADDRESS_UPDATE_REQUEST_SUCCESS,
      response: response
    });

    yield put({
      type: apiConstants.GET_USER_DETAILS_DATA_SUCCESS,
      response: userResponse.data
    });
  } catch (e) {
    yield put({
      type: apiConstants.POST_USER_ADDRESS_UPDATE_REQUEST_FAIL,
      response: e.response
    });
  }
}

/**
 *  Export All Api Action
 *
 */

export function* Saga() {
  yield takeEvery(apiConstants.GET_API_LIST, _getAPIKeys); // get Al APIS
  yield takeEvery(apiConstants.GET_USER_REPORTS, _getUserAllReports); // Hit for Login
  yield takeEvery(apiConstants.DOWNLOAD_REPORT_REQUEST, _downloadReports); // Hit for Login
  yield takeEvery(apiConstants.LEADERBOARD_PAIR_LIST, _getLeaderBoard); // Hit for Theme
  yield takeEvery(apiConstants.LEADERBOARD_PRIVATE_PAIR_LIST, _getPrivateLeaderBoard); // Hit for Theme
  yield takeEvery(apiConstants.GET_MARKET_TOP_DATA, _getMarketTopData); // Hit for get Market Top Data
  yield takeEvery(apiConstants.GET_MARKET_LISTS, _getMarketLists); // Hit for Market Page
  yield takeEvery(apiConstants.SELECTED_PAIR, _selectedPair); // Hit for Selected Page
  yield takeEvery(apiConstants.GET_TRADE_PAIR_CONFIG, _getTradePairConfig); // Hit for Trade Pair config
  yield takeEvery(apiConstants.GET_TRADE_PAIRS_CONFIG, _getTradePairsConfig); // Hit for Trade Pair config
  yield takeEvery(apiConstants.GET_RECENT_TRADE_HISTROY, _getTradeHistory); // Hit for Recent Trade History
  yield takeEvery(apiConstants.GET_OPEN_ORDERS, _getOpenOrders); // Hit for get all user open trades
  yield takeEvery(apiConstants.GET_ORDER_HISTORY, _getOrderHistory); // Hit for get all user open History
  yield takeEvery(apiConstants.GET_TRADE_HISTROY, _getUserTradeHistory); // Hit for get all use trades history
  yield takeEvery(apiConstants.SET_SELECTED_PRICE, _selectedPrice); // Hit for Update Selected Price
  yield takeEvery(apiConstants.SET_PAIR_DATA, _updatePairData); // Hit for Update Selected Price
  yield takeEvery(apiConstants.ASK_PAIR_LIST, _askPairList); // Hit for Ask Trade List
  yield takeEvery(apiConstants.LOGOUT_USER, _logoutUser); // Hit for Logout User
  yield takeEvery(apiConstants.TRADE_SETTING, _tradeSetting); // Hit for Trading Setting
  yield takeEvery(apiConstants.RESET_TRADE_SETTING, _resetTradeTable); // Hit for Resetting Table
  yield takeEvery(apiConstants.CANCEL_TRADE_SETTING, _cancelTradeSetting); // Hit for Cancel Trade Table
  yield takeEvery(apiConstants.GET_USER_DETAILS_DATA, _getUserDetails); // Hit for Get User
  yield takeEvery(apiConstants.GET_WALLET_BALANCE_DATA, _getWalletBalance); // Hit for Get Wallet Balance
  yield takeEvery(apiConstants.GET_RECENT_ACTIVITY_DATA, _getRecentActivity); // Hit for Get Wallet Balance
  yield takeEvery(apiConstants.GENERATE_TWO_AUTH_TOKEN, _generate2AuthToken); // Hit for Generate 2Auth token
  yield takeEvery(apiConstants.SETUP_2FA, _setup2FA); // Hit for Setup 2Fa
  yield takeEvery(apiConstants.CHANGE_PASSWORD, _changePassword); // Hit for Change Password
  // yield takeEvery(apiConstants.GET_KEY_MANAGEMENT, _getApiKeyManagment); // Hit for Change Password
  yield takeEvery(apiConstants.GET_WALLET_BALANCE, _getTradeWalletBalance); // Hit for Change Password
  yield takeEvery(apiConstants.SET_ORDER_HISTORY, _orderTabRecords); // Hit for Change Password
  yield takeEvery(apiConstants.GET_MARKET_TICKER_LISTS, _getMarketTickerData); // Hit for Market Ticker Data
  yield takeEvery(apiConstants.GET_FEES_LIST, _getFeesList); // Hit for Market Ticker Data
  yield takeEvery(apiConstants.UPDATE_FEES_LIST, _updateFeeList); // Hit for Market Ticker Data
  yield takeEvery(apiConstants.GET_ALL_WALLETS, _getAllWallets); // Hit for get Account Balance
  yield takeEvery(apiConstants.CREATE_WALLET, _createWallet); // Hit for Create Wallet
  yield takeEvery(apiConstants.GET_TRANSACTIONS, _getTransactions); // Hit for Get Withrawal and Deposit History
  yield takeEvery(apiConstants.WITHDRAW_TOKEN, _withdrawToken); // Hit for Withrawal Token
  yield takeEvery(apiConstants.WITHDRAW_TOKEN_2FA, _withdrawToken2FA); // Hit for Withrawal Token 2fa
  yield takeEvery(apiConstants.UPDATE_SOCKET_BALANCE, _updatePrivateSocketBalance); // Hit for Withrawal Token 2fa
  yield takeEvery(apiConstants.UPDATE_SOCKET_ORDERS, _updatePrivateSocketOrder); // Hit for Withrawal Token 2fa
  yield takeEvery(apiConstants.GET_TRADING_COMPETITION, _getTradingCompetition); // Hit for get Competition
  yield takeEvery(apiConstants.GET_CONTEST_USER_LIST, _getContestList); // Hit for get contest List
  yield takeEvery(apiConstants.GET_BENEFICIARY_ALL, _getBeneficiary);
  yield takeEvery(apiConstants.GET_BENEFICIARY_SINGLE, _getBeneficiarySingle);
  yield takeEvery(apiConstants.GET_WATTCHLIST_DATA, _watchList);
  yield takeEvery(apiConstants.GET_ANTIPHISING_CODE, _getAntiPhisingCode);
  yield takeEvery(apiConstants.UPDATE_ANTIPHISING_CODE, _updateAntiPhisingCode);
  yield takeEvery(apiConstants.GET_ACTIVESESSIONS_CODE, _getActiveSessions);
  yield takeEvery(apiConstants.UPDATE_ACTIVESESSIONS_CODE, _updateActiveUser);
  yield takeEvery(apiConstants.GET_ORDER_FEES, _getOrderFees);
  yield takeEvery(apiConstants.GET_TIME_ZONE, _getTimeZoneCET);
  yield takeEvery(apiConstants.FAV_PAIR_LIST, _favTradePairsList);
  yield takeEvery(apiConstants.GET_MARKET_LISTS, _updateSocketMarketData); // Hit for Market Page
  yield takeEvery(apiConstants.UPDATE_TICKER_DATA, _updateSocketTickerData); // Hit for Ticker Page

  // Accounts
  yield takeEvery(apiConstants.GET_BOND_INVEST_HISTORY, _getBondInvestHistory);
  yield takeEvery(apiConstants.GET_COUNTRIES_LIST, _getCountries);
  yield takeEvery(apiConstants.GET_KYC_FAQ_CONTENT, _getKycContent);
  yield takeLeading(apiConstants.LOGIN_USER, _loginUser);
  yield takeEvery(apiConstants.LOGIN_WITH_RECAPTCHA_EMAIL_OTP, _recaptchaLoginOtp);
  yield takeEvery(apiConstants.REGISTER_EMAIL, _registerWithEmailOTP);
  yield takeEvery(apiConstants.RESET_REGISTER_AUTH_CODE, _resetRegisterAuthCode);
  yield takeEvery(
    apiConstants.TWO_FA_AND_LOGIN_WITH_RECAPTCHA_EMAIL_OTP,
    _twoFaAndRecaptchaLoginOtp
  );
  yield takeEvery(apiConstants.LOGIN_WITH_TWO_FA, _twoFa);
  yield takeEvery(apiConstants.CHECK_IS_USER_LOGGED_IN, _checkIsLoggedIn);
  yield takeEvery(apiConstants.HANDLE_401, _handle401);
  yield takeEvery(apiConstants.SITE_MAINTENANCE, _getSiteUnderMaintenance);
  yield takeEvery(apiConstants.GET_TOKEN_SALES, _getTokenSales);
  yield takeEvery(apiConstants.GET_ALL_CLIENT_SALES, _getAllClientSale);
  yield takeEvery(apiConstants.VERIFY_REDIRECT_URL, _verifyRedirectUrl);
  yield takeEvery(apiConstants.GET_AUTH_CODE, _getAuthCode);
  yield takeEvery(apiConstants.RESET_FILTER_ORDERS, _resetFilterOrders);
  yield takeEvery(apiConstants.GET_SELECTED_PAIR_CONFIG, _getSelectedPairConfig);
  yield takeEvery(apiConstants.GET_ALL_PAIRS_CATEGORIES, _getAllPairsCategory);
  yield takeEvery(apiConstants.GET_USER_ORDERS, _getUserOrders);
  yield takeEvery(apiConstants.UPDATE_USER_DATA, _updateUserData);
  yield takeEvery(apiConstants.UPDATE_USER_DATA, _updateUserData);
  yield takeEvery(SET_AVAILABLE_LANGUAGES, _fetchAvailableLanguagesSaga);

  yield takeLatest(apiConstants.GET_TOKEN_INFO_DATA, _tokenInfo); // Base Token Info
  yield takeLatest(apiConstants.POST_USER_ADDRESS_UPDATE_REQUEST, _postAddressUpdate);
}
