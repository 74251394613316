/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useRef, useState } from "react";
import { getDectaTransactionStatus } from "../../../../lib/api";
import { showErrorMsg, showSuccessMsg } from "../../../../utils/notification";
import { formatAPIErrors, numberWithCommas } from "../../../../utils/general";
import { DivElement, LineStatusBar, ModalWrapper, TextWrapper } from "../../../Reusable";
import { CloseIcon, CopyBlueIcon, LinkBlueIcon } from "../../../../assets/svgs";
import CardDetails from "./CardDetails";
import { HourGlassIllustration } from "../../../../assets/svgs/illustrations";
import CopyToClipboard from "react-copy-to-clipboard";
import styled from "styled-components";
import { capitalize } from "../../../../utils";
import moment from "moment";
import { formatNumbers } from "../../../../utils/helpers";
import { LoadingButton } from "../../../Loader";

const STATUS_STEPS = {
  Initiated: 1,
  "Partially Confirmed": 2,
  "Approval Pending": 3,
  Confirmed: 4,
  "Processing refund": 5,
  "Refund confirmed": 6,
  Failed: 7,
  Rejected: 7
};

const TxnIdWrapper = styled(TextWrapper)`
  max-width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-break: break-all;

  @media (max-width: 1024px) {
    max-width: 250px;
  }

  @media (max-width: 768px) {
    max-width: 200px;
  }
`;

const DectaTransactionStatus = ({ txnId, onClose }) => {
  const [txnData, setTxnData] = useState("");
  const [txnDetails, setTxnDetails] = useState("");
  const [loader, setLoader] = useState(false);
  const [statusStep, setStatusStep] = useState(1);
  let fetchingStatus = useRef(false);

  useEffect(() => {
    getTransactionStatus(true);
    fetchingStatus.current = setInterval(() => {
      getTransactionStatus(false);
    }, [5000]);

    return () => {
      clearInterval(fetchingStatus.current);
    };
  }, []);

  useEffect(() => {
    if (!txnDetails) return;
    const status_step = STATUS_STEPS[txnDetails.txStatus];

    let data = [];
    if (txnDetails?.txStatus) {
      data.push({ label: "Status", value: capitalize(txnDetails?.txStatus) });
    }

    if (txnDetails?.createdAt) {
      data.push({
        label: "Date & time",
        value: moment(txnDetails?.createdAt).format("DD/MM/YY, hh:mm a")
      });
    }

    data.push({
      label: "Fiat",
      value: `${txnDetails?.coin} (${txnDetails?.txSource})`
    });

    data.push({
      label: "Deposit amount",
      value: `${numberWithCommas(formatNumbers(txnDetails?.amount))} ${txnDetails?.coin}`
    });

    if (txnDetails?.txFee) {
      data.push({
        label: "Card processing fee",
        value: `${numberWithCommas(formatNumbers(txnDetails?.txFee) || 0)} ${txnDetails?.coin}`
      });
    }

    if (txnDetails?.cardInfo) {
      data.push({
        label: "Card number",
        value: txnDetails?.cardInfo
      });
    }

    if (txnDetails?.transactionId) {
      data.push({
        label: status_step === 5 || status_step === 6 ? "Refund ID" : "Tx Id",
        value: txnDetails?.transactionId
      });
    }

    setTxnData(data);

    setStatusStep(status_step);
  }, [txnDetails]);

  const getTransactionStatus = (showLoading) => {
    if (!fetchingStatus.current) return true;
    if (showLoading) setLoader(true);
    let data = {
      txId: txnId
    };
    getDectaTransactionStatus(data)
      .then((res) => {
        const status = res.data?.data?.txStatus;
        if (status && (status === "REJECTED" || status === "CONFIRMED")) {
          clearInterval(fetchingStatus.current);
          fetchingStatus.current = false;
        }
        setTxnDetails(res?.data?.data);
      })
      .catch((err) => {
        onClose();
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => setLoader(false));
  };

  const onTxnIdCopy = (label) => {
    showSuccessMsg(
      `Successfully copied ${
        label === "Address" ? "Address" : label === "Memo" ? "Memo ID" : "Transaction Id"
      }`
    );
  };

  return (
    <ModalWrapper>
      <DivElement ModalContainer padding="20px 16px 32px 14px" paddingSm="16px 12px 32px 10px">
        <DivElement displayBtw>
          <TextWrapper
            fontSize="20px"
            fontSizeSm="16px"
            lineHeight="24px"
            paddingLeft="10px"
            paddingLeftSm="6px"
          >
            Deposit Details
          </TextWrapper>
          <CloseIcon onClick={onClose} />
        </DivElement>
        {!loader ? (
          <DivElement marginTop="40px" marginTopSm="22px">
            <DivElement width="100%" flex>
              <LineStatusBar
                step="1"
                status={statusStep === 1 ? "pending" : statusStep === 7 ? "failed" : "success"}
              />
              <DivElement paddingBottom="30px" paddingBottomSm="22px">
                <TextWrapper
                  fontSize="16px"
                  fontSizeSm="14px"
                  lineHeight="24px"
                  lineHeightSm="20px"
                >
                  {`Card Payment ${
                    statusStep === 1 ? "Initiated" : statusStep === 7 ? "Failed" : "Processed"
                  }`}
                </TextWrapper>
                <TextWrapper
                  fontSizeSm="12px"
                  fontWeight="400"
                  lineHeight="20px"
                  lineHeightSm="18px"
                  marginTop="6px"
                  marginTopSm="4px"
                >
                  {statusStep === 7
                    ? "Transaction failed due to ..."
                    : `The amount of ${numberWithCommas(
                        formatNumbers(txnDetails.amount + txnDetails.txFee) || 0
                      )} ${txnDetails.coin} has been successfully deducted from you card.`}
                </TextWrapper>
              </DivElement>
            </DivElement>

            {(statusStep === 1 || statusStep === 2 || statusStep === 3 || statusStep === 7) && (
              <Fragment>
                <DivElement width="100%" flex>
                  <LineStatusBar
                    step="2"
                    status={statusStep === 7 ? "failed" : "pending"}
                    isLast={true}
                  />
                  <DivElement>
                    <DivElement flexAlignCenter>
                      <TextWrapper
                        secondary={statusStep === 1 ? true : null}
                        fontSize="16px"
                        fontSizeSm="14px"
                        lineHeight="24px"
                        lineHeightSm="20px"
                        marginRight="15px"
                      >
                        {statusStep === 3
                          ? "Awaiting Approval"
                          : `Deposit ${
                              statusStep === 1
                                ? "Pending"
                                : statusStep === 7
                                ? "Failed"
                                : "Processing"
                            }`}
                      </TextWrapper>
                      {statusStep !== 7 && <HourGlassIllustration height="24px" />}
                    </DivElement>
                    <TextWrapper
                      secondary={statusStep === 1 ? true : null}
                      fontSize="14px"
                      fontSizeSm="12px"
                      fontWeight="400"
                      lineHeight="20px"
                      lineHeightSm="18px"
                      marginTop="6px"
                      marginTopSm="4px"
                    >
                      {statusStep === 1
                        ? "Waiting for card payment to process"
                        : statusStep === 2
                        ? "Funds will reflect in your Main balance"
                        : statusStep === 7
                        ? "Our system has initiated your transaction and will be shortly added to your Main balance"
                        : "Waiting for Admin approval, might take some time."}
                    </TextWrapper>
                  </DivElement>
                </DivElement>
                <CardDetails txnDetails={txnDetails} />
              </Fragment>
            )}

            {statusStep === 4 && (
              <DivElement width="100%" flex>
                <LineStatusBar step="2" isLast={true} />
                <DivElement>
                  <TextWrapper
                    fontSize="16px"
                    fontSizeSm="14px"
                    lineHeight="24px"
                    lineHeightSm="20px"
                  >
                    Deposit Processed
                  </TextWrapper>
                  <TextWrapper
                    fontSize="14px"
                    fontSizeSm="12px"
                    fontWeight="400"
                    lineHeight="20px"
                    lineHeightSm="18px"
                  >
                    Funds have been added to your Main balance
                  </TextWrapper>
                </DivElement>
              </DivElement>
            )}

            {(statusStep === 5 || statusStep === 6) && (
              <Fragment>
                <DivElement width="100%" flex>
                  <LineStatusBar step="2" status="failed" />
                  <DivElement paddingBottom="30px" paddingBottomSm="22px">
                    <TextWrapper
                      fontSize="16px"
                      fontSizeSm="14px"
                      lineHeight="24px"
                      lineHeightSm="20px"
                    >
                      Deposit Declined
                    </TextWrapper>
                    <TextWrapper
                      fontSize="14px"
                      fontSizeSm="12px"
                      fontWeight="400"
                      lineHeight="20px"
                      lineHeightSm="18px"
                    >
                      Deposit declined due to ...
                    </TextWrapper>
                  </DivElement>
                </DivElement>

                <DivElement width="100%" flex>
                  <LineStatusBar
                    step="3"
                    isLast={true}
                    status={statusStep === 5 ? "pending" : "success"}
                  />
                  <DivElement>
                    <TextWrapper
                      fontSize="16px"
                      fontSizeSm="14px"
                      lineHeight="24px"
                      lineHeightSm="20px"
                    >
                      {`Refund ${statusStep === 6 ? "Processed" : "Initiated"}`}
                    </TextWrapper>
                    <TextWrapper
                      fontSize="14px"
                      fontSizeSm="12px"
                      fontWeight="400"
                      lineHeight="20px"
                      lineHeightSm="18px"
                    >
                      {statusStep === 6
                        ? "Funds have been added to your Main balance"
                        : "Funds will reflect in your bank account shortly"}
                    </TextWrapper>
                  </DivElement>
                </DivElement>
              </Fragment>
            )}

            {(statusStep === 4 || statusStep === 5 || statusStep === 6) && (
              <DivElement
                bdGrey
                borderTop="1px solid"
                padding="16px 8px 0 8px"
                paddingMd="16px 0 0 0"
                marginTop="16px"
              >
                {txnData &&
                  txnData.map((data, index) => (
                    <DivElement
                      key={index}
                      displayBtw
                      marginBottom={index === txnData.length - 1 ? "0" : "8px"}
                    >
                      <TextWrapper
                        secondary
                        lineHeight="20px"
                        lineHeightSm="18px"
                        fontSizeSm="12px"
                        nowrap
                      >
                        {data.label}
                      </TextWrapper>
                      {data.label === "Tx Id" || data.label === "Refund ID" ? (
                        <DivElement dAlignCenter>
                          <CopyToClipboard text={data.value} onCopy={() => onTxnIdCopy(data.label)}>
                            <DivElement dAlignCenter cursor>
                              <TxnIdWrapper
                                lineHeight="20px"
                                lineHeightSm="18px"
                                fontSize="12px"
                                fontSizeSm="10px"
                              >
                                {data.value}
                              </TxnIdWrapper>
                              <CopyBlueIcon marginLeft="4px" />
                            </DivElement>
                          </CopyToClipboard>
                          {txnDetails?.link && (
                            <a href={txnDetails?.link} target="_blank" rel="noopener noreferrer">
                              <LinkBlueIcon pointer marginLeft="4px" />
                            </a>
                          )}
                        </DivElement>
                      ) : (
                        <TextWrapper lineHeight="20px" lineHeightSm="18px" fontSizeSm="12px">
                          {data.value}
                        </TextWrapper>
                      )}
                    </DivElement>
                  ))}
              </DivElement>
            )}
          </DivElement>
        ) : (
          <DivElement displayCenter height="370px" heightSm="292px">
            <LoadingButton blue />
          </DivElement>
        )}
      </DivElement>
    </ModalWrapper>
  );
};

export default DectaTransactionStatus;
