import { compose } from "redux";
import ModalWrapper from "../../Reusable/ModalWrapper";
import { TextWrapper, DivElement, ImgElement, Button } from "../../Reusable";
import { connect } from "react-redux";
import { LoadingButton } from "../../Loader";
import configs from "../../../constants/config";
import { addThreeDotInString } from "../../../utils";
import { CloseIcon } from "../../../assets/svgs";
import { noExponents, numberWithCommas } from "../../../utils/general";

const BtcLightningPreview = ({ onClose, isDarkTheme, onConfirm, loader, invoiceData }) => {
  const DATA = [
    { label: "Network", value: invoiceData?.network },
    { label: "Invoice", value: addThreeDotInString(invoiceData?.invoice, 30) },
    { label: "Amount", value: `${numberWithCommas(noExponents(invoiceData?.amount || 0))} BTC` },
    { label: "Fee", value: `${numberWithCommas(noExponents(invoiceData?.fee || 0))} BTC` },
    {
      label: "Total",
      value: `${numberWithCommas(noExponents(invoiceData?.amount + invoiceData?.fee))} BTC`
    }
  ];
  return (
    <ModalWrapper>
      <DivElement ModalContainer>
        <DivElement displayBtw>
          <TextWrapper fontSize="20px" fontSizeSm="16px" lineHeight="24px">
            Withdrawal Preview
          </TextWrapper>
          <CloseIcon onClick={onClose} />
        </DivElement>
        <DivElement displayCenter column padding="48px 0 32px 0" paddingSm="32px 0 24px 0">
          <ImgElement
            width="40px"
            widthSm="32px"
            height="40px"
            heightSm="32px"
            src={`${!isDarkTheme ? configs.lightCoinUrl : configs.darkCoinUrl}btc.svg`}
          />
          <TextWrapper
            fontSize="16px"
            fontSizeSm="14px"
            fontWeight="400"
            lineHeight="24px"
            lineHeightSm="20px"
            margin="16px 0 8px 0"
            marginSm="12px 0 8px 0"
          >
            Withdrawal
          </TextWrapper>
          <TextWrapper fontSize="20px" fontSizeSm="16px" lineHeight="24px">{`${numberWithCommas(
            noExponents(invoiceData?.amount || 0)
          )} BTC`}</TextWrapper>
        </DivElement>
        <DivElement bgCream borderRadius="5px" padding="12px" marginBottom="32px">
          {DATA.map((data, index) => (
            <DivElement
              key={index}
              displayBtw
              marginBottom={DATA.length === index + 1 ? "" : "12px"}
            >
              <TextWrapper fontSize="14px" fontWeight="400" lineHeight="20px">
                {data.label}
              </TextWrapper>
              <TextWrapper fontSize="14px" lineHeight="20px">
                {data.value}
              </TextWrapper>
            </DivElement>
          ))}
        </DivElement>
        <Button primaryBlue width="100%" height="48px" onClick={onConfirm} disabled={loader}>
          {loader ? <LoadingButton /> : "Confirm withdrawal"}
        </Button>
      </DivElement>
    </ModalWrapper>
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme
});

export default compose(connect(mapStateToProps))(BtcLightningPreview);
