/* eslint-disable no-unused-vars */
import axios from "axios";
import moment from "moment";
import { API } from "../constants/api";
import apiURL from "../constants/apiConstants";
import globalConfig from "../constants/config";
import { formatAPIErrors, getLocalStorageData } from "../utils/general";
import { getCookie } from "../utils/cookies";
import { showErrorMsg, showSuccessMsg } from "../utils/notification";
import { customDecode } from "../utils/envScript/envCrypto";

axios.interceptors.response.use(
  (r) => r,
  function handle401Errors(error) {
    if (error && error.response && error.response.status === 401) {
      window.location = globalConfig.exchangeUrl;
      window.dispatchEvent(new Event("LOGOUT"));
    }
    return Promise.reject(error);
  }
);

export const walletAPI = axios.create({
  baseURL: apiURL.WALLET_URL,
  headers: {
    "Content-Type": "application/json"
  }
});

export const walletV2API = axios.create({
  baseURL: apiURL.WALLET_URL_V2,
  headers: {
    "Content-Type": "application/json"
  }
});
export const walletV3API = axios.create({
  baseURL: apiURL.WALLET_URL_V3,
  headers: {
    "Content-Type": "application/json"
  }
});

export const exchangeApi = axios.create({
  baseURL: apiURL.EXCHANGE_URL,
  headers: {
    "Content-Type": "application/json"
  }
});

export const exchangeApiV2 = axios.create({
  baseURL: apiURL.EXCHANGE_URL_V2,
  headers: {
    "Content-Type": "application/json"
  }
});

export const notifyAPI = axios.create({
  baseURL: apiURL.NOTIFICATION_URL,
  headers: {
    "Content-Type": "application/json"
  }
});

export const accountApi = axios.create({
  baseURL: apiURL.API_URL,
  headers: {
    "Content-Type": "application/json"
  }
});

export const accountApiV2 = axios.create({
  baseURL: apiURL.API_URL_V2,
  headers: {
    "Content-Type": "application/json"
  }
});

export const accountApiV3 = axios.create({
  baseURL: apiURL.API_URL_V3,
  headers: {
    "Content-Type": "application/json"
  }
});

/**
 *
 * @param {Select Theme } filter
 */

export async function watchList(data) {
  return data;
}
// FIXME: find out a good way to attach Authorization token with every api
/**
 *
 * @param { Logout User } filter
 */

export async function logoutUser() {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    accountApi.defaults.headers.common["Authorization"] = accessToken;
    const response = await accountApi.post("/auth/logout").then((res) => res);
    return response;
  }
}

/**
 *
 * @param {Get Exchange Token } filter
 */

export async function exchangeToken(token, state) {
  if (token) {
    let url =
      globalConfig.api.user.exchangeToken +
      "?authCode=" +
      token +
      "&domain=exchange" +
      "&state=" +
      state;
    try {
      let response = await accountApi.post(url);
      if (response && response.data && response.data.data && response.data.data.token) {
        localStorage.setItem("accessToken", response.data.data.token);
        return response.data.data.token;
      } else return false;
    } catch (e) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      return false;
    }
  }
}

/**
 *
 * @param {Set Trade Setting Value } filter
 */

export async function tradeSetting(data) {
  return data;
}

/**
 *
 * @param {Set Trade Setting Value } filter
 */

export async function resetTradeTable(data) {
  return data;
}

/**
 *
 * @param {Cancel Trade Setting Value } filter
 */

export async function cancelTradeSetting(data) {
  return data;
}

/**
 *
 * @param {Get User Data } filter
 */

export async function getUserData() {
  const accessToken = localStorage.getItem("accessToken");
  accountApi.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    try {
      let response = await accountApi.get(globalConfig.api.user.find);
      if (response && response.data && response.data.data) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
        return response.data.data;
      } else return false;
    } catch (e) {
      // FIXME: if server goes down or any x y reason the user got logout !
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      return false;
    }
  }
}

/**
 *
 * @param {Get Market Lists } filter
 */

export async function getMarketLists() {
  let tickers;
  try {
    axios.defaults.headers.common["Authorization"] = "";
    let response = await exchangeApi.get(globalConfig.api.public.allTickers);

    if (response && response.data) {
      tickers = response.data.data;
    } else tickers = {};
  } catch (e) {
    tickers = {};
  }
  return tickers;
}

/**
 *
 * @param {Get Trade Pair Config } filter
 */

export async function getTradePairConfig(data) {
  const response = await exchangeApi.post("/market/pair", data).then((res) => res);
  return response;
}

/**
 *
 * @param {Get All Trade Pairs Config } filter
 */

export async function getTradePairsConfig() {
  let congig;
  try {
    axios.defaults.headers.common["Authorization"] = "";
    let response = await exchangeApi.get("market/pairs");
    if (response && response.data) {
      congig = response.data.data;
    } else congig = {};
  } catch (e) {
    congig = {};
  }
  return congig;
}

/**
 *
 * @param {To Update selected order price } filter
 */

export async function selectedPrice(data) {
  return data;
}

/**
 *
 * @param {To Update Pair Data price } filter
 */

export async function updatePairData(data) {
  return data;
}

/**
 *
 * @param {Get Trade Pair Config } filter
 */

export async function getTradeHistory(data) {
  // TODO:- improve the coding style -> decleare with object then return no need to set again and agaon object all the time
  let history;
  try {
    if (data && data.pair) {
      axios.defaults.headers.common["Authorization"] = "";
      let response = await exchangeApi.post(globalConfig.api.public.tradeHistory, data);
      // let response;
      if (response && response.data) {
        history = response.data.data;
      } else history = [];
    } else history = [];
  } catch (e) {
    history = [];
  }
  return history;
}

/**
 *
 * @param {Post used for place order } filter
 */

export async function placeOrder(data) {
  // eslint-disable-next-line
  return new Promise(async function (resolve, reject) {
    const accessToken = localStorage.getItem("accessToken");
    exchangeApi.defaults.headers.common["Authorization"] = accessToken;
    if (typeof accessToken === "undefined" || accessToken == null) resolve(false);
    else {
      try {
        let res = await exchangeApi.post("order/create", data);
        resolve(res);
      } catch (e) {
        reject(e);
      }
    }
  });
}

/**
 *
 * @param {Post used for update place order } filter
 */

export async function updatePlaceOrder(data) {
  // eslint-disable-next-line
  return new Promise(async function (resolve, reject) {
    const accessToken = localStorage.getItem("accessToken");
    exchangeApi.defaults.headers.common["Authorization"] = accessToken;
    if (typeof accessToken === "undefined" || accessToken == null) resolve(false);
    else {
      try {
        let res = await exchangeApi.post(globalConfig.api.private.orderModify, data);
        resolve(res);
      } catch (e) {
        reject(e);
      }
    }
  });
}

/**
 *
 * @param {Post used for get open orders } filter
 */

export async function getOpenOrders(data) {
  // TODO:- improve the coding style -> decleare with object then return no need to set again and agaon object all the time
  let orders = {};
  try {
    const accessToken = localStorage.getItem("accessToken");
    exchangeApi.defaults.headers.common["Authorization"] = accessToken;
    if (typeof accessToken === "undefined" || accessToken == null) return orders;

    let response = await exchangeApi.post(globalConfig.api.private.openOrders, data);
    if (response && response.data) {
      orders = response.data;
    } else orders = {};
  } catch (e) {
    orders = {};
  }
  return orders;
}

/**
 *
 * @param {Post used for get Order History } filter
 */

export async function getUserTradeHistory(data) {
  // TODO:- improve the coding style -> decleare with object then return no need to set again and agaon object all the time
  // or undefined data type string ? with ===
  let history = {};
  try {
    const accessToken = localStorage.getItem("accessToken");
    exchangeApi.defaults.headers.common["Authorization"] = accessToken;
    if (typeof accessToken === "undefined" || accessToken == null) return history;

    let response = await exchangeApi.post("/trade/uHistory", data).then((res) => res);
    if (response && response.data) {
      history = response.data;
    } else history = {};
  } catch (e) {
    history = {};
  }
  return history;
}

/**
 *
 * @param {Get Market Top Data }
 */

export async function getMarketTopData() {
  let result = {};
  try {
    const accessToken = localStorage.getItem("accessToken");
    exchangeApi.defaults.headers.common["Authorization"] = accessToken;

    let response = await exchangeApi.get("/market/market-top-data").then((res) => res);
    if (response && response.data) {
      result = response.data;
    } else result = {};
  } catch (e) {
    result = {};
  }
  return result;
}

export async function recentTrades(data) {
  // TODO:- improve the coding style -> decleare with object then return no need to set again and agaon object all the time
  let history = [];
  try {
    if (data && data.pair) {
      let response = await exchangeApi.post("/trade/recent", data).then((res) => res);
      if (response && response.data) {
        history = response.data.data;
      } else history = {};
    } else history = [];
  } catch (e) {
    history = [];
  }
  return history;
}

export async function recentTicker(data) {
  // TODO:- improve the coding style -> decleare with object then return no need to set again and agaon object all the time
  let history = {};
  try {
    let response = await exchangeApi.post("market/ticker", data).then((res) => res);
    if (response && response.data) {
      history = response.data.data;
    } else history = {};
  } catch (e) {
    history = {};
  }
  return history;
}

// Get All categories For Market
export async function getAllcategoriesMarket() {
  const response = await exchangeApi.get("/market/categories").then((res) => res);
  return response;
}

/**
 *
 * @param {Post used for get Order History }
 */

export async function getOrderHistory(data) {
  const accessToken = getLocalStorageData("accessToken");
  if (accessToken) {
    exchangeApi.defaults.headers.common["Authorization"] = accessToken;
    const response = await exchangeApi.post("/order/orderHistory", data).then((res) => res);
    return response;
  }
  return false;
}

/**
 *
 * @param {Post used for get open orders } filter
 */

export async function cancelOpenOrder(user) {
  const accessToken = localStorage.getItem("accessToken");
  exchangeApi.defaults.headers.common["Authorization"] = accessToken;

  const response = await exchangeApi.post("/order/cancel", user).then((res) => res);
  return response;
}
/**
 *
 * @param {get for get leader board } filter
 */

export async function getLeaderBoard(user) {
  const response = await exchangeApi.post("/tc/leaderBoards", user).then((res) => res);
  return response;
}
export async function getPrivateLeaderBoard(user) {
  const accessToken = localStorage.getItem("accessToken");
  exchangeApi.defaults.headers.common["Authorization"] = accessToken;

  const response = await exchangeApi.post("/tc/leaderBoards-private", user).then((res) => res);
  return response;
}

/*
 * GetUserAllReports
 */
export async function getUserAllReports(data) {
  const accessToken = localStorage.getItem("accessToken");
  exchangeApi.defaults.headers.common["Authorization"] = accessToken;

  const response = await exchangeApi.post("/wallet/reports", data).then((res) => res);
  return response;
}
/*
 * setup report request
 */
export async function setupUserReports(data) {
  const accessToken = localStorage.getItem("accessToken");
  exchangeApi.defaults.headers.common["Authorization"] = accessToken;
  const response = await exchangeApi.post("/wallet/reports/create", data).then((res) => res);
  return response;
}
/*
 * cancel report request
 */
export async function cancelReportRequest(data) {
  const accessToken = localStorage.getItem("accessToken");
  exchangeApi.defaults.headers.common["Authorization"] = accessToken;
  const response = await exchangeApi.post("/wallet/reports/cancel", data).then((res) => res);
  return response;
}
/*
 * download report request
 */
export async function downloadReports(id) {
  const accessToken = localStorage.getItem("accessToken");
  exchangeApi.defaults.headers.common["Authorization"] = accessToken;
  if (id) {
    const response = await exchangeApi.get(`/wallet/reports/download/${id}`).then((res) => res);
    return response;
  }
}

/**
 *
 * @param {Select Pair Name with Exchange ID } filter
 */

export async function selectedPair(data) {
  return data;
}

/*
 * Get Balance to Account Wallet
 */
export async function getAllWallets() {
  const accessToken = localStorage.getItem("accessToken");
  walletAPI.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await walletAPI.post("/getAll").then((res) => res);
    return response;
  }
}

/*
 * Get Conversion Rate
 */
export async function getConversionRate() {
  const response = await walletAPI.get("/rate").then((res) => res);
  return response;
}

/*
 * Get Wallet Fee Details
 */
export async function getFeeDetails(data) {
  const response = await walletAPI.post("/sto/whitelist-fee", data).then((res) => res);
  return response;
}

/*
 * LCX Purchase History
 */
export async function lcxPurchasHistory(data) {
  const response = await walletAPI.post("/buylcxTransactions", data);
  return response;
}
/*
 * Create Wallet
 */
export async function createWallet(data) {
  const accessToken = localStorage.getItem("accessToken");
  walletV2API.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    const response = await walletV2API.post("/user/create", data).then((res) => res);
    return response;
  }
}

/*
 * Get Trasnsaction
 */
export async function getTransactions(data) {
  const accessToken = localStorage.getItem("accessToken");
  walletAPI.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await walletAPI.post("/getTransactions", data).then((res) => res);
    return response;
  }
}

/*
 * Convert to LCX
 */
export async function convertToLcx(data) {
  if (data) {
    const response = await walletAPI.post("/buyLcx", data);
    return response;
  }
}

/*
 * Get Wallet Fee Details
 */
export async function whiteListAddressApi(data) {
  const response = await walletAPI.post("/sto/whitelist", data).then((res) => res);
  return response;
}

/*
 * Get Wallet Fee Details
 */
export async function whiteListHistory(data) {
  const response = await walletAPI.post("/sto/whitelist-history", data).then((res) => res);
  return response;
}

/*
 * Add Balance to Wallet Accounts to Exchnage
 */
export async function transferFundToExchange(data) {
  const accessToken = localStorage.getItem("accessToken");
  walletAPI.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await walletAPI.post("/transferBalance", data).then((res) => res);
    return response;
  }
}

/*
 * Withraw Token
 */
export async function withdrawToken(data) {
  const response = await walletAPI.post("/withdrawal", data).then((res) => res);
  return response;
}

/*
 * Withraw Token 2Fa
 */
export async function withdrawToken2FA(data) {
  const response = await walletAPI.post("/confirmWithdrawal", data).then((res) => res);
  return response;
}

/**
 *
 * @param {Get Coin Network} data
 * @returns
 */
export async function getCoinNetworks(data) {
  const accessToken = localStorage.getItem("accessToken");
  walletV3API.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  const response = await walletV3API
    .get("/user/getCoinNetworks", { params: data })
    .then((res) => res);
  return response;
}

/**
 *
 * @param {Get Fait Provider} data
 * @returns
 */
export async function getFiatDepositDetails(data) {
  const accessToken = localStorage.getItem("accessToken");
  walletV2API.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  const response = await walletV2API
    .get(`/user/fiatDepositDetails?coin=${data.coin}`)
    .then((res) => res);
  return response;
}

/**
 *
 * @param {WithdrawCoin Api} data
 * @returns
 */

export async function withdrawCoin(params) {
  const accessToken = localStorage.getItem("accessToken");
  walletV2API.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await walletV2API
      .post(`/user/${params.isBtcLightning ? "lightningWithdrawal" : "withdrawal"}`, params.data)
      .then((res) => res);
    return response;
  }
}

export async function withdrawFiat(data) {
  const accessToken = localStorage.getItem("accessToken");
  walletV2API.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await walletV2API.post("/user/withdrawalFiat", data).then((res) => res);
    return response;
  }
}

/**
 *
 * @param {Withdraw Otp Api} data
 * @returns
 */
export async function getWithdrawalOtp(data) {
  const accessToken = localStorage.getItem("accessToken");
  walletV2API.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await walletV2API.post("/user/getWithdrawalOTP", data).then((res) => res);
    return response;
  }
}

/**
 *
 * @param {Confirm Coin Withdrawal Api } data
 * @returns
 */

export async function confirmCoinWithdrawal(params) {
  const accessToken = localStorage.getItem("accessToken");
  walletV3API.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await walletV3API
      .post(
        `/user/${params.isBtcLightning ? "confirmLightningWithdrawal" : "confirmWithdrawal"}`,
        params.data
      )
      .then((res) => res);
    return response;
  }
}

/**
 *
 * @param {Confirm Coin Withdrawal Api } data
 * @returns
 */
export async function getTransactionStatus(data) {
  const accessToken = localStorage.getItem("accessToken");
  walletV2API.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await walletV2API.post("/user/getTransactionStatus", data).then((res) => res);
    return response;
  }
}

/*
 * Add Balance to Wallet Exchange to Account
 */
export async function transferFundToAccount(data) {
  const accessToken = localStorage.getItem("accessToken");
  walletAPI.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await walletAPI.post("/transferFromBalance", data).then((res) => res);
    return response;
  }
}

/**
 *
 * @param {Ask Pair List with Exchange ID } filter
 */

export async function askPairList(data) {
  let list = {};
  try {
    axios.defaults.headers.common["Authorization"] = "";
    let response = await exchangeApi.post(globalConfig.api.public.orderBook, data);
    if (response && response.data) {
      list = response.data.data;
    } else list = [];
  } catch (e) {
    list = [];
  }
  return list;
}

export async function fetchCurrency(filter) {
  // TODO:- improve the coding style -> decleare with null then return no need to set again and agaon null all the time
  let cryptoPrice;
  try {
    axios.defaults.headers.common["Authorization"] = "";
    let response = await exchangeApi.get(
      globalConfig.api.currency.findAll + "?filter=" + JSON.stringify(filter)
    );
    if (response && response.data && response.data.length > 0) {
      cryptoPrice = response.data;
    } else cryptoPrice = null;
  } catch (e) {
    cryptoPrice = null;
  }
  return cryptoPrice;
}

/**
 *
 * @param {Create Order} data
 */

// FIXME this function need to be changed
export function createOrder(data) {
  if (data) {
    const accessToken = getLocalStorageData("accessToken");
    axios.defaults.headers.common["Authorization"] = accessToken;
    return axios.post(
      globalConfig.apiUrl +
        globalConfig.api.user.exchange.order.create(
          data.exchange_id,
          data.base_pair,
          data.quote_pair
        ),
      data.order_data
    );
  }
  return false;
}

/**
 *
 * @param {Error Format Api} error
 */

export function errorFormat(error) {
  if (typeof error === "object") {
    let errorObject = JSON.parse(JSON.stringify(error));
    if (
      errorObject &&
      errorObject.response &&
      errorObject.response.data &&
      errorObject.response.data.error
    ) {
      return errorObject.response.data.error;
    } else if (errorObject.message) {
      return errorObject;
    } else
      return {
        message: ""
      };
  }
}

/**
 *
 * @param {Fetch Active Exchange} error
 */

export async function fetchActiveExchanges() {
  // FIXME: use .env file
  let data;
  try {
    let env = customDecode(process.env.REACT_APP_NODE_ENV);
    let isProductionEnv = env === "production" ? true : false;
    axios.defaults.headers.common["Authorization"] = "";
    let response = await exchangeApi.get(globalConfig.api.exchanges.active);

    if (response && response.data && response.data.data) {
      data = response.data.data.s;
      if (isProductionEnv === true) {
        data = response.data.data.p;
      }
    } else data = null;
  } catch (e) {
    data = null;
  }
  return data;
}

/**
 *
 * @param {Fetch All Pairs} error
 */

export async function fetchAllPairs() {
  let pairList;

  try {
    axios.defaults.headers.common["Authorization"] = "";
    let response = await exchangeApi.get(globalConfig.api.exchanges.getAllCurrencies);
    if (response && response.data && response.data.data) {
      pairList = response.data.data;
    } else pairList = null;
  } catch (e) {
    pairList = null;
  }

  return pairList;
}

/**
 *
 * @param {Check For Exchange} error
 */

export function checkForexUpdate(forexPrice) {
  let isFetch = false;
  if (forexPrice != null && forexPrice.lastUpdated) {
    let diff = moment(moment().utc().format()).diff(moment.utc(forexPrice.lastUpdated), "minutes");
    if (diff > 360) {
      isFetch = true;
    }
  } else isFetch = true;
  return isFetch;
}

/**
 *
 * @param {getForex} error
 */

export async function getForex() {
  let forexPrice;
  try {
    let response = await exchangeApi.get(globalConfig.api.currency.forexPrice);
    if (response && response.data && response.data.data) {
      response.data.lastUpdated = moment().utc().format();
      localStorage.setItem("forexPrice", JSON.stringify(response.data));
      forexPrice = response.data;
    } else forexPrice = null;
  } catch (e) {
    forexPrice = null;
  }
  return forexPrice;
}

/**
 *
 * @param {fetch Exchange Currency} error
 */

export async function fetchExchangeCurrency(filter) {
  let cryptoPrice;
  let uniqueId;
  try {
    axios.defaults.headers.common["Authorization"] = "";
    let response = await exchangeApi.post(globalConfig.api.exchanges.currency.findAll, filter);

    if (response && response.data && response.data.data) {
      if (response.data.data.data.length > 0) {
        cryptoPrice = response.data.data.data;
      } else cryptoPrice = null;
      uniqueId = response.data.data.exchange_id;
    }
  } catch (e) {
    cryptoPrice = null;
  }
  return { cryptoPrice: cryptoPrice, uniqueExchangeId: uniqueId };
}

/**
 *
 * @param { Get User Details } error
 */

export async function getUserDetails() {
  const accessToken = getLocalStorageData("accessToken");
  API.defaults.headers.common["Authorization"] = accessToken;

  if (accessToken && (accessToken !== undefined || accessToken !== null)) {
    let response = await API.get("/user").then((res) => res);
    localStorage.setItem("user", JSON.stringify(response.data.data));
    return response;
  }
}

/**
 *
 * @param { Get FEE Private LIST } error
 */

export async function getFeesList() {
  const accessToken = getLocalStorageData("accessToken");
  exchangeApiV2.defaults.headers.common["Authorization"] = accessToken;

  if (accessToken && (accessToken !== undefined || accessToken !== null)) {
    let response = await exchangeApiV2.get("/order/fee").then((res) => res);
    return response;
  }
}
/**
 *
 * @param { Get FEE Public LIST } error
 */

export async function getFeesListPublic() {
  let response = await exchangeApiV2.get("/order/fee-public").then((res) => res);
  return response;
}

/**
 *
 * @param { Update FEE LIST } error
 */

export async function updateFeeList(params) {
  const accessToken = getLocalStorageData("accessToken");
  exchangeApiV2.defaults.headers.common["Authorization"] = accessToken;

  if (accessToken && (accessToken !== undefined || accessToken !== null)) {
    let response = await exchangeApiV2.post("/order/fee", params).then((res) => res);
    return response;
  }
}

/**
 *
 * @param { Get User Details } error
 */

export async function getWalletBalance() {
  exchangeApi.defaults.headers.common["Authorization"] = localStorage.getItem("accessToken");

  let response = await exchangeApi.get("/wallet/all").then((res) => res);

  return response;
}

/**
 *
 * @param { Get Recent Activity } error
 */

export async function getRecentActivity(data) {
  API.defaults.headers.common["Authorization"] = localStorage.getItem("accessToken");
  let response = await API.get(`/auth/recent-logins?page=${data}`).then((res) => res);
  return response;
}
/**
 *
 * @param { Get All campaigns } error
 */

export async function getAllCampaigins() {
  // API.defaults.headers.common["Authorization"] = localStorage.getItem("accessToken");
  let response = await walletAPI.get(`/user/campaign/allCampaigns`).then((res) => res);
  return response;
}

/**
 *
 * @param { Setup 2FA Calling } error
 */
export async function setup2FA(user) {
  const accessToken = getLocalStorageData("accessToken");
  API.defaults.headers.common["Authorization"] = accessToken;

  if (accessToken && (accessToken !== undefined || accessToken !== null)) {
    const response = await API.post("/user/setup-2fa", user).then((res) => res);
    return response;
  }
}

/**
 *
 * @param { Generate 2FA Calling } error
 */
export async function generate2Auth() {
  const response = await API.get("/user/twofactor-token").then((res) => res);
  return response;
}

/**
 *
 * @param { Change Password Calling } error
 */
export async function changePassword(user) {
  const response = await API.post("/auth/change-password", user).then((res) => res);
  return response;
}

/**
 *
 * @param { Get Api Management Calling } error
 */
export async function getApiKeyManagment(data) {
  const accessToken = getLocalStorageData("accessToken");
  exchangeApi.defaults.headers.common["Authorization"] = accessToken;
  if (accessToken && (accessToken !== undefined || accessToken !== null)) {
    const response = await exchangeApi.post("/api/all", data).then((res) => res);
    return response;
  }
}

/**
 *
 * @param { Create Api Management Calling } error
 */
export async function createApiManagement(user, twoFa) {
  exchangeApi.defaults.headers.common["twoFAToken"] = twoFa;
  const response = await exchangeApi.post("/api/generate", user).then((res) => res);
  return response;
}

/**
 *
 * @param { Update Api Management Calling } error
 */
export async function updateApiManagement(user, twoFa) {
  exchangeApi.defaults.headers.common["twoFAToken"] = twoFa;
  if (
    exchangeApi.defaults.headers.common["twoFAToken"] &&
    (exchangeApi.defaults.headers.common["twoFAToken"] !== undefined ||
      exchangeApi.defaults.headers.common["twoFAToken"] !== null)
  ) {
    const response = await exchangeApi.post("/api/update", user).then((res) => res);
    return response;
  }
}

/**
 *
 * @param { Delete Api Management Calling } error
 */
export async function deleteUserKey(user, twoFa) {
  exchangeApi.defaults.headers.common["twoFAToken"] = twoFa;
  const response = await exchangeApi.post("/api/remove", user).then((res) => res);
  return response;
}

/**
 *
 * @param { Get Wallet Balance  } error
 */
export async function getTradeWalletBalance(user) {
  const accessToken = getLocalStorageData("accessToken");
  exchangeApi.defaults.headers.common["Authorization"] = accessToken;

  if (accessToken && (accessToken !== undefined || accessToken !== null)) {
    const response = await exchangeApi.post("/wallet/coin", user).then((res) => res);
    return response;
  }
}

/**
 *
 * @param { Get Wallet Balance  } error
 */
export async function IncreaseBalance() {
  const accessToken = getLocalStorageData("accessToken");
  exchangeApi.defaults.headers.common["Authorization"] = accessToken;

  if (accessToken && (accessToken !== undefined || accessToken !== null)) {
    const response = await exchangeApi.get("/wallet/addBalances").then((res) => res);
    return response;
  }
}

/**
 *
 * @param { Get Wallet Balance  } error
 */
export async function getInsights(value) {
  const response = await axios
    .get(`https://lcx.com/wp-json/wp/v2/posts?per_page=${value}`)
    .then((res) => res);
  return response;
}

/**
 *
 * @param { Get Wallet Balance  } error
 */
export async function getMarketTickerData() {
  const response = await exchangeApi.get("/market/market-card").then((res) => res);
  return response;
}

/**
 *
 * @param { Set Order History  } error
 */
export async function orderTabRecords(user) {
  return user;
}

/**
 * Get trading competition details
 * @param
 */
export async function getTradingCompetition() {
  const accessToken = getLocalStorageData("accessToken");
  exchangeApi.defaults.headers.common["Authorization"] = accessToken;

  if (accessToken && (accessToken !== undefined || accessToken !== null)) {
    const response = await exchangeApi.get("/tc/latest").then((res) => res.data);
    return response;
  }
}
/**
 * Registor User
 * @param
 */
export async function registorUser(data) {
  const accessToken = getLocalStorageData("accessToken");
  exchangeApi.defaults.headers.common["Authorization"] = accessToken;

  if (accessToken && (accessToken !== undefined || accessToken !== null)) {
    const response = await exchangeApi
      .post("/tc/user-tc-registration", data)
      .then((res) => res.data);
    return response;
  }
}

export async function getContestList() {
  const accessToken = getLocalStorageData("accessToken");
  exchangeApi.defaults.headers.common["Authorization"] = accessToken;

  if (accessToken && (accessToken !== undefined || accessToken !== null)) {
    const response = await exchangeApi.get("/tc/user-list").then((res) => res.data);
    return response;
  }
}

/*
 * Get Beneficiary Data
 */
export async function getBeneficiary() {
  const accessToken = localStorage.getItem("accessToken");
  walletAPI.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await walletAPI.get("/getBeneficiary").then((res) => res);
    return response;
  }
}

/*
 * Get Single Beneficiary Data
 */
export async function getBeneficiarySingle(data) {
  const accessToken = localStorage.getItem("accessToken");
  walletAPI.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await walletAPI.get(`/getBeneficiary?id=${data.id}`).then((res) => res);
    return response;
  }
}

/*
 * Add Beneficiary Data
 */
export async function addBeneficiary(data) {
  const accessToken = localStorage.getItem("accessToken");
  walletAPI.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await walletAPI.post("/createBeneficiary", data).then((res) => res);
    return response;
  }
}

/*
 * Edit Beneficiary Data
 */
export async function editBeneficiary(data) {
  const accessToken = localStorage.getItem("accessToken");
  walletAPI.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await walletAPI.post("/updateBeneficiary", data).then((res) => res);
    return response;
  }
}

/*
 * Delete Beneficiary Single Data
 */
export async function deleteBeneficieryData(data) {
  const accessToken = localStorage.getItem("accessToken");
  walletAPI.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await walletAPI.post("/deleteBeneficiary", data).then((res) => res);
    return response;
  }
}

/**
 *
 * @param { Update the Notification } error
 */
export async function updateNotificationAlert(data) {
  const accessToken = localStorage.getItem("accessToken");
  accountApi.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await accountApi.post("notify/update-notification", data).then((res) => res);
    return response;
  }
}

/**
 *
 * @param { Get the Notification } error
 */
export async function getNotificationAlert() {
  const accessToken = localStorage.getItem("accessToken");
  accountApi.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await accountApi.get("notify/notification-preference").then((res) => res);
    return response;
  }
}

/**
 *
 * @param { Update the Anti Phishing Code } error
 */
export async function updateAntiPhisingCode(data) {
  const accessToken = localStorage.getItem("accessToken");
  accountApi.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await accountApi.post("notify/anti-phishing", data).then((res) => res);
    return response;
  }
}

/**
 *
 * @param { Disable the Anti Phishing Code } error
 */
export async function disableAntiPhisingCode(data) {
  const accessToken = localStorage.getItem("accessToken");
  accountApi.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await accountApi.post("notify/anti-phishing", data).then((res) => res);
    return response;
  }
}

/**
 *
 * @param { Get AntiPhishing Code } error
 */
export async function getAntiPhisingCode() {
  const abortController = new AbortController();
  let abortSignal = abortController.signal;
  const accessToken = localStorage.getItem("accessToken");
  accountApi.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await accountApi
      .get("notify/anti-phishing", {
        signal: abortSignal
      })
      .then((res) => res);
    abortController.abort();
    return response;
  }
}

/**
 *
 * @param { Balances Public earn } error
 */
export async function EarnPublicBalance() {
  const response = await walletAPI.get("bonds/balancePublic").then((res) => res);
  return response;
}
/**
 *
 * @param { All Details Public earn } error
 */
export async function EarnDetailPublic(data) {
  const response = await walletAPI.post("bonds/detailsPublic", data).then((res) => res);
  return response;
}
/**
 *
 * @param { Accept TC Monerium } error
 */
export async function acceptTCMonerium() {
  const accessToken = localStorage.getItem("accessToken");
  walletAPI.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await walletAPI.get("/accept-tc-monerium").then((res) => res);
    return response;
  }
}

/**
 *
 * @param { Get Trading Fee } error
 */
export async function getTradingFee() {
  const response = await exchangeApi.get("/market/trading-fee").then((res) => res);
  return response;
}
/**
 *
 * @param { Get Order Fee } error
 */
export async function getOrderFees() {
  const response = await exchangeApiV2.get("/order/fee").then((res) => res);
  return response;
}

/**
 *
 * @param { Fee Coin Details  } error
 */
export async function getDepositWithdrawalFee() {
  const response = await walletAPI.get("/public/coin-details").then((res) => res);
  return response;
}

/**
 *
 * @param { Digital Assets  } error
 */
export async function getDigitalAssets() {
  const response = await axios
    .get(globalConfig.newAccountsUrl + "/cms/digital-assets?type=DIGITAL_ASSETS&product=EXCHANGE")
    .then((res) => res);
  return response;
}
/**
 *
 * @param { Trading Comp  } error
 */
export async function getTradingCompetitionFaq() {
  const response = await axios
    .get(globalConfig.newAccountsUrl + "/cms/data?type=TRADING_COMP&product=FAQ")
    .then((res) => res);
  return response;
}

/**
 *
 * @param { Digital Assets  } error
 */
export async function getBannerApi() {
  const response = await accountApi
    .get("marketing?type=LANDING&product=EXCHANGE_DESKTOP")
    .then((res) => res);
  return response;
}

/**
 *
 * @param { Get Tutorials Data  } error
 */
export async function getTutorialsData() {
  const response = await accountApi
    .get("cms/tutorials-list?type=TUTORIALS&product=EXCHANGE")
    .then((res) => res);
  return response;
}

/**
 *
 * @param { Get Fqa Data } error
 */
export async function getFqa(data) {
  const accessToken = localStorage.getItem("accessToken");
  accountApi.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await accountApi
      .get(`/cms/faq?type=${data.type}&product=FAQ&key=${data.key}`)
      .then((res) => res);
    return response;
  }
}

/**
 *
 * @param { Get Import Data } error
 */
export async function getImportantPoints(data) {
  const accessToken = localStorage.getItem("accessToken");
  walletAPI.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await walletAPI
      .get(
        `/public/coin-imp-points?coin=${data.key}&type=${data.type}&network=${data?.network}${
          data.provider ? "&provider=" + data.provider : ""
        }`
      )
      .then((res) => res);
    return response;
  }
}

/**
 *
 * @param { Get Time Zone } error
 */
export async function getTimeZoneCET() {
  const response = await exchangeApi.get("/").then((res) => res);
  return response;
}

export async function createCardTransactionRequest(data) {
  const accessToken = localStorage.getItem("accessToken");
  walletAPI.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await walletAPI.post("/decta/user/create-request", data).then((res) => res);
    return response;
  }
}

/**
 *
 * @param { Active sessions  } error
 */
export async function getActiveSessions() {
  const accessToken = localStorage.getItem("accessToken");
  accountApiV2.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await accountApiV2.get("/user/all-active-sessions").then((res) => res);
    return response;
  }
}
/**
 *
 * @param { Update the Notification } error
 */
export async function logoutfromdevice(data) {
  const accessToken = localStorage.getItem("accessToken");
  accountApiV2.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await accountApiV2.post("/auth/logout-from-device", data).then((res) => res);
    return response;
  }
}
/**
 *
 * @param { Generate the Barcode KYC } error
 */
export async function genrateBarCodeKYC() {
  const accessToken = localStorage.getItem("accessToken");
  accountApiV2.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await accountApiV2.get("/kyc/generate-code").then((res) => res);
    return response;
  }
}
/**
 *
 * @param { Verify KYC Document } error
 */
export async function verifyKYCDocument(data) {
  const accessToken = localStorage.getItem("accessToken");
  accountApiV2.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await accountApiV2.post("/kyc/verify-document", data).then((res) => res);
    return response;
  }
}

/**
 *
 * @param {To Update Fav Trade Pair List } filter
 */

export async function favTradePairsList(data) {
  return data;
}

/**
 *
 * @param {Update Market Lists } filter
 */

export async function updateSocketMarketData(data) {
  let tickers;
  try {
    let response = data;

    if (response) {
      tickers = response;
    } else tickers = {};
  } catch (e) {
    tickers = {};
  }
  return tickers;
}

/**
 *
 * @param { Update Ticker Datta } filter
 */

export async function updateSocketTickerData(data) {
  let ticker;
  try {
    let response = data;

    if (response) {
      ticker = response;
    } else ticker = {};
  } catch (e) {
    ticker = {};
  }
  return ticker;
}

/**
 *
 * @param { Get All Competition List } error
 */
export async function getAllCompetitionsList() {
  // const accessToken = localStorage.getItem("accessToken");
  // exchangeApi.defaults.headers.common["Authorization"] = accessToken;
  // if (typeof accessToken === "undefined" || accessToken == null) return false;
  // else {
  const response = await exchangeApi.get("/tc/all-tc").then((res) => res);
  return response;
  // }
}
/**
 *
 * @param { Get Promotion update } error
 */
export async function getPromotionUpdate() {
  const response = await walletAPI.get("user/campaign/getAllCampaignPromotions").then((res) => res);
  return response;
}

/**
 *
 * @param { Get All Competition List } error
 */
export async function getParticipatedList(api) {
  const accessToken = localStorage.getItem("accessToken");
  exchangeApi.defaults.headers.common["Authorization"] = accessToken;
  let link =
    api === "ongoing" ? "/tc/user-competitions-ongoing" : "/tc/user-competitions-completed";
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await exchangeApi.post(link).then((res) => res);
    return response;
  }
}

/**
 *
 * @param { Get Single Competition Data } error
 */
export async function getSingleCompetition(data) {
  const response = await exchangeApi.post("tc/single-tc", data).then((res) => res);
  return response;
}
export async function getPrivateSingleCompetition(data) {
  const accessToken = localStorage.getItem("accessToken");
  exchangeApi.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await exchangeApi.post("tc/single-tc-private", data).then((res) => res);
    return response;
  }
}

// Accounts API's -------------------------------------------------------------------->

// Referral Module
export async function getReferralCode() {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    accountApi.defaults.headers.common["Authorization"] = accessToken;
    let response = await accountApi.get("/user/get-referral").then((res) => res);
    return response;
  }
}

export async function getReferralAmountGained(data) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    exchangeApi.defaults.headers.common["Authorization"] = accessToken;
    let response = await exchangeApi.post("/wallet/referralAmountGained", data).then((res) => res);
    return response;
  }
}
export async function modifyPreferedLanguage(data) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    accountApiV3.defaults.headers.common["Authorization"] = accessToken;
    let response = await accountApiV3.post("/user/set-preferred-lang", data).then((res) => res);
    return response;
  }
}

// Earn Module
export async function getTokenizedBond() {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    walletAPI.defaults.headers.common["Authorization"] = accessToken;
    const response = await walletAPI.get("/bonds/balance").then((res) => res);
    return response;
  }
}

export async function getBondDetails(data) {
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    walletAPI.defaults.headers.common["Authorization"] = accessToken;
    const response = await walletAPI.post("/bonds/details", data).then((res) => res);
    return response;
  } else {
    const response = await walletAPI.post("/bonds/detailsPublic", data).then((res) => res);
    return response;
  }
}

export async function modifyBondInvestment(data) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    walletAPI.defaults.headers.common["Authorization"] = accessToken;
    const response = await walletAPI.post("/bonds/modify", data).then((res) => res);
    return response;
  }
}

export async function getBondInvestHistory(data) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    walletAPI.defaults.headers.common["Authorization"] = accessToken;
    const response = await walletAPI.post("/bonds/history", data).then((res) => res);
    return response;
  }
}

export async function getHistoryBond(data) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    walletAPI.defaults.headers.common["Authorization"] = accessToken;
    const response = await walletAPI.post("/bonds/history", data).then((res) => res);
    return response;
  }
}

export async function getInviteFriends() {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    accountApi.defaults.headers.common["Authorization"] = accessToken;
    let response = await accountApi
      .get("/cms/data?type=REFERRAL&product=MEDIA_LIST")
      .then((res) => res);
    return response;
  }
}

export async function getBondAccess() {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    walletAPI.defaults.headers.common["Authorization"] = accessToken;
    const response = await walletAPI.get("/bonds/checkBondAccess").then((res) => res);
    return response;
  }
}

export async function buyBonds(data) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    walletAPI.defaults.headers.common["Authorization"] = accessToken;
    const response = await walletAPI.post("/bonds/buy", data).then((res) => res);
    return response;
  }
}

export async function getSourceList() {
  const response = await accountApiV2.get("user/sources-list").then((res) => res);
  return response;
}

export async function completeProfileData(user) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    accountApiV2.defaults.headers.common["Authorization"] = accessToken;
    const response = await accountApiV2.post("/user/complete-profile", user).then((res) => res);
    return response;
  }
}

// => Api used in LEVEL 3 Users
export async function completeProfileDataLevel3Step1(user) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    accountApiV2.defaults.headers.common["Authorization"] = accessToken;
    const response = await accountApiV2.post("/user/add-employer-details", user).then((res) => res);
    return response;
  }
}

// => Api used in LEVEL 3 Get Current Submission
export async function currentSubmissionList() {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    accountApiV2.defaults.headers.common["Authorization"] = accessToken;
    const response = await accountApiV2.get("/user/current-submission").then((res) => res);
    return response;
  }
}

// => Api used in LEVEL 3 upload Fund Proof Image
export async function uploadFundProofImage(user) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    accountApiV2.defaults.headers.common["Authorization"] = accessToken;
    const response = await accountApiV2
      .post("/user/upload-source-document", user)
      .then((res) => res);
    return response;
  }
}

// => Api used in LEVEL 3 Submit All Source Data
export async function submitAllSourceData(user) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    accountApiV2.defaults.headers.common["Authorization"] = accessToken;
    const response = await accountApiV2.post("/user/submit-source-data", user).then((res) => res);
    return response;
  }
}

// => Api used in LEVEL 3 when user Status Approved this API help to not show the text next time
export async function removeApproveText(user) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    accountApiV2.defaults.headers.common["Authorization"] = accessToken;
    const response = await accountApiV2.put("/user/content-display", user).then((res) => res);
    return response;
  }
}

// Country List API
export async function getCountryList() {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    const response = await accountApi.get("/user/country-list").then((res) => res);
    return response;
  } else {
    const response = await accountApiV2.get("/user/country-list").then((res) => res);
    return response;
  }
}

// KYC FAQ Content
export async function getKycFaqContent(data) {
  const accessToken = localStorage.getItem("accessToken");
  accountApi.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await accountApi.get("/cms/faq", { params: data }).then((res) => res);
    return response;
  }
}
// Sumbit ID Document
export async function submitIdProof(user) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    accountApi.defaults.headers.common["Authorization"] = accessToken;
    const response = await accountApi.post("/user/submit/document", user).then((res) => res);
    return response;
  }
}
// Validate Id document
export async function validateDocument(data) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    accountApi.defaults.headers.common["Authorization"] = accessToken;
    const response = await accountApi.post("/user/verify/document", data).then((res) => res);
    return response;
  }
}

// Upload selfie of user
export async function uploadSelfie(img1, img2, type, callback) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    accountApi.defaults.headers.common["Authorization"] = accessToken;
    let data = new FormData();
    data.append("documentType", type);
    data.append("image", img1);
    data.append("image", img2);

    return accountApi
      .post("/user/upload-selfie", data, {
        onUploadProgress: (progressEvent) => {
          let progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          callback(progress);
        }
      })
      .catch((error) => {
        throw error;
      });
  }
}

export async function residentialProof(img, type, typeOfDocument, callback) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    accountApi.defaults.headers.common["Authorization"] = accessToken;
    let data = new FormData();
    data.append("documentType", type);
    data.append("typeOfDocument", typeOfDocument);
    data.append("image", img);

    return accountApi
      .post("/user/proof-of-address", data, {
        onUploadProgress: (progressEvent) => {
          let progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          callback(progress);
        }
      })
      .catch((error) => {
        throw error;
      });
  }
}

export async function loginUser(user) {
  const response = await accountApiV2.post("/auth/login", user).then((res) => res);
  return response;
}

export async function loginWithEmailOtp(token) {
  let config = {
    headers: {
      Authorization: token
    }
  };
  const response = await accountApi.get("/auth/confirm-login", config).then((res) => res);
  return response;
}

export async function getEmailOtp(data) {
  const response = await accountApiV2.post("/auth/send-email-otp", data).then((res) => res);
  return response;
}

export async function twoFaAndRecaptchaLoginOtp(user) {
  const response = await accountApiV2
    .post("/auth/login-email-otp-and-2fa", user)
    .then((res) => res);
  return response;
}

export async function twoFa(user) {
  const response = await accountApi.post("/auth/login-twofactor-auth", user).then((res) => res);
  return response;
}

export async function recaptchaLoginOtp(user) {
  const response = await accountApiV2.post("/auth/login-email-otp", user).then((res) => res);
  return response;
}

export async function checkIsLoggedIn() {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    accountApi.defaults.headers.common["Authorization"] = accessToken;
    let authState = getCookie("lcx-csrf");
    if (accessToken && authState) {
      const response = await accountApi.get("/auth").then((res) => res);
      return response;
    }
  }
}

export async function verifyEmail(user) {
  let config = {
    headers: {
      Authorization: user.token
    }
  };
  const response = await accountApiV2.post("/user/validate-email", {}, config).then((res) => res);
  return response;
}

export async function registerWithEmail(user) {
  const response = await accountApiV2.post("/user/register", user).then((res) => res);
  return response;
}

export async function registerWithEmailOTP(user) {
  const response = await accountApiV3.post("/user/register", user).then((res) => res);
  return response;
}

export async function forgotPassword(user) {
  const response = await accountApiV2.post("/auth/forgot-password", user).then((res) => res);
  return response;
}

export async function resetPassword(user) {
  let config = {
    headers: {
      Authorization: user.token
    }
  };
  let data = {
    password: user.password
  };
  const response = await API.post("/auth/reset-password", data, config).then((res) => res);
  return response;
}

export async function sendVerificationEmail(data) {
  const response = await accountApiV2.post("/user/send-verify-email", data).then((res) => res);
  return response;
}

// Under Maintenance API
export async function getUnderMaintenanceStatus() {
  const response = await accountApiV2.get("/public/maintenance").then((res) => res);
  return response;
}

// Get Token Sales
export async function getTokenSales() {
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    walletAPI.defaults.headers.common["Authorization"] = accessToken;
    const response = await walletAPI.get("token-sales/sales").then((res) => res);
    return response;
  } else {
    const response = await walletAPI.get("token-sales/salesPublic").then((res) => res);
    return response;
  }
}

export async function getSaleDetails(data) {
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    walletAPI.defaults.headers.common["Authorization"] = accessToken;
    const response = await walletAPI.post("token-sales/sale-details", data).then((res) => res);
    return response;
  } else {
    const response = await walletAPI
      .post("token-sales/sale-details-public", data)
      .then((res) => res);
    return response;
  }
}

export async function AllSaleCoins() {
  const response = await walletAPI.get("/token-sales/allSaleCoins").then((res) => res);
  return response;
}
export async function buySalesToken(data) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    walletAPI.defaults.headers.common["Authorization"] = accessToken;
    const response = await walletAPI
      .post("token-sales/" + (data.accessCode ? "buyWithAccessCode" : "buy"), data)
      .then((res) => res);
    return response;
  }
}

export async function verifyAccessCode(data, isPrivate = false) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    walletAPI.defaults.headers.common["Authorization"] = accessToken;
    const response = await walletAPI
      .post(
        `/token-sales/${isPrivate ? "sale-details-only-access-code" : "sale-details-access-code"}`,
        data
      )
      .then((res) => res);
    return response;
  }
}

export async function checkAccessCode(data) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    walletAPI.defaults.headers.common["Authorization"] = accessToken;
    const response = await walletAPI
      .post("/token-sales/sale-details-access-code", data)
      .then((res) => res);
    return response;
  }
}

export async function checkAccessCodeOnly(data) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    walletAPI.defaults.headers.common["Authorization"] = accessToken;
    const response = await walletAPI
      .post("/token-sales/sale-details-only-access-code", data)
      .then((res) => res);
    return response;
  }
}
export async function getCoinRate(data) {
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    walletAPI.defaults.headers.common["Authorization"] = accessToken;
  }
  const response = await walletAPI.post("/token-sales/rates", data).then((res) => res);
  return response;
}

export async function getTokenSale() {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    walletAPI.defaults.headers.common["Authorization"] = accessToken;
    const response = await walletAPI.get("token-sales/sales").then((res) => res);
    return response;
  }
}

export async function getParticipatedSaleDetails(data) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    walletAPI.defaults.headers.common["Authorization"] = accessToken;
    const response = await walletAPI
      .post("token-sales/participated-sale-details", data)
      .then((res) => res);
    return response;
  }
}

export async function claimVestedToken(data) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    walletAPI.defaults.headers.common["Authorization"] = accessToken;
    const response = await walletAPI
      .post("token-sales/claim-vested-token", data)
      .then((res) => res);
    return response;
  }
}

// Get Token Sale Transactions History
export async function getTokenSaleTransactions(data) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    walletAPI.defaults.headers.common["Authorization"] = accessToken;
    const response = await walletAPI.post("token-sales/history", data).then((res) => res);
    return response;
  }
}
// Get all token sales related to user
export async function getAllClientSales() {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    walletAPI.defaults.headers.common["Authorization"] = accessToken;
    const response = await walletAPI.get("token-sales/client/sales").then((res) => res);
    return response;
  }
}

// Get token sale progress related to user
export async function getClientSaleProgress(data) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    walletAPI.defaults.headers.common["Authorization"] = accessToken;
    const response = await walletAPI.post("token-sales/client/progress", data).then((res) => res);
    return response;
  }
}

// Get token sale statistics related to user
export async function getClientSaleStatistic(data) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    walletAPI.defaults.headers.common["Authorization"] = accessToken;
    const response = await walletAPI.post("token-sales/client/chart", data).then((res) => res);
    return response;
  }
}

// Get all the order history of a token sale
export async function getClientSaleOrderHistory(data) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    walletAPI.defaults.headers.common["Authorization"] = accessToken;
    const response = await walletAPI
      .post("token-sales/client/sale-history", data)
      .then((res) => res);
    return response;
  }
}

export async function completeProfileStatusData(user) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    accountApi.defaults.headers.common["Authorization"] = accessToken;
    const response = await accountApi.post("/user/left-profile-update", user).then((res) => res);
    return response;
  }
}
export async function getAcceptedDocument() {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    accountApiV2.defaults.headers.common["Authorization"] = accessToken;
    const response = await accountApiV2.get("/user/accepted-documents").then((res) => res);
    return response;
  }
}
export async function startVerificationPlus(user) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    accountApi.defaults.headers.common["Authorization"] = accessToken;
    const response = await accountApiV2
      .post("/user/start-verification-plus", user)
      .then((res) => res);
    return response;
  }
}

export async function acceptLatestTnc(data) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    accountApi.defaults.headers.common["Authorization"] = accessToken;
    const response = await accountApi.post("/auth/accept-terms", data).then((res) => res);
    return response;
  }
}

export async function acceptBncOwner(data) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    accountApi.defaults.headers.common["Authorization"] = accessToken;
    const response = await accountApi.post("/user/accept-benefial-owner", data).then((res) => res);
    return response;
  }
}

export async function verifyRedirectUrl(url) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const params = new URLSearchParams({
      redirectUrl: url
    }).toString();
    const response = await accountApiV2
      .get("/auth/verifyRedirectUrl?" + params, {
        headers: {
          Authorization: accessToken
        }
      })
      .then((res) => res);
    return response;
  }
}

export async function getAuthCode(domain) {
  let accessToken = localStorage.getItem("accessToken");
  accountApi.defaults.headers.common["Authorization"] = accessToken;
  let authState = getCookie("lcx-csrf");
  if (accessToken !== null || accessToken !== undefined) {
    const response = await accountApi
      .get("/auth/auth-code", {
        params: {
          domain: domain,
          state: authState
        }
      })
      .then((res) => res);
    return response;
  }
}

export async function decodeLightningInvoice(data) {
  const accessToken = localStorage.getItem("accessToken");
  walletV2API.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await walletV2API.post("/user/decodeInvoice", data).then((res) => res);
    return response;
  }
}

export async function cancelAllOpenOrders(data) {
  const accessToken = localStorage.getItem("accessToken");
  exchangeApi.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await exchangeApi.post("order/cancel-all", data).then((res) => res);
    return response;
  }
}

export async function getDectaTransactionStatus(data) {
  const accessToken = localStorage.getItem("accessToken");
  walletAPI.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await walletAPI
      .post("/decta/user/getTransactionStatus", data)
      .then((res) => res);
    return response;
  }
}

export async function createWtDepositRequest(data) {
  const accessToken = localStorage.getItem("accessToken");
  walletAPI.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await walletAPI
      .post("/user/wire-transfer/createDepositRequest", data)
      .then((res) => res);
    return response;
  }
}

export async function getUserOpenOrders(data) {
  const accessToken = localStorage.getItem("accessToken");
  exchangeApi.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await exchangeApi.post("/order/open", data).then((res) => res);
    return response;
  }
}

export async function modifyUserOpenOrders(data) {
  const accessToken = localStorage.getItem("accessToken");
  exchangeApi.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await exchangeApi.post("/order/modify", data).then((res) => res);
    return response;
  }
}

export async function getUserOrders(params) {
  const accessToken = localStorage.getItem("accessToken");
  exchangeApi.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    let data = params?.data;
    let type = params?.type;
    let url = "/order/open";
    if (type === "orderExecutions") {
      url = "/trade/uHistory";
    } else if (type === "orderHistory") {
      url = "/order/orderHistory";
    }
    const response = await exchangeApi.post(url, data).then((res) => res);
    return response;
  }
}

export async function getUserOrderHistory(data) {
  const accessToken = localStorage.getItem("accessToken");
  exchangeApi.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await exchangeApi.post("/trade/uHistory", data).then((res) => res);
    return response;
  }
}

export const resetFilterOrders = () => {
  return true;
};

export async function userWaitListApi(data, publicStatus = false) {
  if (publicStatus) {
    const response = await accountApiV2.post("/public/add-to-waitlist", data).then((res) => res);
    return response;
  } else {
    const accessToken = localStorage.getItem("accessToken");
    accountApiV2.defaults.headers.common["Authorization"] = accessToken;
    if (typeof accessToken === "undefined" || accessToken == null) return false;
    else {
      const response = await accountApiV2.post("/user/add-to-waitlist", data).then((res) => res);
      return response;
    }
  }
}

export async function updateUserAddress(data) {
  const accessToken = localStorage.getItem("accessToken");
  accountApiV2.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await accountApiV2.post(`/user/address-change`, data).then((res) => res);
    return response;
  }
}

export async function updateUserAddressAck(data) {
  const accessToken = localStorage.getItem("accessToken");
  accountApiV2.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await accountApiV2
      .post(`/user/acknowledge-address-change`, data)
      .then((res) => res);
    return response;
  }
}

export async function getUserAddressStatus() {
  const accessToken = localStorage.getItem("accessToken");
  accountApiV2.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await accountApiV2.get(`/user/address-change`).then((res) => res);
    return response;
  }
}

export async function getTokenInfo(data) {
  const accessToken = localStorage.getItem("accessToken");
  walletAPI.defaults.headers.common["Authorization"] = accessToken;
  const response = await walletAPI
    .get(`/public/getTokenInfo?coin=${data?.base}`)
    .then((res) => res?.data);
  return response;
}
