/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, useLocation, useHistory } from "react-router-dom";
import { PageLoader } from "./components/Loader/index";
import { MaintenanceRouter, NonAuthRouter } from "./router";
import { catchAll401Errors, formatAPIErrors } from "./utils/general";
import { verifyEmail } from "./lib/api";
import { trackPage } from "./tracking/track";
import { getTradePairsConfig } from "./actions/trade";
import { setLocalStorage, check24HoursPassedByLocalStorage } from "./utils/helpers";
import { isUserLoggedIn } from "./actions/auth";
import { compose } from "redux";
import { showErrorMsg, showSuccessMsg } from "./utils/notification";
import MainAuth from "./pages/Auth/MainAuth";
import { getSiteMaintenanceStatus } from "./actions/maintenance";
import Navbar from "./components/Navbar";
import FcmCalling from "./views/FcmCalling/fcmCalling";
import { useWindowSize } from "./Hooks/CheckWidthHeight";
import AppDownloadBanner from "./components/Reusable/AppDownloadBanner";
import { changeTheme } from "./actions/theme";
import { DivElement } from "./components/Reusable";
import MobileChartRouter from "./router/mobileChart";
import PromotionContextContainer from "./contexts/Promotion";
import LangCheckComponent from "./Layouts/LangCheckComponent";
import { fetchAvailableLanguagesRequest, updateCurrentLanguage } from "./Modules/Generic/action";
import { createStructuredSelector } from "reselect";
import { getTranslationLanguageList } from "./Modules/Generic/selectors";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const AppHocProps = createStructuredSelector({
  transalationList: getTranslationLanguageList()
});

const App = ({
  isUserLoggedIn,
  getSiteMaintenanceStatus,
  getTradePairsConfig,
  pairsConfig,
  checkingSiteUnderMaintenance,
  isSiteUnderMaintenance,
  isLoggedIn,
  isFetching,
  isDarkTheme,
  changeTheme,
  sessionExpired
}) => {
  const [isVerifying, setIsVerifying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showBanner, setShowBanner] = useState(true);
  const [ipLang, setIpLang] = useState("");
  const location = useLocation();
  const param = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const verifyToken = new URLSearchParams(location.search).get("token");
  const userData = useSelector(state => state.dashboard.userData);
  let { transalationList } = useSelector(AppHocProps);
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    dispatch(fetchAvailableLanguagesRequest());
    if (location.pathname.includes("/trade")) {
      setShowBanner(false);
    }
  }, [location]);

  useEffect(() => {
    if (sessionExpired) {
      showErrorMsg("Session expired, please Log in again.");
    }
  }, [sessionExpired]);

  const callIpCheck = () => {
    fetch("https://ipapi.co/json/", {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((response) => response.json())
      .then((data) => {
        const ipLangString = data.languages;
        const ipLangSplit = ipLangString?.split(",");
        let selectedLang = "en";
        if (ipLangSplit && ipLangSplit.length > 0) {
          selectedLang = ipLangSplit[0].split("-")[0];
        }
        setIpLang(selectedLang);
      })
      .catch(() => {
        setIpLang("en");
        return;
        // console.log("Error fetching country code:", error); // eslint-disable-line no-console
      });
  };

  useEffect(() => {
    const path = location.pathname;
    const LocalStorage = localStorage.getItem("darkTheme");
    let result = check24HoursPassedByLocalStorage("last-lang-update-time");
    if(result) callIpCheck();
    if (isDarkTheme === "") {
      setLocalStorage(JSON.stringify(true), "darkTheme");
      changeTheme();
    }

    if (!LocalStorage && isDarkTheme) {
      setLocalStorage(JSON.stringify(isDarkTheme), "darkTheme");
    }

    catchAll401Errors();
    getTradePairsConfig();
    getSiteMaintenanceStatus();
    if (verifyToken) {
      if (path.includes("verify")) {
        validateEmail(verifyToken);
      } else if (path.includes("resetpassword")) {
        setIsLoading(false);
      }
    } else {
      isUserLoggedIn();
    }

    const page = location?.pathname + location?.search;
    trackPage(page);
  }, []);

  useEffect(() => {
    let result = check24HoursPassedByLocalStorage("last-lang-update-time");
    if(ipLang && result) {
      if(transalationList && transalationList.length > 0 ){
        let checkStatus = transalationList.find((d) => d.code === ipLang);
        let IpLangStatus = "en";
        if(checkStatus) IpLangStatus = checkStatus.code;
        setLocalStorage(IpLangStatus, "ljs-lang");
        setLocalStorage(new Date().getTime(), "last-lang-update-time");
        dispatch(updateCurrentLanguage(IpLangStatus));
        window.Localize.setLanguage(IpLangStatus);
        const newPath = location.pathname?.replace(`/${prevLang}/`, `/${IpLangStatus || "en"}/`);
        history?.replace(newPath);
      }
    }
  }, [ipLang]);

  useEffect(() => {
    const path = location.pathname;
    if (!verifyToken && (!path.includes("verify") || !path.includes("resetpassword"))) {
      setIsLoading(isFetching);
    }
    // eslint-disable-next-line
  }, [isFetching]);
  const { width } = useWindowSize();

  useEffect(() => {
    if (pairsConfig) {
      setLocalStorage(JSON.stringify(pairsConfig), "pairData");
    }
    // eslint-disable-next-line
  }, [pairsConfig]);

  const validateEmail = (verifyToken) => {
    setIsVerifying(true);
    let data = {
      token: verifyToken
    };
    verifyEmail(data)
      .then((res) => {
        showSuccessMsg("E-mail verified successfully. You may now login");
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => {
        history.push(`/${prevLang || userData.preferredLanguage}`);
        setIsLoading(false);
        setIsVerifying(false);
      });
  };

  return (
    <>
      <main
        id="main-container"
        style={{ minHeight: "100vh", overflowX: "hidden" }}
        className={`
        full-screen with-content-panel color-scheme-${isDarkTheme ? "dark" : "light"} theme--lcx_${
          isDarkTheme ? "dark" : "light"
        }`}
      >
        {(location.pathname.includes("/mobile-trade") || location.pathname.includes("/mobile-depth-chart")) && <MobileChartRouter />}

        {(isVerifying || isLoading || checkingSiteUnderMaintenance) && <PageLoader />}

        {isSiteUnderMaintenance && <MaintenanceRouter />}

        {!location.pathname.includes("/mobile-trade") && !location.pathname.includes("/mobile-depth-chart") &&
          !isVerifying &&
          !isLoading &&
          !checkingSiteUnderMaintenance &&
          transalationList && transalationList && transalationList.length > 0 && 
          !isSiteUnderMaintenance && (
            <>
              <PromotionContextContainer>
                <FcmCalling />
                <Navbar />
                <DivElement id={location.pathname.includes("/trade") || location.pathname.includes("/mobile-trade") || location.pathname.includes("/mobile-depth-chart") ? "" : "scroll-bar"} paddingTop="56px">
                  {/* <LangCheckComponent /> */}
                  {isLoggedIn ? <MainAuth /> : <NonAuthRouter />}
                </DivElement>
                {width < 767 && showBanner && (
                  <AppDownloadBanner onClickHandler={() => setShowBanner(false)} />
                )}
              </PromotionContextContainer>
            </>
          )}
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme,
  userData: state.dashboard.getUserData,
  pairsConfig: state.trade.pairsConfig,
  isPairsConfigFetching: state.trade.isPairsConfigFetching,
  isLoggedIn: state.auth.isLoggedIn,
  isFetching: state.auth.isFetching,
  isSiteUnderMaintenance: state.maintenance.isSiteUnderMaintenance,
  checkingSiteUnderMaintenance: state.maintenance.isLoading,
  sessionExpired: state.auth.sessionExpired
});

const mapDispatchToProps = (dispatch) => ({
  changeTheme: () => dispatch(changeTheme()),
  getTradePairsConfig: (data) => dispatch(getTradePairsConfig(data)),
  isUserLoggedIn: () => dispatch(isUserLoggedIn()),
  getSiteMaintenanceStatus: () => dispatch(getSiteMaintenanceStatus())
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(App);