import React from "react";
import { numberWithCommas } from "../../../../../utils/general";
import { Skeleton } from "../../../../../components/Loader";
import {
  CalendarBlueIllustration,
  MoneyBagIllustration,
  PeopleIllustration,
  ReleasedTokenIllustration
} from "../../../../../assets/svgs/illustrations";
import { TokenSaleAdminContainer } from "../../../../../assets/StyleComponents/TokenSaleAdmin.style";
import { BannerBox } from "../../../../../assets/StyleComponents/Exchange.style";
import { HeadingFourth } from "../../../../../assets/StyleComponents/FontSize/headingFourth.style";
import { useWindowSize } from "../../../../../Hooks/CheckWidthHeight";

const SaleAnalyticsRow2 = (props) => {
  let { loader, saleDetails } = props;
  let isLoaded = !loader && saleDetails;
  const { width } = useWindowSize();
  return (
    <TokenSaleAdminContainer SaleAnalyticsRow2>
      <BannerBox
        CardWhite
        width="100%"
        height="145px"
        heightLg="239px"
        heightMd="auto"
        mt="35px"
        display="flex"
        directionLg="column"
        className="saleanalytics__row2 element-box"
      >
        <BannerBox
          width="50%"
          widthLg="100%"
          heightLg="50%"
          display="flex"
          justifyContent="space-between"
          rightBorder={width > 1024 ? true : null}
          bottomBorder
          className="saleanalytics__row2__box"
        >
          <BannerBox className="saleanalytics__row2__box--flexcol">
            <HeadingFourth size="18px" sizeLg="16px" txtGrey className="row2__box__title">
              Total Token Sold
            </HeadingFourth>
            <HeadingFourth weight="bold" size="24px" sizeLg="20px" className="row2__box__value">
              {isLoaded ? (
                `${saleDetails.totalSoldToken ? numberWithCommas(saleDetails.totalSoldToken) : 0} ${
                  saleDetails.tokenSaleCoin ? saleDetails.tokenSaleCoin : ""
                }`
              ) : (
                <Skeleton className="saleanalytics__loader" />
              )}
            </HeadingFourth>
          </BannerBox>
          <MoneyBagIllustration />
        </BannerBox>
        <BannerBox
          width="50%"
          widthLg="100%"
          heightLg="50%"
          display="flex"
          justifyContent="space-between"
          bottomBorder
          className="saleanalytics__row2__box"
        >
          <BannerBox className="saleanalytics__row2__box--flexcol">
            <HeadingFourth size="18px" sizeLg="16px" txtGrey className="row2__box__title">
              No. of people who bought {saleDetails.tokenSaleCoin ? saleDetails.tokenSaleCoin : ""}
            </HeadingFourth>
            <HeadingFourth weight="bold" size="24px" sizeLg="20px" className="row2__box__value">
              {isLoaded ? (
                saleDetails.totalParticipatedUsers ? (
                  saleDetails.totalParticipatedUsers
                ) : (
                  "0"
                )
              ) : (
                <Skeleton className="saleanalytics__loader" />
              )}
            </HeadingFourth>
          </BannerBox>
          <PeopleIllustration />
        </BannerBox>
        <BannerBox
          width="50%"
          widthLg="100%"
          heightLg="50%"
          display="flex"
          justifyContent="space-between"
          rightBorder={width > 1024 ? true : null}
          bottomBorder={width < 1025 ? true : null}
          className="saleanalytics__row2__box"
        >
          <BannerBox className="saleanalytics__row2__box--flexcol">
            <HeadingFourth size="18px" sizeLg="16px" txtGrey className="row2__box__title">
              Total Vested Token
            </HeadingFourth>
            <HeadingFourth weight="bold" size="24px" sizeLg="20px" className="row2__box__value">
              {isLoaded ? (
                `${
                  saleDetails.totalVestedAmount
                    ? numberWithCommas(saleDetails.totalVestedAmount)
                    : 0
                } ${saleDetails.tokenSaleCoin ? saleDetails.tokenSaleCoin : ""}`
              ) : (
                <Skeleton className="saleanalytics__loader" />
              )}
            </HeadingFourth>
          </BannerBox>
          <CalendarBlueIllustration />
        </BannerBox>
        <BannerBox
          width="50%"
          widthLg="100%"
          heightLg="50%"
          display="flex"
          justifyContent="space-between"
          className="saleanalytics__row2__box"
        >
          <BannerBox className="saleanalytics__row2__box--flexcol">
            <HeadingFourth size="18px" sizeLg="16px" txtGrey className="row2__box__title">
              Total Released Token
            </HeadingFourth>
            <HeadingFourth weight="bold" size="24px" sizeLg="20px" className="row2__box__value">
              {isLoaded ? (
                `${
                  saleDetails.totalReleasedAmount
                    ? numberWithCommas(saleDetails.totalReleasedAmount)
                    : 0
                } ${saleDetails.tokenSaleCoin ? saleDetails.tokenSaleCoin : ""}`
              ) : (
                <Skeleton className="saleanalytics__loader" />
              )}
            </HeadingFourth>
          </BannerBox>
          <ReleasedTokenIllustration />
        </BannerBox>
      </BannerBox>
    </TokenSaleAdminContainer>
  );
};

export default SaleAnalyticsRow2;
