import { useSelector } from "react-redux";
import OrderBookTable from "./OrderBookTable";
import { useMemo } from "react";
import moment from "moment";
import { numberFormatter } from "../../../../utils";

const TradeHistoryTable = () => {
  const tradeHistory = useSelector((state) => state.trading.tradeHistory);
  const trades = useMemo(() => tradeHistory, [tradeHistory]);
  const selectedPair = useSelector((state) => state.trading.selectedPair);
  const selectedPairConfig = useSelector((state) => state.trading.selectedPairConfig);

  return (
    <OrderBookTable>
      <OrderBookTable.Tr bgHeader height="18px" padding="0" marginBottom="4px">
        <OrderBookTable.Th textAlign="left" width="33.33%" paddingLeftMd="16px">
          Price({selectedPair.quote})
        </OrderBookTable.Th>
        <OrderBookTable.Th textAlign="right" width="28.67%">
          Amount({selectedPair.base})
        </OrderBookTable.Th>
        <OrderBookTable.Th textAlign="right" width="38%" paddingRightMd="16px">
          Time
        </OrderBookTable.Th>
      </OrderBookTable.Tr>
      {trades &&
        trades.map((trade, index) => (
          <OrderBookTable.Tr key={index}>
            <OrderBookTable.Td
              width="33.33%"
              green={trade[2] === "BUY" ? true : null}
              red={trade[2] === "SELL" ? true : null}
              textAlign="left"
              paddingMd="0 0 0 16px"
            >
              {numberFormatter(trade[0], selectedPairConfig?.orderPrecision?.Price)}
            </OrderBookTable.Td>
            <OrderBookTable.Td width="28.67%" textAlign="right">
              {numberFormatter(trade[1], selectedPairConfig?.orderPrecision?.Amount)}
            </OrderBookTable.Td>
            <OrderBookTable.Td width="38%" textAlign="right" paddingMd="0 16px 0 0">
              {moment(trade[3] * 1000).format("HH:mm:ss")}
            </OrderBookTable.Td>
          </OrderBookTable.Tr>
        ))}
    </OrderBookTable>
  );
};

export default TradeHistoryTable;
