import {
  CryptoIcon,
  DefiIcon,
  DocumentIcon,
  NavbarCampagins,
  PlayIcon,
  ReferIcon,
  ReportsIcon,
  SupportIcon,
  TiamondsIcon,
  TradingCompetitionIcon
} from "../../../assets/svgs";
import { config } from "../../../constants";

const prevLang = localStorage.getItem("ljs-lang");

const NAV_DATA = [
  {
    type: "PRODUCTS",
    value: [
      {
        path: config.tiamondsUrl,
        title: "Tiamonds",
        subtitle: "Diamonds backed NFT’s",
        icon: (props) => <TiamondsIcon {...props} />,
        isOpenInOtherTab: true,
        tag: "NFT"
      },
      {
        path: config.masterdexUrl,
        title: "MasterDEX",
        subtitle: "One-stop DeFi platform",
        icon: (props) => <DefiIcon {...props} />,
        isOpenInOtherTab: true,
        tag: "DeFi"
      },
      {
        path: "https://www.lcx.com/cryptocurrency-reference-price-services/",
        title: "Cryptocurrency reference price ",
        subtitle: "A credible daily benchmark",
        icon: (props) => <CryptoIcon {...props} />,
        isOpenInOtherTab: true
      }
    ]
  },
  {
    type: "RESOURCES",
    value: [
      {
        path: "https://docs.lcx.com/",
        title: "API docs",
        subtitle: "Reference documentation",
        icon: (props) => <DocumentIcon {...props} />,
        isOpenInOtherTab: true
      },
      {
        path: "https://www.lcx.com/info/faq/",
        title: "FAQ & support",
        subtitle: "Resolve queries quick and easy way",
        icon: (props) => <SupportIcon {...props} />,
        isOpenInOtherTab: true
      },
      {
        path: `${prevLang ? `/${prevLang}` : "/en"}/tutorials`,
        title: "Tutorials",
        subtitle: "Learn step by step",
        icon: (props) => <PlayIcon {...props} />
      }
    ]
  },
  {
    type: "FEATURES",
    value: [
      {
        path: `${prevLang ? `/${prevLang}` : "/en"}/setting/reports`,
        title: "Reports",
        subtitle: "Get Tax Report",
        icon: (props) => <ReportsIcon {...props} />,
        noShowinLogin: true
      },
      {
        path: `${prevLang ? `/${prevLang}` : "/en"}/trading-competition`,
        title: "Trading competition",
        subtitle: "compete and win big",
        icon: (props) => <TradingCompetitionIcon {...props} />
      }
    ]
  },
  {
    type: "PROMOTIONS",
    value: [
      {
        path: `${prevLang ? `/${prevLang}` : "/en"}/campaigns`,
        title: "Rewards Hub",
        subtitle: "$10K weekly! KYC required",
        icon: (props) => <NavbarCampagins {...props} />
      },
      {
        path: `${prevLang ? `/${prevLang}` : "/en"}/referral`,
        title: "Referral",
        subtitle: "Refer and earn big bucks",
        icon: (props) => <ReferIcon {...props} />
      }
    ]
  }
];

export default NAV_DATA;
