import { Fragment, useState } from "react";
import { DivElement, TextWrapper, RouterLink } from "../../Reusable";
import { compose } from "redux";
import { connect } from "react-redux";
import { getAuthCode } from "../../../lib/api";
import { config } from "../../../constants";
import { showErrorMsg } from "../../../utils/notification";
import { ExpandMoreIcon, ChevronRightIcon } from "../../../assets/svgs";
import NAV_DATA from "../More/navData";
import PulseAnimation from "../../Reusable/Animation/Pulse";
import { BlueNewMoreNavbarTag } from "../../../assets/svgs";

const MoreNav = ({ isLoggedIn, authState, openHamburger, setOpenHamburger }) => {
  const [active, setActive] = useState(false);

  const onClickHandler = (e, data) => {
    if (data?.isOpenInOtherTab) {
      e.preventDefault();
      if (isLoggedIn && data.title === "Tiamonds") {
        let productDomain = "tiamonds";
        getAuthCode(productDomain)
          .then((res) => {
            let code = res && res.data ? res.data.data.authCode : "";
            if (!code) throw new Error("");

            window.open(`${config.tiamondsUrl}/?token=${code}&state=${authState}`, "_self");
          })
          .catch((err) => {
            showErrorMsg(`Error in generating ${productDomain} auth code.`);
          });
      } else {
        window.open(data.path, "_blank");
      }
    }

    setOpenHamburger(!openHamburger);
  };

  return (
    <DivElement
      bdGrey
      borderBottom="1px solid"
      border={active ? "1px solid" : ""}
      borderTop={active ? "none" : ""}
      onClick={() => setActive(!active)}
    >
      <DivElement
        displayBtw
        height="44px"
        width="100%"
        cursor
        bgPrimary={active ? true : null}
        padding="0 8px"
        position="relative"
      >
        <TextWrapper fontSize="14px" fontSizeSm="12px" lineHeight="20px" lineHeightSm="18px">
          More
          <PulseAnimation />
        </TextWrapper>
        {active ? <ExpandMoreIcon pointer /> : <ChevronRightIcon pointer />}
      </DivElement>
      {active && (
        <DivElement>
          {NAV_DATA.map((data) => (
            <DivElement key={data.type}>
              <TextWrapper
                secondary
                fontSize="12px"
                fontWeight="400"
                lineHeight="18px"
                padding="5px 8px"
              >
                {data.type}
              </TextWrapper>
              {data.value.map((option, i) => (
                <Fragment key={i}>
                  {(isLoggedIn || !option?.hideOnLogout) && (
                    <RouterLink to={option.path} onClick={(e) => onClickHandler(e, option)}>
                      <DivElement displayBtw height="44px" width="100%" bdGrey padding="0 8px">
                        <TextWrapper
                          fontSize="14px"
                          fontSizeSm="12px"
                          lineHeight="20px"
                          lineHeightSm="18px"
                        >
                          <DivElement display="inline-block" marginRight="12px">
                            {option.icon}
                          </DivElement>
                          {option.title}{" "}
                          {option.title === "Trading competition" && (
                            <BlueNewMoreNavbarTag
                              color="white"
                              width="33px"
                              height="20px"
                              marginLeft="8px"
                            />
                          )}
                          {option.title === "Rewards Hub" && (
                            <BlueNewMoreNavbarTag
                              color="white"
                              width="33px"
                              height="20px"
                              marginLeft="8px"
                            />
                          )}
                        </TextWrapper>
                        <ChevronRightIcon pointer />
                      </DivElement>
                    </RouterLink>
                  )}
                </Fragment>
              ))}
            </DivElement>
          ))}
        </DivElement>
      )}
    </DivElement>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  authState: state.auth.authState
});

export default compose(connect(mapStateToProps))(MoreNav);
