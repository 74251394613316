import styled from "styled-components";
import { TableRowComponent } from "../TableHead.style";

const LedgerDepositRow = styled(TableRowComponent)`
  th,
  td {
    display: flex;
    font-size: 14px !important;
    font-weight: 500;
    line-height: 18px;
    text-right: right;
    &.symb-padd {
      justify-content: center;
      margin-bottom: 18px !important;
    }
    &.text-right {
      justify-content: flex-end;
    }

    color: ${({ theme }) => theme.bodyText};
    flex: none;
    flex-grow: 0;
    align-items: center;
    box-sizing: border-box;
    overflow: visible !important;

    &:nth-child(1) {
      flex: 0 0 111px !important;
      justify-content: flex-start;
      text-transform: capitalize !important;
    }
    &:nth-child(2) {
      flex: 0 0 165px !important;
      justify-content: flex-start;
    }
    &:nth-child(3) {
      flex: 1 1 255px !important;
      justify-content: flex-start;
    }
    &:nth-child(4) {
      flex: 0 0 102px !important;
      justify-content: flex-start;
    }
    &:nth-child(5) {
      flex: 0 0 190px !important;
      justify-content: flex-start;
    }
    &:nth-child(6) {
      flex: 0 0 209px !important;
      justify-content: flex-end;
      padding-right: 12px !important;
    }

    // tablet screens +++++++++++++++++++++++++++++++
    @media (max-width: 1024px) {
      &:nth-child(1) {
        flex: 0 0 100px !important;
        justify-content: flex-start;
        font-size: 14px;
        text-transform: capitalize !important;
      }
      &:nth-child(2) {
        flex: 0 0 140px !important;
        justify-content: flex-start;
        font-size: 14px;
      }
      &:nth-child(3) {
        flex: 0 0 189px !important;
        justify-content: flex-start;
        font-size: 14px;
      }
      &:nth-child(4) {
        flex: 1 1 160px !important;
        justify-content: flex-end !important;
        font-size: 14px;
      }
    }
    // phone for row++++++++++++++++++++++++++++
    @media (max-width: 767px) {
      &:nth-child(1) {
        flex: 1 1 80px !important;
        justify-content: flex-start;
        font-size: 12px;
        text-transform: capitalize !important;
      }
      &:nth-child(2) {
        flex: 1 1 90px !important;
        justify-content: flex-start;
        font-size: 12px;
      }
      &:nth-child(3) {
        flex: 1 1 55px !important;
        justify-content: flex-end !important;
        font-size: 12px;
      }
      &:nth-child(4) {
        flex: 1 1 107px !important;
        justify-content: flex-start;
        font-size: 12px;
      }
      font-size: 12px !important;
    }
  }
  th {
    line-height: 16px;
    justify-content: left;
    text-transform: capitalize !important;
    cursor: pointer;
    border: none !important;
    font-weight: 500 !important;
    color: ${({ theme }) => theme.tableHeaderColor} !important;
    p,
    h5,
    button,
    span {
      font-weight: 500 !important;
      color: ${({ theme }) => theme.bodyText} !important;
    }
    &:nth-child(2) {
      justify-content: flex-start;
    }
    @media (max-width: 1024px) {
      &:nth-child(4) {
        padding-right: 16px;
      }
    }
    @media (max-width: 767px) {
      &:nth-child(3) {
        padding-right: 40px;
      }
    }
  }
  td {
    font-size: 16px !important;
    @media (max-width: 1024px) {
      font-size: 14px !important;
    }
    @media (max-width: 767px) {
      font-size: 12px !important;
    }
    font-weight: 500;
    h5,
    button {
      flex-grow: 1;
    }
    &:nth-child(3) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      flex-grow: 0;
      h5,
      button {
        flex-grow: 0;
      }
    }
    &: .growIcon {
      flex-grow: 1 !important;
    }
    @media (max-width: 1024px) {
      &:nth-child(3) {
        flex-direction: row;
        justify-content: flex-start;
      }
      &:nth-child(4) {
        align-items: center;
        justify-content: center;
      }
    }
    @media (max-width: 767px) {
      &:nth-child(3) {
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

export { LedgerDepositRow };
