/* eslint-disable no-unused-vars */
import { connect, useSelector } from "react-redux";
import { compose } from "redux";
import { logout } from "../../actions/auth";
import { Fragment } from "react";
import { DivElement, DropdownWrapper, RouterLink, TextWrapper, UserAvatar } from "../Reusable";
import {
  BagIcon,
  DashboardIcon,
  ErrorOrangeIcon,
  ExpandMoreIcon,
  LogoutIcon,
  ReportsIcon,
  SettingsIcon,
  SuccessBlueIcon
} from "../../assets/svgs";
import { showSuccessMsg } from "../../utils/notification";
import { createStructuredSelector } from "reselect";
import { getUserSelectedLang } from "../../Modules/Generic/selectors";
import { getLocalStorage, setLocalStorage } from "../../utils/helpers";

const ProfileDropdownProps = () => createStructuredSelector({
  userSelectedLang: getUserSelectedLang()
});

const Label = () => {
  return (
    <DivElement flex alignItems="center" height="100%" cursor>
      <UserAvatar />
      <ExpandMoreIcon />
    </DivElement>
  );
};

const ProfileDropdown = ({
  isDarkTheme,
  userData,
  logoutUser,
  actionsRequired,
  allClientSales
}) => {
  const isVerified =
    userData && (userData.KYC.STATUS === "LEVEL2" || userData.KYC.STATUS === "LEVEL3");
  const isKycSubmitted = userData && userData.KYC.LEVEL2.status === "SUBMITTED";
  const noActionRequired = actionsRequired && actionsRequired.length === 0;
  let { userSelectedLang } = useSelector(ProfileDropdownProps);
  let prevLang = getLocalStorage("ljs-lang");
  const language = prevLang ? prevLang : userData?.preferredLanguage;
  return (
    <DropdownWrapper
      alignRight="24px"
      dropdownAlignRight="0px"
      minWidth="210px"
      label={<Label isDarkTheme={isDarkTheme} userData={userData} />}
      containerProps={{ padding: "0 0 0 16px" }}
    >
      {userData?.accountType === "CORPORATE" && (
        <DivElement
          bgPrimary
          height="32px"
          padding="0 16px"
          dAlignCenter
          borderRadius="5px 5px 0 0"
        >
          <BagIcon marginRight="8px" />
          <TextWrapper fontSize="14px" secondary>
            Corporate Account
          </TextWrapper>
        </DivElement>
      )}
      {(userData?.firstName?.length > 0 || userData.email) && (
        <DivElement padding="16px 16px 12px 16px" className="badge_user_localise">
          {(userData.companyName || userData?.firstName) && (
            <TextWrapper
              fontSize="16px"
              lineHeight="24px"
              overflowWrap="break-word"
              verticalAlign="center"
            >
              {userData.accountType === "CORPORATE"
                ? `${userData?.companyName}`
                : `${userData?.firstName} ${userData?.lastName}`}{" "}
              &nbsp;
              {isVerified && <SuccessBlueIcon width="20px" height="20px" />}
            </TextWrapper>
          )}
          <TextWrapper
            fontSize="14px"
            lineHeight="20px"
            secondary
            fontWeight="400"
            className="email_address_user_localise"
          >
            {userData?.email}
          </TextWrapper>
          {!isVerified && !isKycSubmitted && noActionRequired && userData.email && (
            <DivElement dAlignCenter paddingTop="12px">
              <ErrorOrangeIcon marginRight="12px" />
              <RouterLink to={`${language ? `/${language}` : ""}/setting/profile`} underline fontWeight="400">
                Verify your account
              </RouterLink>
            </DivElement>
          )}
        </DivElement>
      )}

      {noActionRequired && (
        <Fragment>
          <RouterLink
            to={`${language ? `/${language}` : ""}/setting/reports`}
            padding="8px 16px"
            primary
            alignCenter
            whiteSpace="nowrap"
            marginBottom="4px"
            fontSize="14px"
            blueHover
          >
            <ReportsIcon marginRight="12px" />
            Reports
          </RouterLink>
          <RouterLink
            to={`${language ? `/${language}` : ""}/setting/profile`}
            padding="8px 16px"
            primary
            alignCenter
            whiteSpace="nowrap"
            marginBottom="4px"
            fontSize="14px"
            blueHover
          >
            <SettingsIcon marginRight="12px" />
            Settings
          </RouterLink>
          {allClientSales && allClientSales.showLink && (
            <RouterLink
              to={`${language ? `/${language}` : ""}/token-sale/admin/ongoing`}
              padding="8px 16px"
              primary
              alignCenter
              whiteSpace="nowrap"
              marginBottom="4px"
              fontSize="14px"
              blueHover
            >
              <DashboardIcon marginRight="12px" />
              Sale Dashboard
            </RouterLink>
          )}
        </Fragment>
      )}

      <DivElement
        padding="8px 16px"
        dAlignCenter
        whiteSpace="nowrap"
        marginBottom="4px"
        blueHover
        onClick={() => {
          localStorage.setItem("ljs-lang", "en");
          setLocalStorage(new Date().getTime(), "last-lang-update-time");
          window.Localize.setLanguage("en");
          logoutUser();
          showSuccessMsg("User Logged Out");
        }}
      >
        <LogoutIcon marginRight="12px" />
        <TextWrapper fontSize="14px" nowrap red>
          Log Out
        </TextWrapper>
      </DivElement>
    </DropdownWrapper>
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme,
  userData: state.dashboard.userData,
  actionsRequired: state.auth.actionsRequired,
  allClientSales: state.sale.allClientSales
});

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logout())
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(ProfileDropdown);
