import styled, { css } from "styled-components";
import { Fragment, useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { InfoBlueIcon } from "../../../../assets/svgs";
import { DivElement, SpanElement, TextWrapper } from "../../../../components/Reusable";

const LiTag = styled.li`
  margin-bottom: ${(props) => props.marginBottom || ""};
  color: ${(props) => props.theme.primaryText};

  ::marker {
    color: ${(props) => props.theme.primaryText};
  }
`;

const Wrapper = styled(DivElement)`
  z-index: 100;
  right: -100%;
  position: absolute;
  width: 360px;
  border: 1px solid ${(props) => props.theme.sepColor};
  border-radius: 5px;
  padding: 24px 16px;

  @media (max-width: 1024px) {
    bottom: calc(100% + 8px);
    top: initial;
    left: initial;
    right: initial;

    ${(props) =>
      props.side === "buy" &&
      css`
        bottom: initial;
        top: calc(100% + 8px);
      `}
  }

  @media (max-width: 767px) {
    width: 320px;
  }

  ::before {
    content: "";
    height: 16px;
    width: 16px;
    position: absolute;
    z-index: -1;
    left: -8px;
    top: calc(50% - 8px);
    transform: rotate(45deg);
    background-color: ${(props) => props.theme.cardBg};
    border: solid ${(props) => props.theme.sepColor};
    border-width: 0 0 1px 1px !important;

    @media (max-width: 1024px) {
      bottom: -8px;
      top: initial;
      transform: rotate(-45deg);
      left: calc(50% - 8px);
      right: initial;

      ${(props) =>
        props.side === "buy" &&
        css`
          bottom: initial;
          top: -8px;
          transform: rotate(135deg);
        `}
    }
  }
`;

const PostOrHaltMode = ({ containerProps, modalProps, side }) => {
  const [showModal, setShowModal] = useState(false);
  const selectedPairConfig = useSelector((state) => state.trading.selectedPairConfig);
  const mode = selectedPairConfig ? selectedPairConfig.mode : "";

  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <DivElement displayCenter width="100%" height="100%" ref={ref} {...containerProps}>
      <TextWrapper lineHeight="20px" marginRight="6px">
        {`${mode === "halt" ? "Halt" : "Post Only"} Mode`}
      </TextWrapper>
      <InfoBlueIcon pointer width="16px" height="16px" onClick={() => setShowModal(!showModal)} />

      {showModal && (
        <Wrapper bgCard {...modalProps} mode={mode} side={side}>
          <TextWrapper lineHeight="20px" secondary>
            {mode === "post_only"
              ? `Post Only Mode allows you to place limit order and the exchange will execute your orders
            once liquidity reflects and buy, sell price matches.`
              : `During the Trading Halt Mode, creating new orders and executing trades for specific pairs is not possible. However, users can still cancel their existing orders associated with the halted pairs. This mode ensures a fair and stable trading environment while allowing users to manage their open orders accordingly.
`}
          </TextWrapper>
          {mode === "post_only" ? (
            <Fragment>
              <TextWrapper margin="20px 0" fontSize="16px" lineHeight="24px">
                Benefits of Post Only Mode:
              </TextWrapper>
              <ol style={{ paddingLeft: "17.73px", marginBottom: "0" }}>
                <LiTag marginBottom="16px">
                  <SpanElement primary>Time Saving</SpanElement> -{" "}
                  <SpanElement secondary>
                    Exchange accepts your order request and executes automatically.
                  </SpanElement>
                </LiTag>
                <LiTag>
                  <SpanElement primary>Money Saving</SpanElement> -{" "}
                  <SpanElement secondary>
                    Post Only Mode benefits you in getting high demand assets through our exchange
                    automation and saves your bucks from volatile prices.
                  </SpanElement>
                </LiTag>
              </ol>
            </Fragment>
          ) : (
            <TextWrapper lineHeight="20px" marginTop="20px">
              Please note that order cancellations are permitted during this period. If you have any
              questions or need assistance, please contact our support team.
            </TextWrapper>
          )}
        </Wrapper>
      )}
    </DivElement>
  );
};

export default PostOrHaltMode;
