/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { logoutUser, updateFeeList } from "../../lib/api";
import { trackPage } from "../../tracking/track";
import SkeletonLoader from "../../components/SkeletonLoader/index";
import { showErrorMsg, showSuccessMsg } from "../../utils/notification";
import { TableBodyComponent } from "../../assets/StyleComponents/Table/TableHead.style";
import { getOrderFees } from "../../actions/setting";
import { FeesRow } from "../../assets/StyleComponents/Table/Setting/Fees.style";
import { numberWithCommas } from "../../utils/general";
import { useWindowSize } from "../../Hooks/CheckWidthHeight/index";
import { TableLoader } from "../../components/Loader";
import { DivElement, ImgElement, InputSwitch } from "../../components/Reusable";
import { BannerHeadingSpan, MainElement } from "../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { BannerBox } from "../../assets/StyleComponents/Exchange.style";
import { HeadingFourth } from "../../assets/StyleComponents/FontSize/headingFourth.style";
import { HeadingSecond } from "../../assets/StyleComponents/FontSize/headsecond.style";
import { ParaTag } from "../../assets/StyleComponents/FontSize/para.style";
import { HeadingThird } from "../../assets/StyleComponents/FontSize/headingThird.style";
import { ButtonComponent } from "../../assets/StyleComponents/Button.style";
import NoRecordsFound from "../../components/NoRecordsFound/noRecordsFound";
import { Table, Thead, Th, Tbody, Td } from "../../components/Reusable";
import { Fees } from "../../assets/png/index";
import { FeeIcon } from "../../assets/svgs";

function FeesComponent(props) {
  let { isTheme } = props;
  const { width } = useWindowSize();
  const location = useLocation();
  const [feedata, setFeedata] = useState({});
  const [feeCheck, setFeeCheck] = useState(false);
  const [currentFee, setCurrentFees] = useState({});
  const [feeVolume, setFeeVolume] = useState(0);

  useEffect(() => {
    props.getOrderFees();
    let page = location.pathname + location.search;
    trackPage(page);
    setFeeCheck(feedata?.feeInLCX);
    // setCurrentFees(feedata?.fee[feedata?.feeLevel]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckBox = (event) => {
    event.preventDefault();
    setFeeCheck(!feeCheck);
    let data = {
      FeeInLCX: String(!feeCheck)
    };
    // setIsloader(true);
    updateFeeList(data)
      .then((res) => {
        setFeeVolume(res.data?.data?.user30DaysVolume);
        showSuccessMsg(res.data?.message);
      })
      .catch((err) => {
        if (err.status === 401) {
          logoutUser()
            .then((res) => {
              localStorage.removeItem("accessToken");
              localStorage.removeItem("user");
              showSuccessMsg(res.data.message);
              window.location.reload();
            })
            .catch((err) => {
              showErrorMsg(err.response.data.message, { autoClose: 5000 });
            });
        }
      });
  };

  // Fee checker
  useEffect(() => {
    setFeedata(props.orderFees);
    setFeeCheck(feedata?.feeInLCX);
    setCurrentFees(feedata?.current);
    setFeeVolume(feedata?.user30DaysVolume);
  }, [feedata?.feeInLCX, props?.orderFees]);

  const styles = {
    background: "transparent",
    color: "#E66767",
    borderColor: "#E66767"
  };

  return (
    <MainElement padding={"0 16px"} paddingMd={"20px 0px"}>
      <BannerBox>
        <BannerBox
          display="flex"
          justifyContent="space-between"
          mb="24px"
          mbMd="12px"
          displayMd="block"
          paddingMd={"0px 10px"}
        >
          <BannerBox>
            <BannerHeading size="18px" weight="400" sizeLg="16px" sizeMd="12px">
              Save more and get the most out of every transaction!
            </BannerHeading>
            <HeadingSecond
              size="44px"
              weight="500"
              txtPrimary
              mt="12px"
              mb="24px"
              sizeLg="30px"
              sizeMd="24px"
            >
              Enjoy Ultra-Low Fees
            </HeadingSecond>
            <BannerBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt="20px"
              mb="20px"
              mbMd="30px"
              // gap="5%"
            >
              <BannerBox display="flex" gap="2.5%">
                {/* <BannerBox> */}
                <BannerBox width="160px" widthMd="95px">
                  <HeadingFourth weight="500" size="20px" sizeMd="16px">
                    Maker:
                  </HeadingFourth>
                  {props.isOrderFeesFetching ? (
                    <SkeletonLoader height={40} width="90%" />
                  ) : !feeCheck ? (
                    <HeadingSecond size="28px" weight="300" sizeMd="14px">
                      {currentFee?.MakerFees}%
                    </HeadingSecond>
                  ) : (
                    <HeadingSecond size="28px" weight="300" sizeMd="14px">
                      <BannerHeadingSpan
                        textDecoration="line-through"
                        size="16px"
                        weight="300"
                        sizeMd="12px"
                      >
                        {currentFee?.MakerFees}%
                      </BannerHeadingSpan>{" "}
                      {currentFee?.MakerFeesInLCX}%
                    </HeadingSecond>
                  )}
                </BannerBox>
                <BannerBox width="200px" widthMd="95px" leftBorder plMd="10px" pl="20px">
                  <HeadingFourth weight="500" size="20px" sizeMd="16px">
                    Taker:
                  </HeadingFourth>
                  {props.isOrderFeesFetching ? (
                    <SkeletonLoader height={40} width="90%" />
                  ) : !feeCheck ? (
                    <HeadingSecond size="28px" weight="300" sizeMd="14px">
                      {currentFee?.TakerFees}%
                    </HeadingSecond>
                  ) : (
                    <HeadingSecond size="28px" weight="300" sizeMd="14px">
                      <BannerHeadingSpan
                        textDecoration="line-through"
                        size="16px"
                        weight="300"
                        sizeMd="12px"
                      >
                        {currentFee?.TakerFees}%
                      </BannerHeadingSpan>{" "}
                      {currentFee?.TakerFeesInLCX}%
                    </HeadingSecond>
                  )}
                </BannerBox>
              </BannerBox>
              <BannerBox>
                <FeeIcon widthSm="64px" heightSm="64px" display="none" displaySm="block" />
              </BannerBox>
            </BannerBox>
            {/* </BannerBox> */}
          </BannerBox>
          <BannerBox>
            <FeeIcon
              widthMd="120px"
              heightMd="120px"
              width="200px"
              height="200px"
              widthSm="64px"
              heightSm="64px"
              displaySm="none"
            />
          </BannerBox>
        </BannerBox>

        <BannerBox
          FeesBannerBg
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="20px 24px"
          paddingMd="20px 10px"
          BorderRadius="5px"
          // paddingMd={"0px 10px"}
          // marginLg="30px 0px"
        >
          <BannerBox
            alignItems="center"
            BufferArea
            gap="12px"
            gapMd="4px"
            directionLg="column"
            alignItemsLg="flex-start"
          >
            <ParaTag BodyColor weight="300" size="18px" sizeLg="16px" sizeMd="12px">
              {!feeCheck ? "Looking for discounted trading fees?" : "50% discount on fees! "}
            </ParaTag>
            <HeadingThird
              size="20px"
              weight="500"
              sizeLg="18px"
              sizeMd="12px"
              weightMd="800"
              mt="12px"
            >
              {feeCheck
                ? "You're paying fees in LCX! Enjoy a 50% discount!"
                : "Pay the fee in LCX and receive a 50% discount!"}
            </HeadingThird>
          </BannerBox>
          {props.isOrderFeesFetching && feeCheck ? (
            <SkeletonLoader height={50} />
          ) : (
            <>
              <InputSwitch
                checked={feeCheck}
                setChecked={(e) => {
                  setFeeCheck(!feeCheck);
                  handleCheckBox(e);
                }}
              />
            </>
          )}
        </BannerBox>

        <BannerBox
          mt="35px"
          mb="27px"
          marginLg="24px 0px"
          mtMd="24px"
          mbMd="16px"
          paddingMd={"0px 10px"}
        >
          <BannerBox
            display="flex"
            justifyContent="space-between"
            mb="12px"
            directionMd="column"
            gapMd="10px"
          >
            <HeadingThird size="20px" weight="500" sizeMd="14px">
              Fee Structure
            </HeadingThird>
            <HeadingThird size="16px" weight="300" sizeMd="14px" textAlignMd="right">
              Your 30-day trading volume:
              <BannerHeadingSpan weight="bold">
                {" "}
                ${numberWithCommas(feeVolume ? feeVolume : 0)}
              </BannerHeadingSpan>
            </HeadingThird>
          </BannerBox>
          <ParaTag weight="300" size="16px" sizeMd="12px" mt="8px">
            Boost your savings with increased trading! Enjoy lower fees by increasing your trading
            volume.
          </ParaTag>
        </BannerBox>
      </BannerBox>

      {/* Pc View */}
      {!props.isOrderFeesFetching && (
        <BannerBox width="100%" paddingMd={"0px 10px"}>
          <Table bRadius width="100%" marginBottom="16px" TableBodyRowHeight>
            <Thead display="flex" height="40px" fontSize="14px" fontSizeMd="12px">
              <FeesRow displayFlex OrdersPagesHeadRow>
                <Th scope="col" textAlign="left">
                  {width > 767 ? "30 Day Volume (USD)" : "30 Day Vol. (USD)"}
                </Th>
                <Th scope="col" textAlign="left">
                  Fee Maker
                </Th>
                <Th scope="col" textAlign="left">
                  Fee Taker
                </Th>
              </FeesRow>
            </Thead>

            <TableBodyComponent WalletBalance>
              {!props.isOrderFeesFetching && props.orderFees && (
                <>
                  {feedata?.fee &&
                    feedata?.fee?.map((item, index) => {
                      const { Range, MakerFees, MakerFeesInLCX, TakerFees, TakerFeesInLCX } = item;

                      return (
                        <FeesRow
                          displayFlex
                          // FeesBannerBg={index === feedata.feeLevel}
                          key={index}
                          id={
                            index === feedata.feeLevel
                              ? isTheme
                                ? "activeclass"
                                : "activeclass2"
                              : null
                          }
                          OrdersPagesHeadRow={index === feedata.fee.length - 1 ? true : false}
                        >
                          <Td>{Range}</Td>

                          {index === feedata.feeLevel ? (
                            feeCheck ? (
                              <Td style={{ gap: "2%" }}>
                                <BannerHeadingSpan textDecoration="line-through">
                                  {MakerFees}%
                                </BannerHeadingSpan>{" "}
                                {MakerFeesInLCX}%
                              </Td>
                            ) : (
                              <Td style={{ gap: "21%" }}>{MakerFees}%</Td>
                            )
                          ) : (
                            <Td style={{ gap: "21%" }}>{MakerFees}%</Td>
                          )}

                          {index === feedata.feeLevel ? (
                            // Fee Check
                            feeCheck ? (
                              <Td style={{ gap: "2%" }}>
                                <BannerHeadingSpan textDecoration="line-through">
                                  {TakerFees}%
                                </BannerHeadingSpan>{" "}
                                {TakerFeesInLCX}%
                              </Td>
                            ) : (
                              <Td style={{ gap: "21%" }}>{TakerFees}%</Td>
                            )
                          ) : (
                            <Td style={{ gap: "21%" }}>{TakerFees}%</Td>
                          )}
                        </FeesRow>
                      );
                    })}
                  {feedata?.fee?.length < 1 && <NoRecordsFound />}
                </>
              )}
            </TableBodyComponent>
          </Table>
        </BannerBox>
      )}
      {props.isOrderFeesFetching && (
        <DivElement width="100%" paddingMd={"0px 10px"}>
          <TableLoader rows={5} webCol={3} tabletCol={3} mobileCol={3} />
        </DivElement>
      )}
    </MainElement>
  );
}

const maptoStateProps = (state) => ({
  isTheme: state.theme.isTheme,
  isOrderFeesFetching: state.setting.isOrderFeesFetching,
  orderFees: state.setting.orderFees
});

const mapDispatchToProps = (dispatch) => ({
  getOrderFees: () => dispatch(getOrderFees())
});

export default connect(maptoStateProps, mapDispatchToProps)(FeesComponent);
