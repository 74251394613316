/* eslint-disable no-debugger */
/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useMemo } from "react";
import { useLocation, useHistory, Route, Switch, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";

import SecurityComponent from "../containers/Security";
import TutorialsComponent from "../containers/Tutorial";
import DigitalAssetsComponent from "../containers/assets";
import FeeStructureComponent from "../containers/fee";
import MarketContainer from "../containers/marketContainer";
import LcxTokenComponent from "../containers/lcxToken";
// import Error404 from "../containers/Error404";
import MobileTvChart from "../views/MobileTvChart";
import LandingPageComponent from "../views/indexPage/landingPage";
import Login from "../pages/NonAuth/Login";
// import Register from "../pages/NonAuth/Register";
import ForgetPassword from "../pages/NonAuth/ForgetPassword";
import ResetPassword from "../pages/NonAuth/ResetPassword";
import VerifyEmail from "../pages/NonAuth/VerifyEmail";
// import Referral from "../pages/Auth/Referral";
import { BuyToken } from "../pages/Auth/TokenSale";
import { EarnDashboard, EarnInvest } from "../pages/Auth/Earn";
import MainTradingComp from "../views/NewtradingCompetition";
import MainPage from "../views/NewtradingCompetition/mainPage";
import ReCaptchaWrapper from "../components/Modules/Authentication/ReCaptchaWrapper.js";
import WireFramingLandingPage from "../views/campaigns/index.jsx";
import TokenSaleIndexPage from "../views/NewTokenSale/index.jsx";
import TradePage from "../pages/Auth/TradePage";
import RegisterMain from "../pages/NonAuth/RegisterFlow/index.js";
import MobileDepthChart from "../views/MobileDepthChart/MobileDepthChart.jsx";
import { getTranslationLanguageList } from "../Modules/Generic/selectors.js";
import { fetchAvailableLanguagesRequest } from "../Modules/Generic/action.js";
import NewReferal from "../views/NewRefer/index.jsx";
// import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min.js";
// import KycProcess from "../pages/Auth/KycProcess.js";

const prevLang = localStorage.getItem("ljs-lang");

const NonAuthRouterHOCProps = createStructuredSelector({
  transalationList: getTranslationLanguageList()
});

const JoinAuth = ({ param }) => {
  const history = useHistory();
  if (param?.auth) {
    return history.push({
      pathname: `/${prevLang ? `${prevLang}` : "en"}/register`,
      state: { auth: param?.auth }
    });
  }
  return null;
};

function NonAuthRouterHOC(Element) {
  return (props) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    let { transalationList } = useSelector(NonAuthRouterHOCProps);
    let transalationListMemo = useMemo(() => transalationList, [transalationList]);

    useEffect(() => {
      dispatch(fetchAvailableLanguagesRequest());
      let checkPath = location.pathname?.split("/")[1];
      const prevLang = localStorage.getItem("ljs-lang");
      let setLang = checkPath;
      let checkPathRouteIsValid, prevLangRouteIsValid;

      if (transalationListMemo && transalationListMemo.length > 0) {
        checkPathRouteIsValid =
          transalationListMemo &&
          transalationListMemo.length > 0 &&
          transalationListMemo.find((d) => d.code === checkPath);
        prevLangRouteIsValid =
          transalationListMemo &&
          transalationListMemo.length > 0 &&
          transalationListMemo.find((d) => d.code === prevLang);
        // eslint-disable-next-line no-console
        // console.log(checkPath, "check Path", prevLang);
        // debugger;
        if (checkPathRouteIsValid && checkPathRouteIsValid.code && checkPath) {
          // eslint-disable-next-line no-console
          // console.log(checkPathRouteIsValid, "calling", location.pathname, "current Deekho ji ", `${location.pathname}`);
          // history.push(
          //   `${location.pathname}`
          // );
        } else if (prevLangRouteIsValid && prevLangRouteIsValid.code) {
          if (location.pathname.includes("resetpassword")) {
            setLang = prevLang;
            let token = new URLSearchParams(window.location.search).get("token");
            history.push(
              `/${prevLang || "en"}${location.pathname}${token ? `?token=${token}` : ""}`
            );
            return;
          } else if (location.pathname.includes("join")) {
            setLang = prevLang;
            const pathSegments = location.pathname.split("/");
            const availableLanguages = transalationListMemo.map((lang) => lang.code);
            const hasLangPrefix = availableLanguages.includes(pathSegments[1]);
            const authCode = hasLangPrefix ? pathSegments[3] : pathSegments[2];
            history.push({
              pathname: `/${prevLang || "en"}/register`,
              state: { auth: authCode }
            });
          } else {
            setLang = prevLang;
            history.push(`/${prevLang || "en"}${location.pathname}`);
          }
        } else {
          // if(checkPath)
          // eslint-disable-next-line no-console
          // console.log(checkPathRouteIsValid, "calling", location.pathname, `/${checkPath || "en"}${location.pathname}`, checkPath);
          history.push(`/${checkPath || "en"}${location.pathname}`);
        }
      }
      localStorage.setItem(
        "ljs-lang",
        !checkPathRouteIsValid && !prevLangRouteIsValid ? prevLang || "en" : setLang
      );
      window.Localize.setLanguage(
        !checkPathRouteIsValid && !prevLangRouteIsValid ? prevLang || "en" : setLang
      );
    }, [location]);

    return useMemo(() => {
      if (transalationListMemo && transalationListMemo.length === 0) return null;
      return <Element {...props} />;
    }, [location, transalationListMemo]);
  };
}

const NonAuthRouter = () => {
  return (
    <Switch>
      <Route exact path="/:lang" component={LandingPageComponent} />

      <Route
        exact
        path="/login"
        render={() => (
          <ReCaptchaWrapper>
            <Login />
          </ReCaptchaWrapper>
        )}
      />
      <Route
        exact
        path="/:lang/login"
        render={() => (
          <ReCaptchaWrapper>
            <Login />
          </ReCaptchaWrapper>
        )}
      />

      <Route
        exact
        path="/register"
        render={() => (
          <ReCaptchaWrapper>
            {/* <Register /> */}
            <RegisterMain />
          </ReCaptchaWrapper>
        )}
      />
      <Route
        exact
        path="/:lang/register"
        render={() => (
          <ReCaptchaWrapper>
            {/* <Register /> */}
            <RegisterMain />
          </ReCaptchaWrapper>
        )}
      />

      <Route
        path="/forgotpassword"
        render={() => (
          <ReCaptchaWrapper>
            <ForgetPassword />
          </ReCaptchaWrapper>
        )}
      />
      <Route
        path="/:lang/forgotpassword"
        render={() => (
          <ReCaptchaWrapper>
            <ForgetPassword />
          </ReCaptchaWrapper>
        )}
      />

      {/* <Route exact path="/verify/kyc" component={KycProcess} /> */}
      <Route
        path="/resetpassword"
        render={() => (
          <ReCaptchaWrapper>
            <ResetPassword />
          </ReCaptchaWrapper>
        )}
      />
      <Route
        path="/:lang/resetpassword"
        render={() => (
          <ReCaptchaWrapper>
            <ResetPassword />
          </ReCaptchaWrapper>
        )}
      />

      {/* <Route
      path="/join/:auth?"
      render={({ match, history }) => {
      //   return (
        // <Redirect
        //   to={{
        //     pathname: `${prevLang ? `/${prevLang}` : "/en"}/register`,
        //     state: { auth: match?.params?.auth }
        //   }}
        // />
      //   );
    /> */}
      <Route path="/join/:auth?" render={({ match }) => <JoinAuth param={match} />} />

      <Route
        exact
        path="/verify/email"
        render={() => (
          <ReCaptchaWrapper>
            <VerifyEmail />
          </ReCaptchaWrapper>
        )}
      />
      <Route
        exact
        path="/:lang/verify/email"
        render={() => (
          <ReCaptchaWrapper>
            <VerifyEmail />
          </ReCaptchaWrapper>
        )}
      />

      <Route
        exact
        path="/trade"
        render={() => <Redirect to={`${prevLang ? `/${prevLang}` : ""}/trade/LCX-EUR`} />}
      />
      <Route exact path="/:lang?/markets" component={MarketContainer} />
      <Route exact path="/:lang?/security" component={SecurityComponent} />
      <Route exact path="/:lang?/tutorials" component={TutorialsComponent} />
      <Route exact path="/:lang?/assets" component={DigitalAssetsComponent} />
      <Route exact path="/:lang?/lcx-token" component={LcxTokenComponent} />
      <Route exact path="/:lang?/fee" component={FeeStructureComponent} />
      <Route path="/:lang?/trade/:pair?" component={TradePage} />
      <Route exact path="/:lang?/trading-competition" component={MainPage} />
      <Route exact path="/:lang?/campaigns" component={WireFramingLandingPage} />
      <Route exact path="/:lang?/trading-competition/:id" component={MainTradingComp} />
      <Route exact path="/:lang?/mobile-trade/:pair" component={MobileTvChart} />
      <Route exact path="/:lang?/mobile-depth-chart/:pair" component={MobileDepthChart} />

      <Route
        path="/:lang?/support"
        component={() => {
          window.location.href = "https://support.lcx.com/en/";
        }}
      />
      <Route path="/:lang?/earn/invest/:id" component={EarnInvest} />
      <Route exact path="/:lang?/earn" component={EarnDashboard} />
      <Route exact path="/:lang?/referral" component={NewReferal} />
      <Route path="/:lang?/token-sale/ongoing/:id" component={BuyToken} />
      <Route path="/:lang?/token-sale/upcoming/:id" component={BuyToken} />
      <Route path="/:lang?/token-sale/completed/:id" component={BuyToken} />
      <Route path="/:lang?/token-sale" component={TokenSaleIndexPage} />
      <Route path="/:lang?/token-sale/:tab" component={TokenSaleIndexPage} />
      <Route
        exact
        path="/token-sale"
        render={() => <Redirect to={`${prevLang ? `/${prevLang}` : ""}/token-sale`} />}
      />
      {/* <Route path="/404" component={Error404} /> */}
      <Route path="*" render={() => <Redirect to="/" />} />
    </Switch>
  );
};

export default NonAuthRouterHOC(NonAuthRouter);
