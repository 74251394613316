import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import { Button } from "../../../../components/Reusable/Button";
import {
  ElementBox,
  TextWrapper,
  DivElement,
  Table,
  Th,
  Tr,
  Thead,
  Td,
  Tbody,
  ThDropdown,
  ToolTip
} from "../../../../components/Reusable";
import ModifyModal from "./ModifyModal";
import { GetKycVerified } from "../../../../components/Alert";
import { TableLoader } from "../../../../components/Loader";
import { formatAPIErrors, numberWithCommas } from "../../../../utils/general";
import { getTokenizedBond } from "../../../../lib/api";
import NoRecord from "../../../../components/Reusable/NoRecord";
import { RestrictedUser } from "../../../../components/Alert";
import { showErrorMsg } from "../../../../utils/notification";
import styledConstants from "../../../../constants/styledConstants";
import moment from "moment";

const DROPDOWN_OPTIONS = ["Fixed interest", "Invested amount", "Accured Interest"];

const TokenizedBonds = ({ userData }) => {
  const [showModal, setShowModal] = useState(false);
  const [kycModal, setKycModal] = useState(false);
  const [showRestrictedModal, setShowRestrictedModal] = useState(false);
  const [activeCol, setActiveCol] = useState("Invested amount");
  const [tokenizedBonds, setTokenizedBonds] = useState("");
  const [selectedBond, setSelectedBond] = useState(null);
  const history = useHistory();
  const { width } = useWindowSize();
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    getTokenizedBond()
      .then((res) => {
        setTokenizedBonds(res && res.data);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (width >= 768 && activeCol === "Fixed interest") {
      setActiveCol("Invested amount");
    }

    // eslint-disable-next-line
  }, [width]);

  useEffect(() => {
    if (showModal === false) {
      setSelectedBond(null);
    }
  }, [showModal]);

  let kycStatus = useMemo(
    () => (userData && userData.KYC && userData.KYC.STATUS ? userData.KYC.STATUS : "LEVEL0"),
    [userData]
  );

  const handleInviteRoute = (id) => {
    if (userData.status === "RESTRICTED") {
      setShowRestrictedModal(true);
    } else if (kycStatus !== "LEVEL2") setKycModal(true);
    else history.push(`${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/earn/invest/${id}`);
  };

  const isMobile = width <= styledConstants.BREAKING_POINT_MOBILE;

  return (
    <Fragment>
      <ElementBox
        minHeight="280px"
        minHeightMd="239px"
        minHeightSm="184px"
        padding="50px 20px"
        paddingMd="43px 30px"
        paddingSm="28.5px 8px"
      >
        <TextWrapper
          primary
          marginLeft="19px"
          marginLeftMd="0"
          marginLeftSm="8px"
          marginBottom="33px"
          marginBottomMd="26px"
          marginBottomSm="19px"
          fontSize="28px"
          fontSizeMd="24px"
          fontSizeSm="20px"
        >
          Tokenized Bonds
        </TextWrapper>
        {tokenizedBonds && (
          <Table bRadius borderCollapse="collapse">
            <Thead height="42px" heightMd="40px" fontSize="14px" fontSizeMd="12px">
              <Tr>
                <Th textAlign="left" paddingLeft="20px" paddingLeftMd="16px" paddingLeftSm="12px">
                  Bond
                </Th>
                {!isMobile && (
                  <Fragment>
                    <Th textAlign="left" textAlignMd="right">
                      Fixed interest
                    </Th>
                    <Th textAlign="right">Invested amount</Th>
                    <Th textAlign="right">
                      <ToolTip
                        title="Accured Interest"
                        titleProps={{ secondary: true, fontSize: "inherit" }}
                        imgProps={{
                          width: "20px",
                          height: "20px",
                          absolute: null,
                          marginLeft: "8px"
                        }}
                        tipContent={`This quarter's earned interest on your investment will be deposited to your Main balance on ${moment(
                          tokenizedBonds?.nextInterest
                        ).format("DD/MM/YYYY")}`}
                      />
                    </Th>
                  </Fragment>
                )}
                {isMobile && (
                  <ThDropdown
                    textAlign={"right"}
                    OPTIONS={DROPDOWN_OPTIONS}
                    activeCol={activeCol}
                    selectOption={setActiveCol}
                  />
                )}
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody fontSize="20px" fontSizeMd="16px" fontSizeSm="14px">
              {tokenizedBonds &&
                tokenizedBonds.data.map((item, i) => (
                  <Tr key={i} height="52px" heightSm="40px">
                    <Td
                      textAlign="left"
                      paddingLeft="20px"
                      paddingLeftMd="16px"
                      paddingLeftSm="12px"
                    >
                      {item.bondName}
                    </Td>
                    {(!isMobile || activeCol === "Fixed interest") && (
                      <Td textAlignMd="right" textAlign="left" paddingRightSm="12px">
                        7%
                      </Td>
                    )}
                    {(!isMobile || activeCol === "Invested amount") && (
                      <Td textAlign={"right"} textAlignSm="right" paddingRightSm="12px">
                        {`${numberWithCommas(item.investedAmount)} ${item.quoteCoin}`}
                      </Td>
                    )}
                    {(!isMobile || activeCol === "Accured Interest") && (
                      <Td textAlign={"right"} paddingRightSm="12px">
                        {`${numberWithCommas(item.accruedInterest)} ${item.quoteCoin}`}
                      </Td>
                    )}

                    <Td>
                      <DivElement displayCenter>
                        {isMobile ? (
                          <Fragment>
                            <TextWrapper
                              blue
                              textDecoration="underline"
                              cursor
                              lineHeight="1.71"
                              onClick={() => handleInviteRoute(item.bondId)}
                            >
                              Invest
                            </TextWrapper>
                            <TextWrapper
                              red
                              cursor
                              lineHeight="1.71"
                              marginLeft="16px"
                              textDecoration="underline"
                              disabled={item.investedAmount === 0 ? true : null}
                              opacity={item.investedAmount === 0 ? "0.6" : null}
                              onClick={() => {
                                if (item.investedAmount > 0) {
                                  setSelectedBond(item);
                                  setShowModal(true);
                                }
                              }}
                            >
                              Cancel
                            </TextWrapper>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <Button
                              onClick={() => handleInviteRoute(item.bondId)}
                              primaryBlue
                              padding="0 20px"
                              height="32px"
                              marginRight="16px"
                            >
                              Invest
                            </Button>
                            <Button
                              height="32px"
                              padding="0 20px"
                              secondaryRed
                              disabled={item.investedAmount > 0 ? null : true}
                              onClick={() => {
                                if (item.investedAmount > 0) {
                                  setShowModal(true);
                                  setSelectedBond(item);
                                }
                              }}
                            >
                              Cancel
                            </Button>
                          </Fragment>
                        )}
                      </DivElement>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        )}

        {!tokenizedBonds && <TableLoader rows={4} webCol={5} tabletCol={5} mobileCol={3} />}

        {tokenizedBonds && tokenizedBonds.data && tokenizedBonds.data.length === 0 && <NoRecord />}
      </ElementBox>
      {showModal && (
        <ModifyModal
          modifyData={selectedBond}
          onClose={() => {
            setShowModal(false);
          }}
        />
      )}
      {kycModal && <GetKycVerified open={kycModal} onClose={() => setKycModal(false)} />}

      {showRestrictedModal && (
        <RestrictedUser open={showRestrictedModal} onClose={() => setShowRestrictedModal(false)} />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  userData: state.dashboard.getUserData
});

export default compose(connect(mapStateToProps))(TokenizedBonds);
