import React, { Fragment, useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import { formatAPIErrors } from "../../../../utils/general";
import { getTokenSaleTransactions } from "../../../../lib/api";
import configs from "../../../../constants/config";
import { TableLoader } from "../../../Loader";
import {
  Pagination,
  NoRecord,
  ElementBox,
  TextWrapper,
  DivElement,
  Table,
  Thead,
  Th,
  Td,
  Tr,
  Tbody,
  ImgElement,
  ThDropdown
} from "../../../Reusable";
import { numberWithCommas } from "../../../../utils/general";
import { showErrorMsg } from "../../../../utils/notification";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import styledConstants from "../../../../constants/styledConstants";
// import { BannerHeadingSpan } from "../../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../../assets/StyleComponents/FontSize/Heading.style";

const TokenSaleTransactions = ({ isDarkTheme }) => {
  const [page, setPage] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [transactionsData, setTransactionsData] = useState([]);
  const [activeCol, setActiveCol] = useState("");
  const [options, setOptions] = useState(["Sale Type", "Transaction Date", "Fee"]);
  const { width } = useWindowSize();
  const isDesktop = width > styledConstants.BREAKING_POINT_TABLET;
  const isTabletAndDesktop = width > styledConstants.BREAKING_POINT_MOBILE;
  const isTabletAndMobile = width <= styledConstants.BREAKING_POINT_TABLET;
  const isMobile = width <= styledConstants.BREAKING_POINT_MOBILE;

  useEffect(() => {
    getTransactionsHistory();
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    if (isMobile && options.length === 2) {
      setOptions(["Sale Type", "From", "Transaction Date", "Fee"]);
    }
    if (isTabletAndMobile && !activeCol) {
      setActiveCol("Sale Type");
    }
    if (isTabletAndDesktop && activeCol === "From") {
      setActiveCol(["Sale Type", "Fee"]);
    }
    if (isDesktop && activeCol) {
      setActiveCol("");
    }
    // eslint-disable-next-line
  }, [width]);

  const getTransactionsHistory = () => {
    setIsFetching(true);
    let data = {
      page: page,
      order: "DESC"
    };
    getTokenSaleTransactions(data)
      .then((res) => {
        setTransactionsData(res?.data);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  return (
    <ElementBox shadowNone padding="0 16px" paddingSm="20px 16px" paddingMd="0 20px">
      <TextWrapper fontSize="24px" fontSizeSm="18px" fontWeight="bold">
        Token Sale Transactions
      </TextWrapper>
      <TextWrapper fontSize="16px" fontSizeSm="12px">
        You can monitor your transactions history
      </TextWrapper>
      <DivElement marginTop="56px" marginTopMd="48px" marginTopSm="32px">
        {!isFetching && (
          <Fragment>
            <Table bRadius borderCollapse="collapse">
              <Thead fontSize="14px" fontSizeSm="12px">
                <Tr>
                  <Th
                    textAlign="left"
                    paddingLeft="64px"
                    paddingLeftSm="30px"
                    width="20%"
                    widthMd="33.33%"
                    widthSm="50%"
                  >
                    Amount
                  </Th>
                  {isTabletAndDesktop && (
                    <Th textAlign="left" paddingLeft="52px" width="20%" widthMd="33.33%">
                      From
                    </Th>
                  )}
                  {isDesktop && (
                    <Th textAlign="left" paddingLeft="52px" width="20%" widthMd="33.33%">
                      Fee
                    </Th>
                  )}
                  {isDesktop && (
                    <Fragment>
                      <Th textAlign="left" width="15%">
                        Sale Type
                      </Th>
                      <Th textAlign="left" width="35%">
                        Transaction Date
                      </Th>
                    </Fragment>
                  )}
                  {isTabletAndMobile && (
                    <ThDropdown
                      textAlign="left"
                      widthMd="33.33%"
                      widthSm="50%"
                      paddingLeftSm={activeCol === "From" ? "30px" : ""}
                      activeCol={activeCol}
                      OPTIONS={options}
                      selectOption={setActiveCol}
                    />
                  )}
                </Tr>
              </Thead>
              <Tbody fontSize="16px" fontSizeSm="12px">
                {transactionsData &&
                  transactionsData?.data?.map((data, i) => (
                    <Tr
                      key={i}
                      height="56px"
                      border={i === transactionsData?.data?.length - 1 ? null : true}
                    >
                      <Td
                        textAlign="left"
                        paddingLeft="24px"
                        width="20%"
                        widthMd="33.33%"
                        widthSm="50%"
                      >
                        <BannerHeading display="flex" alignItems="center">
                          <ImgElement
                            alt="coin"
                            width="28px"
                            widthSm="16px"
                            height="28px"
                            heightSm="16px"
                            marginRight="12px"
                            marginRightSm="6px"
                            src={`${
                              isDarkTheme ? configs.darkCoinUrl : configs.lightCoinUrl
                            }${data.coin.toLowerCase()}.svg`}
                          />

                          {`${numberWithCommas(+data.amount)} ${data.coin}`}
                        </BannerHeading>
                      </Td>
                      {(activeCol === "From" || isTabletAndDesktop) && (
                        <Td
                          textAlignSm={"right"}
                          textAlign="left"
                          width="20%"
                          widthMd="33.33%"
                          widthSm="50%"
                        >
                          <BannerHeading display="flex" alignItems="center">
                            <ImgElement
                              alt="coin"
                              width="28px"
                              widthSm="16px"
                              height="28px"
                              heightSm="16px"
                              marginRight="12px"
                              marginRightSm="6px"
                              src={`${
                                isDarkTheme ? configs.darkCoinUrl : configs.lightCoinUrl
                              }${data.fromCoin.toLowerCase()}.svg`}
                            />
                            {`${numberWithCommas(+data.quoteAmount)} ${data.fromCoin}`}
                          </BannerHeading>
                        </Td>
                      )}
                      {(activeCol === "Fee" || isDesktop) && (
                        <Td
                          textAlign={"left"}
                          textAlignMd={"right"}
                          paddingMd="0 16px 0 0"
                          width="20%"
                          widthMd="33.33%"
                          widthSm="50%"
                        >
                          {data.fees}
                        </Td>
                      )}
                      {(activeCol === "Sale Type" || isDesktop) && (
                        <Td
                          textAlign={"left"}
                          textAlignMd={"right"}
                          paddingMd="0 16px 0 0"
                          width="15%"
                          widthMd="33.33%"
                          widthSm="50%"
                        >
                          {data.saleType}
                        </Td>
                      )}
                      {(activeCol === "Transaction Date" || isDesktop) && (
                        <Td
                          textAlign={"left"}
                          textAlignMd={"right"}
                          paddingMd="0 16px 0 0"
                          width="35%"
                          widthMd="33.33%"
                          widthSm="50%"
                        >
                          {moment(data.timestamp).format("DD/MM/YYYY, HH:mm")}
                        </Td>
                      )}
                    </Tr>
                  ))}
                {transactionsData && transactionsData?.data?.length === 0 && (
                  <Tr height="56px">
                    <Td colSpan="4" width="100%">
                      <NoRecord />
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>

            {transactionsData && transactionsData?.data?.length > 0 && (
              <Pagination
                page={page + 1}
                totalCount={transactionsData.count}
                setPage={(page) => setPage(page - 1)}
              />
            )}
          </Fragment>
        )}

        {isFetching && <TableLoader rows={10} webCol={4} tabletCol={3} mobileCol={2} />}
      </DivElement>
    </ElementBox>
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme
});

export default compose(connect(mapStateToProps))(TokenSaleTransactions);
