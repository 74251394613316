import { compose } from "redux";
import { DivElement, DropdownWrapper, TextWrapper } from "../../Reusable";
import { connect } from "react-redux";
import { Fragment } from "react";

import { getAuthCode } from "../../../lib/api";
import { showErrorMsg } from "../../../utils/notification";
import { config } from "../../../constants";
import { ExpandMoreIcon } from "../../../assets/svgs";
import CategoryContainer from "./CategoryContainer";
import NAV_DATA from "./navData";
// import { MorePulseMenu } from "../../../assets/svgs";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import PulseAnimation from "../../Reusable/Animation/Pulse";

const Label = () => {
  return (
    <Fragment>
      <BannerBox position="relative" display="flex">
        <TextWrapper secondary>More</TextWrapper>
        {/* <MorePulseMenu position="absolute" width="8px" height="8px" PulseAnimation marginLeft="8px"/> */}
        <PulseAnimation />
        <ExpandMoreIcon marginLeft="5px" />
      </BannerBox>
    </Fragment>
  );
};

const MoreDropdownDesktop = ({ isLoggedIn, authState }) => {
  const onClickHandler = (e, data) => {
    if (data?.isOpenInOtherTab) {
      e.preventDefault();
      if (isLoggedIn && data.title === "Tiamonds") {
        let productDomain = "tiamonds";
        getAuthCode(productDomain)
          .then((res) => {
            let code = res && res.data ? res.data.data.authCode : "";
            if (!code) throw new Error("");

            window.open(`${config.tiamondsUrl}/?token=${code}&state=${authState}`, "_self");
          })
          .catch((err) => {
            showErrorMsg(`Error in generating ${productDomain} auth code.`);
          });
      } else {
        window.open(data.path, "_blank");
      }
    } else if (data.noShowinLogin) {
      if (!isLoggedIn && data.title === "Reports") {
        window.open("https://exchange.lcx.com/en/login");
      }
    }
  };

  return (
    <DropdownWrapper label={<Label />} alignLeft="24px">
      <DivElement width="536px" padding="0 16px" displayBtw flexWrap="wrap">
        {NAV_DATA.map((data) => (
          <CategoryContainer key={data.type} data={data} onClickHandler={onClickHandler} />
        ))}
      </DivElement>
    </DropdownWrapper>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  authState: state.auth.authState
});

export default compose(connect(mapStateToProps))(MoreDropdownDesktop);
