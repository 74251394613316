import React from "react";
import LogoBlue from "../../assets/svg/logo_blue.svg";
import { BannerBox } from "../../assets/StyleComponents/Exchange.style";

const PageLoader = () => (
  <BannerBox 
    position="absolute"
    top="0"
    left="0"
    width="100%"
    height="100vh"
    minHeight="567px"
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <span className="custom_loader">
      <img
        className="loader-11"
        src={LogoBlue}
        alt="Page loader"
      />
    </span>
  </BannerBox>
);

export default PageLoader;
