import styled, { css } from "styled-components";
import { HeadingStyleCommon } from "./fonts.style";

const BannerHeading = styled.h5`
  ${HeadingStyleCommon}
  font-size: ${(props) => props.size || "16px"};
  color: ${(props) => props.color || props.theme.bodyText};
  font-weight: ${(props) => props.weight || "500"};

  ${(props) =>
    props.txtGrey &&
    css`
      color: ${(props) => props.color || props.theme.subText};
    `};
  ${(props) =>
    props.BodyColor &&
    css`
      color: ${(props) => props.color || props.theme.bodyText};
    `};

  ${(props) =>
    props.txtDanger &&
    css`
      color: ${(props) => props.color || props.theme.danger};
    `};

  ${(props) =>
    props.txtSuccess &&
    css`
      color: ${(props) => props.color || props.theme.success};
    `};

  ${(props) =>
    props.txtPrimary &&
    css`
      color: ${(props) => props.color || props.theme.primary};
    `};
  ${(props) =>
    props.ActiveRoadmap &&
    css`
      border-left: 4px solid ${(props) => props.theme.primary};
    `};
  ${(props) =>
    props.PrimaryCard &&
    css`
      background: ${({ theme }) => theme.bgActiveTable} !important;
    `}

  ${(props) =>
    props.TruncateThreeDot &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}
  ${(props) =>
    props.TextTruncate &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `};
  ${(props) =>
    props.ProgressCircle &&
    css`
      height: 40.5px;
      width: 40.5px;
      font-size: 18px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${(props) => props.theme.bodyText};
      background-color: ${(props) => props.theme.ProgesssBarSettings};
    `}
  ${(props) =>
    props.ProgressCircleActive &&
    css`
      color: ${(props) => props.theme.mainBackground} !important;
      background-color: ${(props) => props.theme.primaryBlue} !important;
    `}

  ${(props) =>
    props.ActiveToken &&
    css`
      color: ${(props) => props.theme.primary};
      &::before {
        height: 26px !important;
        border-left: 3px solid ${(props) => props.theme.primary} !important;
      }
    `}
  ${(props) =>
    props.Beforetab &&
    css`
      &::before {
        content: "";
        height: 41px;
        position: absolute;
        border-left: 3px solid #706f6f;
        margin-left: -15px;
      }
    `}
  ${(props) =>
    props.ActiveTabStylesMarkets &&
    css`
      background: ${(props) => props.theme.level3CardTC};
      color: ${(props) => props.theme.primary};
      font-weight: 500;
    `}
`;

export { BannerHeading };
