import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { resetPassword } from "../../lib/api";
import { compose } from "redux";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { PasswordInput } from "../../components/Reusable/FormInput";
import { formatAPIErrors } from "../../utils/general";
import { NonAuthButton } from "../../components/Modules/Authentication";
import { showErrorMsg, showSuccessMsg } from "../../utils/notification";
import { trackPage } from "../../tracking/track";
import { DivElement } from "../../components/Reusable";
import { ChevronLeftBlueIcon } from "../../assets/svgs";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { BannerBox } from "../../assets/StyleComponents/Exchange.style";
import { RouterLink } from "../../components/Reusable";
import { HeadingSix } from "../../assets/StyleComponents/FontSize/HeadingSix.style";

const resetPasswordValidation = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(32, "Password must be at most 32 characters")
    .matches(
      /^(?=.*[A-Z])(?=.*\d).{6,32}$/,
      "Password must contain one uppercase letter and number."
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required")
});

const getInitialValues = () => {
  return {
    password: "",
    confirmPassword: ""
  };
};

const ResetPassword = ({ history, alert, isTheme }) => {
  const [resetToken, setResetToken] = useState("");
  const [loader, setLoader] = useState(false);
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    let token = new URLSearchParams(window.location.search).get("token");
    if (!token) {
      history.push("/");
    }
    if (token) setResetToken(token);

    trackPage("/resetpassword", "Reset Password Page");

    // eslint-disable-next-line
  }, []);

  const onSubmit = (values) => {
    setLoader(true);
    let data = {
      password: values.password,
      token: resetToken
    };

    resetPassword(data)
      .then((res) => {
        showSuccessMsg(res.data.message);
        history.push("/");
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => {
        setTimeout(() => {
          setLoader(false);
        }, 3000);
      });
  };

  return (
    <DivElement pageContainer display="flex" flexDirection="column" alignItems="center">
      <BannerHeading size="28px" weight="bold" sizeSm="20px">
        Reset Password
      </BannerHeading>

      <BannerBox LoginFormBg>
        <Formik
          initialValues={getInitialValues()}
          onSubmit={onSubmit}
          validateOnMount={true}
          validationSchema={resetPasswordValidation}
        >
          {({ values }) => (
            <Form>
              <PasswordInput
                title="New Password"
                name="password"
                value={values.password}
                placeholder="**********"
                containerClass="fInput__form__field--w100"
              />
              <PasswordInput
                title="Confirm New Password"
                name="confirmPassword"
                value={values.confirmPassword}
                placeholder="**********"
                containerClass="fInput__form__field--w100"
              />

              <NonAuthButton loader={loader} label="Reset Password" />

              <BannerBox display="flex" justifyContent="center" mt="24px">
                <HeadingSix size="14px" BodyColor>
                  {"Don't have an account?"}&nbsp;
                </HeadingSix>
                <RouterLink to={`${prevLang ? `/${prevLang}` : "/en"}/register`} txtPrimary underline fontSize="14px">
                  Register
                </RouterLink>
              </BannerBox>

              <BannerBox display="flex" justifyContent="center" alignItems="center" mt="12px">
                <RouterLink
                  to={`${prevLang ? `/${prevLang}` : ""}/login`}
                  txtPrimary
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  fontSize="14px"
                >
                  <ChevronLeftBlueIcon />
                  Back to Log in
                </RouterLink>
              </BannerBox>
            </Form>
          )}
        </Formik>
      </BannerBox>
    </DivElement>
  );
};
const mapStateToProps = (state) => ({
  isTheme: state.theme.isTheme
});

export default compose(withRouter, connect(mapStateToProps))(ResetPassword);
