/* eslint-disable no-unused-vars */
import { Fragment, useContext, useEffect, useState } from "react";
import { ModalWrapper, DivElement } from "../Reusable";
import DepositModal from "./DepositModal";
import InternalTransfer from "./InternalTransfer";
import { compose } from "redux";
import { connect } from "react-redux";
import { getAllWallets } from "../../actions/wallet";
import { getWalletBalance } from "../../actions/dashboard";
import { InternalTransferLoader } from "../Loader";
import { Enable2FA, GetKycVerified } from "../Alert";
import WithdrawalModal from "./WithdrawalModal";
import RestrictedComponent from "../../views/walletPageCopy/DepositPage/Component/Modal/restrictedModal";
import { DepositContext } from "../../contexts/WalletPage/DepositPage/depositContext";

const InsufficientBalance = ({
  onClose,
  getMainWallet,
  getExchangeWallet,
  loader,
  coin = "LCX",
  requiredFunds,
  mainWallet,
  exchWallet,
  userData,
  modalType,
  defaultActiveWallet // Can be MAIN or EXCHANGE
}) => {
  const [activeModal, setActiveModal] = useState("");
  const [isFetchingWallets, setIsFetchingWallets] = useState(true);
  const [selectedCoin, setSelectedCoin] = useState("");
  const [showRestrictedModal, setShowRestrictedModal] = useState(false);
  const { setisTopModalActive } = useContext(DepositContext);

  useEffect(() => {
    getMainWallet();
    getExchangeWallet();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isFetchingWallets && !loader && mainWallet && exchWallet) {
      setIsFetchingWallets(false);
    }
    // eslint-disable-next-line
  }, [loader, mainWallet, exchWallet]);

  const onCloseHandler = () => {
    getMainWallet();
    getExchangeWallet();
    onClose();
    setisTopModalActive(false);
  };

  useEffect(() => {
    if (mainWallet && exchWallet) {
      let mainBalance = mainWallet.find((data) => data.coin === coin);
      let exchBalance = exchWallet.find((data) => data.coin === coin);
      let mainFreeBal = mainBalance?.balance?.freeBalance || 0;
      let exchFreeBal = exchBalance?.balance?.freeBalance || 0;
      let totalBalance = mainFreeBal + exchFreeBal;
      if (modalType === "DEPOSIT" || totalBalance < requiredFunds) {
        setActiveModal("DEPOSIT");
        setSelectedCoin(mainBalance);
      } else if (modalType === "WITHDRAWAL") {
        setActiveModal("WITHDRAWAL");
        setSelectedCoin(mainBalance);
      } else {
        setActiveModal("TRANSFER");
        setSelectedCoin(exchBalance);
      }
    }
    // eslint-disable-next-line
  }, [mainWallet, exchWallet]);

  // eslint-disable-next-line no-unused-vars
  const twoFACheck = userData.twoFactorAuth.status;

  const isLoading =
    isFetchingWallets ||
    !activeModal ||
    !selectedCoin ||
    !exchWallet ||
    !mainWallet ||
    exchWallet.length === 0 ||
    mainWallet.length === 0;

  const isRestrictedUser = userData && userData.status === "RESTRICTED" ? true : false;

  useEffect(() => {
    if (activeModal === "DEPOSIT" && isRestrictedUser) {
      setShowRestrictedModal(true);
    }
    activeModal.length > 0 ? setisTopModalActive(true) : setisTopModalActive(false);
  }, [activeModal]);

  return (
    <Fragment>
      {userData && userData.KYC.STATUS !== "LEVEL2" ? (
        <GetKycVerified onClose={onClose} />
      ) : !twoFACheck ? (
        <Enable2FA onClose={onClose} />
      ) : (
        <ModalWrapper>
          <DivElement padding="24px 24px 32px" paddingSm="16px 16px 20px" ModalContainer>
            {isLoading ? (
              <InternalTransferLoader />
            ) : (
              <Fragment>
                {activeModal === "TRANSFER" && (
                  <InternalTransfer
                    coin={selectedCoin}
                    onClose={onCloseHandler}
                    setActiveModal={setActiveModal}
                    modalType={modalType}
                    defaultActiveWallet={defaultActiveWallet}
                  />
                )}
                {activeModal === "DEPOSIT" && isRestrictedUser && showRestrictedModal && (
                  <RestrictedComponent
                    open={showRestrictedModal}
                    onClose={() => {
                      setShowRestrictedModal(false);
                      onCloseHandler();
                      setisTopModalActive(false);
                    }}
                  />
                )}

                {activeModal === "DEPOSIT" && !isRestrictedUser && (
                  <DepositModal coin={selectedCoin} onClose={onCloseHandler} />
                )}

                {activeModal === "WITHDRAWAL" && (
                  <WithdrawalModal coin={selectedCoin} onClose={onCloseHandler} />
                )}
              </Fragment>
            )}
          </DivElement>
        </ModalWrapper>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  mainWallet: state.wallet.wallets?.data,
  exchWallet: state.dashboard.walletBalance,
  loader: state.wallet.apiLoader,
  userData: state.dashboard.userData
});

const mapDispatchToProps = (dispatch) => ({
  getMainWallet: () => dispatch(getAllWallets()),
  getExchangeWallet: () => dispatch(getWalletBalance())
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(InsufficientBalance);
