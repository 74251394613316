import { Fragment, useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import MarketDropdown from "../MarketDropdown";
import { DivElement, TextWrapper } from "../../../../../components/Reusable";
import { Skeleton } from "../../../../../components/Loader";
import { numberFormatter } from "../../../../../utils";
import { checkThousand } from "../../../../../utils/general";

const Container1 = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: initial;
  }
`;

const Container2 = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  @media (max-width: 767px) {
    flex-grow: 1;
    flex-wrap: wrap;
  }
`;

const TickerData = ({ type, title, containerProps }) => {
  const selectedPairTicker = useSelector((state) => state.trading.selectedPairTicker);
  const pairData = useMemo(() => selectedPairTicker, [selectedPairTicker]);
  return (
    <DivElement
      height="100%"
      heightSm="50%"
      widthSm="50%"
      marginRight="24px"
      marginSm="0"
      column
      justifyContent="center"
      {...containerProps}
    >
      {pairData ? (
        <Fragment>
          {type === "lastPrice" ? (
            <TextWrapper lineHeight="20px" green>
              {numberFormatter(pairData[type])}
            </TextWrapper>
          ) : (
            <TextWrapper secondary fontSize="12px" fontWeight="300" lineHeight="18px">
              {title}
            </TextWrapper>
          )}
        </Fragment>
      ) : (
        <Skeleton width="75px" height="18px" />
      )}

      {pairData ? (
        <TextWrapper
          red={type === "change" && pairData[type] < 0 ? true : null}
          green={type === "change" && pairData[type] >= 0 ? true : null}
          secondary={type === "lastPrice" ? true : null}
          fontSize="12px"
          lineHeight="18px"
        >
          {type === "lastPrice"
            ? `$${numberFormatter(pairData["equivalent"])}`
            : type === "change"
            ? `${numberFormatter(pairData[type])}%`
            : type === "usdVolume"
            ? `$${numberFormatter(checkThousand(pairData[type]))}`
            : numberFormatter(pairData[type])}
        </TextWrapper>
      ) : (
        <Skeleton width="55px" height="18px" />
      )}
    </DivElement>
  );
};

const TickerHeader = () => {
  return (
    <DivElement
      bgCard
      flexAlignCenter
      width="100%"
      height="46px"
      heightMd="86px"
      heightSm="120px"
      padding="5px 12px"
      paddingMd="24px 56px"
      paddingSm="16px"
      marginBottomMd="32px"
    >
      <Container1>
        <DivElement width="120px" marginRight="24px" marginBottomSm="16px">
          <MarketDropdown />
        </DivElement>
        <TickerData
          type="lastPrice"
          containerProps={{ justifyContentSm: "flex-start", widthSm: "100%" }}
        />
      </Container1>
      <Container2>
        <TickerData
          type="change"
          title="24h Change"
          containerProps={{ justifyContentSm: "flex-start" }}
        />
        <TickerData
          type="high"
          title="24h High"
          containerProps={{ justifyContentSm: "flex-start" }}
        />
        <TickerData type="low" title="24h Low" containerProps={{ justifyContentSm: "flex-end" }} />
        <TickerData
          type="usdVolume"
          title="24h Volume"
          containerProps={{ justifyContentSm: "flex-end" }}
        />
      </Container2>
    </DivElement>
  );
};

export default TickerHeader;
