import React, { useState } from "react";
import { DivElement, ModalWrapper, TextWrapper } from "../../../Reusable";
import { CloseIcon } from "../../../../assets/svgs";
import {
  GetUkRegistrationOnHold,
  GetUkRegistrationWaitingList
} from "../../../../assets/svgs/illustrations";
import { ButtonComponent } from "../../../../assets/StyleComponents/Button.style";
import { debounce } from "../../../../utils/helpers";
import { userWaitListApi } from "../../../../lib/api";
import { showErrorMsg, showSuccessMsg } from "../../../../utils/notification";
import { formatAPIErrors } from "../../../../utils/general";

const NationalityModal = ({ country, onClose, givenEmail = "" }) => {
  const [isUserOnWaitlist, setIsUserOnWaitlist] = useState(false);

  const handleUserWaitList = debounce(() => {
    let param = {
      country: country
    };
    if (givenEmail) param.email = givenEmail;
    userWaitListApi(param, true)
      .then((res) => {
        setIsUserOnWaitlist(true);
        showSuccessMsg(res.data.message);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      });
  }, 700);

  return (
    <ModalWrapper>
      <DivElement ModalContainer className="modal-container">
        <DivElement displayBtw>
          <TextWrapper fontSize="20px" fontSizeSm="16px" lineHeight="24px">
            {country || "UK"} Registrations on Hold
          </TextWrapper>
          <CloseIcon pointer onClick={() => onClose(isUserOnWaitlist)} />
        </DivElement>

        <DivElement displayCenter padding="32px 0">
          {isUserOnWaitlist && <GetUkRegistrationWaitingList />}
          {!isUserOnWaitlist && <GetUkRegistrationOnHold />}
        </DivElement>

        <TextWrapper textAlign="center" fontSize="14px" fontWeight="400" lineHeight="20px">
          {!isUserOnWaitlist &&
            `We're excited you're interested! While we can't welcome users from the ${
              country || "UK"
            } just yet, we'll be sure to let you know as soon as registrations open. In the meantime, feel free to explore our website and learn more about what we offer.`}
          {isUserOnWaitlist &&
            `Thanks for your interest in joining LCX! You've secured your spot on our exclusive waiting list for ${country || "UK"} users. We'll notify you directly when registrations open up.`}
        </TextWrapper>

        {!isUserOnWaitlist && (
          <ButtonComponent
            primaryBlue
            width="100%"
            height="48px"
            mt="24px"
            onClick={handleUserWaitList}
          >
            Put me on the wait list and notify
          </ButtonComponent>
        )}
      </DivElement>
    </ModalWrapper>
  );
};

export default NationalityModal;
