import { compose } from "redux";
import { DivElement, RouterLink, TextWrapper } from "../../Reusable";
import { connect } from "react-redux";
import { logout } from "../../../actions/auth";
import { ChevronRightIcon } from "../../../assets/svgs";

const NavLink = ({
  label,
  path,
  border,
  openHamburger,
  setOpenHamburger,
  logoutUser,
  toggleChatSupport
}) => {
  const onClickHandler = () => {
    if (label === "Log Out") {
      logoutUser();
    } else if (label === "Support") {
      toggleChatSupport();
    }
    setOpenHamburger(!openHamburger);
  };
  return (
    <RouterLink to={path} onClick={onClickHandler}>
      <DivElement
        displayBtw
        height="44px"
        width="100%"
        bdGrey
        borderBottom={border ? "1px solid" : ""}
        padding="0 8px"
      >
        <TextWrapper
          fontSize="14px"
          fontSizeSm="12px"
          lineHeight="20px"
          lineHeightSm="18px"
          red={label === "Log Out" ? true : null}
          blue={label === "Log In" ? true : null}
        >
          {label}
        </TextWrapper>
        <ChevronRightIcon pointer />
      </DivElement>
    </RouterLink>
  );
};

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logout())
});

export default compose(connect(null, mapDispatchToProps))(NavLink);
