import styled, { css } from "styled-components";
import { MarginStyle } from "../Reusable/Margin.style";
import { PaddingStyle } from "../Reusable/Padding.style";
import { ButtonStyleGlobal } from "../Reusable/Global.style";
import { HeadingStyleCommon } from "./fonts.style";

const ParaTag = styled.p`
  ${HeadingStyleCommon}
  font-size: ${(props) => props.size || "14px"} !important;
  font-weight: ${(props) => props.weight || "500"};
  position: ${(props) => props.position};

  @media (max-width: 1024px) {
    ${(props) => props.paddLeftLg && `padding-left: ${props.paddLeftLg}`};
    ${(props) => props.paddRightLg && `padding-right: ${props.paddRightLg}`};
  }
  @media (max-width: 767px) {
    ${(props) => props.paddLeftMd && `padding-left: ${props.paddLeftMd}`};
    ${(props) => props.paddRightMd && `padding-right: ${props.paddRightMd}`};
  }

  ${(props) =>
    props.txtPrimaryBorder &&
    css`
      color: ${(props) => props.color || props.theme.primary};
      border-bottom: 2px solid ${(props) => props.color || props.theme.primary};
    `};

  ${(props) =>
    props.txtOnHoverPrimary &&
    css`
      &:hover {
        color: ${(props) => props.color || props.theme.primary};
      }
    `};

  ${(props) =>
    props.backgroundHover &&
    css`
      &:hover {
        background: ${(props) => props.color || props.theme.bgActiveTable};
      }
    `};

  ${(props) =>
    props.txtWarning &&
    css`
      color: ${(props) => props.color || props.theme.warningTxt};
    `};
  ${(props) =>
    props.txtDanger &&
    css`
      color: ${(props) => props.color || props.theme.danger};
    `};

  ${(props) =>
    props.txtToolTipRed &&
    css`
      color: ${(props) => props.theme.dangerToolTip};
    `};

  ${(props) =>
    props.TabActive &&
    css`
      color: #fff;
      background: ${(props) => props.theme.primary};
      border-radius: 5px;
      border: 1px solid ${(props) => props.theme.inputBorder};
    `};

  ${(props) =>
    props.TabUnActive &&
    css`
      color: ${(props) => props.theme.subText};
      background: transparent;
      border-radius: 5px;
      border: 1px solid ${(props) => props.theme.inputBorder};
    `};

  ${(props) =>
    props.activeBorder &&
    css`
      position: relative;
      bottom: -2px;
      color: ${(props) => props.theme.primary};
      border-bottom: 2px solid ${(props) => props.theme.primary};
    `};
`;
const AnchorTag = styled.a`
  font-size: ${(props) => props.size};
  color: ${(props) => props.color || props.theme.subText};
  font-weight: ${(props) => props.weight};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding || "0"};
  ${(props) => props.height && `height: ${props.height}`};
  ${(props) => props.radius && `border-radius: ${props.radius}`};
  ${(props) => props.mt && `margin-top: ${props.mt}`};
  ${(props) => props.mb && `margin-bottom: ${props.mb}`};
  ${(props) => props.ml && `margin-left: ${props.ml}`};
  ${(props) => props.mr && `margin-right: ${props.mr}`};
  ${(props) => props.cursor && `cursor: ${props.cursor}`};
  ${(props) => props.display && `display: ${props.display}`};
  ${(props) => props.alignItems && `align-items: ${props.alignItems}`};
  ${(props) => props.flex && `flex: ${props.flex}`};
  ${(props) => props.bck && `background-color: ${props.bck}`};
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}`};
  ${(props) => props.minWidth && `min-width: ${props.minWidth}`};
  ${(props) => props.lineHeight && `line-height: ${props.lineHeight}`};
  ${(props) => props.textDecoration && `text-decoration: ${props.textDecoration} !important`};
  ${(props) => props.justifyContent && `justify-content: ${props.justifyContent}`};
  ${(props) => props.width && `width: ${props.width}`};
  ${(props) => props.textAlign && `text-align: ${props.textAlign}`};
  ${(props) => props.paddRight && `padding-right: ${props.paddRight}`};
  ${(props) => props.paddBottom && `padding-bottom: ${props.paddBottom}`};
  ${(props) => props.borderBottom && `border-bottom: ${props.borderBottom}`};

  ${MarginStyle}
  ${PaddingStyle}

  @media (max-width: 1024px) {
    ${(props) => props.sizeLg && `font-size: ${props.sizeLg}`};
    ${(props) => props.displayLg && `display: ${props.displayLg}`};
    ${(props) => props.alignItemsLg && `align-items: ${props.alignItemsLg}`};
    ${(props) => props.textAlignLg && `text-align: ${props.textAlignLg}`};
    ${(props) => props.maxWidthLg && `max-width: ${props.maxWidthLg}`};
    ${(props) => props.minWidthLg && `min-width: ${props.minWidthLg}`};
    ${(props) => props.mtLg && `margin-top: ${props.mtLg}`};
    ${(props) => props.mbLg && `margin-bottom: ${props.mbLg}`};
    ${(props) => props.mlLg && `margin-left: ${props.mlLg}`};
    ${(props) => props.paddLeftLg && `padding-left: ${props.paddLeftLg}`};
    ${(props) => props.mrMd && `margin-right: ${props.mrMd}`};
    ${(props) => props.paddRightLg && `padding-right: ${props.paddRightLg}`};
  }
  @media (max-width: 767px) {
    ${(props) => props.sizeMd && `font-size: ${props.sizeMd} !important`};
    ${(props) => props.displayMd && `display: ${props.displayMd}`};
    ${(props) => props.alignItemsMd && `align-items: ${props.alignItemsMd}`};
    ${(props) => props.justifyContentMd && `justify-content: ${props.justifyContentMd} !important`};
    ${(props) => props.maxWidthMd && `max-width: ${props.maxWidthMd}`};
    ${(props) => props.minWidthMd && `min-width: ${props.minWidthMd}`};
    ${(props) => props.textAlignMd && `text-align: ${props.textAlignMd}`};
    ${(props) => props.mtMd && `margin-top: ${props.mtMd}`};
    ${(props) => props.mbMd && `margin-bottom: ${props.mbMd}`};
    ${(props) => props.mlMd && `margin-left: ${props.mlMd}`};
    ${(props) => props.paddLeftMd && `padding-left: ${props.paddLeftMd}`};
    ${(props) => props.mrMd && `margin-right: ${props.mrMd}`};
    ${(props) => props.paddRightMd && `padding-right: ${props.paddRightMd}`};
  }

  ${(props) =>
    props.txtGrey &&
    css`
      color: ${(props) => props.color || props.theme.subText} !important;
      &:hover {
        color: ${(props) => props.color || props.theme.subText} !important;
      }
    `};
  ${(props) =>
    props.BodyColor &&
    css`
      color: ${(props) => props.color || props.theme.bodyText} !important;
      &:hover {
        color: ${(props) => props.theme.bodyText} !important;
      }
    `};
  ${(props) =>
    props.BodyColorHover &&
    css`
      &:hover {
        color: ${(props) => props.theme.primary} !important;
      }
    `};

  ${(props) =>
    props.txtDanger &&
    css`
      color: ${(props) => props.color || props.theme.danger};
    `};
  ${(props) =>
    props.BorderBottomTradingComp &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.borderColor};
    `};
  ${(props) =>
    props.Card &&
    css`
      box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      background: ${(props) => props.color || props.theme.level1Card};
      border: 1px solid rgba(0, 0, 0, 0.125);
      display: flex;
      flex-direction: column;
    `};

  ${(props) =>
    props.txtSuccess &&
    css`
      color: ${(props) => props.color || props.theme.success};
    `};

  ${(props) =>
    props.txtPrimary &&
    css`
      color: ${(props) => props.color || props.theme.primary};
    `};

  ${(props) =>
    props.txtWarning &&
    css`
      color: ${(props) => props.color || props.theme.warningTxt};
    `};
  ${(props) =>
    props.txtWarning &&
    css`
      color: ${(props) => props.color || props.theme.warningTxt};
    `};

  ${({ OutlinePrimary }) =>
    OutlinePrimary &&
    css`
      border: 1px solid ${(props) => props.color || props.theme.primary};
      color: ${(props) => props.theme.primary};
      &:hover {
        border: none;
        background: ${(props) => props.theme.primary};
        color: ${(props) => props.theme.bodyText2};
      }
    `};
  ${({ OutlinePrimaryWithoutHover }) =>
    OutlinePrimaryWithoutHover &&
    css`
      border: 1px solid ${(props) => props.color || props.theme.primary};
      color: ${(props) => props.theme.primary};
    `};

  ${(props) =>
    props.txtToolTipRed &&
    css`
      color: ${(props) => props.theme.dangerToolTip};
    `};

  ${(props) =>
    props.TruncateThreeDot &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}
`;
const LiTag = styled.li`
  font-size: ${(props) => props.size};
  color: ${(props) => props.color};
  font-weight: ${(props) => props.weight};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding || "0"};
  ${(props) => props.background && `background: ${props.background}`};
  ${(props) => props.height && `height: ${props.height}`};
  ${(props) => props.radius && `border-radius: ${props.radius}`};
  ${(props) => props.mt && `margin-top: ${props.mt}`};
  ${(props) => props.mb && `margin-bottom: ${props.mb}`};
  ${(props) => props.ml && `margin-left: ${props.ml}`};
  ${(props) => props.mr && `margin-right: ${props.mr}`};
  ${(props) => props.pt && `padding-top: ${props.pt}`};
  ${(props) => props.pb && `padding-bottom: ${props.pb}`};
  ${(props) => props.pl && `padding-left: ${props.pl}`};
  ${(props) => props.pr && `padding-right: ${props.pr}`};
  ${(props) => props.cursor && `cursor: ${props.cursor}`};
  ${(props) => props.display && `display: ${props.display}`};
  ${(props) => props.justifyContent && `justify-content: ${props.justifyContent}`};
  ${(props) => props.alignItems && `align-items: ${props.alignItems}`};
  ${(props) => props.flexWrap && `flex-wrap: ${props.flexWrap}`};
  ${(props) => props.bck && `background-color: ${props.bck}`};
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}`};
  ${(props) => props.lineHeight && `line-height: ${props.lineHeight}`};
  ${(props) => props.textDecoration && `text-decoration: ${props.textDecoration}`};
  ${(props) => props.width && `width: ${props.width}`};
  ${(props) => props.textAlign && `text-align: ${props.textAlign}`};
  ${(props) => props.paddRight && `padding-right: ${props.paddRight}`};
  ${(props) => props.paddBottom && `padding-bottom: ${props.paddBottom}`};
  ${(props) => props.textOverflow && `text-overflow: ${props.textOverflow}`};
  ${(props) => props.overflow && `overflow: ${props.overflow}`};
  ${(props) => props.whiteSpace && `white-space: ${props.whiteSpace}`};
  ${(props) => props.gap && `gap: ${props.gap}`};
  ${(props) => props.borderRight && `border-right: ${props.borderRight} !important`};
  ${(props) =>
    props.leftBorder &&
    `border-left: ${
      props.leftBorder !== true
        ? props.leftBorder
        : ` 1px solid ${props.theme.borderColor}!important`
    }`};
  ${(props) =>
    props.rightBorder &&
    `border-right: ${
      props.rightBorder !== true
        ? props.rightBorder
        : ` 1px solid ${props.theme.borderColor}!important`
    }`};

  @media (max-width: 1024px) {
    ${(props) => props.sizeLg && `font-size: ${props.sizeLg}`};
    ${(props) => props.displayLg && `display: ${props.displayLg}`};
    ${(props) => props.alignItemsLg && `align-items: ${props.alignItemsLg}`};
    ${(props) => props.textAlignLg && `text-align: ${props.textAlignLg}`};
    ${(props) => props.maxWidthLg && `max-width: ${props.maxWidthLg}`};
    ${(props) => props.mtLg && `margin-top: ${props.mtLg}`};
    ${(props) => props.mbLg && `margin-bottom: ${props.mbLg}`};
    ${(props) => props.mlLg && `margin-left: ${props.mlLg}`};
    ${(props) => props.paddLeftLg && `padding-left: ${props.paddLeftLg}`};
    ${(props) => props.mrMd && `margin-right: ${props.mrMd}`};
    ${(props) => props.paddRightLg && `padding-right: ${props.paddRightLg}`};
  }
  @media (max-width: 767px) {
    ${(props) => props.sizeMd && `font-size: ${props.sizeMd}`};
    ${(props) => props.displayMd && `display: ${props.displayMd}`};
    ${(props) => props.alignItemsMd && `align-items: ${props.alignItemsMd}`};
    ${(props) => props.maxWidthMd && `max-width: ${props.maxWidthMd}`};
    ${(props) => props.textAlignMd && `text-align: ${props.textAlignMd}`};
    ${(props) => props.mtMd && `margin-top: ${props.mtMd}`};
    ${(props) => props.mbMd && `margin-bottom: ${props.mbMd}`};
    ${(props) => props.mlMd && `margin-left: ${props.mlMd}`};
    ${(props) => props.paddLeftMd && `padding-left: ${props.paddLeftMd}`};
    ${(props) => props.mrMd && `margin-right: ${props.mrMd}`};
    ${(props) => props.paddRightMd && `padding-right: ${props.paddRightMd}`};
  }

  ${(props) =>
    props.textTrim &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `};

  ${(props) =>
    props.wordWrap &&
    css`
      word-break: break-all;
      word-wrap: break-word;
    `};

  ${(props) =>
    props.txtGrey &&
    css`
      color: ${(props) => props.color || props.theme.subText};
    `};

  ${(props) =>
    props.onHoverTxtGrey &&
    css`
      &:hover {
        color: ${(props) => props.color || props.theme.subText};
      }
    `};

  ${(props) =>
    props.BodyColor &&
    css`
      color: ${(props) => props.color || props.theme.bodyText};
    `};

  ${(props) =>
    props.OnHoverBodyColor &&
    css`
      &:hover {
        color: ${(props) => props.color || props.theme.bodyText};
      }
    `};

  ${(props) =>
    props.txtDanger &&
    css`
      color: ${(props) => props.color || props.theme.danger};
    `};

  ${(props) =>
    props.onHoverTxtDanger &&
    css`
      &:hover {
        color: ${(props) => props.color || props.theme.danger};
      }
    `};

  ${(props) =>
    props.txtSuccess &&
    css`
      color: ${(props) => props.color || props.theme.success};
    `};

  ${(props) =>
    props.OnHoverTxtSuccess &&
    css`
      &:hover {
        color: ${(props) => props.color || props.theme.success};
      }
    `};

  ${(props) =>
    props.txtPrimary &&
    css`
      color: ${(props) => props.color || props.theme.primary};
    `};

  ${(props) =>
    props.txtWarning &&
    css`
      color: ${(props) => props.color || props.theme.warningTxt};
    `};
  ${(props) =>
    props.txtWarning &&
    css`
      color: ${(props) => props.color || props.theme.warningTxt};
    `};

  ${(props) =>
    props.txtToolTipRed &&
    css`
      color: ${(props) => props.theme.dangerToolTip};
    `};

  ${(props) =>
    props.CardGrey &&
    css`
      background: ${(props) => props.theme.mainBackground};
    `};

  ${(props) =>
    props.onHoverTxtPrimary &&
    css`
      &:hover {
        color: ${(props) => props.color || props.theme.primary};
      }
    `};

  ${(props) =>
    props.txtWarning &&
    css`
      color: ${(props) => props.color || props.theme.warningTxt};
    `};

  ${(props) =>
    props.txtToolTipRed &&
    css`
      color: ${(props) => props.theme.dangerToolTip};
    `};

  ${(props) =>
    props.CardGrey &&
    css`
      background: ${(props) => props.theme.mainBackground};
    `};

  ${(props) =>
    props.TruncateThreeDot &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}
`;
const Label = styled.label`
  font-size: ${(props) => props.size || "14px"};
  display: block;
  color: ${(props) => props.color || props.theme.subText};
  font-weight: ${(props) => props.weight};
  ${MarginStyle}
  ${PaddingStyle}
  ${ButtonStyleGlobal}
  ${MarginStyle}
  ${PaddingStyle}
  ${ButtonStyleGlobal}
  
  margin-bottom: ${(props) => props.mb || "8px"};
  padding: ${(props) => props.padding || "0"};
  ${(props) => props.display && `display: ${props.display}`};
  ${(props) => props.justifyContent && `justify-content: ${props.justifyContent}`};
  ${(props) => props.alignItems && `align-items: ${props.alignItems}`};
  ${(props) => props.height && `height: ${props.height}`};
  ${(props) => props.radius && `border-radius: ${props.radius}`};
  ${(props) => props.cursor && `cursor: ${props.cursor}`};
  ${(props) => props.alignItems && `align-items: ${props.alignItems}`};
  ${(props) => props.bck && `background-color: ${props.bck}`};
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}`};
  ${(props) => props.lineHeight && `line-height: ${props.lineHeight}`};
  ${(props) => props.textDecoration && `text-decoration: ${props.textDecoration} !important`};
  ${(props) => props.width && `width: ${props.width}`};
  ${(props) => props.minWidth && `min-width: ${props.minWidth}`};
  ${(props) => props.textAlign && `text-align: ${props.textAlign}`};
  ${(props) => props.paddRight && `padding-right: ${props.paddRight}`};
  ${(props) => props.paddBottom && `padding-bottom: ${props.paddBottom}`};
  ${(props) => props.verticalAlign && `vertical-align: ${props.verticalAlign}`};
  ${(props) => props.direction && `flex-direction: ${props.direction}`};
  ${(props) => props.gap && `gap: ${props.gap}`};
  @media (max-width: 1024px) {
    ${(props) => props.heightLg && `height: ${props.heightLg}`};
    ${(props) => props.sizeLg && `font-size: ${props.sizeLg}`};
    ${(props) => props.displayLg && `display: ${props.displayLg}`};
    ${(props) => props.alignItemsLg && `align-items: ${props.alignItemsLg}`};
    ${(props) => props.textAlignLg && `text-align: ${props.textAlignLg}`};
    ${(props) => props.maxWidthLg && `max-width: ${props.maxWidthLg}`};
    ${(props) => props.mtLg && `margin-top: ${props.mtLg}`};
    ${(props) => props.mbLg && `margin-bottom: ${props.mbLg}`};
    ${(props) => props.mlLg && `margin-left: ${props.mlLg}`};
    ${(props) => props.paddLeftLg && `padding-left: ${props.paddLeftLg}`};
    ${(props) => props.mrMd && `margin-right: ${props.mrMd}`};
    ${(props) => props.paddRightLg && `padding-right: ${props.paddRightLg}`};
  }
  @media (max-width: 767px) {
    ${(props) => props.heightMd && `height: ${props.heightMd}`};
    ${(props) => props.sizeMd && `font-size: ${props.sizeMd}`};
    ${(props) => props.displayMd && `display: ${props.displayMd}`};
    ${(props) => props.alignItemsMd && `align-items: ${props.alignItemsMd}`};
    ${(props) => props.maxWidthMd && `max-width: ${props.maxWidthMd}`};
    ${(props) => props.textAlignMd && `text-align: ${props.textAlignMd}`};
    ${(props) => props.mtMd && `margin-top: ${props.mtMd}`};
    ${(props) => props.mbMd && `margin-bottom: ${props.mbMd}`};
    ${(props) => props.mlMd && `margin-left: ${props.mlMd}`};
    ${(props) => props.paddLeftMd && `padding-left: ${props.paddLeftMd}`};
    ${(props) => props.mrMd && `margin-right: ${props.mrMd}`};
    ${(props) => props.paddRightMd && `padding-right: ${props.paddRightMd}`};
  }

  ${(props) =>
    props.txtGrey &&
    css`
      color: ${(props) => props.color || props.theme.subText} !important;
      &:hover {
        color: ${(props) => props.color || props.theme.subText} !important;
      }
    `};
  ${(props) =>
    props.BodyColor &&
    css`
      color: ${(props) => props.color || props.theme.bodyText};
      &:hover {
        color: ${(props) => props.theme.primary} !important;
      }
    `};
  ${(props) =>
    props.BodyColor2 &&
    css`
      color: ${(props) => props.color || props.theme.mainBackground};
      &:hover {
        color: ${(props) => props.theme.mainBackground} !important;
      }
    `};
  ${(props) =>
    props.BodyColorHover &&
    css`
      c
    `};

  ${(props) =>
    props.txtDanger &&
    css`
      color: ${(props) => props.color || props.theme.danger};
    `};
  ${(props) =>
    props.Card &&
    css`
      box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      background: ${(props) => props.color || props.theme.mainBackground};
      border: 1px solid rgba(0, 0, 0, 0.125);
      display: flex;
      flex-direction: column;
    `};

  ${(props) =>
    props.txtSuccess &&
    css`
      color: ${(props) => props.color || props.theme.success};
    `};
  ${(props) =>
    props.PromotionBanner &&
    css`
      color: white;
      &:hover {
        color: white;
      }
    `};

  ${(props) =>
    props.txtPrimary &
    css`
      color: ${(props) => props.color || props.theme.primary} !important;
    `};

  ${(props) =>
    props.txtWarning &&
    css`
      color: ${(props) => props.color || props.theme.warningTxt};
    `};
  ${(props) =>
    props.txtWarning &&
    css`
      color: ${(props) => props.color || props.theme.warningTxt};
    `};

  ${(props) =>
    props.txtToolTipRed &&
    css`
      color: ${(props) => props.theme.dangerToolTip};
    `};

  ${(props) =>
    props.TruncateThreeDot &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}
`;

export { ParaTag, LiTag, AnchorTag, Label };
