/* eslint-disable no-unused-vars */
import React from "react";
import Select, { components } from "react-select";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import { DropdownIndicator } from "../../SelectDropDown/selectDropDown";
import ImgElement from "../ImgElement";
import config from "../../../constants/config";
import Checkbox from "../Checkbox";
import { useSelector } from "react-redux";
import { reactDropdownStyles } from "../../../utils";
import { IMAGE_PREFIX } from "../../../constants/Images/image";
import { numberWithCommas } from "../../../utils/general";
import { formatNumbers } from "../../../utils/helpers";
import { HeadingSix } from "../../../assets/StyleComponents/FontSize/HeadingSix.style";
import { ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";

const ValueContainer = (props) => {
  const { hasValue, children, selectProps } = props;
  return (
    <components.ValueContainer {...props}>
      {hasValue ? (
        <BannerBox width="100%" display="flex" alignItems="center" justifyContent="space-between">
          <ParaTag
            size="12px"
            width="calc(100% - 24px)"
            lineHeight="20px"
            textOverflow="ellipsis"
            whiteSpace="noWrap"
            overflow="hidden"
            wordWrap
          >
            {selectProps?.value.map((option, i) => {
              if (i === 0) {
                return option.label;
              } else {
                return `, ${option.label}`;
              }
            })}
          </ParaTag>
          {children[1]}
          <BannerBox
            width="24px"
            height="24px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            radius="50%"
            CardPrimary
            marginLeft="4px"
          >
            {selectProps?.value?.length}
          </BannerBox>
        </BannerBox>
      ) : (
        children
      )}
    </components.ValueContainer>
  );
};

const customOptionLabel = ({ isDarkTheme, label, quoteCoin, noImgStatus }) => {
  return (
    <>
      {noImgStatus && (
        <ImgElement
          width="20px"
          height="20px"
          src={`${
            isDarkTheme ? config.darkCoinUrl : config.lightCoinUrl
          }${quoteCoin?.toLowerCase()}.svg`}
          marginLeft="16px"
          marginRight="8px"
        />
      )}
      <HeadingSix sizeMd="14px" ml={noImgStatus ? "0" : "16px"} fontSize="16px" lineHeight="24px">
        {label}
      </HeadingSix>
    </>
  );
};

const customOptionWalletLabel = ({
  isDarkTheme,
  id,
  label,
  isNetwork = false,
  ShowBalance,
  noImg = false
}) => {
  let balance = ShowBalance || false;
  const recievedLabel = String(label).toLowerCase();
  const isMonerium = recievedLabel === "monerium";
  let imgSrc = `${!isDarkTheme ? config.lightCoinUrl : config.darkCoinUrl}${recievedLabel}.svg`;
  if (isMonerium) {
    imgSrc = IMAGE_PREFIX.MoneriumBankLogo;
  } else if (isNetwork) {
    imgSrc = `${!isDarkTheme ? config.lightNetworkUrl : config.darkNetworkUrl}${recievedLabel}.png`;
  }

  return (
    <BannerBox display="flex" alignItems="center" width="100%" justifyContent="space-between">
      <BannerBox display="flex" alignItems="center" width="100%">
        <ImgElement width="20px" height="20px" src={imgSrc} marginLeft="16px" marginRight="8px" />

        <HeadingSix weight="500" fontSize="16px">
          {label}
        </HeadingSix>
      </BannerBox>
      <BannerBox pr="16px">
        {" "}
        {!isNetwork &&
          !balance &&
          numberWithCommas(formatNumbers(id?.balance?.freeBalance || 0))}{" "}
      </BannerBox>
    </BannerBox>
  );
};

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  data,
  ...rest
}) => {
  const style = {
    alignItems: "center",
    color: "inherit",
    display: "flex"
  };

  const props = {
    ...innerProps,
    style
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <BannerBox display="flex" alignItems="center" width="100%" cursorPointer="pointer">
        <Checkbox checked={isSelected} setChecked={() => {}} />
        {children}
      </BannerBox>
    </components.Option>
  );
};

const CustimizeMultiSelectDropDown = ({
  setSelectedOptions,
  options,
  defaultValue = [],
  noImgStatus = true,
  WalletStatus = false,
  isNetwork = false,
  ShowBalance = true,
  placeholder,
  value
}) => {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);

  const customFilter = (option, searchText) => {
    let fullName = option?.data?.id?.fullName?.toLowerCase();
    let baseFullName = option?.data?.id?.baseFullName?.toLowerCase();
    let quoteFullName = option?.data?.id?.quoteFullName?.toLowerCase();
    let value = option?.data?.value?.toLowerCase();
    let label = option?.data?.label?.toLowerCase();
    searchText = searchText?.toLowerCase();
    if (
      label?.includes(searchText) ||
      value?.includes(searchText) ||
      fullName?.includes(searchText) ||
      baseFullName?.includes(searchText) ||
      quoteFullName?.includes(searchText)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <BannerBox width="100%">
      <Select
        isMulti
        // menuIsOpen={true}
        value={value || []}
        placeholder={placeholder}
        defaultValue={defaultValue}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        onChange={setSelectedOptions}
        options={options}
        formatOptionLabel={(val) => {
          return WalletStatus
            ? customOptionWalletLabel({ ...val, isDarkTheme, ShowBalance, isNetwork })
            : customOptionLabel({ ...val, isDarkTheme, noImgStatus });
        }}
        components={{
          DropdownIndicator,
          ValueContainer,
          Option: InputOption
        }}
        styles={reactDropdownStyles({
          isDarkTheme,
          borderBottom: true,
          placeholderProps: { fontSize: "14px" },
          menuProps: { zIndex: "2" },
          showActiveState: value ? true : false
        })}
        filterOption={customFilter}
      />
    </BannerBox>
  );
};

export default CustimizeMultiSelectDropDown;
