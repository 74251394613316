/* eslint-disable import/no-anonymous-default-export */
import store from "../../../store";
import historyProvider from "./historyProvider";
import stream from "./stream";

export default {
  onReady: (cb) => {
    let config = {
      supported_resolutions: [],
      exchanges: [],
      symbols_types: []
    };
    setTimeout(() => cb([config]), 0);
  },
  searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {},
  resolveSymbol: async (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback,
    extension
  ) => {
    let selectedPairConfig = store.getState()?.trading?.selectedPairConfig;

    let precision = Math.pow(10, selectedPairConfig?.pricePrecision || 1);

    const symbolInfo = {
      name: symbolName,
      description: symbolName,
      type: "crypto",
      session: "24x7",
      timezone: "Etc/UTC",
      ticker: symbolName,
      exchange: "",
      minmov: 1,
      pricescale: precision,
      has_intraday: true,
      has_no_volume: true,
      has_weekly_and_monthly: true,
      intraday_multipliers: [
        "1",
        "3",
        "5",
        "15",
        "30",
        "45",
        "60",
        "120",
        "180",
        "240",
        "1D",
        "1W",
        "1M"
      ],
      supported_resolutions: [
        "1",
        "3",
        "5",
        "15",
        "30",
        "45",
        "60",
        "120",
        "180",
        "240",
        "1D",
        "1W",
        "1M"
      ],
      supports_time: true,
      volume_precision: selectedPairConfig?.amountPrecision || 2,
      data_status: "streaming"
    };

    onSymbolResolvedCallback(symbolInfo);
  },
  getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
    let { from, to, firstDataRequest } = periodParams;
    historyProvider
      .getBars(symbolInfo, resolution, from, to, firstDataRequest)
      .then((bars) => {
        if (bars.length) {
          onHistoryCallback(bars, { noData: false });
        } else {
          onHistoryCallback(bars, { noData: true });
        }
      })
      .catch((err) => {
        onErrorCallback(err);
      });
  },
  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback
  ) => {
    stream.subscribeBars(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID,
      onResetCacheNeededCallback
    );
  },
  unsubscribeBars: (subscriberUID) => {
    stream.unsubscribeBars(subscriberUID);
  },
  calculateHistoryDepth: (resolution, resolutionBack, intervalBack) => {
    //optional
    // while optional, this makes sure we request 24 hours of minute data at a time
    // CryptoCompare"s minute data endpoint will throw an error if we request data beyond 7 days in the past, and return no data
    return resolution < 60 ? { resolutionBack: "D", intervalBack: "5" } : undefined;
  },
  getMarks: (symbolInfo, from, to, onDataCallback, resolution) => {
    //optional
  },
  getTimeScaleMarks: (symbolInfo, from, to, onDataCallback, resolution) => {
    //optional
  },
  getServerTime: (cb) => {},
  getVolumeProfileResolutionForPeriod: (currentResolution, from, to, symbolInfo) => {}
};
