import React from "react";
import {
  BannerSection,
  BannerBox,
  BannerHeadingSpan
} from "../../../../assets/StyleComponents/Exchange.style";
import { EditIconBlue, FlagIcon, HomeIcon, UserIcon, EditIconRed } from "../../../../assets/svgs";
import { HeadingThird } from "../../../../assets/StyleComponents/FontSize/headingThird.style";
import { ParaTag } from "../../../../assets/StyleComponents/FontSize/para.style";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import { DivElement, TextWrapper } from "../../../../components/Reusable";
import { ButtonComponent } from "../../../../assets/StyleComponents/Button.style";
import AddressUpdateModal from "../../Modals/AddressUpdateModal";
import { ADDRESS_STATUS_CONSTANTS } from "../ProfileMainContainer";

function UserDetailsSection({
  userData,
  showAddressUpdateModal,
  onShowUpdateAddressModal,
  onUpdateAddressSubmit,
  countriesList,
  reviewStatus
}) {
  const { AddressLine1, AddressLine2, Nationality, BirthCountry, Country, KYC } = userData;
  const { width } = useWindowSize();

  const getUpdateComponent = () => {
    switch (KYC?.addressChangeStatus) {
      case ADDRESS_STATUS_CONSTANTS.CAN_BE_CHANGED:
        return (
          <ButtonComponent
            width="80px"
            btnHeight="28px"
            size="12px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            onClick={onShowUpdateAddressModal}
            OutlinePrimaryWithoutHover
          >
            <EditIconBlue SVGExtraSmallSize />
            <span style={{ marginLeft: "4px" }}>Update</span>
          </ButtonComponent>
        );

      case ADDRESS_STATUS_CONSTANTS.SUBMITTED:
        return (
          <ButtonComponent
            width="100px"
            btnHeight="28px"
            size="12px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            OutlineWarningWithoutHover
            cursor="auto"
          >
            <span>Under Review</span>
          </ButtonComponent>
        );

      case ADDRESS_STATUS_CONSTANTS.REJECTED:
        return (
          <ButtonComponent
            width="80px"
            btnHeight="28px"
            size="12px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            onClick={onShowUpdateAddressModal}
            OutlineOnlyDangerWithoutHover
          >
            <EditIconRed SVGExtraSmallSize />
            <span style={{ marginLeft: "4px" }}>Resubmit</span>
          </ButtonComponent>
        );

      default:
        return <></>;
    }
  };

  return (
    <BannerSection SectionWhite padding="24px" radius="10px">
      {/* Title Section */}
      <BannerBox display="flex" width="100%" gap="4px" mb="40px">
        <UserIcon width="20px" marginRight="6px" height="20px" />
        <HeadingThird size="18px" weight="500" sizeMd="16px">
          User Details
        </HeadingThird>
      </BannerBox>

      <BannerBox>
        {/* Address Section */}
        <BannerBox
          bottomBorder="0.5px solid"
          display="flex"
          direction="column"
          gap="12px"
          pb="24px"
          borderColor
        >
          <DivElement display="flex" justifyContent="space-between">
            <HeadingThird size="16px" weight="500" display="flex" alignItems="center" gap="6px">
              <HomeIcon marginRight="6px" />
              <TextWrapper>Address</TextWrapper>
            </HeadingThird>

            {getUpdateComponent()}
          </DivElement>

          <ParaTag size="14px" weight="400" bodyColor className="address_user_localise">
            {`${AddressLine1}, ${AddressLine2}, ${Country}`}
          </ParaTag>
          {reviewStatus?.status === ADDRESS_STATUS_CONSTANTS.REJECTED && (
            <TextWrapper
              fontSize="12px"
              fontSizeSm="10px"
              weight="400"
              display="inline-flex"
              padding="5px 10px"
              maxWidth="fit-content"
              bgWarning
              color
            >
              {/* {`Rejected: ${reviewStatus?.message?.join(",")}`} */}
              Address update request rejected. Check email for details
            </TextWrapper>
          )}
          {reviewStatus?.status === ADDRESS_STATUS_CONSTANTS.APPROVED && (
            <TextWrapper
              fontSize="12px"
              fontSizeSm="10px"
              weight="400"
              display="inline-flex"
              padding="5px 10px"
              maxWidth="fit-content"
              bgSuccess
            >
              {/* {`Updated: ${reviewStatus?.message}`} */}
              Address update request approved
            </TextWrapper>
          )}
        </BannerBox>

        <BannerSection display="flex" alignItems="center" gap="24px" mt="24px" directionMd="column">
          <BannerBox
            rightBorder={width > 767 && "0.5px solid"}
            bottomBorder={width < 767 && "0.5px solid"}
            borderColor
            display="flex"
            direction="column"
            gap="12px"
            pr={width > 767 && "20px"}
            pb={width < 767 && "20px"}
          >
            <HeadingThird size="16px" weight="500" display="flex" alignItems="center" gap="6px">
              <BannerHeadingSpan>
                <FlagIcon width="18px" marginRight="6px" height="18px" />
              </BannerHeadingSpan>
              Nationality
            </HeadingThird>
            <ParaTag size="14px" weight="400" bodyColor>
              {Nationality}
            </ParaTag>
          </BannerBox>
          <BannerBox display="flex" direction="column" gap="12px">
            <HeadingThird size="16px" weight="500" display="flex" alignItems="center" gap="6px">
              <BannerHeadingSpan>
                <FlagIcon width="18px" marginRight="6px" height="18px" />
              </BannerHeadingSpan>
              Birth Country
            </HeadingThird>
            <ParaTag size="14px" weight="400" bodyColor>
              {BirthCountry}
            </ParaTag>
          </BannerBox>
        </BannerSection>
      </BannerBox>

      {showAddressUpdateModal && (
        <AddressUpdateModal
          showAddressUpdateModal={showAddressUpdateModal}
          onShowUpdateAddressModal={onShowUpdateAddressModal}
          onSubmit={onUpdateAddressSubmit}
          countriesList={countriesList}
        />
      )}
    </BannerSection>
  );
}

export default UserDetailsSection;
