import { css } from "styled-components";

const MarginStyle = css`
  ${({ mt }) => mt && `margin-top: ${mt}; `}
  ${({ mb }) => mb && `margin-bottom: ${mb}; `}
  ${({ ml }) => ml && `margin-left: ${ml}; `}
  ${({ mr }) => mr && `margin-right: ${mr}; `}
  ${({ mx }) => mx && `margin-right: ${mx} !important; margin-left: ${mx} !important;`}
  ${({ my }) => my && `margin-top: ${my} !important; margin-bottom: ${my} !important;`}
  ${({ margin }) => margin && `margin: ${margin} !important; `}

  @media (max-width: 1200px) {
    ${({ mtXl }) => mtXl && `margin-top: ${mtXl}; `}
    ${({ mbXl }) => mbXl && `margin-bottom: ${mbXl}; `}
    ${({ mlXl }) => mlXl && `margin-left: ${mlXl}; `}
    ${({ mrXl }) => mrXl && `margin-right: ${mrXl}; `}
    ${({ mxXl }) => mxXl && `margin-right: ${mxXl} !important; margin-left: ${mxXl} !important; `}
    ${({ myXl }) => myXl && `margin-top: ${myXl} !important; margin-bottom: ${myXl} !important; `}
    ${({ marginXl }) => marginXl && `margin: ${marginXl} !important; `}
  }

  @media (max-width: 1024px) {
    ${({ mtLg }) => mtLg && `margin-top: ${mtLg}; `}
    ${({ mbLg }) => mbLg && `margin-bottom: ${mbLg}; `}
    ${({ mlLg }) => mlLg && `margin-left: ${mlLg}; `}
    ${({ mrLg }) => mrLg && `margin-right: ${mrLg}; `}
    ${({ mxLg }) => mxLg && `margin-right: ${mxLg} !important; margin-left: ${mxLg} !important; `}
    ${({ myLg }) => myLg && `margin-top: ${myLg} !important; margin-bottom: ${myLg} !important; `}
    ${({ marginLg }) => marginLg && `margin: ${marginLg} !important; `}
  }

  @media (max-width: 767px) {
    ${({ mtMd }) => mtMd && `margin-top: ${mtMd}; `}
    ${({ mbMd }) => mbMd && `margin-bottom: ${mbMd}; `}
    ${({ mlMd }) => mlMd && `margin-left: ${mlMd}; `}
    ${({ mrMd }) => mrMd && `margin-right: ${mrMd}; `}
    ${({ mxMd }) => mxMd && `margin-right: ${mxMd} !important; margin-left: ${mxMd} !important; `}
    ${({ myMd }) => myMd && `margin-top: ${myMd} !important; margin-bottom: ${myMd} !important; `}
    ${({ marginMd }) => marginMd && `margin: ${marginMd} !important; `}
  }

  @media (max-width: 560px) {
    ${({ mtSm }) => mtSm && `margin-top: ${mtSm}; `}
    ${({ mbSm }) => mbSm && `margin-bottom: ${mbSm}; `}
    ${({ mlSm }) => mlSm && `margin-left: ${mlSm}; `}
    ${({ mrSm }) => mrSm && `margin-right: ${mrSm}; `}
    ${({ mxSm }) => mxSm && `margin-right: ${mxSm} !important; margin-left: ${mxSm} !important; `}
    ${({ mySm }) => mySm && `margin-top: ${mySm} !important; margin-bottom: ${mySm} !important; `}
    ${({ marginSm }) => marginSm && `margin: ${marginSm} !important; `}
  }
`;
export { MarginStyle };
