import { css } from "styled-components";

const DisplayStyle = css`
  ${({ display }) => display && `display: ${display}; `}

  @media (max-width: 1200px) {
    ${({ displayXl }) => displayXl && `display: ${displayXl}; `}
  }

  @media (max-width: 1024px) {
    ${({ displayLg }) => displayLg && `display: ${displayLg}; `}
  }

  @media (max-width: 767px) {
    ${({ displayMd }) => displayMd && `display: ${displayMd}; `}
  }

  @media (max-width: 560px) {
    ${({ displaySm }) => displaySm && `display: ${displaySm}; `}
  }
`;
export { DisplayStyle };
