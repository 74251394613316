/* eslint-disable no-console */
import { Fragment, useState, useContext } from "react";
import { compose } from "redux";
import { Button, DivElement, ImgElement, RouterLink, TextWrapper } from "../Reusable";
import { connect, useDispatch, useSelector } from "react-redux";
import NavLink from "./NavLink";
import ProfileDropdown from "./ProfileDropdown";
import MoreDropdownDesktop from "./More/MoreDropdownDesktop";
import BalanceDropdown from "./BalanceDropdown";
import NavRightButtons from "./NavRightButtons";
import { useWindowSize } from "../../Hooks/CheckWidthHeight";
import NonDesktop from "./NonDesktop";
import { useEffect } from "react";
import { LcxDarkLogo, LcxLightLogo } from "../../assets/png";
import styledConstants from "../../constants/styledConstants";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useHubspotChat } from "../../Hooks/HubSpot/hubSpot";
import { getAllClientSales } from "../../actions/tokenSale";
import { HeadingSix } from "../../assets/StyleComponents/FontSize/HeadingSix.style";
import { ButtonComponent } from "../../assets/StyleComponents/Button.style";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { AnchorTag } from "../../assets/StyleComponents/FontSize/para.style";
import { performActionOnLayout } from "../../actions/trading";
import { PromotionContext } from "../../contexts/Promotion";
// import { getPromotionUpdate } from "../../lib/api";

const Navbar = ({ isDarkTheme, isLoggedIn, actionsRequired, getAllClientSales }) => {
  const [openHamburger, setOpenHamburger] = useState(false);
  const { width } = useWindowSize();
  const location = useLocation();
  const { toggleChatSupport } = useHubspotChat();
  const selectedPair = useSelector((state) => state.trading.selectedPair);
  const actionOnLayout = useSelector((state) => state.trading.actionOnLayout);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const isChangingLayout = actionOnLayout === "CHANGE" ? true : false;
  // const [activePromotion, setActivePermotion] = useState({});
  const activePromotion = useContext(PromotionContext);
  const userData = useSelector((state) => state.dashboard.userData);
  // const PermotionStartTimeStap = new Date("2024-04-08T18:00").getTime();
  // const PermotionEndTimeStap = new Date("2024-04-12T23:59").getTime();
  // const currentTime = new Date().getTime();
  const prevLang = localStorage.getItem("ljs-lang");
  const TABS = [
    {
      label: "Markets",
      path: `${prevLang ? `/${prevLang}` : "/en"}/markets`,
      showWhenLoggedIn: true,
      showWhenLoggedOut: true,
      activeLink: "markets"
    },
    {
      label: "Trade",
      path: `${prevLang ? `/${prevLang}` : "/en"}/trade/${selectedPair.base}-${selectedPair.quote}`,
      showWhenLoggedIn: true,
      showWhenLoggedOut: true,
      activeLink: "trade"
    },
    {
      label: "Orders",
      path: `${prevLang ? `/${prevLang}` : "/en"}/order/open`,
      showWhenLoggedIn: true,
      showWhenLoggedOut: false,
      activeLink: "order"
    },
    {
      label: "Wallet",
      path: `${prevLang ? `/${prevLang}` : "/en"}/wallet/balance`,
      showWhenLoggedIn: true,
      showWhenLoggedOut: false,
      activeLink: "wallet"
    },
    {
      label: "Earn",
      path: `${prevLang ? `/${prevLang}` : "/en"}/earn`,
      showWhenLoggedIn: true,
      showWhenLoggedOut: true,
      activeLink: "earn"
    },
    {
      label: "Token Sale",
      path: `${prevLang ? `/${prevLang}` : "/en"}/token-sale`,
      showWhenLoggedIn: true,
      showWhenLoggedOut: true,
      activeLink: "token-sale"
    }
  ];

  // useEffect(() => {
  //   if (currentTime <= PermotionStartTimeStap) return;
  //   if (PermotionEndTimeStap - currentTime <= 0) {
  //     setShow(false);
  //     localStorage.removeItem("permotionTemplete");
  //     return;
  //   }
  //   setShow(true);
  // }, []);

  // useEffect(() => {
  //   getPromotionUpdate()
  //     .then((res) => {
  //       const allPromotion = res?.data?.data;
  //       const active = allPromotion.filter((values) => values.isActive === true);
  //       setActivePermotion(active[0]);
  //       setShow(active.length > 0 && true);
  //     })
  //     .catch((err) => {
  //       return;
  //     });
  // }, []);

  useEffect(() => {
    getAllClientSales();
    setShow(activePromotion && activePromotion.length > 0 && true);
  }, []);

  useEffect(() => {
    if (activePromotion && activePromotion.length > 0) {
      setShow(true);
    }
  }, [activePromotion]);

  useEffect(() => {
    if (!location.pathname.includes("/trade") && isChangingLayout) {
      dispatch(performActionOnLayout(""));
    }
    // eslint-disable-next-line
  }, [location, isChangingLayout]);

  useEffect(() => {
    const mainContainer = document.getElementById("main-container");
    if (openHamburger) {
      mainContainer.classList.add("no-scroll");
    } else {
      mainContainer.classList.remove("no-scroll");
    }
  }, [openHamburger]);

  useEffect(() => {
    if (openHamburger && width > 1024) {
      setOpenHamburger(false);
    }
  }, [width]);

  const isDesktop = width > styledConstants.BREAKING_POINT_TABLET;
  const isNoActionRequired =
    !isLoggedIn || (isLoggedIn && actionsRequired && actionsRequired.length === 0);

  return (
    <Fragment>
      {location.pathname === "/" && !isLoggedIn && show && (
        <DivElement
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="fixed"
          zIndex="9999"
          top="0px"
          width="100%"
          gap="15px"
          height="52px"
          bg={isDarkTheme ? "#0A0D12" : "#F5F6FA"}
        >
          {width > 767 ? (
            <HeadingSix>{activePromotion[0].topbar_text}</HeadingSix>
          ) : (
            <marquee direction="left" width="60%">
              <BannerHeading BodyColor>{activePromotion[0].topbar_text}</BannerHeading>
            </marquee>
          )}
          <ButtonComponent width="134px" btnHeight="32px" PrimaryButton>
            <AnchorTag
              BodyColor2
              href={activePromotion[0].topbar_link}
              target="_blank"
              color="white"
            >
              {activePromotion[0]?.button_text || "Claim Now"}
            </AnchorTag>
          </ButtonComponent>
        </DivElement>
      )}
      <DivElement
        position="fixed"
        zIndex="10000"
        width="100%"
        top={location.pathname === "/" && !isLoggedIn && show ? "52px" : "0px"}
        bgCard
        bdGrey
        borderBottom="0.5px solid"
      >
        <DivElement relative padding="0 12px" height="56px" displayBtw>
          <DivElement flex alignItems="center" height="100%">
            <RouterLink
              to={`/${prevLang ? `${prevLang}` : userData?.preferredLanguage ? `${userData?.preferredLanguage}` : ""}`}
            >
              <ImgElement
                maxWidth="87px"
                maxHeight="40px"
                src={isDarkTheme ? LcxLightLogo : LcxDarkLogo}
                alt="lcx"
                marginRight="28px"
              />
            </RouterLink>

            {!isChangingLayout && isNoActionRequired && (
              <Fragment>
                {isDesktop &&
                  TABS.map((tab, i) => (
                    <Fragment key={i}>
                      {((isLoggedIn && tab.showWhenLoggedIn) ||
                        (!isLoggedIn && tab.showWhenLoggedOut)) && (
                        <NavLink to={tab.path} label={tab.label} activeLink={tab.activeLink} />
                      )}
                    </Fragment>
                  ))}

                {isDesktop && <MoreDropdownDesktop />}
              </Fragment>
            )}
          </DivElement>

          {!isChangingLayout && (
            <DivElement flex alignItems="center" height="100%">
              {isLoggedIn && isNoActionRequired && (
                <BalanceDropdown
                  openHamburger={openHamburger}
                  setOpenHamburger={setOpenHamburger}
                  isLoggedIn={isLoggedIn}
                />
              )}

              <NavRightButtons
                openHamburger={openHamburger}
                setOpenHamburger={setOpenHamburger}
                toggleChatSupport={toggleChatSupport}
              />
              {isDesktop && isLoggedIn && <ProfileDropdown />}
            </DivElement>
          )}

          {isChangingLayout && (
            <Fragment>
              <DivElement
                absolute
                right="0"
                left="0"
                marginRight="auto"
                marginLeft="auto"
                width="135px"
              >
                <TextWrapper fontSize="20px" lineHeight="24px">
                  Change Layout
                </TextWrapper>
              </DivElement>
              <DivElement flex justifyContent="flex-end">
                <Button
                  secondaryBlue
                  width="120px"
                  height="32px"
                  marginRight="16px"
                  onClick={() => dispatch(performActionOnLayout("SAVE"))}
                >
                  Save
                </Button>
                <Button
                  secondaryRed
                  width="120px"
                  height="32px"
                  onClick={() => dispatch(performActionOnLayout("RESET"))}
                >
                  Reset
                </Button>
              </DivElement>
            </Fragment>
          )}
        </DivElement>
      </DivElement>

      {width <= 1024 && openHamburger && (
        <NonDesktop
          openHamburger={openHamburger}
          setOpenHamburger={setOpenHamburger}
          toggleChatSupport={toggleChatSupport}
          show={show}
          pathNameCheck={location.pathname === "/" ? true : false}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme,
  isLoggedIn: state.auth.isLoggedIn,
  actionsRequired: state.auth.actionsRequired,
  allClientSales: state.sale.allClientSales
});

const mapDispatchToProps = (dispatch) => ({
  getAllClientSales: () => dispatch(getAllClientSales())
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Navbar);
