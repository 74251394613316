/* eslint-disable no-console */
import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
import {
  DateTimeInput,
  DropdownInput,
  TextInput,
  FormPhoneInput,
  CountriesDropdown
} from "../../../Reusable/FormInput";
import * as Yup from "yup";
import { Persist } from "formik-persist";
import { useRef, useState } from "react";
import { useEffect } from "react";
import KycStepButton from "./KycStepBtn";
import { GENDER } from "../../../../constants/kycOptions";
import { formatCountriesArray, getGenderLabel } from "../../../../utils";
import { isPossiblePhoneNumber } from "libphonenumber-js";
import { BannerBox, BannerHeadingSpan } from "../../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../../assets/StyleComponents/FontSize/Heading.style";
import { ChevronRightIcon, ProgressSuccessIcon } from "../../../../assets/svgs";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
// import NationalityModal from "./NationalityModal";
// import { useHistory } from "react-router-dom";

// Mock async validation function
const validateNotSame = async (val1, val2) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      if (val1 && val2 && val1.value === val2.value) {
        resolve(false);
      } else {
        resolve(true);
      }
    }, 1000);
  });
};

const kycStep1aValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .min(1)
    .max(32, "First Name must be at most 16 characters")
    // eslint-disable-next-line
    .matches(/^(?![0-9]*$)([ \u00C0-\u01FFa-zA-Z0-9'\-])+$/, "Please enter a valid first name")
    .required("First Name is required"),

  lastName: Yup.string()
    .trim()
    .min(1)
    .max(32, "Last Name must be at most 32 characters")
    // eslint-disable-next-line
    .matches(/^(?![0-9]*$)([ \u00C0-\u01FFa-zA-Z0-9'\-])+$/, "Please enter a valid last name")
    .required("Last Name is required"),
  DOB: Yup.date().required("Date of Birth is required"),
  Gender: Yup.string().trim().required("Gender is required"),
  Phone: Yup.number()
    .required("Phone number is required")
    // .max(16, "Please enter a valid Phone Number")
    .test("test-phone", "Please enter a valid Phone Number", (value) =>
      isPossiblePhoneNumber(`+${value}`)
    ),
  BirthCountry: Yup.string().trim().required("Country of birth is required"),
  Nationality: Yup.string().trim().required("Nationality is required"),
  Nationality2: Yup.string()
    .trim()
    // .notOneOf([Yup.ref("Nationality1")], "Nationality2 can't be equal to Nationality1"),
    // .test("not-equal", "Nationality can't be equal.", function (value) {
    //   return value !== this.parent.Nationality1;
    // }),
    .test("not-same", "Nationality2 can't be the same as Nationality1", async function (value) {
      const { Nationality } = this.parent;
      const isValid = await validateNotSame(Nationality, value);
      if (!isValid) {
        return this.createError({ message: "Nationality2 can't be the same as Nationality" });
      }
      return true;
    }),
  AddressLine1: Yup.string().trim().required("Street Address is required"),
  AddressLine2: Yup.string().trim(),
  City: Yup.string().trim().required("City is required"),
  State: Yup.string().trim().required("State/Region is required"),
  ZipCode: Yup.string().trim().required("Zipcode is required"),
  Country: Yup.string()
    .trim()
    // .test("not-selected", "UK registrations on hold temporarily.", function (value) {
    //   return value !== "United Kingdom";
    // })
    .required("Country of residence is required")
});

const Step1a = ({ ParentsetStep }) => {
  const formikRef = useRef();
  // const [showModal, setShowModal] = useState(false);
  const { userData, countriesList, isFetchingCountries } = useSelector(mapStateToProps);
  // eslint-disable-next-line no-unused-vars
  const [mobileSteps, setMobileSteps] = useState(1);
  const [disableStatus, setDisableStatus] = useState(true);
  const [phoneStatus, setPhoneStatus] = useState(true);
  const [dialCode, setDialCode] = useState("");
  const { width } = useWindowSize();
  // const history = useHistory();
  // const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    formikRef.current?.setTouched({}, false);
    // eslint-disable-next-line
  }, []);

  // const UKUserAccess = userData?.allowPartiallyRestrictedCountry;

  const getInitialValues = () => {
    return {
      firstName: userData.firstName || "",
      lastName: userData.lastName || "",
      DOB: userData.DOB || "",
      Gender: userData.Gender || "",
      Nationality: userData.Nationality || "",
      Nationality2: userData.Nationality2 || "",
      BirthCountry: userData.BirthCountry || "",
      Phone: userData.Phone || "",
      AddressLine1: userData.AddressLine1 || "",
      AddressLine2: userData.AddressLine2 || "",
      City: userData.City || "",
      State: userData.State || "",
      ZipCode: userData.ZipCode || "",
      Country: userData.Country || ""
    };
  };

  // const handleNextStepFlow = (values, { setSubmitting }) => {
  const handleNextStepFlow = (values, { setSubmitting, isValid }) => {
    // increaseStep;
  };

  const MobileSteps = [
    {
      StepName: "Profile",
      alpha: "a",
      active: mobileSteps >= 1
    },
    {
      StepName: "Nationality",
      alpha: "b",
      active: mobileSteps >= 2
    },
    {
      StepName: "Address",
      alpha: "c",
      active: mobileSteps === 3
    }
  ];

  // const handleDisbaled = (values) => {
  //   return true;
  //   // Object.keys(errors).length > 0;
  // };

  // const validate = (value) => {
  //   return sleep(0).then(() => {
  //     // if (value === "United Kingdom") {
  //     //   return "UK registrations on hold temporarily";
  //     // }
  //     // if (value.toString().substr(0, 2) === "44") {
  //     //   return "UK registrations on hold temporarily";
  //     // }
  //   });
  // };

  // const errorHandling = async (value, data) => {
  //   if (value) {
  //     // let error = await validate(value);
  //     // if (
  //     //   data.values.Nationality1 &&
  //     //   data.values.Nationality2 &&
  //     //   data.values.Nationality2 == data.values.Nationality1
  //     // ) {
  //     //   data.setTouched({ ...data.touched, Nationality2: true, Nationality1: true });
  //     //   data.setFieldError("Nationality2", "Nationality2 can't be the same as Nationality1");
  //     //   setDisableStatus(true);
  //     //   return;
  //     // }
  //     if (data.errors.Country) {
  //       data.setFieldError("Country", undefined);
  //       // setShowModal(false);
  //       // setOnHold(false);
  //     }
  //   }
  // };

  // const onCloseNationalityModal = (onWaitlist) => {
  //   if (onWaitlist) {
  //     localStorage.removeItem("profileData1");
  //     localStorage.removeItem("profileData2");
  //     localStorage.removeItem("profileData3");
  //     history.push(
  //       `/${
  //         prevLang
  //           ? `${prevLang}`
  //           : userData.preferredLanguage
  //           ? `${userData.preferredLanguage}`
  //           : "en"
  //       }`
  //     );
  //   } else {
  //     setShowModal(false);
  //   }
  // };

  const handleMobileSteps = async (values) => {
    if (values) {
      if (width < 768) {
        if (mobileSteps === 1) {
          if (
            !values.firstName ||
            !values.lastName ||
            !values.DOB ||
            !values.Gender ||
            !values.Phone ||
            !values.BirthCountry
          ) {
            setDisableStatus(true);
          } else {
            setDisableStatus(false);
          }
        } else if (mobileSteps === 2 && !values.Nationality) {
          setDisableStatus(true);
        } else if (mobileSteps === 3) {
          if (
            !values.AddressLine1 ||
            !values.AddressLine2 ||
            !values.City ||
            !values.State ||
            !values.ZipCode ||
            !values.Country
          ) {
            setDisableStatus(true);
          } else {
            setDisableStatus(false);
          }
        } else {
          setDisableStatus(false);
        }
      } else {
        if (
          !values.firstName ||
          !values.lastName ||
          !values.DOB ||
          !values.Gender ||
          !values.Gender === "" ||
          !values.Nationality ||
          !values.BirthCountry ||
          !values.AddressLine1 ||
          !values.AddressLine2 ||
          !values.City ||
          !values.State ||
          !values.ZipCode ||
          !values.Phone ||
          !values.Country ||
          phoneStatus
          // values.Country === "United Kingdom"
        ) {
          setDisableStatus(true);
        } else {
          setDisableStatus(false);
        }
      }
    } else {
      setDisableStatus(false);
    }
  };
  const handlePhoneNumber = (values, data) => {
    let rawPhone = values?.slice(dialCode.length);
    if (rawPhone === "") {
      setPhoneStatus(true);
    } else setPhoneStatus(false);
  };

  // console.log(userData.allowPartiallyRestrictedCountry);

  return (
    <>
      {/* For Mobile Screens */}
      {width < 767 && (
        <BannerBox display="flex" alignItems="center" gap="16px" mb="24px" gapMd="5px">
          {MobileSteps.map((data, index) => {
            return (
              <>
                <BannerBox
                  display="flex"
                  gap="6px"
                  // onClick={() => setMobileSteps(index + 1)}
                >
                  {index + 1 < mobileSteps ? (
                    <ProgressSuccessIcon width="22px" height="22px" />
                  ) : (
                    <>
                      <BannerHeadingSpan
                        width="22px"
                        height="22px"
                        size="14px"
                        weight="500"
                        BorderRadius="50%"
                        BodyColorBg
                        opacity={data.active ? 1 : 0.4}
                      >
                        {data.alpha}
                      </BannerHeadingSpan>
                    </>
                  )}
                  <BannerHeading
                    size="14px"
                    weight="500"
                    opacity={data.active ? 1 : 0.4}
                    txtSuccess={index + 1 < mobileSteps}
                  >
                    {data.StepName}
                  </BannerHeading>
                </BannerBox>
                {data.alpha !== "c" && <ChevronRightIcon />}
              </>
            );
          })}
        </BannerBox>
      )}

      <Formik
        innerRef={formikRef}
        initialValues={getInitialValues()}
        onSubmit={(values) => {
          return handleNextStepFlow(values);
        }}
        validateOnMount={true}
        validationSchema={kycStep1aValidationSchema}
      >
        {(data) => {
          let { setFieldValue, values, handleBlur } = data;
          // errorHandling(values["Country"], data);
          handleMobileSteps(values, data);
          handlePhoneNumber(values["Phone"], data);
          let filteredOptions = countriesList.Nationality;
          if (values.Nationality) {
            filteredOptions = countriesList?.Nationality?.filter(
              (d) => d.name !== values.Nationality
            );
          }

          return (
            <>
              <Form>
                {(width >= 768 || (width < 767 && mobileSteps === 1)) && (
                  <>
                    <BannerBox minHeightMd="60vh">
                      <BannerBox>
                        <BannerBox display="flex" gap="32px" directionMd="column" gapMd="0px">
                          <TextInput
                            title="First Name"
                            placeholder="Enter First Name"
                            name="firstName"
                            value={values.firstName}
                          />
                          <TextInput
                            title="Last Name"
                            name="lastName"
                            placeholder="Enter Last Name"
                            value={values.lastName}
                          />
                        </BannerBox>
                      </BannerBox>

                      <BannerBox display="flex" gap="32px" directionMd="column" gapMd="0px">
                        <DateTimeInput
                          title="Date of birth"
                          name="DOB"
                          left="-24%"
                          value={values.DOB}
                          setFieldValue={setFieldValue}
                        />

                        <DropdownInput
                          title="Gender"
                          name="Gender"
                          placeholder="Select gender"
                          value={getGenderLabel(values.Gender)}
                          setFieldValue={setFieldValue}
                          options={GENDER}
                        />
                      </BannerBox>

                      <BannerBox display="flex" gap="32px" directionMd="column" gapMd="0px">
                        <FormPhoneInput
                          title="Mobile number"
                          name="Phone"
                          value={values.Phone}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          setDialCode={setDialCode}
                        />

                        <CountriesDropdown
                          title="Country of birth"
                          name="BirthCountry"
                          value={values.BirthCountry}
                          disabled={isFetchingCountries || !countriesList}
                          setFieldValue={setFieldValue}
                          countryList={
                            countriesList && formatCountriesArray(countriesList.BirthCountry)
                          }
                          placeholder="Select country"
                        />
                      </BannerBox>
                    </BannerBox>
                  </>
                )}
                {(width >= 768 || (width < 767 && mobileSteps === 2)) && (
                  <BannerBox
                    display="flex"
                    gap="32px"
                    directionMd="column"
                    gapMd="0px"
                    minHeightMd="60vh"
                  >
                    <CountriesDropdown
                      title="Nationality 1"
                      name="Nationality"
                      value={values.Nationality}
                      setFieldValue={setFieldValue}
                      disabled={isFetchingCountries || !countriesList}
                      countryList={countriesList && formatCountriesArray(countriesList.Nationality)}
                      placeholder="Select nationality"
                    />

                    <CountriesDropdown
                      title="Nationality 2"
                      name="Nationality2"
                      value={values?.Nationality2 || ""}
                      showOptional={true}
                      isClearableStatus={true}
                      setFieldValue={setFieldValue}
                      disabled={isFetchingCountries || !countriesList || !values.Nationality}
                      countryList={countriesList && formatCountriesArray(filteredOptions)}
                      placeholder="Select nationality"
                    />
                  </BannerBox>
                )}

                {width >= 768 && (
                  <BannerHeading size="18px" weight="500" mb="20px">
                    Address
                  </BannerHeading>
                )}
                {(width >= 768 || (width < 767 && mobileSteps === 3)) && (
                  <BannerBox>
                    <TextInput
                      title="Street address"
                      name="AddressLine1"
                      placeholder={"Enter street / locality / area"}
                      value={values.AddressLine1}
                    />

                    <TextInput
                      title="Unit"
                      name="AddressLine2"
                      value={values.AddressLine2}
                      placeholder="House number / flat number"
                    />
                    <BannerBox
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                      gap="25px"
                      directionMd="column"
                      gapMd="0px"
                    >
                      <TextInput
                        title="City"
                        name="City"
                        value={values.City}
                        placeholder="Enter city"
                      />
                      <TextInput
                        title="State/Region"
                        name="State"
                        value={values.State}
                        placeholder="Enter state"
                      />
                    </BannerBox>

                    <BannerBox
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                      gap="25px"
                      directionMd="column"
                      gapMd="0px"
                    >
                      <TextInput
                        title="Zip code"
                        name="ZipCode"
                        value={values.ZipCode}
                        placeholder="Enter zip code"
                      />

                      <CountriesDropdown
                        title="Country of residency"
                        name="Country"
                        value={values.Country}
                        setFieldValue={setFieldValue}
                        disabled={isFetchingCountries || !countriesList}
                        countryList={countriesList && formatCountriesArray(countriesList.Country)}
                        placeholder="Select country"
                      />
                    </BannerBox>
                  </BannerBox>
                )}

                {/* {showModal && (
                  <NationalityModal country={values.Country} onClose={onCloseNationalityModal} />
                )} */}
                <Persist name="profileData1" />
                {/* </BannerBox> */}

                <KycStepButton
                  label="Continue"
                  disabled={disableStatus}
                  onClick={() => {
                    if (width < 767 && mobileSteps <= 2) {
                      setMobileSteps(mobileSteps + 1);
                      // handleMobileSteps(values);
                    } else {
                      ParentsetStep((state) => state + 1);
                    }
                  }}
                />
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => ({
  userData: state.dashboard.getUserData,
  countriesList: state.kyc.countriesList,
  isFetchingCountries: state.kyc.isFetchingCountries
});

export default Step1a;
