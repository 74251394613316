/* eslint-disable no-unused-vars */
import { Fragment, useContext, useEffect, useState } from "react";
import { Button, DivElement, TextWrapper } from "../../../Reusable";
import { WalletContext } from "../../../../contexts/WalletPage/BalancePage/wallet";
import { showSuccessMsg, showErrorMsg } from "../../../../utils/notification";
import { debounce, formatNumbers } from "../../../../utils/helpers";
import { decodeLightningInvoice, withdrawCoin } from "../../../../lib/api";
import { formatAPIErrors } from "../../../../utils/general";
import { LoadingButton } from "../../../Loader";
import WithdrawalModal from "./Modal/withdrawalModal";
import BtcLightningPreview from "../../../Wallets/Withdrawals/BtcLightningPreview";
import { DepositContext } from "../../../../contexts/WalletPage/DepositPage/depositContext";
import ShowErrorMessage from "../../../Reusable/ShowErrorMessage";
import { BannerBox, BannerHeadingSpan } from "../../../../assets/StyleComponents/Exchange.style";
import { FormInput } from "../../../../assets/StyleComponents/Input.style";
import { numberInputOnWheelPreventChange } from "../../../../utils";

const WithdrawalNonFiatCoinDetails = (props) => {
  let { selectedCoin, MainOnClose, modalStatus, CloseAllModal } = props;
  let { exchWallet } = useContext(WalletContext);
  let { selectedNetwork } = useContext(DepositContext);
  const [address, set_address] = useState("");
  const [memoId, setMemoId] = useState("");
  const [amount, set_amount] = useState("");
  const [loading, set_loading] = useState(false);
  const [step, setStep] = useState(1);
  const [otpId, setOtpId] = useState("");
  const [withdrawalRes, setWithdrawalRes] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [errorMsg, setErroMsg] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [isDecodingInvoice, setIsDecodingInvoice] = useState(false);
  const [decodedInvoiceData, setDecodedInvoiceData] = useState("");
  const [isBalanceInsufficient, setIsBalanceInsufficient] = useState(false);
  const [error, setError] = useState("");
  const [showMemo, setShowMemo] = useState(false);

  useEffect(() => {
    if (props.apiError && props.apiError.message) {
      showErrorMsg(props.apiError.message);
      props.clearWalletErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.apiError]);

  useEffect(() => {
    setStep(step + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.apiData]);

  useEffect(() => {
    if (decodedInvoiceData) setDecodedInvoiceData("");
    if (isBalanceInsufficient) setIsBalanceInsufficient(false);
    if (error) setError("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    setError("");
    set_address("");
    set_amount("");
    setErroMsg("");

    if (selectedCoin && selectedCoin.coin === "USDT") {
      if (selectedNetwork?.label === "Ton") {
        setShowMemo(true);
      } else {
        setShowMemo(false);
      }
    }
  }, [selectedNetwork, selectedCoin]);

  const checkInvalidInput = (value) => {
    let reg = /^(\d{1,8}|\d{0,8}\.\d{1,8})$/;
    return value.match(reg);
  };

  //=> Check Validation on positve and dicimal
  const validateAmount = (event) => {
    let reg = checkInvalidInput(event.target.value);
    if (!reg) {
      let amount = event.target.value;
      let valueSplit = amount.split(".");
      if (valueSplit && valueSplit.length === 2 && valueSplit[1].length > 8) return true;
      if (valueSplit && valueSplit.length === 2) {
        if (isNaN(valueSplit[1])) return true;
        setErroMsg("Enter correct number");
        set_amount(amount);
      } else {
        if (amount === "") set_amount("");
        return true;
      }
      setErroMsg("Enter correct number");
      set_amount(amount);
    } else {
      let amount = event.target.value;
      if (amount && Number(amount) > Number(selectedCoin.balance.freeBalance)) {
        setErroMsg("Insufficient balance");
        return set_amount(amount);
      }
      if (amount && Number(amount) < Number(selectedCoin.minWithdrawalLimit)) {
        setErroMsg(`Amount should be greater than or equal to ${selectedCoin.minWithdrawalLimit}`);
        return set_amount(amount);
      }

      if (amount < 0 || amount === 0) {
        set_amount(Number(amount));
      }
      setErroMsg("");
      return set_amount(amount);
    }
  };

  const withdrawBtnHandler = debounce(() => {
    if (errorMsg !== "") return;

    if (!address && selectedCoin.coinType !== "fiat_token") {
      showErrorMsg("Invalid Address", { autoClose: 2000 });
      return;
    }

    if (selectedNetwork.label !== "Lightning") {
      if (selectedCoin.coin !== selectedCoin.withdrawalFeeCoin) {
        let feecoinBal = 0;
        if (exchWallet) {
          let feeCoin = exchWallet.data.find((ele) => {
            return ele.coin === selectedCoin.withdrawalFeeCoin;
          });
          if (feeCoin) {
            feecoinBal = feeCoin.balance.freeBalance;
          }
        }
        if (Number(feecoinBal) < Number(selectedCoin.fees)) {
          showErrorMsg(`Insufficient ${selectedCoin.withdrawalFeeCoin} balance`, {
            autoClose: 2000
          });
          return;
        }
      } else {
        if (Number(amount) < Number(selectedCoin.fees)) {
          showErrorMsg(
            `Minimum withdrawal amount is ${selectedCoin.minWithdrawalLimit} ${selectedCoin.coin}`,
            { autoClose: 2000 }
          );
          return;
        }
      }
    }

    if ((selectedCoin.isMemo || showMemo) && !memoId) {
      showErrorMsg("Memo is required", { autoClose: 2000 });
      return;
    }

    if (
      Number(amount) < Number(selectedCoin.minWithdrawalLimit) &&
      selectedNetwork.label !== "Lightning"
    ) {
      showErrorMsg(
        `Minimum withdrawal amount is ${selectedCoin.minWithdrawalLimit} ${selectedCoin.coin}`,
        { autoClose: 2000 }
      );
      return;
    }

    if (!amount || isNaN(amount)) {
      showErrorMsg("Invalid Amount", { autoClose: 2000 });
      return false;
    }

    if (selectedCoin.balance && Number(selectedCoin.balance.freeBalance) < Number(amount)) {
      showErrorMsg("Insufficient balance", { autoClose: 2000 });
      return;
    }

    let data = {
      coin: selectedCoin.coin,
      amount: Number(amount),
      toAddress: address,
      source: "ACCOUNTS"
    };

    if (selectedCoin.isMemo || showMemo) {
      data.memo = memoId;
    }

    set_loading(true);

    const params = {
      data: data,
      isBtcLightning: selectedCoin.coin === "BTC" && selectedNetwork.label === "Lightning"
    };

    withdrawCoin(params)
      .then((res) => {
        showSuccessMsg(res.data.message);
        selectedCoin.supportNotabene = res.data.data.notabeneSupport;
        setOtpId(res.data.data.id);
        setWithdrawalRes(res.data.data);
        MainOnClose(true);
        setShowModal(true);
        set_loading(false);
        setShowPreview(false);
      })
      .catch((err) => {
        set_loading(false);
        showErrorMsg(formatAPIErrors(err));
      });
  }, 700);

  const closeModal = () => {
    setShowModal(false);
    CloseAllModal();
  };

  const onPreviewLightningWithdrawal = () => {
    if (!address) {
      setError("Please enter invoice");
      return;
    }

    setIsDecodingInvoice(true);

    const data = {
      invoice: address
    };

    decodeLightningInvoice(data)
      .then((res) => {
        let resData = res?.data;
        if (resData && resData.validation) {
          let amt = resData?.data?.amount + resData?.data?.fee;
          if (selectedCoin.balance && Number(selectedCoin.balance.freeBalance) < Number(amt)) {
            setIsBalanceInsufficient(true);
            return;
          } else {
            set_amount(amt);
            setShowPreview(true);
          }
        }
        setDecodedInvoiceData(resData);
      })
      .catch((err) => {
        let message = formatAPIErrors(err);
        if (message === "Something went wrong") {
          showErrorMsg(message);
        } else {
          setError(message);
        }
      })
      .finally(() => {
        setIsDecodingInvoice(false);
      });
  };

  let totalAmount;
  if (selectedCoin.coin !== selectedCoin.withdrawalFeeCoin) {
    totalAmount = amount;
  } else {
    if (selectedCoin?.fees) totalAmount = amount - selectedCoin?.fees;
  }
  if (totalAmount < 0 || !totalAmount) totalAmount = 0;

  const isBtcLightning = selectedCoin.coin === "BTC" && selectedNetwork.label === "Lightning";

  return (
    <Fragment>
      {!modalStatus && (
        <Fragment>
          <DivElement
            className="fInput__form__field"
            width="100%"
            marginTop="24px"
            marginBottom="0"
          >
            <TextWrapper
              secondary
              fontSize="14px"
              fontWeight="300"
              lineHeight="20px"
              marginBottom="2px"
            >
              {isBtcLightning ? "Invoice" : "Withdrawal Address"}
            </TextWrapper>
            <BannerBox>
              <FormInput
                className="form-control"
                type="text"
                required="required"
                placeholder={`Enter ${isBtcLightning ? "invoice" : "address"}`}
                value={address}
                onChange={(e) => set_address(e.target.value)}
              />
            </BannerBox>
            {((decodedInvoiceData && !decodedInvoiceData.validation) ||
              isBalanceInsufficient ||
              error) && (
              <ShowErrorMessage
                message={
                  error ? error : isBalanceInsufficient ? "Insufficient balance" : "Invalid invoice"
                }
              />
            )}
          </DivElement>

          {!isBtcLightning && (
            <Fragment>
              {(selectedCoin.isMemo || showMemo) && (
                <DivElement
                  className="fInput__form__field"
                  width="100%"
                  marginTop="24px"
                  marginBottom="0"
                >
                  <TextWrapper
                    secondary
                    fontSize="14px"
                    fontWeight="400"
                    lineHeight="20px"
                    marginBottom="2px"
                  >
                    Memo
                  </TextWrapper>
                  <BannerBox>
                    <FormInput
                      className="form-control"
                      type="text"
                      required="required"
                      placeholder="Enter Memo ID"
                      value={memoId}
                      onChange={(e) => setMemoId(e.target.value)}
                    />
                  </BannerBox>
                </DivElement>
              )}

              <BannerBox className="fInput__form__field" width="100%" mt="24px" mb="0">
                <TextWrapper
                  secondary
                  fontSize="14px"
                  fontWeight="300"
                  lineHeight="20px"
                  marginBottom="2px"
                >
                  Withdrawal Amount
                </TextWrapper>
                <BannerBox className="fInput__form__input">
                  <FormInput
                    className="form-control"
                    type="number"
                    required="required"
                    placeholder="Enter the amount"
                    value={amount}
                    onChange={validateAmount}
                    onWheel={numberInputOnWheelPreventChange}
                  />
                </BannerBox>
              </BannerBox>

              {errorMsg && (
                <BannerHeadingSpan txtDanger size="12px" mt="6px">
                  {errorMsg}
                </BannerHeadingSpan>
              )}

              {selectedCoin?.fees && (
                <DivElement marginTop="24px">
                  <TextWrapper secondary fontSize="14px">
                    Transaction Fee:{" "}
                    <BannerHeadingSpan headerText className="f-14 font-weight-500">
                      {` ${selectedCoin?.fees} ${
                        selectedCoin.withdrawalFeeCoin
                          ? selectedCoin.withdrawalFeeCoin
                          : selectedCoin?.coin
                      }`}
                    </BannerHeadingSpan>
                  </TextWrapper>
                  <TextWrapper secondary fontSize="14px" marginTop="10px">
                    You Will Get:{" "}
                    <BannerHeadingSpan headerText className="f-14 font-weight-500">
                      {`${formatNumbers(totalAmount, 8)} ${selectedCoin?.coin}`}
                    </BannerHeadingSpan>
                  </TextWrapper>
                </DivElement>
              )}
            </Fragment>
          )}

          <Button
            primaryBlue
            width="100%"
            height="48px"
            marginTop="48px"
            disabled={
              isBtcLightning
                ? isDecodingInvoice || error || isBalanceInsufficient
                : loading || errorMsg
            }
            onClick={isBtcLightning ? onPreviewLightningWithdrawal : withdrawBtnHandler}
          >
            {isBtcLightning ? (
              isDecodingInvoice ? (
                <LoadingButton />
              ) : (
                "Preview withdrawal"
              )
            ) : loading ? (
              <LoadingButton />
            ) : (
              "Withdraw"
            )}
          </Button>
        </Fragment>
      )}

      {showModal && (
        <WithdrawalModal
          onClose={closeModal}
          show={showModal}
          otpId={otpId}
          withdrawalRes={withdrawalRes}
          coin={selectedCoin}
          amount={amount}
          address={address}
          setBtnLoading={set_loading}
          goToStep={!selectedCoin.supportNotabene ? 2 : 1}
        />
      )}

      {showPreview && decodedInvoiceData && (
        <BtcLightningPreview
          onClose={() => setShowPreview(false)}
          onConfirm={withdrawBtnHandler}
          loader={loading}
          invoiceData={decodedInvoiceData?.data}
        />
      )}
    </Fragment>
  );
};

export default WithdrawalNonFiatCoinDetails;
