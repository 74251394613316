/* eslint-disable */
// import axios from "axios";
import React from "react";
import { new_BN } from "./bigNumber";
import DropDownListLabel from "./DropDownListLabel";
import {
  accountApi,
  walletAPI,
  walletV2API,
  exchangeApi,
  notifyAPI,
  accountApiV2
} from "../lib/api";
import { API, APIV2 } from "../constants/api";
import store from "../store";
import { eraseCookie } from "./cookies";
import apiConstants from "../constants/apiConstants";
import { toast } from "sonner";

export function flushUserState() {
  unregisterAllOldServiceWorkers();
  eraseCookie("lcx-csrf");
  toast.dismiss();

  localStorage.removeItem("accessToken");
  localStorage.removeItem("user");
  // localStorage.setItem("ljs-lang", "en");
  // window.Localize.setLanguage("en");

  // For user Wallets
  localStorage.removeItem("hideStatus");

  // For Kyc Profile Data
  localStorage.removeItem("kycStep");
  localStorage.removeItem("profileData1");
  localStorage.removeItem("profileData2");
  localStorage.removeItem("profileData3");
  localStorage.removeItem("selfie_instruction");
  // window.location.reload();
}

export function formatAPIErrors(err) {
  if (err && err.data && err.data.message) return err.data.message;

  if (err.response && err.response.data && err.response.data.message)
    return err.response.data.message;

  if (err && err.message) {
    return err.message;
  }

  return "Something went wrong";
}

export function getLocalStorageData(data, isObject) {
  let returnResponse;
  let localdata = localStorage.getItem(data);
  if (
    localdata &&
    typeof localdata !== "undefined" &&
    localdata !== "undefined" &&
    localdata !== "null"
  ) {
    if (isObject === true) {
      localdata = JSON.parse(localdata);
      if (typeof localdata !== "undefined" && localdata !== "") returnResponse = localdata;
      else returnResponse = null;
    } else returnResponse = localdata;
  } else returnResponse = null;

  return returnResponse;
}

export function updateLocalStorageData(updatedData) {
  let user = JSON.parse(localStorage.getItem("user"));

  let updatedUser = { ...user, subscriptionDetails: updatedData };

  localStorage.setItem("user", JSON.stringify(updatedUser));
}

export function setLocalStorageData(key, object) {
  if (key && typeof object === "object") {
    localStorage.setItem(key, JSON.stringify(object));
    return true;
  }
}

export function formatDecimal(num = 0, decimal_place, add_trailing_zeroes = false) {
  if (!decimal_place) {
    return num;
  }
  num = num.toString(); //If it's not already a String
  decimal_place = Number(decimal_place);
  if (decimal_place !== 0) {
    decimal_place = decimal_place + 1;
  }
  let split_result = num.split(".");
  if (split_result[1] === undefined) {
    // let padding_init = ".";
    let required_zeros = Number(decimal_place);
    num = Number(num).toFixed(required_zeros - 1);
  } else if (split_result[1].length >= decimal_place) {
    //do nothing
  } else if (split_result[1]) {
    let decimals_present = split_result[1].length;
    if (decimals_present < decimal_place) {
      num = Number(num).toFixed(decimal_place - 1);
    }
  }
  num = num.slice(0, num.indexOf(".") + decimal_place); //With decimal_place exposing the hundredths place
  return num;
}

export function formatBidsAsks(filtered_data, precision, isAsk) {
  let max_quantity;

  let length = filtered_data.length;
  // let i = 0;
  let s = "";

  if (isAsk) {
    filtered_data.reverse();
  }

  for (let j = 0; j < length; j++) {
    if (j === 0) filtered_data[j][2] = "all";
    else {
      if (filtered_data[j - 1][2] === "all") {
        let p2 = filtered_data[j][0].toString().split("");
        let p1 = filtered_data[j - 1][0].toString().split("");
        for (let k = 0; k < p2.length; k++) {
          if (p1[k] === p2[k]) {
            s = s + p1[k];
          } else {
            filtered_data[j][2] = k - 1;
            break;
          }
        }
      } else {
        if (filtered_data[j][0].toString().includes(s)) {
          filtered_data[j][2] = filtered_data[j - 1][2];
        } else {
          s = "";
          filtered_data[j][2] = "all";
        }
      }
    }

    let quantity = filtered_data[j][1];
    let price = Number(filtered_data[j][0].replace(/,/g, ""));
    if (!max_quantity) {
      max_quantity = Number(formatDecimal(quantity * price, precision ? precision : 4));
    } else if (max_quantity < Number(formatDecimal(quantity * price, precision ? precision : 4))) {
      max_quantity = Number(formatDecimal(quantity * price, precision ? precision : 4));
    }
  }

  if (isAsk) filtered_data.reverse();
  return max_quantity;
}

export function processBackground(val, max_quantity) {
  let bg_width = (val / max_quantity) * 60;
  return bg_width < 2 ? 2 : bg_width;
}

export const find_max_value = (array1, array2, precision) => {
  let length = array1.length;
  let max = 0;
  let value = 0;
  for (let i = 0; i < length; i++) {
    value = array1[i][1];
    if (value > max) max = Number(value);
  }

  length = array2.length;
  for (let i = 0; i < length; i++) {
    value = array2[i][1];
    if (value > max) max = Number(value);
  }
  return Number(max.toFixed(precision));
};

export const noExponents = (parmas) => {
  var data = String(parmas).split(/[eE]/);
  if (data.length == 1) return data[0];

  var z = "",
    sign = parmas < 0 ? "-" : "",
    str = data[0].replace(".", ""),
    mag = Number(data[1]) + 1;

  if (mag < 0) {
    z = sign + "0.";
    while (mag++) z += "0";
    return z + str.replace(/^\-/, "");
  }
  mag -= str.length;
  while (mag--) z += "0";
  return str + z;
};

//=> Number With Comma
export const numberWithCommas = (x) => {
  if (x == 0) return "0";
  if (!x) return "";
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const checkMillion = (labelValue) => {
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? new_BN(Math.abs(Number(labelValue)) / 1.0e9, 2) + " B"
    : Math.abs(Number(labelValue)) >= 1.0e6
    ? new_BN(Math.abs(Number(labelValue)) / 1.0e6, 2) + " M"
    : new_BN(Math.abs(Number(labelValue)), 3);
};

export const checkThousand = (labelValue) => {
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? new_BN(Math.abs(Number(labelValue)) / 1.0e9, 2) + " B"
    : Math.abs(Number(labelValue)) >= 1.0e6
    ? new_BN(Math.abs(Number(labelValue)) / 1.0e6, 2) + " M"
    : Math.abs(Number(labelValue)) >= 1.0e3
    ? new_BN(Math.abs(Number(labelValue)) / 1.0e3, 2) + " K"
    : new_BN(Math.abs(Number(labelValue)), 3);
};

export const sortNumberData = (array) => {
  if (array && array.data && array.data.length > 0) {
    let result = array;
    let resultData = array.data;
    let unFilterZero =
      array.data
        .filter((filterValue) => filterValue.equivalentUSDBalance.totalBalance !== 0)
        .sort((a, b) =>
          a.equivalentUSDBalance.totalBalance < b.equivalentUSDBalance.totalBalance
            ? 1
            : b.equivalentUSDBalance.totalBalance < a.equivalentUSDBalance.totalBalance
            ? -1
            : 0
        ) || [];
    let filterZero =
      array.data.filter((filterValue) => filterValue.equivalentUSDBalance.totalBalance === 0) || [];
    resultData = filterZero.sort((a, b) => (a.coin > b.coin ? 1 : b.coin > a.coin ? -1 : 0));
    result.data = unFilterZero.concat(resultData);
    return result;
  }
};

export const sortData = (array, status) => {
  if (status) {
    if (array && array.data && array.data.length > 0) {
      let result = array;
      let resultData = array.data;
      resultData = array.data.sort((a, b) => (a.coin > b.coin ? 1 : b.coin > a.coin ? -1 : 0));
      result.data = resultData;
      return result;
    }
  } else return array.sort((a, b) => (a.coin > b.coin ? 1 : b.coin > a.coin ? -1 : 0));
};

export const filterPairList = (coinName, tickerData) => {
  let result = [];
  if (!coinName || tickerData.length === 0) return result;
  tickerData.filter((coin_pair) => {
    if (coin_pair.base_pair === coinName || coin_pair.quote_pair === coinName) {
      result.push(coin_pair.symbol);
    }
  });
  return result;
};

export const filterPairListByQuote = (coinName, tickerData, category) => {
  let result = [];
  if (!coinName || tickerData.length === 0) return result;
  tickerData.filter((coin_pair) => {
    const isThere = coin_pair?.categories?.includes(category);
    if (coin_pair.quote_pair === coinName && isThere) {
      result.push(coin_pair);
    }
  });
  return result;
};
export const filterPairListByCategory = (category, tickerData) => {
  let result = [];
  if (!category || tickerData.length === 0) return result;
  tickerData.filter((array) => {
    const isThere = array?.categories?.includes(category);
    isThere && result.push(array);
  });
  return result;
};

export const pricePrecision = (amount) => {
  let result = [];
  if (!amount) return result;
  let decimal = amount;
  let count = 4;
  while (decimal > 0 && count) {
    let rate = new_BN(1 / Math.pow(10, decimal), decimal);
    result.push(rate);
    decimal--;
    count--;
  }
  return result;
};

export const findDecimal = (decimal) => {
  let result = null;
  if (!Number(decimal)) return result;
  let count = 0;
  while (Number(decimal) !== 1) {
    decimal = Number(decimal) * 10;
    count++;
  }
  return count;
};

export const formattedArray = (array) => {
  return array.map((item) => {
    return {
      id: item,
      label: item.coin,
      value: item.coin
    };
  });
};

export const formattedSymbol = (array) => {
  return array.map((item) => {
    return {
      id: item,
      label: item.symbol,
      value: item.symbol
    };
  });
};

export const filterUSDVolume = (arr) => {
  let result = arr;
  if (arr && arr.length > 0) {
    result = arr.sort((a, b) => b.usdVolume - a.usdVolume);
  }
  return result;
};

export const filterCoin = (data, availStaus = true, all = false) => {
  let arr = [];
  if (data && data.length > 0) {
    let faitType = [];
    let unFaitType = [];
    data.filter((item) => {
      if (item.coinType === "fiat_token") {
        const coin = item.coin;
        faitType.push(item);
        return coin;
      }
    });
    data.filter((item) => {
      if (item.coinType !== "fiat_token") {
        unFaitType.push(item);
        return item.coin;
      }
    });
    const All = {
      id: "0",
      label: <DropDownListLabel First="All" Second="Available Balance" availStaus={availStaus} />,
      options: [{ id: "0", label: "All Assets", value: "All Assets" }]
    };

    let sortUnFaitType = sortData(unFaitType);
    if (all) {
      arr.push(All);
    }
    arr.push({
      id: "1",
      label: <DropDownListLabel First="Fiat" Second="Available Balance" availStaus={availStaus} />,
      options: formattedArray(faitType)
    });
    arr.push({
      id: "2",
      label: (
        <DropDownListLabel First="Crypto" Second="Available Balance" availStaus={availStaus} />
      ),
      options: formattedArray(sortUnFaitType)
    });
  }

  return arr;
};

export const checkInvalidInput = (value) => {
  if (isNaN(Number(value))) return true;
  else return false;
};

export const decimalCount = (num) => {
  // Convert to String
  const numStr = String(num);
  // String Contains Decimal
  if (numStr.includes(".")) {
    return numStr.split(".")[1].length;
  }
  // String Does Not Contain Decimal
  return 0;
};

export const catchAll401Errors = () => {
  const triggerSessionExpired = () => ({ type: apiConstants.HANDLE_401 });
  const errorHandler = (error) => {
    if (error?.response?.status === 401) {
      flushUserState();
      store.dispatch(triggerSessionExpired());
    }
    return Promise.reject(error);
  };
  const successHandler = (resp) => resp;

  API.interceptors.response.use(successHandler, errorHandler);
  APIV2.interceptors.response.use(successHandler, errorHandler);
  walletAPI.interceptors.response.use(successHandler, errorHandler);
  walletV2API.interceptors.response.use(successHandler, errorHandler);
  exchangeApi.interceptors.response.use(successHandler, errorHandler);
  notifyAPI.interceptors.response.use(successHandler, errorHandler);
  accountApi.interceptors.response.use(successHandler, errorHandler);
  accountApiV2.interceptors.response.use(successHandler, errorHandler);
};

export function unregisterAllOldServiceWorkers() {
  if (window.navigator && navigator.serviceWorker) {
    navigator.serviceWorker
      .getRegistrations()
      .then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
      })
      .catch(() => console.log("Failed to remove service workers"));
  }
}

export const yearRange = (start, end, step = 1) => {
  const arr = [];
  for (let i = start; i < end; i += step) {
    arr.unshift(i);
  }
  return arr;
};

export const removeAuthorizationForPublic = () => {
  API.defaults.headers.common["Authorization"] = "";
  APIV2.defaults.headers.common["Authorization"] = "";
  walletAPI.defaults.headers.common["Authorization"] = "";
  walletV2API.defaults.headers.common["Authorization"] = "";
  walletAPIMain.defaults.headers.common["Authorization"] = "";
  exchangeApi.defaults.headers.common["Authorization"] = "";
  notifyAPI.defaults.headers.common["Authorization"] = "";
  accountApi.defaults.headers.common["Authorization"] = "";
  accountApiV2.defaults.headers.common["Authorization"] = "";
};
