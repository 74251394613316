import { Fragment, useState, useEffect } from "react";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import { numberWithCommas } from "../../../../utils/general";
import {
  DivElement,
  ElementBox,
  LinkButton,
  Table,
  Tbody,
  Td,
  TextWrapper,
  Th,
  ThDropdown,
  Thead,
  Tr
} from "../../../../components/Reusable";
import styledConstants from "../../../../constants/styledConstants";
import { EarnPublicBalance } from "../../../../lib/api";
import { showErrorMsg } from "../../../../utils/notification";
import { formatAPIErrors } from "../../../../utils/general";
import { TableLoader } from "../../../../components/Loader";

const TokenizedBondsPublic = () => {
  const [activeCol, setActiveCol] = useState("Fixed Interest");
  const { width } = useWindowSize();
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    setLoading(true);
    EarnPublicBalance()
      .then((res) => {
        setDetails(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
        setLoading(false);
      });
  }, []);

  return (
    <ElementBox
      minHeight="280px"
      minHeightMd="239px"
      minHeightSm="184px"
      padding="50px 20px"
      paddingMd="43px 30px"
      paddingSm="28.5px 8px"
    >
      <TextWrapper
        primary
        marginLeft="19px"
        marginLeftMd="0"
        marginLeftSm="8px"
        marginBottom="33px"
        marginBottomMd="26px"
        marginBottomSm="19px"
        fontSize="28px"
        fontSizeMd="24px"
        fontSizeSm="20px"
      >
        Tokenized Bonds
      </TextWrapper>

      <DivElement marginTop="32px">
        {!loading && (
          <Table bRadius nowrap>
            <Thead fontSize="14px" fontSizeMd="12px">
              <Tr>
                <Th
                  minWidth="20%"
                  minWidthMd="84px"
                  minWidthSm="33.33%"
                  textAlign="left"
                  paddingLeft="7px"
                >
                  Asset
                </Th>
                {width > styledConstants.BREAKING_POINT_MOBILE && (
                  <Fragment>
                    <Th minWidth="20%" minWidthMd="98px" textAlign="left">
                      Fixed Interest
                    </Th>
                    <Th minWidth="20%" minWidthMd="141px" textAlign="right">
                      Minimum Investment
                    </Th>
                    <Th minWidth="20%" minWidthMd="141px" textAlign="right">
                      Cap
                    </Th>
                  </Fragment>
                )}
                {width <= styledConstants.BREAKING_POINT_MOBILE && (
                  <ThDropdown
                    widthSm="33.33%"
                    textAlign="right"
                    activeCol={activeCol}
                    selectOption={setActiveCol}
                    OPTIONS={["Fixed Interest", "Minimum Investment", "Cap"]}
                  />
                )}
                <Th minWidth="20%" minWidthMd="120px" minWidthSm="33.33%" textAlignSm="right">
                  Action
                </Th>
              </Tr>
            </Thead>
            <Tbody fontSize="20px" fontSizeMd="16px" fontSizeSm="14px" nowrap>
              {details.map((data, i) => (
                <Tr height="56px" heightSm="40px" key={i}>
                  <Td textAlign="left" paddingLeft="7px">
                    {data?.bondName}
                  </Td>
                  {(width > styledConstants.BREAKING_POINT_MOBILE ||
                    activeCol === "Fixed Interest") && (
                    <Td textAlign="left" textAlignSm="right">
                      {data?.bondInterest}%
                    </Td>
                  )}
                  {(width > styledConstants.BREAKING_POINT_MOBILE ||
                    activeCol === "Minimum Investment") && (
                    <Td textAlign="right" textAlignSm="right">{`${numberWithCommas(
                      data?.minAmount
                    )} ${data.quoteCoin}`}</Td>
                  )}
                  {(width > styledConstants.BREAKING_POINT_MOBILE || activeCol === "Cap") && (
                    <Td textAlign="right" textAlignSm="right">{`${numberWithCommas(
                      data?.totalCap
                    )} ${data?.quoteCoin}`}</Td>
                  )}
                  <Td>
                    <DivElement
                      displayCenter
                      displayEnd={width <= styledConstants.BREAKING_POINT_MOBILE || null}
                    >
                      <LinkButton
                        to={`${prevLang ? `/${prevLang}` : "/en"}/earn/invest/${data.bondId}`}
                        primaryBlue
                        height="32px"
                        padding="0 20px"
                        blueTextBtn={width <= styledConstants.BREAKING_POINT_MOBILE || null}
                      >
                        Explore
                      </LinkButton>
                    </DivElement>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
        {loading && <TableLoader rows={4} webCol={5} tabletCol={3} mobileCol={3} />}
      </DivElement>
    </ElementBox>
  );
};

export default TokenizedBondsPublic;
