/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { updateOrderFromOrderBook } from "../../../../../../actions/trading";
import { Fragment, useEffect, useState } from "react";
import { divideNumbers, setPrecision } from "../../../../../../utils/math";
import AvgSumToolTip from "./AvgSumToolTip";
import OrderRowAnimation from "./OrderRowAnimation";
import { numberFormatter } from "../../../../../../utils";
import { multiplyNumbers } from "../../../../../../utils/math";
import { useWindowSize } from "../../../../../../Hooks/CheckWidthHeight";
import OrderRowBlink from "./OrderRowBlink";

const SIDES = {
  BUY: "buy",
  SELL: "sell"
};

const orderRowHoc = (RowComponent) => {
  return function WrappedComponent(props) {
    const { setCurrRowIndex, index, order, side } = props;
    const [avgSumData, setAvgSumData] = useState("");
    const [graphPercent, setGraphPercent] = useState(0);
    const orderBookConfig = useSelector((state) => state.trading.orderBookConfig);
    const displayAvgSum = orderBookConfig && orderBookConfig["displayAvgSum"];
    const precision = useSelector((state) => state.trading.selectedPricePrecision);
    const selectedPairConfig = useSelector((state) => state.trading.selectedPairConfig);
    const activeSide = useSelector((state) => state.trading.orderBookActiveSide);
    const obGraphData = useSelector(
      (state) => state.trading[side === SIDES.BUY ? "obBuyGraphData" : "obSellGraphData"]
    );
    const dispatch = useDispatch();
    const { width } = useWindowSize();
    const isTablet = width > 767 && width <= 1024;

    useEffect(() => {
      updateGraphData();
    }, [obGraphData, orderBookConfig]);

    useEffect(() => {
      if (side === SIDES.SELL) {
        updateGraphData();
      }
    }, [isTablet]);

    const updateGraphData = () => {
      let percentage = 100;
      let sumIndex = side === "sell" && !isTablet ? obGraphData.count - index - 1 : index;

      if (orderBookConfig && orderBookConfig.cumulative) {
        percentage = (100 / obGraphData.sumOfTotals) * obGraphData.totalSumArray[sumIndex];
        percentage = 100 - percentage;
      } else {
        let total = order[0] * order[1];
        percentage = (100 / obGraphData.maxOfTotals) * total;
        percentage = 100 - percentage;
      }
      percentage = setPrecision(percentage, 2);

      if (percentage !== graphPercent) {
        setGraphPercent(percentage);
      }
      let avgPrice = +divideNumbers(obGraphData.priceSumArray[sumIndex], sumIndex + 1);
      let sumOfBase = obGraphData.amountSumArray[sumIndex];
      let sumOfQuote = obGraphData.totalSumArray[sumIndex];
      let avgData = {
        avgPrice,
        sumOfBase,
        sumOfQuote
      };

      setAvgSumData(avgData);
    };

    const onClickHandler = () => {
      let data = {
        price: order[0],
        amount: order[1]
      };

      dispatch(updateOrderFromOrderBook(data));
    };

    const onMouseOver = () => {
      if (displayAvgSum) {
        setCurrRowIndex(index);
      }
    };

    return (
      <RowComponent
        {...props}
        activeSide={activeSide}
        displayAvgSum={displayAvgSum}
        onClickHandler={onClickHandler}
        onMouseOver={onMouseOver}
        SIDES={SIDES}
        rowChildren={
          <Fragment>
            <AvgSumToolTip avgSumData={avgSumData} index={index} side={side} />
            <OrderRowAnimation side={side} percent={graphPercent} />
            {orderBookConfig && orderBookConfig.animation && (
              <OrderRowBlink side={side.toUpperCase()} price={order[0]} />
            )}
          </Fragment>
        }
        price={numberFormatter(order[0], precision)}
        amount={numberFormatter(order[1], selectedPairConfig?.orderPrecision?.Amount)}
        total={numberFormatter(
          multiplyNumbers(order[0], order[1]),
          selectedPairConfig?.orderPrecision?.Total
        )}
      />
    );
  };
};

export default orderRowHoc;
