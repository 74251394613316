import { DivElement, TextWrapper } from "../../Reusable";
import { Button } from "../../Reusable/Button";
import { formatAPIErrors, numberWithCommas } from "../../../utils/general";
import Select from "react-select";
import { reactDropdownStyles, toFixedTrunc } from "../../../utils";
import { DropdownIndicator } from "../../SelectDropDown/selectDropDown";
import { compose } from "redux";
import { connect } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import { LoadingButton } from "../../Loader";
import { createCardTransactionRequest } from "../../../lib/api";
import { showErrorMsg } from "../../../utils/notification";
import ShowErrorMessage from "../../Reusable/ShowErrorMessage";
import { numberInputOnWheelPreventChange } from "../../../utils";
import { FormInput } from "../../../assets/StyleComponents/Input.style";
import { BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";
import { MasterCardIcon } from "../../../assets/svgs";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { Label } from "../../../assets/StyleComponents/FontSize/para.style";

const currencyOptions = [
  { label: "EUR", value: "EUR" }
  // { label: 'USD', value: 'USD' },
];

const FiatCardDeposit = ({
  amount,
  setAmount,
  coin,
  isDarkTheme,
  onCardPaymentCancel,
  cardDetails
}) => {
  const [selectedCurrency, setSelectedCurrency] = useState({ label: "EUR", value: "EUR" });
  const [amountData, setAmountData] = useState("");
  const [totalCharge, setTotalCharge] = useState("");
  const [loader, setLoader] = useState(false);
  const [amountErr, setAmountErr] = useState("");
  const location = useLocation();

  useEffect(() => {
    let processingFee = Number(
      BigNumber(amount).multipliedBy(cardDetails.processingFee).toFixed(2)
    );
    let total = Number(BigNumber(amount).plus(processingFee).toFixed(2));
    setAmountData([
      {
        label: "Subtotal",
        value: `${amount ? numberWithCommas(amount) : "-"} ${coin}`
      },
      {
        label: "Card processing fee",
        value: `${amount ? numberWithCommas(processingFee) : "-"} ${coin}`
      },
      // {
      //   label: 'Foreign exchange fee',
      //   value: `- ${coin}`,
      // },
      {
        label: "Total charge",
        value: `${amount ? numberWithCommas(total) : "-"} ${coin}`
      }
    ]);

    setTotalCharge(total);

    //FIXME: Please add the Error message or returned the value
    // eslint-disable-next-line no-empty
    if (amount === "" && amountErr === "") {
    } else if (amount < cardDetails.minAmount) {
      setAmountErr(
        `Minimum deposit amount is ${numberWithCommas(cardDetails.minAmount)} ${
          selectedCurrency.value
        }.`
      );
    } else if (amount > cardDetails.maxAmount) {
      setAmountErr(
        `Maximum deposit amount is ${numberWithCommas(cardDetails.maxAmount)} ${
          selectedCurrency.value
        }.`
      );
    } else {
      setAmountErr("");
    }
  }, [amount]);

  useEffect(() => {
    return () => {
      setLoader(false);
    };
  }, []);

  const onCheckout = () => {
    setLoader(true);
    let data = {
      amount: Number(amount),
      currency: selectedCurrency && selectedCurrency.value,
      theme: isDarkTheme ? "dark" : "light",
      deviceType: "web",
      redirectPath: location?.pathname || "/wallet/deposits"
    };
    createCardTransactionRequest(data)
      .then((res) => {
        const data = res?.data?.data;
        if (data?.redirect_full_page_url) {
          localStorage.setItem(data?.transaction_id, data?.decta_id);
          window.open(data?.redirect_full_page_url, "_self");
        } else {
          throw new Error();
        }
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
        setLoader(false);
      });
  };

  const amountChangeHandler = (e) => {
    let amt = e.target.value;
    if (amt) {
      if (amt >= cardDetails.maxAmount * 100) {
        return;
      } else {
        setAmount(BigNumber(toFixedTrunc(amt, 2)));
      }
    } else {
      setAmount("");
    }
  };

  return (
    <Fragment>
      <DivElement marginTop="30px" width="100%">
        <DivElement width="100%" marginBottom="30px" 
          relative
          // className={`fInput__form__field `} 
        >
          <Label>Amount to deposit</Label>
          <DivElement
            height="48px"
            fontSize="16px"
            fontSizeSm="14px"
            position="relative"
          >
            <FormInput
              type="number"
              placeholder="Enter Amount"
              value={amount}
              onChange={amountChangeHandler}
              onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
              onWheel={numberInputOnWheelPreventChange}
            />
            <BannerHeadingSpan txtGrey size="16px" position="absolute" top="24%" right="3%">
              {coin}
            </BannerHeadingSpan>
          </DivElement>
          {amountErr && (
            <ShowErrorMessage
              message={amountErr}
              containerProps={{ marginTop: "4px", absolute: true }}
            />
          )}
        </DivElement>

        <DivElement width="100%" marginBottom="24px">
          <DivElement displayBtw>
            <Label>Charge to your Credit/Debit card</Label>
            <DivElement flex>
              {/* <MaestroCardIcon widthSm="24px" /> */}
              <MasterCardIcon widthSm="24px" />
              {/* <VisaCardIcon widthSm="43px" /> */}
            </DivElement>
          </DivElement>
          <DivElement flex>
            <DivElement width="132px" marginRight="16px">
              <Select
                name="currency"
                components={{ DropdownIndicator }}
                isSearchable={true}
                placeholder={coin}
                onChange={setSelectedCurrency}
                styles={reactDropdownStyles({ isDarkTheme, borderBottom: true, height: "48px" })}
                options={currencyOptions}
                value={selectedCurrency}
                minMenuHeight={200}
                maxMenuHeight={200}
                menuPlacement="auto"
              />
            </DivElement>
            <DivElement
              displayBtw
              bgCreamInput
              radius="5px"
              width="100%"
              height="48px"
              padding="0 16px"
            >
              <TextWrapper secondary fontSize="16px">
                {amount ? numberWithCommas(totalCharge) : "-"}
              </TextWrapper>
              <TextWrapper secondary fontSize="16px">
                {coin}
              </TextWrapper>
            </DivElement>
          </DivElement>
        </DivElement>

        <DivElement
          border="1px solid"
          bdGrey
          radius="2px"
          maxWidth="454px"
          padding="18px 16px 32px 16px"
          paddingSm="16px 12px 32px 12px"
        >
          {amountData &&
            amountData.map((data, index) => (
              <DivElement
                key={index}
                displayBtw
                marginBottom={amountData.length - 1 === index ? "32px" : "20px"}
              >
                <TextWrapper secondary fontSize="14px" fontSizeSm="12px">
                  {data.label}
                </TextWrapper>
                <TextWrapper
                  fontSize="16px"
                  fontSizeSm="12px"
                  fontWeight={amountData.length - 1 === index ? "bold" : null}
                >
                  {data.value}
                </TextWrapper>
              </DivElement>
            ))}

          <DivElement displayBtw>
            <Button
              height="48px"
              fontSize="14px"
              padding="0 45px"
              paddingMd="0 51px"
              paddingSm="0 21px"
              secondaryBlue
              marginRight="16px"
              marginRightSm="12px"
              onClick={onCardPaymentCancel}
            >
              Cancel
            </Button>
            <Button
              height="48px"
              padding="0 16px"
              fontSize="14px"
              width="100%"
              primaryBlue
              onClick={onCheckout}
              disabled={loader || !amount || amountErr}
            >
              {loader ? <LoadingButton /> : "Enter card info next"}
            </Button>
          </DivElement>
        </DivElement>
      </DivElement>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme
});

export default compose(connect(mapStateToProps))(FiatCardDeposit);
