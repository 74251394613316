import React, { useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import DollarImageComponent from "../../../components/DollarImage/dollarImage";
import SharedNumberComponent from "../../../components/ReusedComponent/SharedNumberComponent";
import { WalletContext } from "../../../contexts/WalletPage/BalancePage/wallet";
import SkeletonLoader from "../../../components/SkeletonLoader";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { DoubleArrowDownIcon } from "../../../assets/svgs";
import { bool } from "prop-types";
import { createStructuredSelector } from "reselect";
import {
  getAccountTotalUSD,
  getExchangeTotalUSD,
  getisFetchingAccountWallet,
  getisFetchingExchangeWallet
} from "../../../Modules/Wallet/WalletBalance/selectors";
import { useSelector } from "react-redux";

const DashboardDataCard = (props) => {
  let { title, children, activePlan, sent, isTheme } = props;
  // let { totalBalance } = rowData || {};

  let { hideStatus } = useContext(WalletContext);
  const { width } = useWindowSize();
  const history = useHistory();
  const { isFetchingAccounts, isFetchingExchange, apiAccountTotalUSD, apiExchangeTotalUSD } =
    useSelector(mapStateToProps);
  const userData = useSelector((state) => state.dashboard.userData);
  const prevLang = localStorage.getItem("ljs-lang");

  const sendToWalletPage = () => {
    history.push(
      `${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/wallet/balances`,
      { state: sent }
    );
  };

  return useMemo(() => {
    return (
      <BannerBox
        borderStyle
        borderColor
        borderWidth="0.5px"
        position="relative"
        radius="5px"
        // padding="19px 16px 32px"
        padding="19px 0 0"
      >
        <BannerBox padding="0 16px" display="flex" justifyContent="space-between">
          <BannerHeading size="16px" sizeLg="14px" ml="16px" mlLg="0px">
            {" "}
            {title} balance{" "}
          </BannerHeading>

          <BannerBox display="flex" direction="column" alignItems="flex-end" mr="16px" mrLg="0px">
            {(isFetchingExchange || isFetchingAccounts) && (
              <>
                <SkeletonLoader width={width > 1024 ? "250px" : width > 768 ? "150px" : "100px"} />
                <SkeletonLoader width={width > 1024 ? "250px" : width > 768 ? "150px" : "100px"} />
              </>
            )}

            {!isFetchingExchange && !isFetchingAccounts && (
              <>
                <BannerHeading size="18px" sizeLg="16px">
                  {!hideStatus ? (
                    "*******"
                  ) : (
                    <DollarImageComponent
                      givenClass="hght-18 hght-lg-14"
                      volumeStatus="true"
                      topValue="-2px"
                      imgMarginRight="4px"
                      DollarAmount={
                        <SharedNumberComponent
                          prevValue={
                            sent === "account"
                              ? (apiAccountTotalUSD &&
                                  apiAccountTotalUSD?.inUSD &&
                                  apiAccountTotalUSD?.inUSD) ||
                                0
                              : (apiExchangeTotalUSD &&
                                  apiExchangeTotalUSD?.inUSD &&
                                  apiExchangeTotalUSD?.inUSD) ||
                                0
                          }
                          digit={7}
                          status={true}
                        />
                      }
                      {...props}
                    />
                  )}
                </BannerHeading>

                <BannerHeading txtGrey size="14px" sizeLg="12px" margin="0 0 0 6px">
                  {hideStatus && "≈ "}
                  <SharedNumberComponent
                    prevValue={
                      sent === "account"
                        ? (apiAccountTotalUSD &&
                            apiAccountTotalUSD?.inBTC &&
                            apiAccountTotalUSD?.inBTC) ||
                          0
                        : (apiExchangeTotalUSD &&
                            apiExchangeTotalUSD?.inBTC &&
                            apiExchangeTotalUSD?.inBTC) ||
                          0
                    }
                    digit={7}
                    status={true}
                  />{" "}
                  BTC
                </BannerHeading>
              </>
            )}
          </BannerBox>
        </BannerBox>

        <BannerBox
          CardDashboardSky
          // cursorPointer="pointer"
          position={"relative"}
          height={"250px"}
          heightLg={activePlan === "list" ? (width > 768 ? "230px" : "215px") : "270px"}
          width="100%"
          zIndex="1"
          padding="0 16px"
          paddingLg="0 12px 16px"
          paddingMd="0 8px 16px"
        >
          {activePlan === "list" && (
            <BannerBox
              CardWhite
              cursorPointer="pointer"
              position={"absolute"}
              top="0"
              left="0"
              height={"20px"}
              width="100%"
              zIndex="1"
              padding="0 16px"
            />
          )}
          {children}
        </BannerBox>

        <BannerBox
          width="100%"
          position="absolute"
          bottom="calc(0% - 25px)"
          height="50px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <BannerBox
            onClick={sendToWalletPage}
            position={"absolute"}
            bottom="0"
            zIndex="0"
            display="flex"
            justifyContent="center"
            alignItems="center"
            cursorPointer="pointer"
            height="50px"
          >
            <figure className="mb-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="65.666"
                height="47.553"
                viewBox="0 0 65.666 47.553"
              >
                <path
                  id="Path_5496"
                  data-name="Path 5496"
                  d="M32.833,0A53.452,53.452,0,0,1,45.6,1.5,39.106,39.106,
                  0,0,1,55.964,5.591a22.005,22.005,0,0,1,6.891,6.022,12.074,
                  12.074,0,0,1,2.4,7.309,20.725,20.725,0,0,1-2.724,8.862,40.092,
                  40.092,0,0,1-7.051,9.377,38.734,38.734,0,0,1-10.244,7.393,28.3,
                  28.3,0,0,1-12.406,3,28.3,28.3,0,0,1-12.407-3,38.738,38.738,0,0,
                  1-10.244-7.393,40.1,40.1,0,0,1-7.051-9.377A20.726,20.726,0,0,1,.408,
                  18.923a12.073,12.073,0,0,1,2.4-7.309A22,22,0,0,1,9.7,5.591,39.1,39.1,
                  0,0,1,20.066,1.5,53.45,53.45,0,0,1,32.833,0Z"
                  fill={!isTheme ? "#f5faff" : "#151e26"}
                  stroke={!isTheme ? "#CCCED0" : "#38393D"}
                  strokeWidth="1px"
                />
              </svg>
            </figure>
            <DoubleArrowDownIcon absolute bottom="12px" />
          </BannerBox>
        </BannerBox>
      </BannerBox>
    );
  }, [
    apiAccountTotalUSD,
    apiExchangeTotalUSD,
    hideStatus,
    activePlan,
    children,
    isFetchingAccounts,
    isFetchingExchange,
    isTheme
  ]);
};

DashboardDataCard.propTypes = {
  isFetchingAccounts: bool.isRequired,
  isFetchingExchange: bool.isRequired
};

const mapStateToProps = createStructuredSelector({
  isFetchingAccounts: getisFetchingAccountWallet(),
  isFetchingExchange: getisFetchingExchangeWallet(),
  apiAccountTotalUSD: getAccountTotalUSD(),
  apiExchangeTotalUSD: getExchangeTotalUSD()
});

export default DashboardDataCard;
