import React, { useContext } from "react";
import { BannerBox, BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { WalletContext } from "../../../contexts/WalletPage/BalancePage/wallet";
import DollarImageComponent from "../../../components/DollarImage/dollarImage";
import SharedNumberComponent from "../../../components/ReusedComponent/SharedNumberComponent";
import { add } from "../../../utils/bigNumber";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import SkeletonLoader from "../../../components/SkeletonLoader";
import { formatNumbers } from "../../../utils/helpers";
import { bool } from "prop-types";
import { createStructuredSelector } from "reselect";
import {
  getAccountTotalUSD,
  getExchangeTotalUSD,
  getisFetchingAccountWallet,
  getisFetchingExchangeWallet
} from "../../../Modules/Wallet/WalletBalance/selectors";
import { useSelector } from "react-redux";
import { HeadingSix } from "../../../assets/StyleComponents/FontSize/HeadingSix.style";

const TotalBalance = (props) => {
  let { isLoaderWallet, isLoaderExch } = props;
  const { width } = useWindowSize();

  let {
    hideStatus
    // exchWallet,
    // mainBalance
  } = useContext(WalletContext);

  const { isFetchingAccounts, isFetchingExchange, apiAccountTotalUSD, apiExchangeTotalUSD } =
    useSelector(mapStateToProps);

  return (
    <BannerBox
      gridTemplateColums
      gridColumn="minmax(58%, 59%) 1fr"
      gridColumnMd="minmax(50%, 1fr) 1fr"
      alignItems="center"
      borderStyle
      borderColor
      borderWidth="0.5px"
      radius={"5px"}
      padding="13px 15px 11px 24px"
      paddingLg="17px 20px"
      paddingMd="16px 8px"
    >
      {width > 1024 && (
        <>
          <BannerHeading size="20px">Total balance</BannerHeading>

          {(isFetchingAccounts || isFetchingExchange) && <SkeletonLoader />}

          {!isFetchingAccounts && !isFetchingExchange && (
            <BannerBox display="flex" alignItems="center" size="24px" mb="0">
              <BannerHeading size="24px" weight="700" textAlign="end">
                <DollarImageComponent
                  givenClass="hght-22 hght-lg-18 hght-sm-18"
                  userHideStatus={true}
                  topValue="-2px"
                  imgMarginRight="4px"
                  boldStatus="true"
                  DollarAmount={
                    // <SharedNumberComponent
                    //   prevValue={((mainBalance && mainBalance?.totalBalance) || (exchWallet && exchWallet?.totalBalance)) &&
                    //     formatNumbers(add(Number(mainBalance?.totalBalance?.inUSD) || 0, Number(exchWallet?.totalBalance?.inUSD) || 0))
                    //   }
                    //   digit={7}
                    //   status={true}
                    // />
                    <SharedNumberComponent
                      prevValue={formatNumbers(
                        add(
                          Number(apiAccountTotalUSD?.inUSD) || 0,
                          Number(apiExchangeTotalUSD?.inUSD) || 0
                        )
                      )}
                      digit={7}
                      status={true}
                    />
                  }
                  {...props}
                />
              </BannerHeading>

              <HeadingSix size="16px" margin="0 0 0 6px" weight={300}>
                ( {hideStatus && "≈ "}
                <SharedNumberComponent
                  prevValue={formatNumbers(
                    add(apiAccountTotalUSD?.inBTC || 0, apiExchangeTotalUSD?.inBTC || 0),
                    2
                  )}
                  digit={7}
                  status={true}
                />{" "}
                BTC )
              </HeadingSix>
            </BannerBox>
          )}
        </>
      )}

      {width < 1025 && (
        <>
          <BannerBox display="flex" direction="column">
            <BannerHeading size="18px" sizeMd="16px">
              {" "}
              Total{" "}
            </BannerHeading>
            <BannerHeading size="18px" sizeMd="16px">
              {" "}
              balance{" "}
            </BannerHeading>
          </BannerBox>

          {(isLoaderExch || isLoaderWallet) && <SkeletonLoader />}

          {!isLoaderExch && !isLoaderWallet && (
            <BannerBox
              display="flex"
              direction="column"
              className="align-items-end"
              size="24px"
              mb="0"
            >
              <BannerHeading weight="bold" size="24px" sizeMd="17px" textAlign="right">
                <DollarImageComponent
                  // givenClass="hght-22 hght-md-18"
                  userHideStatus={true}
                  boldStatus={true}
                  topValue="-2px"
                  imgMarginRight="4px"
                  DollarAmount={
                    <SharedNumberComponent
                      prevValue={formatNumbers(
                        add(
                          Number(apiAccountTotalUSD?.inUSD) || 0,
                          Number(apiExchangeTotalUSD?.inUSD) || 0
                        )
                      )}
                      digit={7}
                      status={true}
                    />
                  }
                  {...props}
                />
              </BannerHeading>

              <BannerHeadingSpan
                textAlign="right"
                textAlignSm="right"
                size="16px"
                sizeLg="16px"
                sizeMd="12px"
                margin="0 0 0 6px"
                marginMd="0 0 0 0px"
                weight={300}
                txtGrey
              >
                {hideStatus && "≈ "}
                <SharedNumberComponent
                  prevValue={formatNumbers(
                    add(apiAccountTotalUSD?.inBTC || 0, apiExchangeTotalUSD?.inBTC || 0),
                    2
                  )}
                  digit={7}
                  status={true}
                />{" "}
                BTC
              </BannerHeadingSpan>
            </BannerBox>
          )}
        </>
      )}
    </BannerBox>
  );
};

TotalBalance.propTypes = {
  isFetchingAccounts: bool.isRequired,
  isFetchingExchange: bool.isRequired
};

const mapStateToProps = createStructuredSelector({
  isFetchingAccounts: getisFetchingAccountWallet(),
  isFetchingExchange: getisFetchingExchangeWallet(),
  apiAccountTotalUSD: getAccountTotalUSD(),
  apiExchangeTotalUSD: getExchangeTotalUSD()
});

export default TotalBalance;
