import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";

const NavLinkComponent = styled(Link)`
  color: ${(props) => props.theme.subText};
  padding: 0 12px;
  font-weight: 500;
  font-size: 14px;
  height: 100%;
  display: flex;
  align-items: center;
  border-bottom: 2px solid transparent;

  :hover {
    color: ${(props) => props.theme.primaryBlue};
    border-bottom: 2px solid ${(props) => props.theme.primaryBlue};
  }

  ${(props) =>
    props.active &&
    css`
      color: ${(props) => props.theme.primaryBlue};
      border-bottom: 2px solid ${(props) => props.theme.primaryBlue};
    `}
`;

const NavLink = ({ to, label, activeLink }) => {
  const location = useLocation();

  return (
    <NavLinkComponent
      to={to}
      active={
        label === "Trade"
          ? location.pathname.includes(to) && !location.pathname.includes("/trade-history")
            ? true
            : null
          : location.pathname.includes(`/${activeLink?.toLowerCase()}`)
          ? true
          : null
      }
    >
      {label}
    </NavLinkComponent>
  );
};

export default NavLink;
