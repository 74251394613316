import React, { Fragment, useEffect, useState } from "react";
import {
  ModalWrapper,
  DivElement,
  TextWrapper,
  Button,
  ToolTip,
  SpanElement
} from "../../../../../components/Reusable";
import AmountInput from "../../Components/AmountInput";
import { compose } from "redux";
import { connect } from "react-redux";
import { getBondDetails, modifyBondInvestment } from "../../../../../lib/api";
import moment from "moment";
import { LoadingButton } from "../../../../../components/Loader";
import { formatAPIErrors, numberWithCommas } from "../../../../../utils/general";
import { TAndC } from "../../../../../pages/Auth/Earn/Components";
import { getBondInvestHistory } from "../../../../../actions/earn";
import ModifyLoader from "./ModifyLoader";
import BigNumber from "bignumber.js";
import { showErrorMsg, showSuccessMsg } from "../../../../../utils/notification";
import { CloseIcon } from "../../../../../assets/svgs";

const ModifyModal = ({ onClose, modifyData, getBondInvestHistory }) => {
  const [checked, setChecked] = useState(false);
  const [amount, setAmount] = useState(1);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [bondDetails, setBondDetails] = useState("");
  const [avlBal, setAvlBal] = useState(0);

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (modifyData && bondDetails) {
      let invested = modifyData.investedAmount || 0;
      let cancelled = bondDetails.totalCancelledAmount || 0;
      setAvlBal(BigNumber(invested).minus(cancelled));
    }
  }, [modifyData, bondDetails]);

  useEffect(() => {
    let invested = BigNumber(modifyData.investedAmount || 0);
    let cancelled = BigNumber(bondDetails.totalCancelledAmount || 0);
    let val = BigNumber(amount).multipliedBy(bondDetails.denomination);
    let amt = invested.minus(cancelled).minus(val);
    let remainingAmount = amount ? (amt < 0 ? 0 : amt) : invested.minus(cancelled);

    setData([
      {
        label: "Cancellation Amount",
        value:
          amount && bondDetails
            ? `${numberWithCommas(BigNumber(amount).multipliedBy(bondDetails.denomination))} ${
                bondDetails.bondCoin
              }`
            : "0"
      },
      {
        label: "Amount Release Date",
        value: bondDetails
          ? moment(bondDetails.firstCancelledDate).format("DD/MM/YYYY, HH:mm")
          : " -- ",
        tip: bondDetails?.next
          ? "Cancellations in the 1st month of this quarter: Released on the first day of the next quarter."
          : "Cancellations in the 2nd or 3rd month of this quarter: Held until the first day of the next to next quarter."
      },
      {
        label: "Remaining invested amount",
        value: remainingAmount ? numberWithCommas(remainingAmount) : 0
      },
      {
        label: `Remaining ${bondDetails ? bondDetails.bondName : ""} amount`,
        value:
          remainingAmount && bondDetails
            ? `${numberWithCommas(BigNumber(remainingAmount).div(bondDetails.denomination))} ${
                bondDetails.bondName
              }`
            : 0
      }
    ]);
  }, [modifyData, amount, bondDetails]);

  const getDetails = () => {
    let data = {
      bondId: modifyData.bondId
    };
    getBondDetails(data)
      .then((res) => {
        setBondDetails(res && res.data.data);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      });
  };

  const onModifyBond = () => {
    setLoader(true);
    let data = {
      bondId: modifyData.bondId,
      amount: BigNumber(amount).multipliedBy(bondDetails.denomination).toNumber()
    };
    modifyBondInvestment(data)
      .then((res) => {
        showSuccessMsg(res && res.data.message);
        getBondInvestHistory({ page: 0 });
        onClose();
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => {
        setLoader(false);
      });
  };

  let isLoading = !modifyData || !bondDetails;

  return (
    <ModalWrapper>
      <DivElement bgPopup padding="0" borderRadius="10px" width="450px" widthSm="328px">
        <DivElement bgCream displayBtw height="54px" padding="0 24px" borderRadius="10px 10px 0 0">
          <TextWrapper fontSize="20px" lineHeight="1.2">
            Cancel
          </TextWrapper>
          <CloseIcon onClick={onClose} />
        </DivElement>
        <DivElement padding="20px 12px 32px 12px">
          {!isLoading ? (
            <Fragment>
              <DivElement padding="0 12px">
                <AmountInput
                  amount={amount}
                  setAmount={setAmount}
                  name="modifyAmount"
                  balance={avlBal}
                  bondDetails={bondDetails}
                />
              </DivElement>

              <DivElement bgCream padding="12px 16px" marginTop="32px">
                {data &&
                  data.map((item, i) => (
                    <DivElement
                      key={i}
                      displayBtw
                      marginBottom={i === data.length - 1 ? "0" : "12px"}
                      height="16px"
                      heightSm="14px"
                    >
                      <TextWrapper
                        secondary
                        fontSize="14px"
                        fontSizeSm="12px"
                        lineHeight="1.71"
                        lineHeightSm="2"
                      >
                        {item.tip ? (
                          <ToolTip
                            title={item.label}
                            tipContent={item.tip}
                            titleProps={{
                              secondary: true,
                              fontSize: "14px",
                              fontSizeSm: "12px",
                              lineHeight: "1.71",
                              lineHeightSm: "2"
                            }}
                            imgProps={{
                              absolute: null,
                              height: "20px",
                              width: "20px",
                              marginLeft: "6px"
                            }}
                          />
                        ) : (
                          item.label
                        )}
                      </TextWrapper>
                      <TextWrapper
                        fontSize="14px"
                        fontSizeSm="12px"
                        lineHeight="1.71"
                        lineHeightSm="2"
                        green={i < 2 ? true : null}
                      >
                        {item.value}
                      </TextWrapper>
                    </DivElement>
                  ))}
              </DivElement>

              <DivElement
                bgCream
                padding="12px 20px"
                paddingMd="12px 16px"
                marginTop="12px"
                borderRadius="5px"
              >
                <TextWrapper
                  fontSize="14px"
                  fontSizeSm="12px"
                  lineHeight="20px"
                  lineHeightSm="18px"
                >
                  <SpanElement red fontSize="inherit" lineHeight="inherit">
                    Interest Amount
                  </SpanElement>{" "}
                  till {moment(bondDetails.firstCancelledDate).format("DD/MM/YYYY")} will be
                  calculated on original invested amount ({numberWithCommas(avlBal)}{" "}
                  {bondDetails.bondCoin})
                </TextWrapper>
              </DivElement>

              <TAndC setChecked={setChecked} checked={checked} />
              <DivElement width="100%" padding="0 14px" paddingSm="0 8px">
                <Button
                  primaryBlue
                  primaryGrey={
                    !checked || !amount || amount * bondDetails.denomination > avlBal ? true : null
                  }
                  disabled={
                    !checked || !amount || amount * bondDetails.denomination > avlBal || loader
                  }
                  height="48px"
                  width="100%"
                  onClick={onModifyBond}
                >
                  {loader ? <LoadingButton /> : "Confirm Cancellation"}
                </Button>
              </DivElement>
            </Fragment>
          ) : (
            <ModifyLoader />
          )}
        </DivElement>
      </DivElement>
    </ModalWrapper>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getBondInvestHistory: (data) => dispatch(getBondInvestHistory(data))
});

export default compose(connect(null, mapDispatchToProps))(ModifyModal);
