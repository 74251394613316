/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { DivElement } from "../../../components/Reusable";
import TickerHeader from "./Components/TickerHeader";
import TradePageMobileLayout from "./TradePageMobileLayout";
import TradePageWebLayout from "./TradePageWebLayout";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TradePageFooter from "./Components/TradePageFooter";
import { updateSelectedPair } from "../../../actions/trading";
import SetTradeTitleComponent from "../../../components/ChangeHeaderTitle/tradePageTitle";

const TradePage = () => {
  const { width } = useWindowSize();
  const isDesktop = width > 1024 ? true : false;
  const params = useParams();
  const history = useHistory();
  const selectedPair = useSelector((state) => state.trading.selectedPair);
  // const userData = useSelector((state) => state.dashboard.userData);
  // const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();
  const prevLang = localStorage.getItem("ljs-lang");

    useEffect(() => {
      let pair = params.pair;
      if (pair) {
        let pairData = pair.split("-");
        if (pair.includes("-")) pairData = pair.split("-");
        else if (pair.includes("_")) pairData = pair.split("_");

        if (pairData && pairData.length === 2) {
          dispatch(updateSelectedPair(pairData.join("/")));
        } else {
          history.replace(`${prevLang ? `/${prevLang}` : "/en"}/trade/${selectedPair.base}-${selectedPair.quote}`);
        }
      }
    }, []);

  useEffect(() => {
    let pair = params.pair;
    if (pair) {
      let currPair = pair.split("-").join("/");
      if (pair.includes("-")) currPair = pair.split("-").join("/");
      else if (pair.includes("_")) currPair = pair.split("_").join("/");

      if (currPair !== selectedPair.symbol) {
        history.push(`${prevLang ? `/${prevLang}` : "/en"}/trade/${selectedPair.base}-${selectedPair.quote}`);
      }
    } else {
      history.push(`${prevLang ? `/${prevLang}` : "/en"}/trade/${selectedPair.base}-${selectedPair.quote}`);
    }
  }, [selectedPair]);

  const selectedPairTicker = useSelector((state) => state.trading.selectedPairTicker);

  return (
    <Fragment>
      <SetTradeTitleComponent
        base_pair={selectedPair.base || "LCX"}
        quote_pair={selectedPair.quote || "EUR"}
        lastPrice={selectedPairTicker.lastPrice || 0}
      />
      <DivElement pageContainer={isDesktop ? null : true} paddingBottom="37px">
        <TickerHeader />
        {isDesktop ? <TradePageWebLayout /> : <TradePageMobileLayout />}
      </DivElement>

      <TradePageFooter />
    </Fragment>
  );
};

export default TradePage;
