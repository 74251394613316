import { numberWithCommas } from "../../../../../utils/general";
import {
  DivElement,
  TextWrapper,
  ModalWrapper,
  LinkButton,
  Animation
} from "../../../../../components/Reusable";
import BigNumber from "bignumber.js";
import { CloseIcon } from "../../../../../assets/svgs";

const Step3 = ({ onClose, amount, bondDetails }) => {
  amount = amount ? amount.toNumber() : 0;
  return (
    <ModalWrapper>
      <DivElement
        bgPopup
        padding="0"
        borderRadius="10px"
        width="450px"
        widthMd="408px"
        widthSm="328px"
      >
        <DivElement bgCream displayBtw height="54px" padding="0 24px" borderRadius="10px 10px 0 0">
          <TextWrapper fontSize="20px" lineHeight="1.2">
            Success
          </TextWrapper>
          <CloseIcon onClick={onClose} />
        </DivElement>
        <DivElement padding="30px 24px 32px 24px">
          <DivElement displayCenter height="196px" width="100%">
            <DivElement
              height="248px"
              heightMd="240px"
              heightSm="152px"
              width="248px"
              widthMd="248px"
              widthSm="152px"
            >
              <Animation type="SUCCESS" />
            </DivElement>
          </DivElement>
          <TextWrapper
            fontSize="16px"
            fontSizeMd="14px"
            fontSizeSm="12px"
            lineHeight="1.5"
            textAlign="center"
          >
            Investment of{" "}
            {numberWithCommas(
              BigNumber(amount).multipliedBy(bondDetails ? bondDetails.denomination : 0)
            )}{" "}
            {bondDetails.bondCoin || ""} ( = {amount} {bondDetails.bondName || ""}) is successful
          </TextWrapper>
          <DivElement padding="24px 14px 0 14px">
            <LinkButton to="/earn" secondaryBlue width="100%" height="53px" nowrap>
              Check Investment History
            </LinkButton>
          </DivElement>
        </DivElement>
      </DivElement>
    </ModalWrapper>
  );
};

export default Step3;
