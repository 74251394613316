import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import { Formik, Form } from "formik";
import {
  DropdownInput,
  TextInput,
  CountriesDropdown,
  DropdownWithSpecifyOtherInput
} from "../../../Reusable/FormInput";
import * as Yup from "yup";
import { Persist } from "formik-persist";
import { useRef } from "react";
import { useEffect } from "react";
import { getUserDetails } from "../../../../actions/dashboard";
import KycStepButton from "./KycStepBtn";
import { EMPLOYEMENT_STATUS, NATURE_OF_BUSINESS } from "../../../../constants/kycOptions";
import { formatCountriesArray } from "../../../../utils";
import { BannerHeading } from "../../../../assets/StyleComponents/FontSize/Heading.style";
import { BannerHeadingSpan } from "../../../../assets/StyleComponents/Exchange.style";
import { showErrorMsg, showSuccessMsg } from "../../../../utils/notification";
import { completeProfileDataLevel3Step1 } from "../../../../lib/api";
import { formatAPIErrors } from "../../../../utils/general";
import { KycSection } from "../../../../assets/StyleComponents/kyc.style";

const kycStep1aValidationSchema = Yup.object().shape({
  employerName: Yup.string()
    .trim()
    .min(1)
    // .max(32, "First Name must be at most 32 characters")
    // eslint-disable-next-line
    .matches(/^(?![0-9]*$)([ \u00C0-\u01FFa-zA-Z0-9'\-])+$/, "Please Enter a valid Employer Name.")
    .required("Employer Name is required."),

  natureOfBusiness: Yup.string()
    .trim()
    .required("Nature of business is required")
    .test("is-other", "Nature of business is required", (value) => {
      return !(value === "Other -");
    }),
  addressLine1: Yup.string().trim().required("Street address is required"),
  addressLine2: Yup.string().trim(),
  city: Yup.string().trim().required("City is required"),
  state: Yup.string().trim().required("State/Region is required"),
  zipCode: Yup.string().trim().required("Zipcode is required"),
  country: Yup.string().trim().required("Country of residence is required")
});

const Step1a = ({ increaseStep, userData, countriesList, isFetchingCountries }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    formikRef.current?.setTouched({}, false);
    // eslint-disable-next-line
  }, []);

  const submitDataKyc = () => {
    let s1Data = JSON.parse(localStorage.getItem("lastLevelProfileData1")).values;

    setLoader(true);
    setDisabled(true);
    let data = {
      employmentStatus: s1Data.employmentStatus,
      employerName: s1Data.employerName,
      natureOfBusiness: s1Data.natureOfBusiness,
      addressLine1: s1Data.addressLine1,
      addressLine2: s1Data.addressLine2,
      city: s1Data.city,
      state: s1Data.state,
      zipCode: s1Data.zipCode,
      country: s1Data.country
    };

    completeProfileDataLevel3Step1(data)
      .then((res) => {
        dispatch(getUserDetails());
        showSuccessMsg(res.data.message);
        localStorage.removeItem("kycStepLastLevel");
        localStorage.removeItem("lastLevelProfileData1");
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => {
        setLoader(false);
        setDisabled(false);
      });
  };

  const getInitialValues = () => {
    return {
      employmentStatus: userData.employmentStatus || "",
      employerName: userData.employerName || "",
      natureOfBusiness: userData.natureOfBusiness || "",
      addressLine1: userData.addressLine1 || "",
      addressLine2: userData.addressLine2 || "",
      city: userData.city || "",
      state: userData.state || "",
      zipCode: userData.zipCode || "",
      country: userData.country || ""
    };
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={getInitialValues()}
      onSubmit={submitDataKyc}
      validateOnMount={true}
      validationSchema={kycStep1aValidationSchema}
    >
      {({ setFieldValue, values, handleBlur }) => (
        <Form style={{ width: "100%" }}>
          <KycSection className="kycS1__form__container">
            <DropdownInput
              title="Employment status"
              name="employmentStatus"
              placeholder="Select an option"
              value={values.employmentStatus}
              setFieldValue={setFieldValue}
              options={EMPLOYEMENT_STATUS}
            />

            <BannerHeading
              borderBottom
              width="100%"
              lineHeight="0.1em"
              textAlign="center"
              mb="12px"
              mbMd="20px"
            >
              <BannerHeadingSpan txtGrey CardLevel1 padding="0 10px" weight="400">
                {" "}
                EMPLOYER DETAILS{" "}
              </BannerHeadingSpan>
            </BannerHeading>

            <TextInput
              containerClass="w-100"
              title={
                values?.employmentStatus === "Employed"
                  ? "Full name of employer"
                  : "Name of your business"
              }
              placeholder={
                values?.employmentStatus === "Employed" ? "Employer name" : "Business name"
              }
              name="employerName"
              value={values.employerName}
            />

            <DropdownWithSpecifyOtherInput
              containerClass="100%"
              title="Nature of business"
              isOtherTitle="Nature of business"
              isPlaceHolderTitle="Enter the business"
              name="natureOfBusiness"
              placeholder="Select an option"
              value={values.natureOfBusiness}
              setFieldValue={setFieldValue}
              options={NATURE_OF_BUSINESS}
            />

            <TextInput
              title={
                values.employmentStatus === "Employed" ? "Employer address" : "Business address"
              }
              name="addressLine1"
              placeholder={"Enter street / locality / area"}
              value={values.addressLine1}
              givenMargin={"32px 0 0 0"}
            />

            <TextInput
              title="Unit"
              name="addressLine2"
              value={values.addressLine2}
              placeholder="Plot number"
            />

            <TextInput title="City" name="city" value={values.city} placeholder="Enter city" />

            <TextInput
              title="State/Region"
              name="state"
              value={values.state}
              placeholder="Enter state"
            />

            <TextInput
              title="Zip code"
              name="zipCode"
              value={values.zipCode}
              placeholder="Enter zip code"
            />

            <CountriesDropdown
              title="Country of residency"
              name="country"
              value={values.country}
              setFieldValue={setFieldValue}
              disabled={isFetchingCountries || !countriesList}
              countryList={countriesList && formatCountriesArray(countriesList.Country)}
              placeholder="Select country"
            />

            <Persist name="lastLevelProfileData1" />
          </KycSection>
          <KycStepButton loader={loader} label="Continue" disabled={disabled} />
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => ({
  userData: state.dashboard.getUserData,
  countriesList: state.kyc.countriesList,
  isFetchingCountries: state.kyc.isFetchingCountries
});

export default compose(connect(mapStateToProps))(Step1a);
