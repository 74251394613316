import React, { createContext, useState, useEffect } from "react";
import { getPromotionUpdate } from "../../lib/api";

export const PromotionContext = createContext({});
function PromotionContextContainer({ children }) {
  const [activePromotion, setActivePermotion] = useState({});

  useEffect(() => {
    getPromotionUpdate()
      .then((res) => {
        const allPromotion = res?.data?.data;
        const active = allPromotion.filter((values) => values.isActive === true);
        setActivePermotion(active);
      })
      .catch((err) => {
        return;
      });
  }, []);

  return <PromotionContext.Provider value={activePromotion}>{children}</PromotionContext.Provider>;
}

export default PromotionContextContainer;
