import { DivElement, TextWrapper, ImgElement } from "../components/Reusable";
import { styleConstant } from "../constants/style";
import { IMAGE_PREFIX, IMAGE_PREFIX_DARK } from "../constants/Images/imageIndex";
import { noExponents, numberWithCommas } from "./general";
import { WireTransferIcon } from "../assets/svgs";
import config from "../constants/config";
import { BannerHeadingSpan } from "../assets/StyleComponents/Exchange.style";

export const numberFormatter = (number, minFractionDigits) => {
  if (number === null || number === undefined) return "";
  const parts = noExponents(number).split(".");
  const integerPart = parts[0];
  let decimalPart = parts[1] || "";

  if (minFractionDigits && decimalPart.length < minFractionDigits) {
    decimalPart += "0".repeat(minFractionDigits - decimalPart.length);
  }

  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
};

export const setPrecision = (number, precision) => {
  let divisior = Math.pow(10, precision);
  let floorNum = Math.floor(number * divisior);
  let res = floorNum / divisior;

  return res;
};

export const capitalize = (phrase) => {
  return phrase
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const toFixedTrunc = (x, n) => {
  const v = (typeof x === "string" ? x : x.toString()).split(".");
  if (n <= 0) return v[0];
  let f = v[1] || "";
  if (f.length > n) return `${v[0]}.${f.substr(0, n)}`;
  while (f.length < n) f += "0";
  return `${v[0]}.${f}`;
};

export const reactDropdownStyles = ({
  isDarkTheme,
  borderBottom,
  width,
  height,
  disabled = false,
  valueContainerProps,
  placeholderProps,
  menuProps,
  showActiveState = false,
  containerProps,
  isTokenSale = false,
  DobHeaderStatus = false,
  dropdownIndicatorProps,
  optionProps,
  ScreenWidth
}) => {
  const style = styleConstant(isDarkTheme);
  return {
    control: (base, { isFocused }) => ({
      ...base,
      width: width ? width : "100%",
      height: height ? height : "44px",
      display: "flex",
      fontSize: "14px",
      fontWeight: 500,
      border: isTokenSale ? `1px solid ${style.sep_color}` : "0 !important",
      borderRadius: "5px 5px 0 0",
      boxShadow: "none !important",
      background: isTokenSale
        ? style.popups_bg
        : showActiveState
        ? style.inputActive
        : style.input_field,
      borderBottom: borderBottom
        ? `1px solid ${
            isFocused || showActiveState ? style.primary_blue : style.tapable_input_field
          } !important`
        : "",
      ...containerProps
    }),
    placeholder: (base) => ({
      ...base,
      color: isDarkTheme ? "#A7A9AB" : "#606F80",
      fontSize: ScreenWidth < 768 ? "12px" : DobHeaderStatus ? "16px" : "14px",
      fontWeight: 300,
      margin: "0",
      ...placeholderProps
    }),
    valueContainer: (provided) => ({
      ...provided,
      width: "90%",
      minHeight: "36px",
      padding: "0 0 0 12px",
      display: "flex !important",
      flexWrap: "nowrap",
      ...valueContainerProps
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      width: "10%",
      minHeight: "1px",
      background: "transparent !important"
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      paddingRight: "12px",
      ...dropdownIndicatorProps
    }),
    menu: (base) => ({
      ...base,
      background: style.popups_bg,
      border: `0.5px solid ${style.tapable_input_field}`,
      borderRadius: "5px",
      padding: "5px 0",
      zIndex: "2",
      ...menuProps
    }),
    singleValue: (provided, { data }) => {
      return {
        ...provided,
        color: disabled ? style.sub_text : style.primary_text,
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "none"
      };
    },
    option: (base) => ({
      ...base,
      background: style.popups_bg,
      color: style.primary_text,
      borderRadius: "5px",

      "&:hover": {
        borderRadius: "5px 0 0 5px",
        background: `${style.dropdown_option_bg} !important`,
        borderRight: `4px solid ${style.primary_blue}`
      },
      ...optionProps
    }),
    input: () => ({
      color: style.sub_text,
      "&:focus": {
        color: !isDarkTheme ? "#012243 !important" : "#f5f6fa !important",
        textDecoration: "none!important",
        background: "transparent !important"
      }
    }),
    multiValue: (styles, { data }) => {
      const bg = !isDarkTheme ? "#FFFFFF !important" : "#151e26 !important";
      return {
        ...styles,
        backgroundColor: bg
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ":hover": {
        cursor: "pointer",
        backgroundColor: data.color,
        color: data.color
      }
    })
  };
};

export const selectProviderCustomOptions = (props) => {
  let { label, isDarkTheme, id, supports: main } = props;
  let str = label?.toLowerCase();
  let supports = id?.supports || main || "";
  return (
    <DivElement dAlignCenter key={label}>
      {str === "wire transfer" ? (
        <WireTransferIcon width="20px" height="auto" marginRight="10px" />
      ) : (
        <ImgElement
          width="20px"
          height="auto"
          marginRight="10px"
          src={
            str === "credit/debit card"
              ? isDarkTheme
                ? IMAGE_PREFIX_DARK.FiatCardIconDark
                : IMAGE_PREFIX.FiatCardIcon
              : str === "monerium"
              ? IMAGE_PREFIX.MoneriumBankLogo
              : `${isDarkTheme ? config.darkNetworkUrl : config.lightNetworkUrl}${label}.png`
          }
        />
      )}
      <TextWrapper>
        {str === "wire transfer" ? "Wire Transfer" : label}
        <BannerHeadingSpan size="11px" margin="0px 8px">
          {supports && supports}
        </BannerHeadingSpan>
      </TextWrapper>
    </DivElement>
  );
};

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const formatDropdownOptions = (arr) => {
  const newArr = arr.map((val) => {
    return { label: val, value: val };
  });

  return newArr;
};

export const formatCountriesArray = (countries) => {
  let arr = countries?.map((data) => {
    return {
      id: data.code,
      label: data.name,
      value: data.code,
      icon: data.flagSvg4x3Link
    };
  });

  return arr;
};

export const getGenderLabel = (value) => {
  if (value === "M") return "Male";
  else if (value === "F") return "Female";
  else return value;
};

export const getIdTypeLabel = (value) => {
  if (value === "PASSPORT") return "Passport";
  else if (value === "NATIONALID") return "National ID";
  else return value;
};

export const getIdValueFromLabel = (value) => {
  if (value === "Passport") return "PASSPORT";
  else if (value === "National ID") return "NATIONALID";
  else return value;
};

export const addZeroes = (num) => {
  num = String(+num);
  const dec = num.split(".")[1];
  const len = dec && dec.length > 2 ? dec.length : 2;
  return Number(num).toFixed(len);
};

export const setDecimal = (num, decimals = 8) => {
  var re = new RegExp("^-?\\d+(?:.\\d{0," + (decimals || -1) + "})?");
  return numberWithCommas(Number(num.toString().match(re)[0]));
};

export const checkEmailPattern = (givenValue) => {
  if (givenValue) {
    const emailPattern = new RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$");
    return emailPattern.test(givenValue);
  } else return false;
};

export const padTo2Digits = (num) => {
  return num.toString().padStart(2, "0");
};

export const convertMsToTime = (duration) => {
  let seconds = duration;
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);
  let months = Math.floor(days / 30);
  let years = Math.floor(months / 12);

  seconds = seconds % 60;
  minutes = minutes % 60;
  hours = hours % 24;
  days = days % 30;
  months = months % 12;

  let date = "";

  if (years > 0) {
    let y = "";
    if (years > 1) y = "s";
    date += `${padTo2Digits(years)} year${y} `;
  }

  if (months > 0) {
    let mnth = "";
    if (months > 1) mnth = "s";
    date += `${padTo2Digits(months)} month${mnth} `;
  }

  if (days > 0 && (years <= 0 || months <= 0)) {
    let d = "";
    if (days > 1) d = "s";
    date += `${padTo2Digits(days)} day${d} `;
  }

  if (hours > 0 && (months <= 0 || days <= 0)) {
    let h = "";
    if (hours > 1) {
      h = "s";
    }
    date += `${padTo2Digits(hours)} hour${h} `;
  }

  if (minutes > 0 && (days <= 0 || hours <= 0)) {
    let m = "";
    if (minutes > 1) {
      m = "s";
    }
    date += `${padTo2Digits(minutes)} min${m} `;
  }

  if (seconds > 0 && (hours <= 0 || minutes <= 0)) {
    date += `${padTo2Digits(seconds)} sec `;
  }
  return date;
};

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const numberExponentToLarge = (numIn) => {
  numIn += ""; // To cater to numric entries
  var sign = ""; // To remember the number sign
  // FIXME Need to be resolved
  // let a = numIn.charAt(0) == "-" && ((numIn = numIn.substring(1)), (sign = "-")); // remove - sign & remember it
  var str = numIn.split(/[eE]/g); // Split numberic string at e or E
  if (str.length < 2) return sign + numIn; // Not an Exponent Number? Exit with orginal Num back
  var power = str[1]; // Get Exponent (Power) (could be + or -)
  if (power == 0 || power < 0) return sign + str[0]; // If 0 exponents (i.e. 0|-0|+0) then That's any easy one

  var deciSp = (1.1).toLocaleString().substring(1, 2); // Get Deciaml Separator
  str = str[0].split(deciSp); // Split the Base Number into LH and RH at the decimal point
  var baseRH = str[1] || "", // RH Base part. Make sure we have a RH fraction else ""
    baseLH = str[0]; // LH base part.

  if (power > 0) {
    // ------- Positive Exponents (Process the RH Base Part)
    if (power > baseRH.length) baseRH += "0".repeat(power - baseRH.length); // Pad with "0" at RH
    baseRH = baseRH.slice(0, power) + deciSp + baseRH.slice(power); // Insert decSep at the correct place into RH base
    if (baseRH.charAt(baseRH.length - 1) == deciSp) baseRH = baseRH.slice(0, -1); // If decSep at RH end? => remove it
  } else {
    // ------- Negative Exponents (Process the LH Base Part)
    let num = Math.abs(power) - baseLH.length; // Delta necessary 0's
    if (num > 0) baseLH = "0".repeat(num) + baseLH; // Pad with "0" at LH
    baseLH = baseLH.slice(0, power) + deciSp + baseLH.slice(power); // Insert "." at the correct place into LH base
    if (baseLH.charAt(0) == deciSp) baseLH = "0" + baseLH; // If decSep at LH most? => add "0"
  }
  return sign + baseLH + baseRH; // Return the long number (with sign)
};

export const numberInputOnWheelPreventChange = (e) => {
  // Prevent the input value change
  e.target.blur();

  // Refocus immediately, on the next tick (after the current function is done)
  setTimeout(() => {
    e.target.focus();
  }, 0);
};

export const addThreeDotInString = (str, maxLen) => {
  let length = str.length;
  if (maxLen < length) {
    let size = (maxLen - 3) / 2;
    let newString = str.substring(0, size) + "..." + str.substring(length - size);
    return newString;
  } else {
    return str;
  }
};
